import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { Sun } from '@styled-icons/heroicons-outline/Sun'
import { Moon } from '@styled-icons/boxicons-regular/Moon'
import { setMode } from 'state/applications/actions'

const ThemeModeButton = ({ setIsDark }) => {
  const dispatch = useDispatch()
  const isDark = useSelector(({ application }) => application.isDark)
  return (
    <Container>
      <Mode
        isDark={isDark}
        onClick={() => {
          dispatch(setMode(!isDark))
          setIsDark(!isDark)
        }}
        data-testid="change-mode"
      >
        {isDark ? <LightModeIcon /> : <DarkModeIcon />}
      </Mode>
    </Container>
  )
}

export default ThemeModeButton

const Container = styled.div`
  width: 32px;
`

const Mode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  background-color: ${(props) =>
    props.isDark ? 'rgba(236, 240, 243, 0.1)' : 'rgba(33, 45, 99, 0.08)'};
  cursor: pointer;
`

const LightModeIcon = styled(Sun)`
  width: 20px;
  height: 20px;
  color: white;
`

const DarkModeIcon = styled(Moon)`
  width: 18px;
  height: 18px;
  transform: rotate(-135deg);
`
