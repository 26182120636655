import { useCallback, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ethers } from 'ethers'

import useWallet from 'hooks/useWallet'
import { AccountContext } from 'contexts/AccountProvider'
import { addTransaction } from 'state/transaction/actions'
import { TRANSACTION_STATUS } from 'monox/util'
import { GAS_FACTOR } from 'monox/constants'
import config from 'monox/config'

const useApprove = (onFinishTransaction, onSuccess = () => {}) => {
  const dispatch = useDispatch()
  const { account, chainId } = useWallet()
  const { getToken } = useContext(AccountContext)
  const SWAP_ADDRESS = useSelector(({ network }) => network?.SWAP_ADDRESS)
  const FUTURE_ADDRESS = useSelector(({ network }) => network?.FUTURE_ADDRESS)
  const VCASH_ADDRESS = useSelector(({ network }) => network?.VCASH)?.address
  const STAKED_MONO_ADDRESS = useSelector(({ network }) => network?.dMONO)?.address
  const BOND_STAKING_ADDRESS = config?.[chainId]?.BOND_STAKING_ADDRESS
  const BOND_STAKING_HELPER_ADDRESS = config?.[chainId]?.BOND_STAKING_HELPER_ADDRESS

  const handleApprove = useCallback(
    async (ERC20TokenAddress, payload) => {
      try {
        ERC20TokenAddress = ERC20TokenAddress || VCASH_ADDRESS
        const ERC20Token = await getToken(
          ERC20TokenAddress,
          payload?.isVaultApprove || false
        )
        const searchedTokenSymbol = await ERC20Token?.methods?.symbol().call()
        const updatedPayload = {
          ...payload,
          symbol: searchedTokenSymbol,
        }
        const tx = await ERC20Token?.methods
          ?.approve(
            payload?.isFutureApprove
              ? FUTURE_ADDRESS
              : payload?.isVaultApprove
              ? STAKED_MONO_ADDRESS
              : payload?.isBondStakeApprove
              ? BOND_STAKING_HELPER_ADDRESS
              : payload?.isBondUnstakeApprove
              ? BOND_STAKING_ADDRESS
              : payload?.isBondApprove
              ? payload?.bondAddress
              : SWAP_ADDRESS,
            ethers.constants.MaxUint256.toString()
          )
          .send({
            from: account,
            ...(payload?.gasPrice
              ? { gasPrice: (Number(payload?.gasPrice) * GAS_FACTOR).toFixed(0) }
              : {}),
          })
          .on('transactionHash', function (tx) {
            updatedPayload.tx = tx
            dispatch(addTransaction(updatedPayload))
            return tx
          })
          .on('receipt', function (data) {
            onSuccess()
            dispatch(
              addTransaction({
                ...updatedPayload,
                status: TRANSACTION_STATUS.SUCCESS,
                tx: data.transactionHash,
              })
            )
          })
          .on('error', function (error) {
            let status = TRANSACTION_STATUS.FAIL
            if (
              error.message.includes('User denied transaction signature') ||
              error?.code === 4001
            ) {
              status = TRANSACTION_STATUS.REJECTED
            }
            dispatch(
              addTransaction({
                ...updatedPayload,
                status,
                error,
              })
            )
          })
        onFinishTransaction()
        return tx
      } catch (e) {
        onFinishTransaction()
        if (process.env.REACT_APP_DEV_ENV === 'development') {
          console.log('error: ', e)
        }
        return false
      }
    },
    [getToken, account]
  )

  return { onApprove: handleApprove }
}

export default useApprove
