import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

import { QuestionCircleIcon, QuestionCircleFillIcon } from 'components/IconButton'

const QuestionHelper = ({ text, id, children, translateId }) => {
  const isDark = useSelector(({ application }) => application.isDark)
  const [active, setActive] = useState(false)

  const Tooltip = ({ ...props }) => (
    <StyledTooltip
      multiline
      {...props}
      arrowColor="transparent"
      afterShow={() => setActive(true)}
      afterHide={() => setActive(false)}
      isDark={isDark}
    />
  )

  return (
    <>
      {active ? (
        <QuestionCircleFillIcon pointer data-tip data-for={id} />
      ) : (
        <QuestionCircleIcon pointer data-tip data-for={id} isDark={isDark} />
      )}

      <Tooltip id={id} effect="solid" place="right">
        <div style={{ margin: '10px 15px' }}>
          {translateId ? (
            <FormattedMessage id={translateId} defaultMessage={text ?? children} />
          ) : (
            text ?? children
          )}
        </div>
      </Tooltip>
    </>
  )
}

export default QuestionHelper

const StyledTooltip = styled(ReactTooltip)`
  border-radius: 12px !important;
  padding: 0px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  color: ${(props) =>
    props.isDark ? 'rgba(255,255,255,0.5)' : 'rgba(33, 45, 99, 0.5)'} !important;
  opacity: 1 !important;
  max-width: 250px;
  ${(props) => props.theme.inputFocusBorder}
  box-shadow:${(props) => (props.isDark ? '' : '0 18px 30px 0 #d1d9e6 !important')};
`
