import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { ArrowDropDown } from '@styled-icons/material/ArrowDropDown'

import useWallet from 'hooks/useWallet'

import Label from 'components/Label'
import Spacer from 'components/Spacer'
import Divider from 'components/Divider'
import {
  EXPLORER_FILTER,
  EXPLORER_FILTER_ETH,
  isProdEnv,
  SWAP_ALLOW_CHAINS,
  STAKING_ALLOW_CHAINS,
} from 'monox/constants'

import vault from 'assets/img/security.svg'
import farm from 'assets/img/farm.svg'
import future from 'assets/img/future.svg'
import explore from 'assets/img/explore-outlined.svg'
import chevronRight from 'assets/img/chevron-right-green.svg'

const MoreFooter = ({ isMore, setIsMore, containerRef }) => {
  const networkName = useSelector(({ network }) => network?.NAME)?.toLowerCase()
  const isDark = useSelector(({ application }) => application.isDark)
  const networkId = useSelector(({ network }) => network.id)
  const { chainId } = useWallet()
  const [isExploreOpen, setIsExploreOpen] = useState(false)
  const isSupported = useMemo(() => {
    return SWAP_ALLOW_CHAINS.includes(chainId || networkId)
  }, [chainId, networkId])

  const isFarmSupported = useMemo(() => {
    return STAKING_ALLOW_CHAINS.includes(chainId || networkId)
  }, [chainId, networkId])

  return (
    <Container>
      <DropDown isDark={isDark} ref={containerRef}>
        {isFarmSupported && (
          <StyledLink
            variant="nav"
            to={`/farm?network=${networkName}`}
            style={{ paddingTop: 0 }}
            onClick={() => setIsMore(!isMore)}
          >
            <img src={farm} alt="farm" />
            <Spacer size="sm" />
            <Label
              size="14"
              weight="800"
              text="Farm"
              translateId="common.menu.bar_item.4"
            />
          </StyledLink>
        )}

        {/* <Divider /> */}
        {/* <StyledLink
          variant="nav"
          to={`/vault?network=${networkName}`}
          onClick={() => setIsMore(!isMore)}
        >
          <img src={vault} alt="vault" />
          <Spacer size="sm" />
          <Label size="14" weight="800">
            Vault
          </Label>
        </StyledLink>
        <Divider /> */}
        {/* <StyledLink
          variant="nav"
          to={`/futures?network=${networkName}`}
          onClick={() => setIsMore(!isMore)}
        >
          <img src={future} alt="future" />
          <Spacer size="sm" />
          <Label size="14" weight="800">
            Future
          </Label>
        </StyledLink> */}
        <Divider />
        {isSupported && (
          <ExploreMenu
            onClick={() => setIsExploreOpen(!isExploreOpen)}
            isExploreOpen={isExploreOpen}
          >
            <img src={explore} alt="explore" />
            <Spacer size="sm" />
            <Label size="14" weight="800">
              Explore
            </Label>
            <ArrowDown />
          </ExploreMenu>
        )}

        {isExploreOpen &&
          Object.entries(
            networkName === 'ethereum' ? EXPLORER_FILTER_ETH : EXPLORER_FILTER ?? {}
          ).map(([key, item], index) => (
            <div key={index}>
              <StyledLink
                variant="nav"
                to={`/explore/${key}?network=${networkName}`}
                style={{ padding: '8px 18px 8px 35px' }}
                onClick={() => setIsMore(!isMore)}
              >
                <div>
                  <Label
                    text={item?.name}
                    size="16"
                    weight="800"
                    style={{ marginBottom: '3px' }}
                  />

                  <Label
                    text={item?.short_description?.[0]?.text}
                    size="12"
                    weight="800"
                    opacity="0.5"
                    style={{ lineHeight: 1.08 }}
                  />
                </div>
                <img
                  src={chevronRight}
                  alt="chevron-right"
                  style={{ marginLeft: 'auto' }}
                />
              </StyledLink>
            </div>
          ))}
      </DropDown>
    </Container>
  )
}

export default MoreFooter

const Container = styled.div`
  width: 100%;
  position: fixed;
  bottom: ${({ theme }) => theme.footerSize + 18}px;
  z-index: 10;
`

const DropDown = styled.div`
  margin: 0 20px;
  padding: 25px 20px;
  height: 100%;
  border-radius: 12px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: ${({ isDark }) =>
    isDark ? 'rgba(255, 255, 255, 0.1)' : '#f5f5f8'};
  box-shadow: ${({ theme }) => theme.shadows.footerMenu};
`

const StyledLink = styled(NavLink)`
  cursor: pointer;
  padding: 19px 9px;
  text-decoration: none;
  transition: color 0.2s ease, background-color 0.2s ease;
  display: flex;
  align-items: center;
  margin-right: auto;
  :hover {
    div {
      color: ${({ theme }) => theme.color.font.green};
    }
  }
`
const ExploreMenu = styled.div`
  cursor: pointer;
  padding: 19px 9px ${({ isExploreOpen }) => (isExploreOpen ? 10 : 0)}px 9px;
  text-decoration: none;
  transition: color 0.2s ease, background-color 0.2s ease;
  display: flex;
  align-items: center;
  margin-right: auto;
  :hover {
    div {
      color: ${({ theme }) => theme.color.font.green};
    }
  }
`
const ArrowDown = styled(ArrowDropDown)`
  height: 25px;
  width: 25px;
  margin-left: auto;
  color: ${({ theme }) => theme.color.font.secondary};
`
