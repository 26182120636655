import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'

import useWallet from 'hooks/useWallet'
import { weiToEthNum } from 'monox/constants'
import { uriToHttp } from 'monox/getTokenList'
import config from 'monox/config'
import { precise } from 'monox/util'

import Label from 'components/Label'
import TokenImage from 'components/TokenImage'
import { RowFixed, Row } from 'components/Row'

const Card = ({ title, content, per, translateId }) => {
  const isDark = useSelector(({ application }) => application.isDark)

  return (
    <CardContainer isDark={isDark}>
      <Label
        text={title}
        opacity="0.3"
        size="14"
        weight="800"
        translateId={translateId}
      />
      <Label
        text={content}
        weight="bold"
        size="24"
        style={{ maring: '5px 0 10px 0' }}
      />
      {per > 0 && <Label text={`${per}%`} weight="800" size="16" primary />}
    </CardContainer>
  )
}

const PriceData = ({ pool, currency }) => {
  const networkId = useSelector(({ network }) => network.id)
  const { chainId } = useWallet()
  const vCASHData = config?.[networkId || chainId]?.VCASH
  const decimals = currency?.decimals || 18
  const price = weiToEthNum(BigNumber(pool?.price), 36 - decimals)
  const vcashPrice =
    useSelector(({ application }) => application?.vcashPrice)?.[networkId] || 1

  if (!currency || !price) return <></>

  return (
    <div>
      <Row style={{ marginBottom: '18px' }}>
        <RowFixed>
          <TokenImage
            src={`${
              vCASHData?.address === currency?.address
                ? vCASHData?.logoURI
                : currency?.notInList
                ? undefined
                : uriToHttp(currency?.logoURI)?.[0]
            }`}
            height="30"
            width="30"
            style={{ marginRight: '10px' }}
            letter={currency?.symbol?.[0]}
          />
        </RowFixed>
        <RowFixed style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          <Label
            text={`1 ${currency?.symbol} = ${price ? precise(price, 3) : ''} vUNIT`}
            size="12"
            weight="800"
          />
          <Label
            text={`$${precise(price * vcashPrice, 3)}`}
            size="12"
            weight="800"
            opacity="0.3"
          />
        </RowFixed>
      </Row>
      <Row>
        <RowFixed>
          <TokenImage
            src={`${vCASHData?.logoURI}`}
            height="30"
            width="30"
            style={{ marginRight: '10px' }}
          />
        </RowFixed>
        <RowFixed style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          <Label
            text={`1 vUNIT = ${price ? precise(1 / precise(price, 6), 3) : ''} ${
              currency?.symbol
            }`}
            size="12"
            weight="800"
          />
          <Label
            text={`$${precise(vcashPrice, 6)}`}
            size="12"
            weight="800"
            opacity="0.3"
          />
        </RowFixed>
      </Row>
    </div>
  )
}

const DataCard = ({ currency, pool, poolValue, sharedPercent, data }) => {
  const decimals = currency?.decimals || 18
  const price = weiToEthNum(BigNumber(pool?.price), 36 - decimals)
  const vcashBalance = weiToEthNum(
    BigNumber(pool?.vcashCredit - pool?.vcashDebt),
    decimals
  )
  const poolBalance = poolValue ? weiToEthNum(poolValue) : 0
  const isDark = useSelector(({ application }) => application.isDark)
  const volumn = useMemo(() => {
    if (data) {
      const volumnIn = weiToEthNum(BigNumber(data.VolumeIn))
      const volumnOut = weiToEthNum(BigNumber(data.VolumeOut))
      return volumnIn + volumnOut
    }
    return 0
  }, [data])

  const fee = useMemo(() => {
    if (data) {
      return weiToEthNum(BigNumber(data.fee))
    }
    return 0
  }, [data])

  return (
    <Grid>
      {vcashBalance >= 0 && (
        <Card
          title="Liquidity"
          content={`$${
            poolBalance >= 1
              ? new Intl.NumberFormat().format(precise(poolBalance, 4))
              : precise(poolBalance, 4)
          }`}
          per={precise(sharedPercent, 2)}
          translateId="analytics.token.locked_value"
        />
      )}
      <Card
        title="Volume (24hrs)"
        content={`$${
          volumn >= 1
            ? new Intl.NumberFormat().format(precise(volumn, 4))
            : precise(volumn, 4)
        }`}
        per={0}
        translateId="analytics.token.volume"
      />
      <Card
        title="Fees (24hrs)"
        content={`$${
          fee >= 1
            ? new Intl.NumberFormat().format(precise(fee, 4))
            : precise(fee, 4)
        }`}
        per={0}
        translateId="analytics.token.fees"
      />
      {price !== 0 && (
        <CardContainer isDark={isDark}>
          <PriceData pool={pool} currency={currency} />
        </CardContainer>
      )}
    </Grid>
  )
}

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  justify-content: space-evenly;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
      justify-content:center;
  `};
`
const CardContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-width: 215px;
  width: auto;
  min-height: 134px;
  border-radius: 38px;
  box-shadow: ${({ theme }) => theme.shadows.thin};
  margin: 15px 10px;
  padding: 3px 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 20px;
  `};
  ${(props) => props.isDark && props.theme.darkMode}
`

export default DataCard
