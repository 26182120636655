import { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Web3 from 'web3'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { useHistory } from 'react-router-dom'
import MobileDetect from 'mobile-detect'

import { saveWallet, savePrivateKey } from 'state/users/actions'
import { connectorsByName } from 'monox/connectors'
import config from 'monox/config'
import { networks, DEFAULT_NETWORK_ID, supportedChainIds } from 'monox/constants'

const md = new MobileDetect(window.navigator.userAgent)

const useQuery = (history) => {
  return new URLSearchParams(history?.location?.search)
}

const useWalletHook = () => {
  const privateKey = useSelector(({ user }) => user.privateKey)
  const connector = useSelector(({ user }) => user.wallet)
  const provider = useSelector(({ provider }) => provider.provider)
  const networkId = useSelector(({ network }) => network.id)
  const [isRejected, setIsRejected] = useState(false)
  let { ethereum } = window

  const {
    chainId: web3ChainId,
    account,
    active,
    activate,
    deactivate,
    error,
    library,
    connector: Web3Connector,
  } = useWeb3React()

  const customAccount = privateKey
    ? Web3.utils.toChecksumAddress(provider?.address)
    : account
  let customStatus = privateKey ? true : active
  const customConnector = privateKey ? 'private' : connector
  const customEthereum = privateKey ? provider : library
  const customChainId = privateKey ? networkId : web3ChainId
  const dispatch = useDispatch()
  const history = useHistory()
  const referBy = useQuery(history).get('ref')

  const switchChain = async (chainId, connector = 'injected') => {
    const hexChainId = '0x' + Number(chainId).toString(16)
    const network = networks[chainId]
    if (!config?.[chainId]) return
    const data = [
      {
        chainId: hexChainId,
        chainName: network.name,
        nativeCurrency: {
          name: config?.[chainId].MAIN_CURRENCY.name,
          symbol: config?.[chainId].MAIN_CURRENCY.symbol,
          decimals: 18,
        },
        rpcUrls: [config?.[chainId].NETWORK_URL],
        blockExplorerUrls: [network.blockExplorer],
      },
    ]
    if (ethereum) {
      try {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexChainId }],
        })
        activate(connector)
      } catch (switchErr) {
        if (switchErr?.code !== 4001 && switchErr?.code !== -32002) {
          try {
            setIsRejected(false)
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: data,
            })
            activate(connector)
          } catch (addError) {
            if (process.env.REACT_APP_DEV_ENV === 'development') {
              console.log(addError)
            }
          }
        } else {
          setIsRejected(true)
          dispatch(saveWallet('UnsupportedChainIdError'))
        }
      }
    }
    if (history && typeof history.push === 'function') {
      history.push({
        search: `?network=${network?.name?.toLowerCase()}${
          referBy ? `&ref=${referBy}` : ''
        }`,
      })
    }
  }

  const customConnect = useCallback(
    async (connectorName) => {
      if (privateKey) {
        if (process.env.REACT_APP_DEV_ENV === 'development') {
          console.log('connected')
        }
      } else {
        const connector = connectorsByName[connectorName]
        let conn = typeof connector === 'function' ? await connector() : connector
        // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
        if (
          conn instanceof WalletConnectConnector &&
          conn.walletConnectProvider?.wc?.uri
        ) {
          conn.walletConnectProvider = undefined
        }
        setIsRejected(false)
        conn &&
          activate(conn, undefined, true).catch((error) => {
            if (process.env.REACT_APP_DEV_ENV === 'development') {
              console.log(JSON.stringify(error))
            }
            if (error?.message?.includes('ser rejected')) {
              setIsRejected(true)
              return
            }
            if (
              error?.code === -32002 &&
              (error?.message?.includes('wallet_requestPermissions') ||
                error?.message?.includes('processing'))
            )
              return
            if (error instanceof UnsupportedChainIdError) {
              md.mobile() && activate(conn) // a little janky...can't use setError because the connector isn't set
            }
            if (error?.code === -32002) window.location.reload()
            const chainToConnect = supportedChainIds?.includes(networkId)
              ? networkId
              : DEFAULT_NETWORK_ID
            switchChain(chainToConnect, conn)
          })
      }
    },
    [privateKey]
  )

  const customDisconnect = useCallback(() => {
    if (privateKey) {
      if (process.env.REACT_APP_DEV_ENV === 'development') {
        console.log('disconnected')
      }
      dispatch(savePrivateKey(null))
    } else {
      dispatch(saveWallet(null))
      if (connector === 'walletconnect') {
        localStorage.removeItem('walletconnect')
      }
      return deactivate()
    }
  })

  return {
    account: customAccount,
    status: customStatus,
    ethereum: customEthereum,
    connector: customConnector,
    Web3Connector: Web3Connector,
    chainId: customChainId,
    connect: customConnect,
    isRejected: isRejected,
    disconnect: customDisconnect,
    error,
  }
}

export default useWalletHook
