import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PrivateKeyProvider from 'truffle-privatekey-provider'
import styled from 'styled-components'
import Web3 from 'web3'

import { changeNetwork } from 'state/network/actions'
import { savePrivateKey, savePendingSwitch } from 'state/users/actions'
import { saveProvider } from 'state/provider/actions'
import {
  networks,
  MAINNET_CHAINDS,
  TESTNET_CHAINDS,
  isProdEnv,
} from 'monox/constants'
import config from 'monox/config'
import useWallet from 'hooks/useWallet'
import useModal from 'hooks/useModal'

import Modal from 'components/Modal'
import { Row, RowBetween, RowFixed } from 'components/Row'
import Label from 'components/Label'
import { CloseIcon } from 'components/IconButton'
import Divider from 'components/Divider'
import Spacer from 'components/Spacer'
import StyledIconButton from 'components/StyledIconButton'
import WalletListModal from 'components/WalletListModal'

import check from 'assets/img/check.png'
import darkCheck from 'assets/img/dark-check.svg'

const useQuery = (history) => {
  return new URLSearchParams(history?.location?.search)
}

const NetworkListModal = ({ onDismiss }) => {
  const networkId = useSelector(({ network }) => network.id)
  const wallet = useSelector(({ user }) => user.wallet)
  const isDark = useSelector(({ application }) => application.isDark)
  const privateKey = useSelector(({ user }) => user.privateKey)
  const dispatch = useDispatch()
  const { account, connect } = useWallet()
  const history = useHistory()
  const referBy = useQuery(history).get('ref')
  let { ethereum } = window

  const [handleUnlockClick] = useModal(<WalletListModal />)

  const switchChain = async (chainId) => {
    const hexChainId = '0x' + Number(chainId).toString(16)
    const network = networks[chainId]
    const data = [
      {
        chainId: hexChainId,
        chainName: network.name,
        nativeCurrency: {
          name: config[chainId].MAIN_CURRENCY.name,
          symbol: config[chainId].MAIN_CURRENCY.symbol,
          decimals: 18,
        },
        rpcUrls: [config[chainId].NETWORK_URL],
        blockExplorerUrls: [network.blockExplorer],
      },
    ]
    if (ethereum) {
      try {
        dispatch(savePendingSwitch(true))
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexChainId }],
        })
        setTimeout(() => {
          dispatch(savePendingSwitch(false))
        }, 1500)
      } catch (switchErr) {
        if (switchErr?.code !== 4001) {
          try {
            dispatch(savePendingSwitch(true))
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: data,
            })
          } catch (addError) {
            if (process.env.REACT_APP_DEV_ENV === 'development') {
              console.log(addError)
            }
            setTimeout(() => {
              dispatch(savePendingSwitch(false))
            }, 1500)
          }
        } else {
          console.log(1)
          dispatch(savePendingSwitch(false))
        }
      }
    }
    history.push({
      search: `?network=${network?.name?.toLowerCase()}${
        referBy ? `&ref=${referBy}` : ''
      }`,
    })
  }

  const handleNetworkClick = (id) => {
    dispatch(changeNetwork({ network: id, networkDetails: config[id] }))
    if (id !== networkId && account) {
      switchChain(id)
    } else if (id !== networkId) {
      history.push({
        search: `?network=${networks[id]?.name?.toLowerCase()}${
          referBy ? `&ref=${referBy}` : ''
        }`,
      })
    }
    if (privateKey) {
      const provider = new PrivateKeyProvider(privateKey, config[id].NETWORK_URL)
      dispatch(savePrivateKey({ id: id, privateKey: privateKey }))
      dispatch(saveProvider(provider))
    } else {
      const new_provider = !!config[id].WSS_URL
        ? new Web3.providers.WebsocketProvider(config[id].WSS_URL, {
            reconnect: {
              auto: true,
              delay: 3000, // ms
              maxAttempts: 10,
              onTimeout: false,
            },
          })
        : new Web3.providers.HttpProvider(config[id].NETWORK_URL)
      dispatch(savePrivateKey({ id: id, privateKey: undefined }))
      dispatch(saveProvider(new_provider))
      !!wallet && connect(wallet)
    }
    onDismiss()
  }

  return (
    <Modal>
      <RowBetween style={{ alignItems: 'center' }}>
        <RowFixed>
          <Label
            text="Switch Network"
            size="16px"
            weight="800"
            translateId="modal.common.network.title"
          />
        </RowFixed>
        <RowFixed style={{ alignItems: 'center' }}>
          <RowFixed>
            <CloseIcon onClick={onDismiss} data-testid="dismiss" />
          </RowFixed>
        </RowFixed>
      </RowBetween>
      <Spacer size="xs" />
      <Divider />
      {MAINNET_CHAINDS?.length > 0 && (
        <>
          <Spacer size="xs" />
          <Label
            size={14}
            weight={800}
            translateId="modal.common.network.mainnets"
            text="Mainnets"
          />
        </>
      )}
      <Grid>
        {Object.values(networks)
          ?.filter((item) => {
            if (MAINNET_CHAINDS?.includes(item?.chainId)) return true
            else return false
          })
          ?.map((network, index) => (
            <div key={index}>
              <NetworkRow
                onClick={() => handleNetworkClick(network?.chainId)}
                active
                data-testid={network?.network}
              >
                <NetworkImg src={network?.image} alt={network?.network} />
                <Label
                  text={network?.name}
                  size="14"
                  weight="800"
                  color={!isDark ? network?.fontColor : ''}
                />
                {networkId === network?.chainId ? (
                  <img
                    className="check"
                    src={isDark ? darkCheck : check}
                    alt="check"
                    style={{ marginLeft: 'auto' }}
                  />
                ) : null}
              </NetworkRow>
              <Spacer size="xs" />
            </div>
          ))}
      </Grid>
      {!isProdEnv && (
        <>
          <Spacer />
          <Label
            size={14}
            weight={800}
            translateId="modal.common.network.testnets"
            text="Testnets"
          />
          <Grid>
            {Object.values(networks)
              ?.filter((item) => {
                if (TESTNET_CHAINDS?.includes(item?.chainId)) return true
                else return false
              })
              ?.map((network, index) => (
                <div key={index}>
                  <NetworkRow
                    onClick={() => handleNetworkClick(network?.chainId)}
                    active
                    data-testid={network?.network}
                  >
                    <NetworkImg src={network?.image} alt={network?.network} />
                    <Label
                      text={network?.name}
                      size="14"
                      weight="800"
                      color={!isDark ? network?.fontColor : ''}
                    />
                    {networkId === network?.chainId ? (
                      <img
                        className="check"
                        src={isDark ? darkCheck : check}
                        alt="check"
                        style={{ marginLeft: 'auto' }}
                      />
                    ) : null}
                  </NetworkRow>
                  <Spacer size="xs" />
                </div>
              ))}
          </Grid>
        </>
      )}
      {account ? (
        <StyledIconButton
          block
          icon="arrow"
          variant="primary"
          onClick={onDismiss}
          modal={true}
          translateId="modal.common.network.button"
          testId="close"
        >
          Close
        </StyledIconButton>
      ) : (
        <StyledIconButton
          block
          icon="arrow"
          variant="primary"
          onClick={handleUnlockClick}
          modal={true}
          translateId="common.wallet.button.not_connected"
          testId="close"
        >
          Connect Wallet
        </StyledIconButton>
      )}
      <Spacer />
    </Modal>
  )
}

export default NetworkListModal

const NetworkRow = styled(Row)`
  border-radius: 27px;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  margin-left:${(props) => props.right && 'auto'}
  width: 170px;
  height: 50px;
  align-items: center;
  cursor: ${(props) => props.active && 'pointer'};
  padding: 9px 16px;
  background-color: rgba(255, 255, 255, 0.08);
  box-shadow: ${({ theme }) => theme.shadows.button};
  div {
    margin-right: 12px;
  }
  .check {
    width: 18px;
    height: 18px;
  }
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  margin-top: 15px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  grid-template-columns: repeat(1, 1fr);
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  flex-direction:column;`}
`
const NetworkImg = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin-right: 12px;
`
