import { useCallback, useContext, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { matchSorter } from 'match-sorter'
import uniqBy from 'lodash/uniqBy'

import useWallet from 'hooks/useWallet'
import useContributedPoolList from 'hooks/useContributedPoolList'
import { AccountContext } from 'contexts/AccountProvider'
import uniswapTokens from 'monox/uniswap_all_tokens_list'
import { isAddress } from 'monox/constants'
import config from 'monox/config'

const useSearchToken = (
  filterByChainId = true,
  tokensListWithStatus = [],
  fetchContributedPool = false
) => {
  const { getToken } = useContext(AccountContext)
  const { chainId, account } = useWallet()
  const networkId = useSelector(({ network }) => network.id)
  const poolList =
    useSelector(({ user }) => user?.activePools?.[networkId || chainId]) || []
  const { getActivePoolData } = useContributedPoolList(false)

  const MONOData = config?.[networkId || chainId]?.MONO
  const vCASHData = config?.[networkId || chainId]?.VCASH

  const MAIN_CURRENCY = config?.[networkId || chainId]?.MAIN_CURRENCY
  const tokenList = useMemo(() => uniqBy(uniswapTokens.tokens, 'address'), [])
  const filteredTokenList = useMemo(
    () =>
      uniqBy(
        uniswapTokens.tokens.filter((t) => t.chainId === (networkId || chainId)),
        'address'
      ),
    [networkId, chainId]
  )
  const tokens = useSelector(({ user }) => user.tokens)

  const currentChainTokens = tokens[chainId] ?? {}

  const filteredTokens = useMemo(
    () => (filterByChainId ? filteredTokenList : tokenList),
    [filterByChainId, chainId, networkId]
  )

  useEffect(() => {
    if (account && !poolList) {
      getActivePoolData()
    }
  }, [account])

  const onGetToken = useCallback(
    async (address, onlySearchTokenList) => {
      const isAddressSearch = isAddress(address)
      const userTokens = Object.values(currentChainTokens)
      const data = tokensListWithStatus.length
        ? tokensListWithStatus
        : [...filteredTokens, ...userTokens, MAIN_CURRENCY, vCASHData, MONOData]
      poolList.forEach(async (pool) => {
        const index = data.findIndex((token) => token?.address === pool?.token)
        if (index === -1 && pool?.token !== MONOData?.address)
          if (pool?.name && pool?.symbol) {
            data.push({
              name: pool?.name,
              symbol: pool?.symbol,
              status: pool?.status,
              logoURI: pool?.logoURI,
              notInList: true,
              chainId: networkId || chainId,
              address: pool?.token,
              decimals: parseInt(pool?.length),
            })
          } else {
            const searchedToken = await getToken(pool?.token)
            const searchedTokenName = await searchedToken?.methods?.name().call()
            const searchedTokenSymbol = await searchedToken?.methods?.symbol().call()
            const searchedTokenDecimals = await searchedToken?.methods
              ?.decimals()
              .call()
            data.push({
              name: searchedTokenName,
              symbol: searchedTokenSymbol,
              logoURI: undefined,
              address: searchedToken?._address,
              notInList: true,
              chainId: networkId || chainId,
              status: 1,
              decimals: searchedTokenDecimals,
            })
          }
      })

      const keys = isAddressSearch
        ? ['address', 'symbol', 'name']
        : ['symbol', 'name']
      const existedData = matchSorter(data, address, {
        keys,
      })

      if (existedData && existedData.length > 0) {
        return uniqBy(existedData, 'address')
      }
      if (onlySearchTokenList) {
        return []
      }

      try {
        if (!isAddressSearch) return []
        const searchedToken = await getToken(address)
        if (searchedToken) {
          const fromList = [...filteredTokenList, vCASHData, MONOData].find(
            (token) => token?.address === address
          )
          if (fromList) {
            return []
          }
          const searchedTokenName = await searchedToken?.methods?.name().call()
          const searchedTokenSymbol = await searchedToken?.methods?.symbol().call()
          const searchedTokenDecimals = await searchedToken?.methods
            ?.decimals()
            .call()
          return [
            {
              name: searchedTokenName,
              symbol: searchedTokenSymbol,
              decimals: searchedTokenDecimals,
              address: searchedToken?._address,
              notInList: true,
              status: 1,
              chainId: networkId || chainId,
              showWarning: true,
            },
          ]
        }
        return []
      } catch (err) {
        if (process.env.REACT_APP_DEV_ENV === 'development') {
          console.log('onGetToken error: ', err)
        }
        return {
          isInvalidToken: true,
          message: 'Invalid token address in this network',
        }
      }
    },
    [getToken, tokensListWithStatus, poolList, chainId, networkId]
  )

  return {
    onGetToken,
    filteredTokenList: [...filteredTokenList, vCASHData, MONOData],
  }
}

export default useSearchToken
