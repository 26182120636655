import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { useHistory, useParams } from 'react-router-dom'
import Spinner from 'react-svg-spinner'
import { FormattedMessage } from 'react-intl'

import useWallet from 'hooks/useWallet'
import useModal from 'hooks/useModal'
import useSearchToken from 'hooks/useSearchToken'
import useSwapToken from 'hooks/useSwapToken'
import useTokenBalance from 'hooks/useTokenBalance'
import usePool from 'hooks/usePool'
import useContributedPoolList from 'hooks/useContributedPoolList'
import { addTransaction } from 'state/transaction/actions'
import useApprove from 'hooks/useApprove'
import { AccountContext } from 'contexts/AccountProvider'
import {
  ubetToEth,
  weiToEthNum,
  weiToEthLongString,
  DELAY_MS,
  DISPLAY_MIN_VALUE,
  getGasPrice,
} from 'monox/constants'
import {
  precise,
  poolValue,
  showToast,
  TRANSACTION_STATUS,
  ACTIONS,
} from 'monox/util'
import config from 'monox/config'
import { setSwapTolerance, setSwapDeadline } from 'state/settings/action'
import { updateSwapTransaction } from 'state/swap/actions'

import SwapWidget from 'views/Swapper/components/SwapWidget'
import CurrencySelector from 'views/Swapper/components/CurrencySelector'
import WalletListModal from 'components/WalletListModal'
import ImportTokenModal from 'components/ImportTokenModal'
import ConfirmOrderModal from 'components/ConfirmOrderModal'
import Spacer from 'components/Spacer'
import StyledIconButton from 'components/StyledIconButton'
import { StyledSwapIcon, StyledSettingIcon } from 'components/StyledIcon'
import PriceInput from 'components/PriceInput'
import TransactionSettingModal from 'components/TransactionSettingModal'
import NoPoolModal from 'components/NoPoolModal/NoPoolModal'
import Label from 'components/Label'
import { StyledSwapDoubleIcon } from 'components/StyledIcon'
import { Row } from 'components/Row'

import WarningImg from 'assets/img/alert-warning.svg'

import { getReferralId } from 'api'

const SwapperCard = ({
  toCurrency,
  setToCurrency,
  fromCurrency,
  setFromCurrency,
  isDropdown,
  setIsDropdown,
  isSwapped,
  setIsSwapped,
  fromPoolData,
  toPoolData,
  poolLoading,
  fromPoolLoading = false,
  toPoolLoading = false,
  setLoading,
  isSwitching,
  setSwitching,
  handleSwapItems,
  isSwapDisable,
  exactAmount,
  setExactAmount,
  fromAmount,
  setFromAmount,
  toAmount,
  setToAmount,
  swapRef,
}) => {
  const { account, chainId, ethereum } = useWallet()
  const wallet = useSelector(({ user }) => user.wallet)
  const networkId = useSelector(({ network }) => network.id)
  const MAIN_CURRENCY = config?.[networkId || chainId]?.MAIN_CURRENCY
  const WRAPPED_MAIN_ADDRESS = config?.[networkId || chainId]?.WRAPPED_MAIN_ADDRESS
  const VCASH_ADDRESS = config?.[networkId || chainId]?.VCASH?.address

  const {
    pool,
    getPoolData,
    poolLoading: poolDataLoading,
    insurance,
  } = usePool(
    fromCurrency?.address || (!!fromCurrency?.symbol && WRAPPED_MAIN_ADDRESS),
    0,
    fromCurrency?.chainId
  )

  const fromPoolPrice = weiToEthNum(
    BigNumber(pool?.price),
    36 - fromCurrency?.decimals
  )
  const vcashDebt = weiToEthNum(BigNumber(pool?.vcashDebt))
  const vcashBalance =
    pool?.status !== '2' && insurance && vcashDebt > insurance
      ? 0
      : pool?.status !== '2' && insurance
      ? precise(
          insurance + weiToEthNum(BigNumber(pool?.vcashCredit - pool?.vcashDebt)),
          4
        )
      : precise(weiToEthNum(BigNumber(pool?.vcashCredit - pool?.vcashDebt)), 4)
  const contributedPoolList =
    useSelector(({ user }) => user?.activePools?.[networkId || chainId]) || []

  const { loading: contributedPoolListLoading, getActivePoolData } =
    useContributedPoolList(false)

  const vcashPrice =
    useSelector(({ application }) => application?.vcashPrice)?.[networkId] || 1

  const { getAllowance, swapRouterContract, infuraContract } =
    useContext(AccountContext)

  const { onGetToken } = useSearchToken()
  const dispatch = useDispatch()
  const { onGetAmountIn, onGetAmountOut } = useSwapToken()
  const tolerance = useSelector(({ settings }) => settings.swapTolerance)
  const deadline = useSelector(({ settings }) => settings.swapDeadline)
  const { address1, address2 } = useParams()
  const history = useHistory()
  const transactions = useSelector((state) => state.transactions)
  const { isUpdated } = useSelector((state) => state.application)
  const isDark = useSelector(({ application }) => application.isDark)
  const networkName = useSelector(({ network }) => network?.NAME)?.toLowerCase()
  const { onApprove } = useApprove(
    () => setApproving(false),
    () => setIsApproveSuccess(true)
  )
  const [code, setCode] = useState(null)
  const [fromRealAmount, setFromRealAmount] = useState('')
  const [tradeVcash, setTradeVcash] = useState(0)
  const [toRealAmount, setToRealAmount] = useState('')
  const [contractError, setContractError] = useState(false)
  const [liquidityError, setLiquidityError] = useState(false)
  const [pendingVcashValue, setPendingVcashValue] = useState(0)
  const [calculating, setCalculating] = useState(false)
  const [scError, setSCError] = useState(false)
  const [allowance, setAllowance] = useState(null)
  const [isApproving, setApproving] = useState(false)
  const [lessThanIndex, setLessThanIndex] = useState(0)
  const [hoveredImpact, setHoveredImpact] = useState(false)
  const [isSwap, setIsSwap] = useState(false)
  const [isApproveSuccess, setIsApproveSuccess] = useState(false)

  const { balance: fromBalance, fetchBalance: fetchFromBalance } =
    useTokenBalance(fromCurrency)

  const { balance: toBalance, fetchBalance: fetchToBalance } =
    useTokenBalance(toCurrency)

  useEffect(() => {
    if (!fromAmount) {
      setContractError(false)
      setLiquidityError(false)
    }
  }, [fromAmount])

  useEffect(() => {
    if (account) {
      getActivePoolData()
    }
  }, [account])

  useEffect(() => {
    if (account) {
      getReferralId({ referrer: account }, chainId).then((res) => {
        setCode(res?.id)
      })
    }
  }, [account])

  useEffect(() => {
    if (!poolDataLoading) {
      const swapTxs = transactions[chainId]?.filter((tx) => tx.type === ACTIONS.SWAP)
      if (swapTxs?.length > 0) {
        const lastTx = swapTxs[swapTxs.length - 1]
        if (lastTx?.status === TRANSACTION_STATUS.SUCCESS && chainId) {
          setPendingVcashValue(0)
        }
      }
    }
  }, [poolDataLoading])

  useEffect(() => {
    setContractError(false)
    setLiquidityError(false)
    setFromAmount('')
    setFromRealAmount('')
    setToAmount('')
    setToRealAmount('')
  }, [fromCurrency])

  useEffect(() => {
    setContractError(false)
    setLiquidityError(false)
    setFromAmount('')
    setFromRealAmount('')
    setToAmount('')
    setToRealAmount('')
  }, [toCurrency])

  useEffect(() => {
    if (
      fromPoolData &&
      fromPoolData?.price === '0' &&
      fromCurrency?.address !== VCASH_ADDRESS &&
      !isSwitching &&
      fromCurrency?.chainId === (networkId || chainId) &&
      !fromPoolLoading
    ) {
      handleNoPoolModal()
      setFromCurrency(null)
      history.push(`/swap${address2 ? `/${address2}` : ''}?network=${networkName}`)
      setLoading(false)
    }
  }, [fromPoolData])

  useEffect(() => {
    if (
      toPoolData &&
      toPoolData?.price === '0' &&
      toCurrency?.address !== VCASH_ADDRESS &&
      !isSwitching &&
      toCurrency?.chainId === (networkId || chainId) &&
      !toPoolLoading
    ) {
      handleNoPoolModal()
      setToCurrency(null)
      history.push(`/swap${address1 ? `/${address1}` : ''}?network=${networkName}`)
      setLoading(false)
    }
  }, [toPoolData])

  const close = () => {
    setIsDropdown(false)
  }

  const isWrap = () =>
    !fromCurrency?.address &&
    toCurrency?.address?.toLowerCase() === WRAPPED_MAIN_ADDRESS?.toLowerCase()

  const isUnWrap = () =>
    fromCurrency?.address?.toLowerCase() === WRAPPED_MAIN_ADDRESS?.toLowerCase() &&
    !toCurrency?.address

  const [handleImportTokenModal] = useModal(<ImportTokenModal />)
  const [handleNoPoolModal] = useModal(<NoPoolModal />)

  const setToken1 = useCallback(async () => {
    let address = address1
    let flag = false
    const tokens = await onGetToken(address, flag)

    if (tokens?.isInvalidToken) {
      setFromCurrency(null)
      !address2 && setToCurrency(null)
      return
    }

    const ts =
      tokens.filter(
        (token) =>
          (chainId && token?.chainId === chainId) ||
          (!chainId && token?.chainId === networkId)
      ) || []

    const findIndex = ts.findIndex((item) => !!item.logoURI)

    if ((ts && ts.length > 0) || address === MAIN_CURRENCY?.symbol) {
      const index = findIndex > 0 ? findIndex : 0
      const pool = ts[index]?.address
        ? await swapRouterContract?.methods?.pools(ts[index]?.address).call()
        : null
      if (
        (pool && pool?.status !== '0' && pool?.price !== '0') ||
        ts[index].address === VCASH_ADDRESS ||
        !ts[index]?.address
      ) {
        const fromHomePage = history.location?.state?.fromHomePage
        const fromExplorePage = history.location?.state?.fromExplorePage
        const setAsReceiving = history.location?.state?.setAsReceiving

        const fromContributedList = contributedPoolList?.find(
          (token) => token?.token === ts[index]?.address
        )

        if (
          ts[index]?.notInList &&
          ts[index]?.showWarning &&
          contributedPoolList?.length > 0 &&
          !fromContributedList
        ) {
          let setCurrency
          if (fromHomePage || fromExplorePage || setAsReceiving) {
            setCurrency = setToCurrency
          } else {
            setCurrency = setFromCurrency
          }
          handleImportTokenModal({ currrency: ts[index], close, setCurrency })
          return
        }
        if (fromHomePage || fromExplorePage || setAsReceiving) {
          !contributedPoolListLoading && setToCurrency(ts[index])
        } else {
          if (!address2 && !fromCurrency && toCurrency) {
            !contributedPoolListLoading && setToCurrency(ts[index])
            return
          }
          !contributedPoolListLoading && setFromCurrency(ts[index])
        }
      } else {
        handleNoPoolModal()
        history.push(`/swap${address2 ? `/${address2}` : ''}?network=${networkName}`)
      }
    } else {
      history.push(`/swap${address2 ? `/${address2}` : ''}?network=${networkName}`)
    }
  }, [address1, onGetToken, history, networkId])

  const setToken2 = useCallback(async () => {
    let address = address2
    let flag = false
    if (address1 !== address2) {
      const tokens2 = await onGetToken(address, flag)
      if (tokens2?.isInvalidToken || tokens2?.length === 0) {
        setToCurrency(null)
        return
      }
      const ts2 =
        tokens2.filter(
          (token) =>
            (chainId && token?.chainId === chainId) ||
            (!chainId && token?.chainId === networkId)
        ) || []
      const findIndex = ts2.findIndex((item) => !!item.logoURI)
      if ((ts2 && ts2.length > 0) || address === MAIN_CURRENCY?.symbol) {
        const index = findIndex > 0 ? findIndex : 0
        const pool = ts2[index]?.address
          ? await swapRouterContract?.methods?.pools(ts2[index]?.address).call()
          : null
        if (
          (pool && pool?.status !== '0' && pool?.price !== '0') ||
          ts2[index]?.address === VCASH_ADDRESS ||
          !ts2[index]?.address
        ) {
          const fromContributedList = contributedPoolList.find(
            (token) => token?.token === ts2[index]?.address
          )
          if (
            ts2[index]?.notInList &&
            ts2[index]?.showWarning &&
            contributedPoolList?.length > 0 &&
            !fromContributedList
          ) {
            handleImportTokenModal({
              currrency: ts2[index],
              close,
              setCurrency: setToCurrency,
            })
            return
          }
          !contributedPoolListLoading && setToCurrency(ts2[index])
        } else if (ts2[index]?.address !== VCASH_ADDRESS) {
          handleNoPoolModal()
          history.push(`/swap/${address1 ? address1 : ''}?network=${networkName}`)
        }
      } else if (address1?.toLowerCase() !== 'vunit') {
        history.push(`/swap/${address1 ? address1 : ''}?network=${networkName}`)
      } else {
        history.push(`/swap/vUNIT?network=${networkName}`)
      }
    }
  }, [address2, onGetToken, networkId])

  useEffect(() => {
    if (
      address1 &&
      (!isDropdown || fromCurrency?.chainId !== (networkId || chainId)) &&
      swapRouterContract &&
      contributedPoolList
    ) {
      if (
        chainId == MAIN_CURRENCY?.chainId ||
        (!wallet && !contributedPoolListLoading)
      ) {
        setToken1()
      }
    }
  }, [
    address1,
    networkId,
    isDropdown,
    swapRouterContract,
    contributedPoolList,
    contributedPoolListLoading,
  ])

  useEffect(() => {
    if (
      address2 &&
      (!isDropdown || toCurrency) &&
      swapRouterContract &&
      contributedPoolList
    ) {
      if (
        chainId == MAIN_CURRENCY?.chainId ||
        (!wallet && !contributedPoolListLoading)
      ) {
        setToken2()
      }
    }
  }, [
    address2,
    networkId,
    isDropdown,
    swapRouterContract,
    contributedPoolList,
    contributedPoolListLoading,
  ])

  useEffect(() => {
    if (chainId) {
      setSwitching(0)
    }
  }, [chainId])

  useEffect(() => {
    const calAllowance = async () => {
      const res = await getAllowance(fromCurrency?.address, true)
      setAllowance(res)
    }

    if (fromCurrency?.address) {
      calAllowance()
    }
  }, [fromCurrency])

  useEffect(() => {
    if (fromPoolData && toPoolData && fromPoolData?.token !== toPoolData?.token) {
      handleChangeAmount()
    }
  }, [fromPoolData, toPoolData])

  useEffect(() => {
    const getAllowanceCallback = async () => {
      const allowance = await getAllowance(fromCurrency?.address, true)
      setAllowance(Number(allowance))
    }
    if (transactions[chainId]) {
      const savedTx = transactions[chainId]?.find(
        (tx) => tx.type === ACTIONS.APPROVE
      )
      if (savedTx && savedTx?.status === TRANSACTION_STATUS.SUCCESS) {
        getAllowanceCallback()
        setApproving(false)
        !savedTx?.isChecked &&
          dispatch(
            addTransaction({
              ...savedTx,
              isChecked: true,
            })
          )
      }
      const swapTxs = transactions[chainId]?.filter((tx) => tx.type === ACTIONS.SWAP)
      if (swapTxs?.length > 0) {
        const lastTx = swapTxs[swapTxs.length - 1]
        if (
          lastTx?.status === TRANSACTION_STATUS.PENDING &&
          fromCurrency?.address === lastTx?.tradeToken
        ) {
          setPendingVcashValue(lastTx?.tradeVcash)
        }
        if (
          lastTx?.status === TRANSACTION_STATUS.SUCCESS &&
          chainId &&
          Date.now() - lastTx.confirmedTime < DELAY_MS
        ) {
          getPoolData()
        }
      }
    }
  }, [transactions, isUpdated, fromCurrency])

  const checkValuable = (value = null, isFromAmount = true) => {
    if (
      !fromCurrency ||
      (!fromPoolData && (!isFromAmount ? value : toAmount) && !fromPoolLoading)
    ) {
      if (!fromAmount && !value) {
        return false
      }
      showToast(
        <FormattedMessage
          id="toast.swap.select.from.currency"
          defaultMessage="Please select the token you’d like to swap from."
        />,
        {
          toastId: 'fromCurrency',
        }
      )
      return false
    }
    if (
      !toCurrency ||
      (!toPoolData && (isFromAmount ? value : fromAmount) && !toPoolLoading)
    ) {
      if (!value && !toAmount) {
        return false
      }
      showToast(
        <FormattedMessage
          id="toast.swap.select.to.currency"
          defaultMessage="Please select the token you’d like to swap to."
        />,
        {
          toastId: 'toCurrency',
        }
      )
      return false
    }
    if (
      fromCurrency?.address &&
      fromCurrency?.address !== VCASH_ADDRESS &&
      fromPoolData?.status === '0' &&
      fromPoolData?.price !== '0'
    ) {
      showToast(
        <FormattedMessage
          id="toast.swap.from.pool.unavailable"
          defaultMessage={`You should create a pool before using ${fromCurrency?.symbol} token`}
          values={{ symbol: fromCurrency?.symbol }}
        />,
        {
          toastId: 'fromCurrencyPool',
        }
      )
      return false
    }
    if (
      toCurrency?.address &&
      toCurrency?.address !== VCASH_ADDRESS &&
      toPoolData?.status === '0' &&
      toPoolData?.price !== '0'
    ) {
      showToast(
        <FormattedMessage
          id="toast.swap.to.pool.unavailable"
          defaultMessage={`You should create a pool before using ${toCurrency?.symbol} token`}
          values={{ symbol: toCurrency?.symbol }}
        />,
        {
          toastId: 'toCurrencyPool',
        }
      )
      return false
    }
    return true
  }

  const getAmountOut = async (amount, currency = null) => {
    if (!parseFloat(amount)) {
      setToAmount('')
      return
    }
    setCalculating(true)
    setLessThanIndex(0)
    const res = await onGetAmountOut(currency || fromCurrency, toCurrency, amount)
    if (res) {
      const amountOut = BigNumber(res?.amountOut)
        .div(10 ** toCurrency?.decimals)
        .toNumber()
      const tradeVcashValue = BigNumber(res?.tradeVcashValue)
        .div(10 ** 18)
        .toNumber()
      const fromVcashCredit =
        pool?.status !== '2' && insurance
          ? precise(
              insurance +
                weiToEthNum(BigNumber(pool?.vcashCredit - pool?.vcashDebt)),
              4
            )
          : BigNumber(pool?.vcashCredit)
              .div(10 ** 18)
              .toNumber()
      setTradeVcash(tradeVcashValue)
      const decimals = toCurrency?.decimals || 18
      const priceData = weiToEthNum(BigNumber(toPoolData?.price), 36 - decimals)
      const tokenBalanceData = weiToEthNum(
        BigNumber(toPoolData?.tokenBalance),
        decimals
      )
      const poolBalance = poolValue(0, tokenBalanceData, priceData)
      if (amountOut < DISPLAY_MIN_VALUE && amountOut !== 0) {
        setLessThanIndex(2)
        setToAmount(DISPLAY_MIN_VALUE)
      } else {
        setLessThanIndex(0)
        setToAmount(precise(amountOut, 6))
      }
      setToRealAmount(
        amountOut > DISPLAY_MIN_VALUE
          ? precise(amountOut, 6)
          : weiToEthLongString(BigNumber(res?.amountOut), toCurrency?.decimals, 6)
      )
      setCalculating(false)
      setSCError(false)
      if (
        tradeVcashValue * 1.003 > poolBalance &&
        toPoolData?.token === WRAPPED_MAIN_ADDRESS
      ) {
        setLiquidityError(true)
        return
      } else if (
        amountOut > tokenBalanceData &&
        toCurrency?.address !== VCASH_ADDRESS
      ) {
        setLiquidityError(true)
        return
      } else {
        setLiquidityError(false)
      }
      if (
        tradeVcashValue * 1.003 > fromVcashCredit - pendingVcashValue &&
        fromCurrency?.address !== VCASH_ADDRESS &&
        pool?.status !== '2'
      ) {
        setContractError(true)
        return
      }
      setExactAmount(false)
      setContractError(false)
    } else {
      setToAmount(0)
      setContractError(true)
      setCalculating(false)
      if (!fromCurrency) {
        showToast(
          <FormattedMessage
            id="toast.swap.lack.to.balance"
            defaultMessage="Token balance is not enough. Please add some liquidity on the pool page."
          />,
          {
            toastId: 'toLiquidity',
          }
        )
      } else {
        setSCError(true)
      }
    }
    if (pool?.status !== '2' && vcashDebt > insurance) {
      setContractError(true)
    }
  }

  const getAmountIn = async (amount, currency = null) => {
    if (!parseFloat(amount)) {
      setFromAmount('')
      return
    }
    setCalculating(true)
    setLessThanIndex(0)
    const res = await onGetAmountIn(fromCurrency, currency || toCurrency, amount)
    if (res) {
      const amountIn = BigNumber(res?.amountIn)
        .div(10 ** fromCurrency?.decimals)
        .toNumber()
      const tradeVcashValue = BigNumber(res?.tradeVcashValue)
        .div(10 ** 18)
        .toNumber()
      const fromVcashCredit =
        pool?.status !== '2' && insurance
          ? precise(
              insurance +
                weiToEthNum(BigNumber(pool?.vcashCredit - pool?.vcashDebt)),
              4
            )
          : BigNumber(pool?.vcashCredit)
              .div(10 ** 18)
              .toNumber()
      setTradeVcash(tradeVcashValue)
      if (amountIn < DISPLAY_MIN_VALUE && amountIn !== 0) {
        setLessThanIndex(1)
        setFromAmount(DISPLAY_MIN_VALUE)
      } else {
        setLessThanIndex(0)
        setFromAmount(precise(amountIn, 6))
      }
      setFromRealAmount(
        amountIn > DISPLAY_MIN_VALUE
          ? precise(amountIn, 6)
          : weiToEthLongString(BigNumber(res?.amountIn), fromCurrency?.decimals, 6)
      )
      setCalculating(false)
      setSCError(false)
      if (
        tradeVcashValue * 1.003 > fromVcashCredit - pendingVcashValue &&
        fromCurrency.address !== VCASH_ADDRESS &&
        pool?.status !== '2'
      ) {
        setContractError(true)
        return
      }
      setExactAmount(true)
      setContractError(false)
    } else {
      setFromAmount(0)
      setContractError(true)
      setCalculating(false)
      if (!fromCurrency) {
        showToast(
          <FormattedMessage
            id="toast.swap.lack.from.balance"
            defaultMessage="Token balance is not enough. Please add some liquidity on the pool page."
          />,
          {
            toastId: 'fromLiquidity',
          }
        )
      } else {
        setSCError(true)
      }
      if (pool?.status !== '2' && vcashDebt > insurance) {
        setContractError(true)
      }
    }
  }

  const toggleToList = () => {
    handleToCurrencySelector()
  }

  const toggleFromList = () => {
    handleFromCurrencySelector()
  }

  const handleFinishTransaction = () => {
    setFromAmount(0)
    setToAmount(0)
    fetchFromBalance()
    fetchToBalance()
  }

  const handleClearInputs = () => {
    setFromAmount(0)
    setToAmount(0)
  }

  const handleConfirmOrder = async () => {
    dispatch(
      updateSwapTransaction({
        isPerforming: false,
        isRejected: false,
        successResult: undefined,
        successEnded: false,
        status: undefined,
      })
    )
    if (Number(fromAmount) > weiToEthNum(fromBalance, fromCurrency?.decimals)) {
      showToast(
        <FormattedMessage
          id="toast.swap.insufficient_balance"
          defaultMessage="Insufficient balance"
        />,
        { toastId: 'balance' }
      )
      return
    }
    if ((fromCurrency?.symbol && !fromCurrency?.address) || isUnWrap()) {
      onPresentConfirmOrderModal()
      return
    }
    if (allowance && weiToEthNum(BigNumber(allowance)) > fromAmount) {
      onPresentConfirmOrderModal()
    } else {
      try {
        const gasPrice = ethereum ? await getGasPrice(ethereum) : 0
        const tempTransactionTime = +new Date()
        const payload = {
          type: ACTIONS.APPROVE,
          status: TRANSACTION_STATUS.PENDING,
          startTime: tempTransactionTime,
          chainId,
          isChecked: false,
          tx: undefined,
          gasPrice,
        }
        setApproving(true)
        onApprove(fromCurrency?.address, payload)
      } catch (e) {
        if (process.env.REACT_APP_DEV_ENV === 'development') {
          console.log(e)
        }
      }
    }
  }

  const handleChangeAmount = () => {
    if (!exactAmount) {
      handleChangeFromAmount(fromAmount, true)
    } else {
      handleChangeToAmount(toAmount, true)
    }
  }

  const handleChangeFromAmount = (value, isChecking = false) => {
    if (typeof value == 'number') {
      setFromAmount(value)
    } else if (!value || value?.match(/^\d{0,}(\.\d{0,18})?$/)) {
      if (value === '.') {
        setFromAmount(value)
        return
      } else if (value && value.slice(-1) === '.') {
        setFromAmount(`${parseFloat(value?.slice(0, value?.length - 1))}.`)
      } else {
        if (
          value &&
          parseFloat(value) < 1 &&
          parseFloat(value) !== value &&
          value?.slice(-1) !== '0'
        ) {
          setFromAmount(
            parseFloat(value)
              ?.toFixed(value.length - 1)
              ?.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1')
          )
        } else {
          setFromAmount(
            typeof value == 'number'
              ? value
              : value === '0'
              ? '0'
              : value?.replace(/^0+/, '')?.replace(/^\./, '0.')
          )
        }
      }
    }

    if (isNaN(value) || value === 0) {
      setToAmount(0)
      return
    }
    if ((checkValuable(value, true) && isUnWrap()) || isWrap()) {
      setToAmount(
        typeof value == 'number'
          ? value
          : value === '0'
          ? '0'
          : value?.replace(/^0+/, '')?.replace(/^\./, '0.')
      )
      return
    }
    if (isChecking || checkValuable(value, true)) {
      getAmountOut(value, fromCurrency)
    } else {
      setToAmount(0)
    }
  }

  const handleChangeToAmount = (value, isChecking = false) => {
    if (typeof value == 'number') {
      setToAmount(value)
    } else if (!value || value?.match(/^\d{0,}(\.\d{0,18})?$/)) {
      if (value === '.') {
        setToAmount(value)
        return
      } else if (value && value.slice(-1) === '.') {
        setToAmount(`${parseFloat(value?.slice(0, value?.length - 1))}.`)
      } else {
        if (
          value &&
          parseFloat(value) < 1 &&
          parseFloat(value) !== value &&
          value?.slice(-1) !== '0'
        ) {
          setToAmount(
            parseFloat(value)
              ?.toFixed(value?.length - 1)
              ?.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1')
          )
        } else {
          setToAmount(
            typeof value == 'number'
              ? value
              : value === '0'
              ? '0'
              : value?.replace(/^0+/, '')?.replace(/^\./, '0.')
          )
        }
      }
    }

    if (isNaN(value) || value === 0) {
      setFromAmount(0)
      return
    }

    if ((checkValuable(value, false) && isUnWrap()) || isWrap()) {
      setFromAmount(
        typeof value == 'number'
          ? value
          : value === '0'
          ? '0'
          : value?.replace(/^0+/, '')?.replace(/^\./, '0.')
      )
      return
    }

    if (isChecking || checkValuable(value, false)) {
      getAmountIn(value, toCurrency)
    } else {
      setFromAmount(0)
    }
  }

  const setTolerance = (value) => {
    dispatch(setSwapTolerance(parseFloat(value)))
  }

  const setDeadline = (value) => {
    dispatch(setSwapDeadline(parseFloat(value)))
  }

  const [handleSettingsModal] = useModal(
    <TransactionSettingModal
      tolerance={tolerance}
      setTolerance={setTolerance}
      setDeadline={setDeadline}
      initDeadline={deadline}
    />
  )

  const [handleConnectClick] = useModal(<WalletListModal />)

  const [handleToCurrencySelector] = useModal(
    <CurrencySelector
      setCurrency={setToCurrency}
      setIsDropdown={setIsDropdown}
      selected={[toCurrency, fromCurrency]}
      setSwitching={setSwitching}
      isToCurrency={true}
      isSwitching={isSwitching}
    />
  )

  const [handleFromCurrencySelector] = useModal(
    <CurrencySelector
      setCurrency={setFromCurrency}
      setIsDropdown={setIsDropdown}
      selected={[fromCurrency, toCurrency]}
      setSwitching={setSwitching}
      isFromCurrency={true}
      isSwitching={isSwitching}
    />
  )

  const fromAmountBig = new BigNumber(fromAmount)
  const fromBalanceConverted = ubetToEth(fromBalance, fromCurrency?.decimals)
  const toBalanceConverted = ubetToEth(toBalance, toCurrency?.decimals)

  const insufficientFromBalance =
    fromAmount > weiToEthLongString(fromBalance, fromCurrency?.decimals)

  const errors = account && (contractError || liquidityError || scError)

  const isApproved = useMemo(() => {
    if (isApproveSuccess) return true
    return (
      (allowance && weiToEthNum(BigNumber(allowance)) > fromAmount) ||
      !fromCurrency?.address
    )
  }, [allowance, fromAmount, isApproveSuccess])

  const priceImpact = useMemo(() => {
    const fromPrice =
      fromCurrency?.address === VCASH_ADDRESS
        ? 1
        : weiToEthNum(BigNumber(fromPoolData?.price), 36 - fromCurrency?.decimals)

    const toPrice =
      toCurrency?.address === VCASH_ADDRESS
        ? 1
        : weiToEthNum(BigNumber(toPoolData?.price), 36 - toCurrency?.decimals)
    const ratio = fromPrice / toPrice

    const fromAmountReal =
      (isWrap() || isUnWrap()
        ? fromAmount
        : exactAmount
        ? fromRealAmount
        : fromAmount) || fromAmount
    const toAmountReal =
      (isWrap() || isUnWrap() ? toAmount : exactAmount ? toAmount : toRealAmount) ||
      toAmount
    return (Math.abs(ratio - toAmountReal / fromAmountReal) * 100) / ratio - 0.3 || 0
  }, [
    fromPoolData,
    toPoolData,
    fromAmount,
    toAmount,
    exactAmount,
    fromRealAmount,
    toRealAmount,
    fromCurrency?.address,
    toCurrency?.address,
  ])

  const [onPresentConfirmOrderModal] = useModal(
    <ConfirmOrderModal
      fromToken={fromCurrency}
      toToken={toCurrency}
      fromAmount={
        isWrap() || isUnWrap()
          ? fromAmount
          : exactAmount
          ? fromRealAmount
          : fromAmount
      }
      toAmount={
        isWrap() || isUnWrap() ? toAmount : exactAmount ? toAmount : toRealAmount
      }
      exactAmount={exactAmount}
      fromBalance={fromBalance}
      toBalance={toBalance}
      deadline={deadline}
      tradeVcash={tradeVcash}
      onCloseModal={handleClearInputs}
      onFinishTransaction={handleFinishTransaction}
      priceImpact={priceImpact}
    />,
    'success'
  )

  return (
    <Div ref={swapRef}>
      <SwapCard isDark={isDark}>
        <CardHeader>
          <CardTabs>
            <Label
              text="Swap"
              weight="800"
              size="18"
              translateId="swap.card.title"
            />
          </CardTabs>
          <StyledSettingIcon
            style={{ justifyContent: 'flex-end' }}
            onClick={handleSettingsModal}
            testId="swap-setting"
          />
        </CardHeader>
        <Content>
          <PriceInput
            text={'You Pay'}
            amount={fromAmount}
            handleChangeAmount={handleChangeFromAmount}
            currency={fromCurrency}
            toggle={toggleFromList}
            balance={fromBalanceConverted}
            balanceDetail={weiToEthLongString(fromBalance, fromCurrency?.decimals)}
            lessThan={
              weiToEthNum(fromBalance, fromCurrency?.decimals) !== 0 &&
              fromBalanceConverted === 0
            }
            showMax={
              account && weiToEthNum(fromBalance, fromCurrency?.decimals) === 0
                ? false
                : true
            }
            testToggleId="from-toggle"
            testId="from-price-input"
            translateId={
              lessThanIndex === 1
                ? 'swap.card.token.title.you_pay_less'
                : 'swap.card.token.title.you_pay'
            }
          />
          <Spacer />
          {!poolLoading ? (
            <StyledSwapIcon
              onClick={handleSwapItems}
              isSwapped={isSwapped}
              style={{ justifyContent: 'center' }}
              testId="swap-icon"
              disabled={isSwapDisable}
            />
          ) : (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Spinner size="35px" thickness={3} color="#2dc48f" />
            </div>
          )}
          <Spacer />
          <PriceInput
            text={'You Receive'}
            amount={toAmount}
            handleChangeAmount={handleChangeToAmount}
            currency={toCurrency}
            toggle={toggleToList}
            balance={toBalanceConverted}
            balanceDetail={weiToEthLongString(toBalance, toCurrency?.decimals)}
            lessThan={
              weiToEthNum(toBalance, toCurrency?.decimals) !== 0 &&
              toBalanceConverted === 0
            }
            showMax={
              account && weiToEthNum(toBalance, toCurrency?.decimals) === 0
                ? false
                : true
            }
            testToggleId="to-toggle"
            testId="to-price-input"
            translateId={
              lessThanIndex === 2
                ? 'swap.card.token.title.you_receive_less'
                : 'swap.card.token.title.you_receive'
            }
          />
          <Row
            style={{
              flexWrap: 'wrap',
            }}
          >
            {(toAmount > 0 || fromAmount > 0) && fromCurrency && toCurrency ? (
              isSwap ? (
                <Row style={{ marginTop: '20px', marginLeft: '20px' }}>
                  <Label
                    text={`1 ${toCurrency?.symbol} = ${precise(
                      fromAmount / toAmount,
                      6
                    )} ${fromCurrency?.symbol}`}
                    size="12"
                    weight="800"
                    opacity={0.5}
                  />
                  <StyledSwapDoubleIcon
                    style={{
                      marginLeft: '5px',
                    }}
                    onClick={() => {
                      if (toAmount > 0 || fromAmount > 0) setIsSwap(!isSwap)
                    }}
                  />
                </Row>
              ) : (
                <Row style={{ marginTop: '20px', marginLeft: '20px' }}>
                  <Label
                    text={`1 ${fromCurrency?.symbol} = ${precise(
                      toAmount / fromAmount,
                      6
                    )} ${toCurrency?.symbol}`}
                    size="12"
                    weight="800"
                    opacity={0.5}
                  />
                  <StyledSwapDoubleIcon
                    style={{
                      marginLeft: '5px',
                    }}
                    onClick={() => {
                      if (toAmount > 0 || fromAmount > 0) setIsSwap(!isSwap)
                    }}
                  />
                </Row>
              )
            ) : null}
          </Row>
          <div style={{ height: 16 }}></div>
          {account ? (
            insufficientFromBalance ? (
              <StyledIconButton
                disabled
                block
                shadow
                variant="primary"
                translateId="swap.card.button.insufficient_balance"
                values={{ currency: fromCurrency?.symbol }}
              >
                {`Insufficient ${fromCurrency?.symbol} token balance`}
              </StyledIconButton>
            ) : toPoolData &&
              toPoolData?.status === '0' &&
              contractError &&
              fromAmount ? (
              <StyledIconButton
                disabled
                block
                shadow
                variant="primary"
                icon="arrow"
                translateId={`swap.card.button.${
                  (isWrap() ? 'Wrap' : isUnWrap() ? 'Unwrap' : 'Swap').toLowerCase
                }`}
              >
                {isWrap() ? 'Wrap' : isUnWrap() ? 'Unwrap' : 'Swap'}
              </StyledIconButton>
            ) : toAmount && fromAmount ? (
              <StyledIconButton
                onClick={handleConfirmOrder}
                onMouseEnter={() => {
                  priceImpact > 15 && setHoveredImpact(true)
                }}
                onMouseLeave={() => {
                  setHoveredImpact(false)
                }}
                disabled={
                  contractError ||
                  liquidityError ||
                  scError ||
                  insufficientFromBalance ||
                  calculating ||
                  isApproving
                }
                block
                isPerforming={isApproving}
                isConfirmSwap={true}
                icon="arrow"
                variant={priceImpact > 5 ? 'secondary' : 'primary'}
                translateId={`swap.card.button.${
                  calculating
                    ? 'calculating'
                    : isWrap()
                    ? 'wrap'
                    : isUnWrap()
                    ? 'unwrap'
                    : isApproved &&
                      (hoveredImpact || (priceImpact > 10 && priceImpact <= 15))
                    ? 'swap-anyway'
                    : isApproved && priceImpact > 15
                    ? 'price-impact-too-high'
                    : isApproved
                    ? 'swap'
                    : isApproving
                    ? 'waiting_for_approval'
                    : 'approve_swap'
                }`}
                testId={`${
                  calculating
                    ? 'calculating'
                    : isWrap()
                    ? 'wrap'
                    : isUnWrap()
                    ? 'unwrap'
                    : isApproved &&
                      (hoveredImpact || (priceImpact > 10 && priceImpact <= 15))
                    ? 'swap-anyway'
                    : isApproved && priceImpact > 15
                    ? 'price-impact-too-high'
                    : isApproved
                    ? 'swap'
                    : isApproving
                    ? 'waiting-for-approval'
                    : 'approve-swap'
                }`}
              >
                {calculating
                  ? 'Calculating'
                  : isWrap()
                  ? 'Wrap'
                  : isUnWrap()
                  ? 'Unwrap'
                  : isApproved &&
                    (hoveredImpact || (priceImpact > 10 && priceImpact <= 15))
                  ? 'Swap Anyway'
                  : isApproved && priceImpact > 15
                  ? 'Price Impact Too High'
                  : isApproved
                  ? 'Swap'
                  : isApproving
                  ? 'Waiting for Approval'
                  : 'Approve Swap'}
              </StyledIconButton>
            ) : (
              <StyledIconButton
                disabled={true}
                block
                variant="primary"
                translateId={`swap.card.button.${
                  calculating ? 'calculating' : 'enter_an_amount'
                }`}
                testId={`${calculating ? 'calculating' : 'enter-an-amount'}`}
              >
                {calculating ? 'Calculating' : 'Enter an Amount'}
              </StyledIconButton>
            )
          ) : (
            <StyledIconButton
              onClick={handleConnectClick}
              block
              icon="arrow"
              variant="primary"
              translateId="swap.card.button.connect_wallet"
              testId="connect-wallet"
            >
              Connect Wallet
            </StyledIconButton>
          )}
        </Content>
        {errors && (
          <Warnings isDark={isDark}>
            <ul>
              {scError && (
                <StyledItem>
                  <FormattedMessage
                    id="swap.card.error.1"
                    defaultMessage="Insufficient liquidity for this trade"
                  />
                </StyledItem>
              )}
              {contractError ? (
                scError || liquidityError ? (
                  <StyledItem>
                    {vcashBalance > 0 ? (
                      <FormattedMessage
                        id="swap.card.error.2"
                        defaultMessage={`This pool doesn't have enough vUnit balance to support this transaction.Please make a transaction value of $${precise(
                          (vcashBalance - precise(pendingVcashValue, 4)) *
                            vcashPrice,
                          4
                        )}(${precise(
                          (vcashBalance - pendingVcashValue) / fromPoolPrice,
                          4
                        )} ${fromCurrency?.symbol}) or less for ${
                          fromCurrency?.symbol
                        }`}
                        values={{
                          vunitBalance: precise(
                            (vcashBalance - precise(pendingVcashValue, 4)) *
                              vcashPrice,
                            4
                          ),
                          fromCurrency: fromCurrency?.symbol,
                          tokenAmount: precise(
                            (vcashBalance - pendingVcashValue) / fromPoolPrice,
                            4
                          ),
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        id="swap.card.error.3"
                        defaultMessage={`The vUNIT balance for {fromCurrency} is currently 0, more {fromCurrency} needs to be purchased before selling`}
                        values={{ fromCurrency: fromCurrency?.symbol }}
                      />
                    )}
                  </StyledItem>
                ) : (
                  <StyledWarning>
                    {vcashBalance > 0 ? (
                      <FormattedMessage
                        id="swap.card.error.2"
                        defaultMessage={`This pool doesn't have enough vUnit balance to support this transaction.Please make a transaction value of $${precise(
                          (vcashBalance - precise(pendingVcashValue, 4)) *
                            vcashPrice,
                          4
                        )}(${precise(
                          (vcashBalance - pendingVcashValue) / fromPoolPrice,
                          4
                        )} ${fromCurrency?.symbol}) or less for ${
                          fromCurrency?.symbol
                        }`}
                        values={{
                          vunitBalance: precise(
                            (vcashBalance - precise(pendingVcashValue, 4)) *
                              vcashPrice,
                            4
                          ),
                          fromCurrency: fromCurrency?.symbol,
                          tokenAmount: precise(
                            (vcashBalance - pendingVcashValue) / fromPoolPrice,
                            4
                          ),
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        id="swap.card.error.3"
                        defaultMessage={`The vUNIT balance for {fromCurrency} is currently 0, more {fromCurrency} needs to be purchased before selling`}
                        values={{ fromCurrency: fromCurrency?.symbol }}
                      />
                    )}
                  </StyledWarning>
                )
              ) : (
                <StyledItem>
                  {vcashBalance > 0 ? (
                    <FormattedMessage
                      id="swap.card.error.2"
                      defaultMessage={`This pool doesn't have enough vUnit balance to support this transaction.Please make a transaction value of $${precise(
                        (vcashBalance - precise(pendingVcashValue, 4)) * vcashPrice,
                        4
                      )}(${precise(
                        (vcashBalance - pendingVcashValue) / fromPoolPrice,
                        4
                      )} ${fromCurrency?.symbol}) or less for ${
                        fromCurrency?.symbol
                      }`}
                      values={{
                        vunitBalance: precise(
                          (vcashBalance - precise(pendingVcashValue, 4)) *
                            vcashPrice,
                          4
                        ),
                        fromCurrency: fromCurrency?.symbol,
                        tokenAmount: precise(
                          (vcashBalance - pendingVcashValue) / fromPoolPrice,
                          4
                        ),
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="swap.card.error.3"
                      defaultMessage={`The vUNIT balance for {fromCurrency} is currently 0, more {fromCurrency} needs to be purchased before selling`}
                      values={{ fromCurrency: fromCurrency?.symbol }}
                    />
                  )}
                </StyledItem>
              )}
              {liquidityError && (
                <StyledItem>
                  <FormattedMessage
                    id="swap.card.error.4"
                    defaultMessage={`{toCurrency} pool does not have enough liquidity to execute this transaction.`}
                    values={{ toCurrency: toCurrency?.symbol }}
                  />
                </StyledItem>
              )}
            </ul>
            <img
              src={WarningImg}
              style={{
                position: 'absolute',
                bottom: '-18px',
                right: '-25px',
              }}
              alt="WarningImg"
            />
          </Warnings>
        )}
      </SwapCard>
      {isWrap() || isUnWrap() ? null : (
        <SwapWidget
          fromToken={fromCurrency}
          fromPoolData={fromPoolData}
          toPoolData={toPoolData}
          toToken={toCurrency}
          code={code}
          fromAmount={
            isWrap() || isUnWrap()
              ? fromAmount
              : exactAmount
              ? fromRealAmount || fromAmount
              : fromAmount
          }
          toAmount={
            isWrap() || isUnWrap()
              ? toAmount
              : exactAmount
              ? toAmount
              : toRealAmount || toAmount
          }
        />
      )}
      {/* {toCurrency && fromCurrency && (
        <StartTrading
          toCurrency={toCurrency}
          fromCurrency={fromCurrency}
          toAmount={
            isWrap() || isUnWrap()
              ? toAmount
              : exactAmount
              ? toAmount
              : toRealAmount || toAmount
          }
          fromAmount={
            isWrap() || isUnWrap()
              ? fromAmount
              : exactAmount
              ? fromRealAmount || fromAmount
              : fromAmount
          }
        />
      )} */}
    </Div>
  )
}

const SwapCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 35px;
  object-fit: contain;
  border-radius: 39px;
  border: ${(props) => props?.isDark && 'solid 1px rgba(220, 226, 235, 0.1)'};
  box-shadow: ${({ theme }) => theme?.shadows?.thin};
  background-color: ${({ theme }) => theme?.color?.background?.main};
`
const CardTabs = styled.div`
  margin-top: 0.8rem;
  width: fit-content;
  float: left;
`

const Div = styled.div`
  position: relative;
  max-width: 390px;
  display: flex;
  flex-direction: column;
`
const CardHeader = styled.div``

const Content = styled.div`
  padding-top: 35px;
`

export const Warnings = styled.div`
  border-left: 5px solid ${({ theme }) => theme?.color?.font?.warning};
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: ${(props) =>
    props?.isDark ? 'rgba(226, 129, 86, 0.15)' : '#f4eded'};
  min-height: 80px;
  display: flex;
  position: relative;
  overflow: hidden;
  margin-top: 30px;
  ul {
    padding: 30px 20px;
    margin-block-start:0;
    margin-block-end:0;
    padding-inline-start: 18px;
    list-style: none;
    }
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  height: auto;
  padding:.5rem 0;
  `}
`

const StyledItem = styled.li`
  font-size: 14px;
  font-weight: 800;
  width: 100%;
  color: ${({ theme }) => theme?.color?.font?.warning};
  &::before {
    content: '•';
    color: ${({ theme }) => theme?.color?.font?.warning};
    font-weight: bold;
    display: inline-block;
    font-size: 16px;
    margin-right: 8px;
  }
`

const StyledWarning = styled.div`
  font-size: 14px;
  font-weight: 800;
  width: 100%;
  color: ${({ theme }) => theme?.color?.font?.warning};
`

export default SwapperCard
