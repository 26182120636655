import {
  SET_LOCALE,
  SET_MODE,
  UPDATE_APPLICATION,
  SET_VCASH_PRICE,
} from 'state/applications/constants'

export function updateApplication(payload) {
  return {
    type: UPDATE_APPLICATION,
    payload,
  }
}

export function setMode(payload) {
  return { type: SET_MODE, payload }
}

export function setLocale(payload) {
  return { type: SET_LOCALE, payload }
}

export function setVcashPrice(payload) {
  return { type: SET_VCASH_PRICE, payload }
}
