import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import BigNumber from 'bignumber.js'

import { weiToEthNum } from 'monox/constants'
import { precise } from 'monox/util'
import config from 'monox/config'
import useWallet from 'hooks/useWallet'
import theme from 'theme'

import { getPriceChanges } from 'api'

import { Row } from 'components/Row'
import TokenImage from 'components/TokenImage'
import Spacer from 'components/Spacer'
import Label from 'components/Label'
import Button from 'components/Button'

const Header = ({
  currency,
  pool,
  isTokenInvalid = false,
  modal = false,
  onDismiss = () => {},
}) => {
  const history = useHistory()
  const { chainId } = useWallet()
  const networkId = useSelector(({ network }) => network.id)
  const networkName = config?.[networkId || chainId]?.NAME
  const vcashPrice =
    useSelector(({ application }) => application?.vcashPrice)?.[networkId] || 1
  const WRAPPED_MAIN_ADDRESS = config?.[networkId || chainId]?.WRAPPED_MAIN_ADDRESS
  const decimals = currency?.decimals || 18
  const price = weiToEthNum(BigNumber(pool?.price), 36 - decimals)
  const [priceChanges, setPriceChanges] = useState([])

  const changeRoute = (route) => {
    history.push({
      pathname: route,
      search: `?network=${networkName?.toLowerCase()}`,
      state: { setAsReceiving: true },
    })
  }

  const getTokenPriceChanges = async () => {
    try {
      const priceChangeData = await getPriceChanges(
        chainId,
        currency?.address || WRAPPED_MAIN_ADDRESS
      )
      setPriceChanges(priceChangeData?.response)
    } catch (err) {
      setPriceChanges([])
    }
  }

  useEffect(() => {
    if (chainId && currency) {
      getTokenPriceChanges()
    }
  }, [chainId, currency?.address])

  if (isTokenInvalid) {
    return (
      <Row style={{ justifyContent: 'center' }}>
        <Label
          size="20"
          weight="bold"
          color="#2eca93"
          align="center"
          text={`Entered token address is not in ${networkName} network`}
          translateId="analytics.token.header.currency.invalid_token"
          values={{ networkName }}
        />
      </Row>
    )
  }

  return (
    <Container>
      <Col style={{ width: '100%' }}>
        <Label
          size="32"
          weight="bold"
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
        >
          <TokenImage
            src={currency?.logoURI}
            letter={currency?.symbol && currency?.symbol?.[0]}
          />
          <Spacer size="sm" />
          {currency?.name} <Spacer size="sm" />({currency?.symbol})
          <Spacer />
          {price !== 0 && (
            <>
              <Label
                text={`${
                  price < 0.0001
                    ? '< $ 0.0001'
                    : `$${new Intl.NumberFormat().format(
                        precise(price * vcashPrice, 3)
                      )}`
                }`}
                size="24"
                weight="bold"
              />
              <Spacer />
              <Label
                style={{
                  color:
                    priceChanges[0]?.price_change < 0
                      ? 'rgb(255, 101, 109)'
                      : theme.color.font.primary,
                }}
                text={`$${new Intl.NumberFormat().format(
                  precise(
                    (priceChanges[0]?.price_change * price * vcashPrice) / 100,
                    4 ?? 0
                  )
                )}(${new Intl.NumberFormat().format(
                  precise(priceChanges[0]?.price_change, 4 ?? 0)
                )}%)`}
                size="16"
                primary
                weight="800"
              />
            </>
          )}
        </Label>
      </Col>
      {price !== 0 && (
        <Col justify="flex-end" style={{ width: '90%' }} modal={modal}>
          {/* <Button
            size="sm"
            bg="#3dcf9726"
            fontColor="primary"
            style={{ border: 'none' }}
            onClick={() => {
              changeRoute(
                `/swap/${currency?.notInList ? currency?.address : currency?.symbol}`
              )
              onDismiss()
            }}
            translateId="analytics.token.header.button.1"
            testId="swap"
          >
            Swap
          </Button> */}
          <Spacer />
          <Button
            size="sm"
            bg="#3dcf9726"
            fontColor="primary"
            style={{ border: 'none' }}
            onClick={() => {
              changeRoute(
                `/add/${currency?.notInList ? currency?.address : currency?.symbol}`
              )
              onDismiss()
            }}
            translateId="analytics.token.header.button.2"
            testId="add-liquidity"
          >
            Add Liquidity
          </Button>
        </Col>
      )}
    </Container>
  )
}

const Col = styled(Row)`
  justify-content: ${(props) => props.justify};
  line-height: 50px;
  height: 100%;
  align-items: ${(props) => (props.modal ? 'flex-start' : 'center')};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  flex-direction: column;
  `};
`
const Container = styled(Row)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  `}
`
export default Header
