import axios from 'axios'
import {
  DEFAULT_NETWORK_ID,
  MAINNNET_BACKEND_URL,
  TESTNET_BACKEND_URL,
  MAINNET_CHAINDS,
} from 'monox/constants'

axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_URL}`

export async function get(path, chainId = DEFAULT_NETWORK_ID, params = {}) {
  const endPoint = MAINNET_CHAINDS.includes(chainId)
    ? MAINNNET_BACKEND_URL
    : TESTNET_BACKEND_URL
  axios.defaults.baseURL = endPoint

  const response = await axios({
    method: 'get',
    url: `${path}`,
    params,
    responseType: 'json',
  })
    .then((res) => res.data)
    .catch((e) => {
      return {
        hasError: true,
        ...e,
      }
    })
  return response
}

export async function post(path, data, chainId = DEFAULT_NETWORK_ID, params = {}) {
  const endPoint = MAINNET_CHAINDS.includes(chainId)
    ? MAINNNET_BACKEND_URL
    : TESTNET_BACKEND_URL
  axios.defaults.baseURL = endPoint

  const response = await axios({
    method: 'post',
    url: `${path}`,
    data,
    params,
    responseType: 'json',
  })
    .then((res) => res.data)
    .catch((e) => {
      return {
        hasError: true,
        ...e,
      }
    })
  return response
}
