import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, NavLink, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { isProdEnv, SWAP_ALLOW_CHAINS } from 'monox/constants'

import useWallet from 'hooks/useWallet'

import MobileFooter from 'components/Footer/MobileFooter'
import MoreFooter from 'components/Footer/MoreFooter'
import Label from 'components/Label'

import swap from 'assets/img/swapNav.svg'
import home from 'assets/img/home.svg'
import pool from 'assets/img/pool.svg'
import bond from 'assets/img/bond.svg'
import moreGreen from 'assets/img/more-green.svg'
import swapDark from 'assets/img/swap-dark.svg'
import homeDark from 'assets/img/home-dark.svg'
import poolDark from 'assets/img/pool-dark.svg'
import bondDark from 'assets/img/bond-dark.svg'
import moreDark from 'assets/img/more-dark.svg'
import swapLight from 'assets/img/swap-light.svg'
import homeLight from 'assets/img/home-light.svg'
import poolLight from 'assets/img/pool-light.svg'
import bondLight from 'assets/img/bond-light.svg'
import moreLight from 'assets/img/more-light.svg'

const Footer = ({ isDark }) => {
  const history = useHistory()
  const location = useLocation()
  const networkName = useSelector(({ network }) => network?.NAME)?.toLowerCase()
  const [isMore, setIsMore] = useState(false)
  const params = location.pathname.split('/')
  const dropdownRef = useRef(null)
  const containerRef = useRef(null)
  const { chainId } = useWallet()
  const networkId = useSelector(({ network }) => network.id)

  const isSupported = useMemo(() => {
    return SWAP_ALLOW_CHAINS.includes(chainId || networkId)
  }, [chainId, networkId])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef?.current &&
        !dropdownRef.current.contains(event.target) &&
        containerRef?.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsMore(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef, containerRef])

  if (/restricted|error/.test(params?.[1])) {
    return null
  }

  const SafeLink = ({ to, text, img, activeImg, translateId }) => {
    const active = history.location.pathname === to?.replace(/\?.*$/, '')
    return (
      <StyledLink to={to} exact>
        <img
          src={active ? activeImg : img}
          alt={text.toLowerCase()}
          style={{ color: active ? '#41dea2' : isDark ? '#ffffff' : '#202b65' }}
        />
        <Label
          text={text}
          size="12"
          weight="800"
          opacity={active ? 1 : 0.5}
          color={active ? '#41dea2' : null}
          style={{ marginTop: '6px' }}
          translateId={translateId}
        />
      </StyledLink>
    )
  }

  return (
    <>
      <MobileFooter />
      {isMore ? (
        <MoreFooter
          setIsMore={setIsMore}
          isMore={isMore}
          containerRef={containerRef}
        />
      ) : (
        ''
      )}
      <StyledNavButtons isDark={isDark}>
        <SafeLink
          to="/home"
          text="Home"
          img={isDark ? homeDark : homeLight}
          activeImg={home}
          translateId="common.menu.bar_item.1"
        />

        {isSupported && (
          <>
            <SafeLink
              to={`/swap?network=${networkName}`}
              text="Swap"
              img={isDark ? swapDark : swapLight}
              activeImg={swap}
              translateId="common.menu.bar_item.2"
            />

            <SafeLink
              to={`/pool?network=${networkName}`}
              text="Pool"
              img={isDark ? poolDark : poolLight}
              activeImg={pool}
              translateId="common.menu.bar_item.3"
            />
          </>
        )}

        <SafeLink
          to={`/bond?network=${networkName}`}
          text="Bond"
          img={isDark ? bondDark : bondLight}
          activeImg={bond}
          translateId="common.menu.bar_item.8"
        />

        {isSupported && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              setIsMore(!isMore)
            }}
            ref={dropdownRef}
          >
            <img
              src={isMore ? moreGreen : isDark ? moreDark : moreLight}
              width="22"
              alt="moreGreen"
            />
          </div>
        )}
      </StyledNavButtons>
    </>
  )
}

export default Footer

const StyledNavButtons = styled.div`
  display: none;
  margin-left: auto;
  height: 100%;
  width: auto;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    justify-self: center;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 64px;
    border-radius: 12px 12px 0 0;
    background-color: ${(props) =>
      props.isDark ? '#052730' : props.theme.color.background.main};
  `};
`

const StyledLink = styled(NavLink)`
  cursor: pointer;
  text-decoration: none;
  transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease,
    box-shadow 0.2s ease, transform 0.2s ease;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
