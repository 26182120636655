import {
  NetworkId,
  IMAGEURL,
  GETLPTOKENLINK,
  WSS_URL,
  NETWORK_URL,
} from 'monox/constants'
import BondContractAbi from 'monox/abi/bond/BondContract.json'
import wETHBondContractAbi from 'monox/abi/bond/wETHBondContract.json'
import VDAILPBondContractAbi from 'monox/abi/bond/vUNIT-DAI_LPBondContract.json'
import VUSDTLPBondContractAbi from 'monox/abi/bond/V-USDTLPBondContract.json'

const configProd = {
  [NetworkId?.MAINNET]: {
    NAME: 'Ethereum',
    LONGNAME: 'Ethereum',
    POOL_ADDRESS: '0x59653E37F8c491C3Be36e5DD4D503Ca32B5ab2f4',
    SWAP_ADDRESS: '0xC36a7887786389405EA8DA0B87602Ae3902B88A1',
    STAKING_ADDRESS: '',
    FUTURE_ADDRESS: '',
    WSS_URL: WSS_URL?.Ethereum,
    NETWORK_URL: NETWORK_URL?.Ethereum,
    WRAPPED_MAIN_ADDRESS: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    MAIN_CURRENCY: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      chainId: NetworkId?.MAINNET,
      logoURI: IMAGEURL?.WETH,
    },
    VCASH: {
      name: 'Virtual Unit',
      symbol: 'vUNIT',
      address: '0x532D7ebE4556216490c9d03460214b58e4933454',
      decimals: 18,
      chainId: NetworkId?.MAINNET,
      logoURI: IMAGEURL?.VUNIT,
    },
    MONO: {
      name: 'Mono',
      symbol: 'MONO',
      address: '0x2920f7d6134f4669343e70122cA9b8f19Ef8fa5D',
      decimals: 18,
      chainId: NetworkId?.MAINNET,
      logoURI: IMAGEURL?.MONO,
    },
  },
  /*   
MonoXPool address: 0xE37d8F265d3Dd3234C947300bD86681F8587e61b
Monoswap address: 0x67b746E7bCef1341017A9400Ac33eceD1778f0C9
MonoswapRouter address: 0x9Bd0254B0a400aa6b56C022D76f00FDe66FB48Aa
MonoXPool Impl Address 0x77154Fb02AeadD82Eb6CB96A3fA79f5eEbFD4435
Monoswap Impl Address 0xaF536547C384B3562aAA89E066390072ED45EBE2
MonoswapStaking address: 0xa1dB2EedB8A247B94f5DD0654fb3ebd5490AfBA6
MonoswapStaking Impl Address 0x086BE93EF2345AE696671acA1843beb0A97Ed226 */
  [NetworkId?.POLYGON]: {
    NAME: 'Polygon',
    LONGNAME: 'Polygon',
    POOL_ADDRESS: '0xE37d8F265d3Dd3234C947300bD86681F8587e61b',
    SWAP_ADDRESS: '0x67b746E7bCef1341017A9400Ac33eceD1778f0C9',
    SWAP_ROUTER_ADDRESS: '0x9Bd0254B0a400aa6b56C022D76f00FDe66FB48Aa',
    STAKING_ADDRESS: '0xa1dB2EedB8A247B94f5DD0654fb3ebd5490AfBA6',
    WSS_URL: WSS_URL?.Polygon,
    NETWORK_URL: NETWORK_URL?.Polygon,
    WRAPPED_MAIN_ADDRESS: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    MAIN_CURRENCY: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
      chainId: NetworkId?.POLYGON,
      status: 2,
      logoURI: IMAGEURL?.WMATIC,
    },
    MONO: {
      name: 'Mono',
      symbol: 'MONO',
      address: '0x05323017c00874198Cf1b0832922074BeEA0db83',
      decimals: 18,
      chainId: NetworkId?.POLYGON,
      logoURI: IMAGEURL?.MONO,
    },
    VCASH: {
      name: 'Virtual Unit',
      symbol: 'vUNIT',
      decimals: 18,
      chainId: NetworkId?.POLYGON,
      logoURI: IMAGEURL?.VUNIT,
      address: '0x632fbF85F77978437073a8CE5CEEC29e3209514c',
    },
    STAKED_VUNIT_ADDRESS: '0xA1d476d66867e6692aFB46887Bbae84180E2b871', //svUNIT
    TREASURY_ADDRESS: '0x2f4862f141eA85ebED8e28285A932A98A510c337', // treasury
    BOND_STAKING_ADDRESS: '0x79D13d36d90AD54d4734313252b7eAc56784B231', // staking
    BOND_STAKING_WARMUP_ADDRESS: '0x543cA905FDC27F1b86494B5868f1f6C63Bd70232', // stakingWarnup
    BOND_STAKING_HELPER_ADDRESS: '0xF48bb507362096323C46FAc1640b2872B712E906', // stakingHelper
    BOND_DISTRIBUTOR_ADDRESS: '0x78c5B52a59729c4b0Ab646c4902dF1ec959E7fE1', // distributor
    BOND_CALCULATOR_ADDRESS: '0x266aC667206820058EFb02DE646F159057f7a321', // calculator
    BOND_REDEEM_HELPER_ADDRESS: '0x515550c840D601E8A6df4b35E8161C7c9a29DE60', // redeemHelper
  },
  [NetworkId?.AVALANCHE]: {
    NAME: 'Avalanche',
    LONGNAME: 'Avalanche',
    POOL_ADDRESS: '',
    SWAP_ADDRESS: '',
    STAKING_ADDRESS: '',
    WSS_URL: WSS_URL?.Avalanche,
    NETWORK_URL: NETWORK_URL?.Avalanche,
    WRAPPED_MAIN_ADDRESS: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    MAIN_CURRENCY: {
      name: 'Avax',
      symbol: 'AVAX',
      decimals: 18,
      chainId: NetworkId?.AVALANCHE,
      status: 2,
      logoURI: IMAGEURL?.AVAX,
    },
    VCASH: {
      name: 'Virtual Unit',
      symbol: 'vUNIT',
      address: '0x94695C9942b1F306bC9A6dC38563EC02312446Ff',
      decimals: 18,
      chainId: NetworkId?.AVALANCHE,
      logoURI: IMAGEURL?.VUNIT,
    },
    STAKED_VUNIT_ADDRESS: '0x82f6fa2457C203E1756F038a9bb00a253d609866', //svUNIT
    TREASURY_ADDRESS: '0x51f9e4F6D3290b6fA70d819b3Be743d26D1f7E02', // treasury
    BOND_STAKING_ADDRESS: '0x451e567Bad74F29C9E520100064D7539281b3289', // staking
    BOND_STAKING_WARMUP_ADDRESS: '0x954ECD6391Fc540c012d2b9f762a6301D1739c29', // stakingWarnup
    BOND_STAKING_HELPER_ADDRESS: '0xB3A940136539dF918D65d3e8eFe692A5F58Ae2ba', // stakingHelper
    BOND_STAKING_HELPER1_ADDRESS: '0xcC203c4Cf8Ab4dcA95B60FC9816c84eD7aAce315', // stakingHelper1
    BOND_DISTRIBUTOR_ADDRESS: '0x3e9fA143E63D6e163212335931c2Dd8EA2406beb', // distributor
    BOND_CALCULATOR_ADDRESS: '0x00820480ca623a21A852A5ec3242d3E0F4eB9e0c', // calculator
    BOND_REDEEM_HELPER_ADDRESS: '0x8E519A8645732B969A07dEabfd327F7CAA049055', // redeemHelper
  },
  [NetworkId?.TESTNET_KOVAN]: {
    NAME: 'Kovan',
    LONGNAME: 'Kovan',
    POOL_ADDRESS: '0xeF100734316bC82f95f914d557D4E0Ca3186d9e0',
    SWAP_ADDRESS: '0xD7cd52ccC403cFEA4acE872c16bb953B26b730e6',
    SWAP_ROUTER_ADDRESS: '0xBdf6ae683435111A9C70f57aA58686134d88eFBE',
    STAKING_ADDRESS: '0x6549f8aE5dfee52B5da9127B8Dda7504798ec347',
    FUTURE_ADDRESS: '0x1D35c18B513A5dC4C60567b984929079202E2DA3',
    WSS_URL: WSS_URL?.Kovan,
    NETWORK_URL: NETWORK_URL?.Kovan,
    WRAPPED_MAIN_ADDRESS: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    STAKED_VUNIT_ADDRESS: '0xa890Ed17db8624C8A288CEAcB9C572B60B770556', //svUNIT
    TREASURY_ADDRESS: '0x1a64C8596C779108E8010907FdCb1C77Fc154cf8', // treasury
    BOND_STAKING_ADDRESS: '0x1C8d7FaAfdBf75A30A574B08Dd29dbA05efd40e3', // staking
    BOND_STAKING_WARMUP_ADDRESS: '0x0f6D7077CBf895495C1EF7d423D96eaF40091Abe', // stakingWarnup
    BOND_STAKING_HELPER_ADDRESS: '0x3ace36B88ca20DF3768f2ebc04C1cd466f3B4F81', // stakingHelper
    BOND_DISTRIBUTOR_ADDRESS: '0xD744A74431b42FE64835714dacE2694952a1BCc0', // distributor
    BOND_CALCULATOR_ADDRESS: '0x90e9D9d806e6237C074aD0A0964dB79B71c400AA', // calculator
    BOND_REDEEM_HELPER_ADDRESS: '0xd4402791cf2E8eC5DCe2F0517ACA8229A768eF09', // redeemHelper
    VCASH: {
      name: 'Virtual Unit',
      symbol: 'vUNIT',
      address: '0x6547427F900ad4BF7C450332026A46394520Efe2', // vUNIT
      decimals: 18,
      chainId: NetworkId?.TESTNET_KOVAN,
      logoURI: IMAGEURL?.VUNIT,
    },
    MAIN_CURRENCY: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      chainId: NetworkId?.TESTNET_KOVAN,
      status: 2,
      logoURI: IMAGEURL?.WETH,
    },
    MONO: {
      name: 'Mono',
      symbol: 'MONO',
      address: '0xC963bf29405884e3C0c52C16793Ead51Ccb88761',
      decimals: 18,
      chainId: NetworkId?.TESTNET_KOVAN,
      logoURI: IMAGEURL?.MONO,
    },
    sMONO: {
      name: 'staked Mono',
      symbol: 'sMONO',
      address: '0xC8D8de2DA77c22e7876227Ca7Ad642ab194C37bE',
      decimals: 18,
      chainId: NetworkId?.TESTNET_KOVAN,
      logoURI: '',
    },
    dMONO: {
      name: 'dMONO',
      symbol: 'dMONO',
      address: '0x2B3b935E76ca16223C261d7C7841667Fb1910575',
      decimals: 18,
      chainId: NetworkId?.TESTNET_KOVAN,
      logoURI: '',
    },
  },
  [NetworkId?.TESTNET_GOERLI]: {
    NAME: 'Goerli',
    LONGNAME: 'Goerli',
    POOL_ADDRESS: '0x45337C535a7d1D83a7e5835D1E7f19ad192cE2aA',
    SWAP_ADDRESS: '0x01d4dF7e6AfC5B0C54C73bf3b8b7297E247da2AC',
    STAKING_ADDRESS: '',
    WSS_URL: WSS_URL?.Goerli,
    NETWORK_URL: NETWORK_URL?.Goerli,
    WRAPPED_MAIN_ADDRESS: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    MAIN_CURRENCY: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      chainId: NetworkId?.TESTNET_GOERLI,
      status: 2,
      logoURI: IMAGEURL?.WETH,
    },
    VCASH: {
      name: 'Virtual Unit',
      symbol: 'vUNIT',
      address: '0xfa2efEBC7Ee641ED5e9138b0bfF213E8138b077e',
      decimals: 18,
      chainId: NetworkId?.TESTNET_GOERLI,
      logoURI: IMAGEURL?.VUNIT,
    },
  },
  [NetworkId?.AVALANCHE_TESTNET]: {
    NAME: 'Fuji',
    LONGNAME: 'Fuji',
    POOL_ADDRESS: '0xfa2efEBC7Ee641ED5e9138b0bfF213E8138b077e',
    SWAP_ADDRESS: '0xbd5828d5469D3596b8bD86106156C1d71059D0B4',
    STAKING_ADDRESS: '',
    WSS_URL: WSS_URL?.Fuji,
    NETWORK_URL: NETWORK_URL?.Fuji,
    WRAPPED_MAIN_ADDRESS: '0x1d308089a2d1ced3f1ce36b1fcaf815b07217be3',
    MAIN_CURRENCY: {
      name: 'Avax',
      symbol: 'AVAX',
      decimals: 18,
      chainId: NetworkId?.AVALANCHE_TESTNET,
      status: 2,
      logoURI: IMAGEURL?.AVAX,
    },
    VCASH: {
      name: 'Virtual Unit',
      symbol: 'vUNIT',
      address: '0x455405d36Ea8FEf6dCdC9CFCe83A00E105B6A843',
      decimals: 18,
      chainId: NetworkId?.AVALANCHE_TESTNET,
      logoURI: IMAGEURL?.VUNIT,
    },
    STAKED_VUNIT_ADDRESS: '0xb248430DBcCaD231a19E69169766E09B93488295', //svUNIT
    TREASURY_ADDRESS: '0xD0E2b229C22442C58949Df0dA6158Ac12f1b6ca2', // treasury
    BOND_STAKING_ADDRESS: '0x1BF46eA1936d5d656aA8D4AD5acc3085b949D842', // staking
    BOND_STAKING_WARMUP_ADDRESS: '0xc0d129b60AcF2ca477360fB7471a6B526c54659B', // stakingWarnup
    BOND_STAKING_HELPER_ADDRESS: '0xF202B368968137969Feb3377Fcd198F93cF15a86', // stakingHelper
    BOND_DISTRIBUTOR_ADDRESS: '0x8CDF503E2210766f4514ffF14e678AdA7b889253', // distributor
    BOND_CALCULATOR_ADDRESS: '0x78205D0CE9bBf4e1956613925667a88fFa24331F', // calculator
    BOND_REDEEM_HELPER_ADDRESS: '0xdBF49b74e4abCBC19679AD51B4789619A0Ef353C', // redeemHelper
  },
  [NetworkId?.POLYGON_TESTNET]: {
    NAME: 'Mumbai',
    LONGNAME: 'Mumbai',
    POOL_ADDRESS: '0xe96a332f93Fe0B367CeA439d0979e5F75da81Bd6',
    SWAP_ADDRESS: '0x23301465bC80c04B3c240Ca37146BDa535Fca94F',
    SWAP_ROUTER_ADDRESS: '0x364D5e92AC39931300055c6433C97D290e1dfFe1',
    STAKING_ADDRESS: '0xA61D94c0fD0Fa1A4742428CeDA1a14eED120E61b',
    WSS_URL: WSS_URL?.Mumbai,
    NETWORK_URL: NETWORK_URL?.Mumbai,
    WRAPPED_MAIN_ADDRESS: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    STAKED_VUNIT_ADDRESS: '0x8bDF9ddbFda9204c2326b4d5B258327739C0EF7a', //svUNIT
    TREASURY_ADDRESS: '0x00A0D699A591E1Fe82c14f2D05d6D0330b389BC5', // treasury
    BOND_STAKING_ADDRESS: '0x86905D68CE81e786E710C7e8386e74Bbf026Ea13', // staking
    BOND_STAKING_WARMUP_ADDRESS: '0x2a6C568a181790f169667f3A32dDF11Ac0B598C4', // stakingWarnup
    BOND_STAKING_HELPER_ADDRESS: '0x74e2dd397897Ea659CF7e15AEbedec603f38c244', // stakingHelper
    BOND_DISTRIBUTOR_ADDRESS: '0xDF194b19F5AF3150F776F69EE7E37F4C7835F407', // distributor
    BOND_CALCULATOR_ADDRESS: '0xe2b9008DA2a660490085C19411Be8EC38ACa1F86', // calculator
    MAIN_CURRENCY: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
      chainId: NetworkId?.POLYGON_TESTNET,
      status: 2,
      logoURI: IMAGEURL?.WMATIC,
    },
    VCASH: {
      name: 'Virtual Unit',
      symbol: 'vUNIT',
      address: '0x22a1BfDd3287614eC8ad881A82b9897950aaD603',
      decimals: 18,
      chainId: NetworkId?.POLYGON_TESTNET,
      logoURI: IMAGEURL?.VUNIT,
    },
    MONO: {
      name: 'Mono',
      symbol: 'MONO',
      address: '0x553CaA470bd8AddA72F8CAF60ceef8238540FDb6',
      decimals: 18,
      chainId: NetworkId?.POLYGON_TESTNET,
      logoURI: IMAGEURL?.MONO,
    },
  },
  [NetworkId?.TESTNET_HARMONY]: {
    NAME: 'Harmony',
    LONGNAME: 'Harmony',
    POOL_ADDRESS: '',
    SWAP_ADDRESS: '',
    STAKING_ADDRESS: '',
    WSS_URL: WSS_URL?.Harmony,
    NETWORK_URL: NETWORK_URL?.Harmony,
    WRAPPED_MAIN_ADDRESS: '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a',
    STAKED_VUNIT_ADDRESS: '0x220afDcaE34D63EDe6ba68d9F50fFe5632d70a28', //svUNIT
    TREASURY_ADDRESS: '0xc4fc2EdA939DC8ae6eD6E99AEd09773A0Db9C9ca', // treasury
    BOND_STAKING_ADDRESS: '0xFE03CdcAd1B0fb077Fe0155AB98981b5b73B9D75', // staking
    BOND_STAKING_WARMUP_ADDRESS: '0x841Bd93c45671AcAc37C00475920C6E87F855811', // stakingWarnup
    BOND_STAKING_HELPER_ADDRESS: '0x07EC827B9A72D20153484780cB213d4C218ebA19', // stakingHelper
    BOND_DISTRIBUTOR_ADDRESS: '0xdd640C0eB39E6a9A305900E9697f05E4B2cD7F87', // distributor
    BOND_CALCULATOR_ADDRESS: '0x98Cf308FF848E00B79D9FDeA49e0101fEceE8223', // calculator
    MAIN_CURRENCY: {
      name: 'ONE',
      symbol: 'ONE',
      decimals: 18,
      chainId: NetworkId?.TESTNET_HARMONY,
      status: 2,
      logoURI: IMAGEURL?.ONE,
    },
    VCASH: {
      name: 'Virtual Unit',
      symbol: 'vUNIT',
      address: '0x26d1fe45321Eba501340945F84ee61f0264AE3dF',
      decimals: 18,
      chainId: NetworkId?.TESTNET_HARMONY,
      logoURI: IMAGEURL?.VUNIT,
    },
    MONO: {},
  },
  [NetworkId?.ARBITRUM]: {
    NAME: 'Arbitrum',
    LONGNAME: 'Arbitrum',
    POOL_ADDRESS: '',
    SWAP_ADDRESS: '',
    STAKING_ADDRESS: '',
    WSS_URL: WSS_URL?.Arbitrum,
    NETWORK_URL: NETWORK_URL?.Arbitrum,
    WRAPPED_MAIN_ADDRESS: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    STAKED_VUNIT_ADDRESS: '0x543cA905FDC27F1b86494B5868f1f6C63Bd70232', //svUNIT
    TREASURY_ADDRESS: '0x78c5B52a59729c4b0Ab646c4902dF1ec959E7fE1', // treasury
    BOND_STAKING_ADDRESS: '0xF48bb507362096323C46FAc1640b2872B712E906', // staking
    BOND_STAKING_WARMUP_ADDRESS: '0x8A5De106BC8B38Bd8Ff01bD7082fB908FcCaAA05', // stakingWarnup
    BOND_STAKING_HELPER_ADDRESS: '0x1FC976Bdb9dCA3d5125687656F40C9f16Be5E7d6', // stakingHelper
    BOND_DISTRIBUTOR_ADDRESS: '0xA1d476d66867e6692aFB46887Bbae84180E2b871', // distributor
    BOND_CALCULATOR_ADDRESS: '0x515550c840D601E8A6df4b35E8161C7c9a29DE60', // calculator
    BOND_REDEEM_HELPER_ADDRESS: '0x79D13d36d90AD54d4734313252b7eAc56784B231', // redeemHelper
    MAIN_CURRENCY: {
      name: 'ARETH',
      symbol: 'ARETH',
      decimals: 18,
      chainId: NetworkId?.ARBITRUM,
      status: 2,
      logoURI: IMAGEURL?.ARETH,
    },
    VCASH: {
      name: 'Virtual Unit',
      symbol: 'vUNIT',
      address: '0x2f4862f141eA85ebED8e28285A932A98A510c337',
      decimals: 18,
      chainId: NetworkId?.ARBITRUM,
      logoURI: IMAGEURL?.VUNIT,
    },
    MONO: {},
  },
  [NetworkId?.TESTNET_ARBITRUM]: {
    NAME: 'arbirinkeby',
    LONGNAME: 'Arbitrum Rinkeby',
    POOL_ADDRESS: '',
    SWAP_ADDRESS: '',
    STAKING_ADDRESS: '',
    WSS_URL: WSS_URL?.Arbirinkeby,
    NETWORK_URL: NETWORK_URL?.Arbirinkeby,
    WRAPPED_MAIN_ADDRESS: '0xb47e6a5f8b33b3f17603c83a0535a9dcd7e32681',
    STAKED_VUNIT_ADDRESS: '0x8E51650b0C0C0ac7eAE5B226AA58DFA9f79a18b8', //svUNIT
    TREASURY_ADDRESS: '0x220afDcaE34D63EDe6ba68d9F50fFe5632d70a28', // treasury
    BOND_STAKING_ADDRESS: '0xe5E90f2ea787D81726bbc1CAFE9710784A72Ea20', // staking
    BOND_STAKING_WARMUP_ADDRESS: '0x61337FDE9429047d3977496169F3FFe007429a21', // stakingWarnup
    BOND_STAKING_HELPER_ADDRESS: '0xE3850A3DE5FDc203e416aFD9760d8153DC3bcbcE', // stakingHelper
    BOND_DISTRIBUTOR_ADDRESS: '0x841Bd93c45671AcAc37C00475920C6E87F855811', // distributor
    BOND_CALCULATOR_ADDRESS: '0xFE03CdcAd1B0fb077Fe0155AB98981b5b73B9D75', // calculator
    BOND_REDEEM_HELPER_ADDRESS: '0x07EC827B9A72D20153484780cB213d4C218ebA19', // redeemHelper
    MAIN_CURRENCY: {
      name: 'ARETH',
      symbol: 'ARETH',
      decimals: 18,
      chainId: NetworkId?.TESTNET_ARBITRUM,
      status: 2,
      logoURI: IMAGEURL?.ARETH,
    },
    VCASH: {
      name: 'Virtual Unit',
      symbol: 'vUNIT',
      address: '0xdd640C0eB39E6a9A305900E9697f05E4B2cD7F87',
      decimals: 18,
      chainId: NetworkId?.TESTNET_ARBITRUM,
      logoURI: IMAGEURL?.VUNIT,
    },
    MONO: {},
  },
  [NetworkId?.BINANCE]: {
    NAME: 'BSC',
    LONGNAME: 'Binance Smart Chain',
    POOL_ADDRESS: '',
    SWAP_ADDRESS: '',
    STAKING_ADDRESS: '',
    WSS_URL: WSS_URL?.BSC,
    NETWORK_URL: NETWORK_URL?.BSC,
    WRAPPED_MAIN_ADDRESS: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    STAKED_VUNIT_ADDRESS: '0xA1d476d66867e6692aFB46887Bbae84180E2b871', //svUNIT
    TREASURY_ADDRESS: '0x2f4862f141eA85ebED8e28285A932A98A510c337', // treasury
    BOND_STAKING_ADDRESS: '0x79D13d36d90AD54d4734313252b7eAc56784B231', // staking
    BOND_STAKING_WARMUP_ADDRESS: '0x543cA905FDC27F1b86494B5868f1f6C63Bd70232', // stakingWarnup
    BOND_STAKING_HELPER_ADDRESS: '0xF48bb507362096323C46FAc1640b2872B712E906', // stakingHelper
    BOND_DISTRIBUTOR_ADDRESS: '0x78c5B52a59729c4b0Ab646c4902dF1ec959E7fE1', // distributor
    BOND_CALCULATOR_ADDRESS: '0x266aC667206820058EFb02DE646F159057f7a321', // calculator
    BOND_REDEEM_HELPER_ADDRESS: '0x515550c840D601E8A6df4b35E8161C7c9a29DE60', // redeemHelper
    MAIN_CURRENCY: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
      chainId: NetworkId?.BINANCE,
      status: 2,
      logoURI: IMAGEURL?.BNB,
    },
    VCASH: {
      name: 'Virtual Unit',
      symbol: 'vUNIT',
      address: '0x632fbF85F77978437073a8CE5CEEC29e3209514c',
      decimals: 18,
      chainId: NetworkId?.BINANCE,
      logoURI: IMAGEURL?.VUNIT,
    },
    MONO: {},
  },
  [NetworkId?.BINANCE_TESTNET]: {
    NAME: 'BSCTestnet',
    LONGNAME: 'Binance Smart Chain Testnet',
    POOL_ADDRESS: '',
    SWAP_ADDRESS: '',
    STAKING_ADDRESS: '',
    WSS_URL: WSS_URL?.BSCTestnet,
    NETWORK_URL: NETWORK_URL?.BSCTestnet,
    WRAPPED_MAIN_ADDRESS: '0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F',
    STAKED_VUNIT_ADDRESS: '0x8E51650b0C0C0ac7eAE5B226AA58DFA9f79a18b8', //svUNIT
    TREASURY_ADDRESS: '0x220afDcaE34D63EDe6ba68d9F50fFe5632d70a28', // treasury
    BOND_STAKING_ADDRESS: '0xe5E90f2ea787D81726bbc1CAFE9710784A72Ea20', // staking
    BOND_STAKING_WARMUP_ADDRESS: '0x61337FDE9429047d3977496169F3FFe007429a21', // stakingWarnup
    BOND_STAKING_HELPER_ADDRESS: '0xE3850A3DE5FDc203e416aFD9760d8153DC3bcbcE', // stakingHelper
    BOND_DISTRIBUTOR_ADDRESS: '0x841Bd93c45671AcAc37C00475920C6E87F855811', // distributor
    BOND_CALCULATOR_ADDRESS: '0xFE03CdcAd1B0fb077Fe0155AB98981b5b73B9D75', // calculator
    BOND_REDEEM_HELPER_ADDRESS: '0x07EC827B9A72D20153484780cB213d4C218ebA19', // redeemHelper
    MAIN_CURRENCY: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
      chainId: NetworkId?.BINANCE_TESTNET,
      status: 2,
      logoURI: IMAGEURL?.BNB,
    },
    VCASH: {
      name: 'Virtual Unit',
      symbol: 'vUNIT',
      address: '0xdd640C0eB39E6a9A305900E9697f05E4B2cD7F87',
      decimals: 18,
      chainId: NetworkId?.BINANCE_TESTNET,
      logoURI: IMAGEURL?.VUNIT,
    },
    MONO: {},
  },
  [NetworkId?.MOONBEAM]: {
    NAME: 'Moonbeam',
    LONGNAME: 'MoonBeam',
    POOL_ADDRESS: '',
    SWAP_ADDRESS: '',
    STAKING_ADDRESS: '',
    WSS_URL: WSS_URL?.Moonbeam,
    NETWORK_URL: NETWORK_URL?.Moonbeam,
    WRAPPED_MAIN_ADDRESS: '0xAcc15dC74880C9944775448304B263D191c6077F',
    STAKED_VUNIT_ADDRESS: '0x543cA905FDC27F1b86494B5868f1f6C63Bd70232', //svUNIT
    TREASURY_ADDRESS: '0x78c5B52a59729c4b0Ab646c4902dF1ec959E7fE1', // treasury
    BOND_STAKING_ADDRESS: '0xF48bb507362096323C46FAc1640b2872B712E906', // staking
    BOND_STAKING_WARMUP_ADDRESS: '0x8A5De106BC8B38Bd8Ff01bD7082fB908FcCaAA05', // stakingWarnup
    BOND_STAKING_HELPER_ADDRESS: '0x1FC976Bdb9dCA3d5125687656F40C9f16Be5E7d6', // stakingHelper
    BOND_DISTRIBUTOR_ADDRESS: '0xA1d476d66867e6692aFB46887Bbae84180E2b871', // distributor
    BOND_CALCULATOR_ADDRESS: '0x515550c840D601E8A6df4b35E8161C7c9a29DE60', // calculator
    BOND_REDEEM_HELPER_ADDRESS: '0x79D13d36d90AD54d4734313252b7eAc56784B231', // redeemHelper
    MAIN_CURRENCY: {
      name: 'GLMR',
      symbol: 'GLMR',
      decimals: 18,
      chainId: NetworkId?.MOONBEAM,
      status: 2,
      logoURI: IMAGEURL?.GLMR,
    },
    VCASH: {
      name: 'Virtual Unit',
      symbol: 'vUNIT',
      address: '0x2f4862f141eA85ebED8e28285A932A98A510c337',
      decimals: 18,
      chainId: NetworkId?.MOONBEAM,
      logoURI: IMAGEURL?.VUNIT,
    },
    MONO: {},
  },
  [NetworkId?.MOONBEAM_TESTNET]: {
    NAME: 'Moonbase',
    LONGNAME: 'Moonbase Alpha',
    POOL_ADDRESS: '',
    SWAP_ADDRESS: '',
    STAKING_ADDRESS: '',
    WSS_URL: WSS_URL?.Moonbase,
    NETWORK_URL: NETWORK_URL?.Moonbase,
    WRAPPED_MAIN_ADDRESS: '0x9dCca533798Aae4EC78cFEb057cb7745DcDe3048',
    STAKED_VUNIT_ADDRESS: '0x220afDcaE34D63EDe6ba68d9F50fFe5632d70a28', //svUNIT
    TREASURY_ADDRESS: '0xc4fc2EdA939DC8ae6eD6E99AEd09773A0Db9C9ca', // treasury
    BOND_STAKING_ADDRESS: '0xFE03CdcAd1B0fb077Fe0155AB98981b5b73B9D75', // staking
    BOND_STAKING_WARMUP_ADDRESS: '0x841Bd93c45671AcAc37C00475920C6E87F855811', // stakingWarnup
    BOND_STAKING_HELPER_ADDRESS: '0x07EC827B9A72D20153484780cB213d4C218ebA19', // stakingHelper
    BOND_DISTRIBUTOR_ADDRESS: '0xdd640C0eB39E6a9A305900E9697f05E4B2cD7F87', // distributor
    BOND_CALCULATOR_ADDRESS: '0x98Cf308FF848E00B79D9FDeA49e0101fEceE8223', // calculator
    BOND_REDEEM_HELPER_ADDRESS: '0x767FCA94FE90bD5724d6C8e3D778166C163aB432', // redeemHelper
    MAIN_CURRENCY: {
      name: 'GLMR',
      symbol: 'GLMR',
      decimals: 18,
      chainId: NetworkId?.MOONBEAM_TESTNET,
      status: 2,
      logoURI: IMAGEURL?.GLMR,
    },
    VCASH: {
      name: 'Virtual Unit',
      symbol: 'vUNIT',
      address: '0x26d1fe45321Eba501340945F84ee61f0264AE3dF',
      decimals: 18,
      chainId: NetworkId?.MOONBEAM_TESTNET,
      logoURI: IMAGEURL?.VUNIT,
    },
    MONO: {},
  },
}

let config = configProd
export default config

export const MAIN_LANGUAGE = {
  id: 'en',
  abbr: 'EN',
  name: 'English',
  first: true,
}

export const BondType = {
  StableAsset: 'StableAsset',
  LP: 'LP',
}

export const BOND_LIST = {
  [NetworkId?.POLYGON]: [
    {
      token1: {
        logoURI: IMAGEURL?.USDC,
        name: 'USD Coin',
        symbol: 'USDC',
        chainId: NetworkId?.POLYGON,
        address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        decimals: 6,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0x1FC976Bdb9dCA3d5125687656F40C9f16Be5E7d6',
      reserveAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      bondRoute: '7day-usdc',
      bondName: 'USDC 7-day',
      period: 7,
    },
    {
      token1: {
        logoURI: IMAGEURL?.WETH,
        name: 'Wrapped Ether',
        symbol: 'WETH',
        chainId: NetworkId?.POLYGON,
        address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
        decimals: 18,
      },
      token2: {},
      isWethBond: true,
      bondABI: wETHBondContractAbi,
      bondAddress: '0x2535833B07cECfd67d7a12aA4552cB7bbCf20bE3',
      reserveAddress: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
      bondRoute: '7day-weth',
      bondName: 'WETH 7-day',
      period: 7,
    },
    {
      token1: {
        logoURI: IMAGEURL?.USDT,
        name: 'Tether USD',
        symbol: 'USDT',
        chainId: NetworkId?.POLYGON,
        address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        decimals: 6,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0xF91e189ee81afa32ff6FB2Ba0070594042d0f73B',
      reserveAddress: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      bondRoute: '7day-usdt',
      bondName: 'USDT 7-day',
      period: 7,
    },
    {
      token1: configProd?.[NetworkId?.POLYGON].VCASH,
      token2: {
        logoURI: IMAGEURL?.USDC,
        name: 'USD Coin',
        symbol: 'USDC',
        chainId: NetworkId?.POLYGON,
        address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        decimals: 6,
      },
      isLP: true,
      bondABI: VUSDTLPBondContractAbi,
      bondAddress: '0xfbF656d2b7ec0C0385e080326FE9EA4Efcbe1B0d',
      reserveAddress: '0xae67289a092387184f6286788a6c8c6d8a8abe79',
      bondRoute: '7day-vunit-usdc-lp',
      bondName: 'vUNIT-USDC Sushi LP 7-day',
      getLPTokenLink: GETLPTOKENLINK?.POLYGON,
      period: 7,
    },
    {
      token1: configProd?.[NetworkId?.POLYGON].VCASH,
      token2: {
        logoURI: IMAGEURL?.USDC,
        name: 'USD Coin',
        symbol: 'USDC',
        chainId: NetworkId?.POLYGON,
        address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        decimals: 6,
      },
      isLP: true,
      bondABI: VUSDTLPBondContractAbi,
      bondAddress: '0x008E2aA55e3f93B10454be0a59c8C2826828C2f0',
      reserveAddress: '0xae67289a092387184f6286788a6c8c6d8a8abe79',
      bondRoute: '30day-vunit-usdc-lp-30day',
      bondName: 'vUNIT-USDC Sushi LP 30-day',
      getLPTokenLink: GETLPTOKENLINK?.POLYGON,
      period: 30,
    },
    {
      token1: configProd?.[NetworkId?.POLYGON].VCASH,
      token2: {
        logoURI: IMAGEURL?.USDC,
        name: 'USD Coin',
        symbol: 'USDC',
        chainId: NetworkId?.POLYGON,
        address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        decimals: 6,
      },
      isLP: true,
      bondABI: VUSDTLPBondContractAbi,
      bondAddress: '0x260D4bd384718F93402dB522552577B8225C7497',
      reserveAddress: '0xae67289a092387184f6286788a6c8c6d8a8abe79',
      bondRoute: '1year-vunit-usdc-lp',
      bondName: 'vUNIT-USDC Sushi LP 1-year',
      getLPTokenLink: GETLPTOKENLINK?.POLYGON,
      period: 365,
    },
    {
      token1: configProd?.[NetworkId?.POLYGON].VCASH,
      token2: {},
      isWethBond: true,
      isvUnitBond: true,
      bondABI: wETHBondContractAbi,
      bondAddress: '0x30934326F797B95ce71400846551C1b47a4e956c',
      reserveAddress: configProd?.[NetworkId?.POLYGON].VCASH.address,
      bondRoute: '30day-vunit',
      bondName: 'vUNIT 30-day Staking',
      period: 30,
    },
    {
      token1: configProd?.[NetworkId?.POLYGON].VCASH,
      token2: {},
      isWethBond: true,
      isvUnitBond: true,
      bondABI: wETHBondContractAbi,
      bondAddress: '0x0fB12a9C344287704d7A4247C61387Bf3E22d90F',
      reserveAddress: configProd?.[NetworkId?.POLYGON].VCASH.address,
      bondRoute: '1year-vunit',
      bondName: 'vUNIT 1-year Staking',
      period: 365,
    },
  ],
  [NetworkId?.TESTNET_KOVAN]: [
    {
      displayName: 'wETH',
      bondToken: 'wETH',
      payoutToken: 'vUNIT',
      bondType: BondType.StableAsset,
      isWethBond: true,
      token1: {
        ...configProd?.[NetworkId?.TESTNET_KOVAN].MAIN_CURRENCY,
        address: configProd?.[NetworkId?.TESTNET_KOVAN].WRAPPED_MAIN_ADDRESS,
        name: 'Wrapped Ether',
        symbol: 'WETH',
      },
      token2: {},
      bondABI: wETHBondContractAbi,
      bondAddress: '0x846ea93DDC900F7619bB65851b584574D40612bC',
      reserveAddress: configProd?.[NetworkId?.TESTNET_KOVAN].WRAPPED_MAIN_ADDRESS,
      bondRoute: 'weth',
      bondName: 'WETH',
      period: 7,
    },
    {
      token1: {
        logoURI: '',
        name: 'Dai Stablecoin',
        symbol: 'DAI',
        chainId: NetworkId?.TESTNET_KOVAN,
        address: '0x59c988F2F27c5C9692b2a0dF84fA1C468E9A02dD', // fake DAI
        decimals: 18,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0x2Ed43A5a16405a7071e2368b1aFD9b719bf619E0',
      reserveAddress: '0x59c988F2F27c5C9692b2a0dF84fA1C468E9A02dD',
      bondRoute: 'dai',
      bondName: 'DAI',
      period: 7,
    },
    {
      token1: {
        logoURI: '',
        name: 'USDC Coin',
        symbol: 'USDC',
        chainId: NetworkId?.TESTNET_KOVAN,
        address: '0x5555dba87dcc9dfe457902f20d5a1c85236f669b', // fake USDT
        decimals: 6,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0xa62243f5F0c4C27fa87137260777aE6d637372BA',
      reserveAddress: '0x5555dba87dcc9dfe457902f20d5a1c85236f669b',
      bondRoute: 'usdc',
      bondName: 'USDC',
      period: 7,
    },
    {
      token1: configProd?.[NetworkId?.TESTNET_KOVAN].VCASH,
      token2: {
        logoURI: '',
        name: 'Dai Stablecoin',
        symbol: 'DAI',
        chainId: NetworkId?.TESTNET_KOVAN,
        address: '0x59c988F2F27c5C9692b2a0dF84fA1C468E9A02dD', // fake DAI
        decimals: 18,
      },
      isLP: true,
      bondABI: VDAILPBondContractAbi,
      bondAddress: '0x6FC43418CD30b19F5602F4cC2D3eeC38bc5C513e',
      reserveAddress: '0x0eD9af76E8d323d7B37bd2a6D50e28dEf1503c07',
      bondRoute: 'vunit-dai-uni-lp',
      bondName: 'vUNIT-DAI Uni LP',
      getLPTokenLink: GETLPTOKENLINK?.KOVAN,
      period: 7,
    },
    {
      token1: configProd?.[NetworkId?.TESTNET_KOVAN].VCASH,
      token2: {
        logoURI: '',
        name: 'Tether USD',
        symbol: 'USDT',
        chainId: NetworkId?.TESTNET_KOVAN,
        address: '0xea351E9b63E3E1b330C1690C2780447ab10Ca2E9', // fake DAI
        decimals: 6,
      },
      isLP: true,
      bondABI: VDAILPBondContractAbi,
      bondAddress: '0x5C17dc9BDeAAB1562be9c1f88118C18fc0f07470',
      reserveAddress: '0x7a27Df1C2Afa09F11C8873770374326B0A2a4c55',
      bondRoute: 'vunit-usdt-uni-lp',
      bondName: 'vUNIT-USDT Uni LP',
      getLPTokenLink: GETLPTOKENLINK?.KOVAN,
      period: 7,
    },
  ],
  [NetworkId?.POLYGON_TESTNET]: [
    {
      token1: {
        logoURI: IMAGEURL?.USDC,
        name: 'USD Coin',
        symbol: 'USDC',
        chainId: NetworkId?.POLYGON_TESTNET,
        address: '0xe6b8a5CF854791412c1f6EFC7CAf629f5Df1c747', // fake USDC
        decimals: 6,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0x05d7A7C37f5cb1972261dED243cB6F86F36b7a67',
      reserveAddress: '0xe6b8a5CF854791412c1f6EFC7CAf629f5Df1c747',
      bondRoute: 'usdc',
      bondName: 'USDC',
      period: 7,
    },
    {
      token1: configProd?.[NetworkId?.POLYGON_TESTNET].VCASH,
      token2: {
        logoURI: IMAGEURL?.USDT,
        name: 'Tether USD',
        symbol: 'USDT',
        chainId: NetworkId?.POLYGON_TESTNET,
        address: '0xA02f6adc7926efeBBd59Fd43A84f4E0c0c91e832',
        decimals: 6,
      },
      isLP: true,
      bondABI: VUSDTLPBondContractAbi,
      bondAddress: '0x5696309db859d8a1Ee6B646a35ed9FE4F0de30E8',
      reserveAddress: '0x927a6dEC8347271aa021AAAd394b95648db02E83',
      bondRoute: 'vunit-usdt-lp',
      bondName: 'vUNIT-USDT LP',
      getLPTokenLink: GETLPTOKENLINK?.KOVAN,
      period: 7,
    },
  ],
  [NetworkId?.AVALANCHE]: [
    {
      token1: {
        logoURI: IMAGEURL?.USDT,
        name: 'Tether USD',
        symbol: 'USDT',
        chainId: NetworkId?.AVALANCHE,
        address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
        decimals: 6,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0x140ceD0c04e03eFFC3Ada0f71C27d815E21835C6',
      reserveAddress: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      bondRoute: 'usdt',
      bondName: 'USDT',
      period: 7,
    },
    {
      token1: {
        logoURI: IMAGEURL?.USDC,
        name: 'USD Coin',
        symbol: 'USDC',
        chainId: NetworkId?.AVALANCHE,
        address: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
        decimals: 6,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0x228444d71CEb2069C3a0F9eF7F92d224d0cfE24C',
      reserveAddress: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
      bondRoute: 'usdc',
      bondName: 'USDC',
      period: 7,
    },
    {
      token1: {
        logoURI: IMAGEURL?.WETH,
        name: 'Wrapped Ether',
        symbol: 'WETH',
        chainId: NetworkId?.AVALANCHE,
        address: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
        decimals: 18,
      },
      token2: {},
      isWethBond: true,
      bondABI: wETHBondContractAbi,
      bondAddress: '0xe7c0460be1025d1749449c07e8d159ddd109c089',
      reserveAddress: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
      bondRoute: 'weth',
      bondName: 'WETH',
      period: 7,
    },
  ],
  [NetworkId?.AVALANCHE_TESTNET]: [
    {
      token1: {
        logoURI: '',
        name: 'Tether USD',
        symbol: 'USDT',
        chainId: NetworkId?.AVALANCHE_TESTNET,
        address: '0x7D1D32d12d63dB904CBF40c8A58f0AAcF606B61D',
        decimals: 6,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0x2b780F47DAA14CF8C68c89Fa3de57B556A8e6023',
      reserveAddress: '0x7D1D32d12d63dB904CBF40c8A58f0AAcF606B61D',
      bondRoute: 'usdt',
      bondName: 'USDT',
      period: 7,
    },
  ],
  [NetworkId?.TESTNET_HARMONY]: [
    {
      token1: {
        logoURI: '',
        name: 'Tether USD',
        symbol: 'USDT',
        chainId: NetworkId?.TESTNET_HARMONY,
        address: '0x31716f0109412028477069a224d8b8eb5777207C',
        decimals: 6,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0xe5E90f2ea787D81726bbc1CAFE9710784A72Ea20',
      reserveAddress: '0x31716f0109412028477069a224d8b8eb5777207C',
      bondRoute: 'usdt',
      bondName: 'USDT',
      period: 7,
    },
  ],
  [NetworkId?.ARBITRUM]: [
    {
      token1: {
        logoURI: IMAGEURL?.WETH,
        name: 'Wrapped Ether',
        symbol: 'WETH',
        chainId: NetworkId?.ARBITRUM,
        address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
        decimals: 18,
      },
      token2: {},
      isWethBond: true,
      bondABI: wETHBondContractAbi,
      bondAddress: '0xe9b111f9f4D3a748c6CE817aC7a7E72EFB147F94',
      reserveAddress: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      bondRoute: 'weth',
      bondName: 'WETH',
      period: 7,
    },
    {
      token1: {
        logoURI: IMAGEURL?.USDC,
        name: 'USD Coin',
        symbol: 'USDC',
        chainId: NetworkId?.ARBITRUM,
        address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
        decimals: 6,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0x77c9349fE4b2dcc6C17570B802FB2298714C30Fe',
      reserveAddress: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      bondRoute: 'usdc',
      bondName: 'USDC',
      period: 7,
    },
    {
      token1: {
        logoURI: IMAGEURL?.USDT,
        name: 'Tether USD',
        symbol: 'USDT',
        chainId: NetworkId?.ARBITRUM,
        address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        decimals: 6,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0x374a3d831b3c57cD958411A087B1BDb4f93Be59E',
      reserveAddress: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      bondRoute: 'usdt',
      bondName: 'USDT',
      period: 7,
    },
  ],
  [NetworkId?.TESTNET_ARBITRUM]: [
    {
      token1: {
        logoURI: '',
        name: 'Tether USD',
        symbol: 'USDT',
        chainId: NetworkId?.TESTNET_ARBITRUM,
        address: '0xc4fc2EdA939DC8ae6eD6E99AEd09773A0Db9C9ca',
        decimals: 6,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0x0001e2fa4D96618304497dd9D923627EeDDE36b9',
      reserveAddress: '0xc4fc2EdA939DC8ae6eD6E99AEd09773A0Db9C9ca',
      bondRoute: 'usdt',
      bondName: 'USDT',
      period: 7,
    },
  ],
  [NetworkId?.BINANCE_TESTNET]: [
    {
      token1: {
        logoURI: '',
        name: 'DAI Stablecoin',
        symbol: 'DAI',
        chainId: NetworkId?.BINANCE_TESTNET,
        address: '0x26d1fe45321Eba501340945F84ee61f0264AE3dF',
        decimals: 18,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0x0001e2fa4D96618304497dd9D923627EeDDE36b9',
      reserveAddress: '0x26d1fe45321Eba501340945F84ee61f0264AE3dF',
      bondRoute: 'dai',
      bondName: 'DAI',
      period: 7,
    },
  ],
  [NetworkId?.BINANCE]: [
    {
      token1: {
        logoURI: IMAGEURL?.BUSD,
        name: 'Binance-Peg BUSD',
        symbol: 'BUSD',
        chainId: NetworkId?.BINANCE,
        address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        decimals: 18,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0x1FC976Bdb9dCA3d5125687656F40C9f16Be5E7d6',
      reserveAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      bondRoute: 'busd',
      bondName: 'BUSD',
      period: 7,
    },
    {
      token1: {
        logoURI: IMAGEURL?.WETH,
        name: 'Binance-Peg Ethereum',
        symbol: 'ETH',
        chainId: NetworkId?.BINANCE,
        address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
        decimals: 18,
      },
      token2: {},
      bondABI: wETHBondContractAbi,
      isWethBond: true,
      bondAddress: '0x505803F5C920856608209d31671f3D2CFa154FD5',
      reserveAddress: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
      bondRoute: 'eth',
      bondName: 'ETH',
      period: 7,
    },
    {
      token1: {
        logoURI: IMAGEURL?.BNB,
        name: 'Wrapped BNB',
        symbol: 'WBNB',
        chainId: NetworkId?.BINANCE,
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        decimals: 18,
      },
      token2: {},
      bondABI: wETHBondContractAbi,
      isWethBond: true,
      bondAddress: '0x7668Cd461D264ac8ff42A2f0ffd4FD2d3bA43016',
      reserveAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      bondRoute: 'wbnb',
      bondName: 'WBNB',
      period: 7,
    },
  ],
  [NetworkId?.MOONBEAM_TESTNET]: [
    {
      token1: {
        logoURI: '',
        name: 'Tether USD',
        symbol: 'USDT',
        chainId: NetworkId?.MOONBEAM_TESTNET,
        address: '0x31716f0109412028477069a224d8b8eb5777207C',
        decimals: 6,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0xe5E90f2ea787D81726bbc1CAFE9710784A72Ea20',
      reserveAddress: '0x31716f0109412028477069a224d8b8eb5777207C',
      bondRoute: 'usdt',
      bondName: 'USDT',
      period: 7,
    },
  ],
  [NetworkId?.MOONBEAM]: [
    {
      token1: {
        logoURI: IMAGEURL?.USDC,
        name: 'USD Coin',
        symbol: 'USDC',
        chainId: NetworkId?.MOONBEAM,
        address: '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
        decimals: 6,
      },
      token2: {},
      bondABI: BondContractAbi,
      bondAddress: '0x77c9349fE4b2dcc6C17570B802FB2298714C30Fe',
      reserveAddress: '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
      bondRoute: 'usdc',
      bondName: 'USDC',
      period: 7,
    },
  ],
}
export const RESTRICTED_COUNTRIES = ['CN']
