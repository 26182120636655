import React, { useEffect, useState } from 'react'
import { Provider as ReduxProvider, useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Web3ReactProvider } from '@web3-react/core'
import 'react-toastify/dist/ReactToastify.css'
import { IntlProvider } from 'react-intl'
import { ErrorBoundary } from 'react-error-boundary'
import MobileDetect from 'mobile-detect'
import VConsole from 'vconsole'
import { localeData } from 'i18n'
import { MAIN_LANGUAGE } from 'monox/config'
import { isOnTest, getLibrary } from 'monox/util'
import { store } from 'state/index'
import ModalsProvider from 'contexts/Modals'
import AccountProvider from 'contexts/AccountProvider'
import TokenProvider from 'contexts/TokenProvider'
// import { RefreshContextProvider } from 'contexts/RefreshContext'

import ErrorFallback from 'ErrorFallback'
import 'App.css'
import PublicRoutes from 'PublicRoutes'
import { config as themeConfig } from 'theme/config'

const md = new MobileDetect(window.navigator.userAgent)

if (md.mobile() && isOnTest()) {
  new VConsole()
}

function App() {
  const [isDark, setIsDark] = useState(false)
  const [theme, setTheme] = useState(
    localStorage.getItem('monox:theme')
      ? themeConfig[localStorage.getItem('monox:theme')]
      : themeConfig.A
  )
  const [lang, setLang] = useState(MAIN_LANGUAGE)
  return (
    <IntlProvider locale={lang?.id} messages={localeData[lang?.id]}>
      <ReduxProvider store={store}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <AccountProvider>
            <TokenProvider>
              {/* <RefreshContextProvider> */}
              <ThemeProvider theme={isDark ? theme.dark : theme.light}>
                <Router>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <ModalsProvider>
                      <PublicRoutes
                        isDark={isDark}
                        setTheme={setTheme}
                        setIsDark={setIsDark}
                        lang={lang}
                        setLang={setLang}
                      />
                    </ModalsProvider>
                  </ErrorBoundary>
                </Router>
              </ThemeProvider>
              {/* </RefreshContextProvider> */}
            </TokenProvider>
          </AccountProvider>
        </Web3ReactProvider>
      </ReduxProvider>
    </IntlProvider>
  )
}

export default App
