import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'

import {
  DISPLAY_MIN_VALUE,
  removeNotation,
  weiToEth,
  allTokensDict,
  DEFAULT_NETWORK_ID,
  networks,
} from 'monox/constants'
import StyledToast from 'components/StyledToast'

import { uriToHttp } from 'monox/getTokenList'

require('dayjs/locale/ko')
require('dayjs/locale/es')
require('dayjs/locale/tr')
require('dayjs/locale/zh')
require('dayjs/locale/ru')
require('dayjs/locale/ko')
require('dayjs/locale/uk')
require('dayjs/locale/id')
require('dayjs/locale/lv')
require('dayjs/locale/vi')
require('dayjs/locale/ja')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
const userTimezone = dayjs.tz.guess()

export const getTokenMetaFromAddress = (address) => {
  if (address) return allTokensDict[address.toLowerCase()]
  else return {}
}

export const getPoolTokenSymbol = (pool) =>
  getTokenMetaFromAddress(pool?.token)?.symbol

export const getPoolRewardText = (pool) =>
  `${weiToEth(BigNumber(pool?.reward))} ${getPoolTokenSymbol(pool)}`

export const getPoolTokenLogoURL = (pool) => {
  const token = getTokenMetaFromAddress(pool?.token)
  return token ? uriToHttp(token?.logoURI)?.[0] : null
}

export const getTokenEtherFromWeiByAddress = (token, amount) =>
  BigNumber(10 ** token?.decimals)
    .times(BigNumber(amount))
    .toFixed(0)

export const isUnnamed = (pool) =>
  pool && (pool?.name === 'undefined' || pool?.name === '')

export const timeDurationMap = {
  '24H': {
    difference: 30,
    unit: 'day',
    format: 'hh:mm A z',
    graphFormat: 'HH:mm',
    displayText: 'Past 24 Hour',
    timeRangeText: '24H',
    interval: 1,
    translateId: 'common.graph.sub_title.hours',
    timeRangeId: 'common.graph.sub_range_title.hours',
  },
  '1W': {
    difference: 120,
    unit: 'week',
    format: 'hh:mm A MMM D z',
    graphFormat: 'MMM D',
    displayText: 'Past Week',
    timeRangeText: '1W',
    interval: 1,
    translateId: 'common.graph.sub_title.weeks',
    timeRangeId: 'common.graph.sub_range_title.weeks',
  },
}

function getRandomInclusive(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.random() * (max - min + 1) + min
}

export const generateChartData = (range) => {
  const min = 1200
  const max = 1300
  const basePrice = getRandomInclusive(1000, 1300)

  const now = dayjs()

  const selected = timeDurationMap[range]
  const end = now.subtract(1, selected.unit)
  const data = []
  let start = now.subtract(selected.difference, 'seconds')
  while (start.isAfter(end.toDate())) {
    data.push({
      x: start.unix() * 1000,
      y: getRandomInclusive(min, max),
      '24H': start.tz(userTimezone).format(timeDurationMap['24H'].format),
      '1W': start.tz(userTimezone).format(timeDurationMap['1W'].format),
      '1M': start.tz(userTimezone).format(timeDurationMap['1M'].format),
    })
    start = start.subtract(selected.difference, 'minutes')
  }
  return { data: data.reverse(), basePrice: basePrice }
}

export function setTokenValue(symbol, tokenList) {
  let token
  if (symbol && symbol.startsWith('0x')) {
    token = tokenList.filter(
      (list) => list.address.toLowerCase() === symbol.toLowerCase()
    )
  } else {
    token = tokenList.filter((list) => list?.symbol === symbol)
  }
  return token?.length ? token?.[0] : 'Choose Token'
}

export const getToken = (symbol, tokenList) => {
  let token
  if (symbol && symbol.startsWith('0x')) {
    token = tokenList.find(
      (list) => list?.address?.toLowerCase() === symbol.toLowerCase()
    )
  } else {
    token = tokenList.find((list) => list.symbol === symbol)
  }
  return token
}

export function getEtherscanLink(chainId, data, type) {
  const { blockExplorer: prefix = '' } = networks?.[chainId] || {}
  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

export function precise(value, digit, fixedDigit) {
  if (!parseFloat(value) || !isFinite(parseFloat(value))) {
    return 0
  }
  const sign = Math.sign(parseFloat(value))
  if (parseFloat(Math.abs(value)) >= 1) {
    return fixedDigit
      ? parseFloat(Math.abs(value))
          .toFixed(digit)
          .match(/^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/)
          .input.replace(/(\.0+|0+)$/, '')
      : sign *
          parseFloat(Math.abs(value))
            .toFixed(digit)
            .match(/^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/)[1]
  } else if (parseFloat(Math.abs(value)) >= 0) {
    const zeroCount = -Math.floor(Math.log10(parseFloat(Math.abs(value))) + 1)
    if (zeroCount >= 17) return 0
    return fixedDigit
      ? parseFloat(Math.abs(value))
          .toFixed(zeroCount >= digit ? zeroCount + 1 : digit)
          .match(/^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/)
          .input.replace(/(\.0+|0+)$/, '')
      : sign *
          parseFloat(Math.abs(value))
            .toFixed(digit + zeroCount > 18 ? 18 : digit + zeroCount)
            .match(/^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/)[1]
  }
}

export const preciseNotationRemove = (value, length) => {
  if (precise(value, length) >= DISPLAY_MIN_VALUE) return precise(value, length)
  else
    return removeNotation(
      value?.toFixed(18)?.match(/^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/)?.[1],
      length
    )
}

export function poolValue(vcashBalance, tokenBalance, price) {
  return vcashBalance + tokenBalance * price
}

export function amountLPReceive(amount, price, poolBalance, totalSupply) {
  if (totalSupply > 0) {
    return (amount * price * totalSupply) / poolBalance
  } else if (totalSupply === 0) {
    return amount * 100
  } else {
    return 0
  }
}

export const ACTIONS = {
  SWAP: 'SWAP',
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  CREATE: 'CREATE',
  STAKE: 'STAKE',
  UNSTAKE: 'UNSTAKE',
  BOND: 'BOND',
  APPROVE: 'APPROVE',
  HARVEST: 'HARVEST',
  WRAP: 'WRAP',
  UNWRAP: 'UNWRAP',
  REDEEM: 'REDEEM',
}

export const TRANSACTION_STATUS = {
  FAIL: 'FAIL',
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  REQUESTED: 'REQUESTED',
  REJECTED: 'REJECTED',
}

export const toEllipsis = (str, numCharsToHide = 7) => {
  if (!str) return null
  if (numCharsToHide === 0) return str
  else
    return (
      str.substr(0, str.length / 2 - numCharsToHide) +
      '...' +
      str.substr(str.length / 2 + numCharsToHide, str.length)
    )
}

export const showToast = (msg, options) => {
  toast(<StyledToast>{msg}</StyledToast>, { ...options })
}

export const VCASH_LOGO =
  'https://user-images.githubusercontent.com/57688920/112673530-e3da8980-8e75-11eb-99fc-3788ad5e8f79.png'

export const formatAmount = (x) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const tokenSymbol = (currency, WRAPPED_MAIN_ADDRESS, MAIN_CURRENCY) => {
  if (currency?.address === WRAPPED_MAIN_ADDRESS) return `W${MAIN_CURRENCY.symbol}`
  else return currency?.symbol
}

export const arrayToObject = (arrayData, key, value = null) =>
  arrayData.reduce((obj, item) => {
    obj[item?.token || item?.[key] || item] = value ?? item
    return obj
  }, {})

export const useAbortableEffect = (effect, dependencies) => {
  const status = {}
  useEffect(() => {
    status.aborted = false
    const cleanUpFn = effect(status)
    return () => {
      status.aborted = true
      if (typeof cleanUpFn === 'function') {
        cleanUpFn()
      }
    }
  }, [...dependencies])
}

export const getNetworkScanName = (chainId) => {
  const { scanName = 'Etherscan' } = networks?.[chainId] || {}
  return scanName
}

export const isOnTest = () => {
  const { origin } = window?.location
  if (origin?.includes('localhost') || origin?.includes('dev')) return true
  else return false
}

export const formatThousands = (num) => {
  var values = num.toString().split('.')
  return (
    values[0].replace(/.(?=(?:.{3})+$)/g, '$&,') +
    (values.length === 2 ? '.' + values[1] : '')
  )
}

export function timeDifference(difference) {
  var daysDifference = Math.floor(difference / 60 / 60 / 24)
  difference -= daysDifference * 60 * 60 * 24

  var hoursDifference = Math.floor(difference / 60 / 60)
  difference -= hoursDifference * 60 * 60

  var minutesDifference = Math.floor(difference / 60)
  difference -= minutesDifference * 60

  var secondsDifference = Math.floor(difference)

  return {
    day: daysDifference,
    hour: hoursDifference,
    minute: minutesDifference,
    second: secondsDifference,
  }
}

export function timeDifferenceFormat(remainedTime) {
  const { second, minute, hour, day } = remainedTime
  const time = day ? [day, hour] : hour ? [hour, minute] : [minute, second]
  const label = day
    ? [
        { id: 'days', label: 'D' },
        { id: 'hours', label: 'H' },
      ]
    : hour
    ? [
        { id: 'hours', label: 'H' },
        { id: 'minutes', label: 'M' },
      ]
    : [
        { id: 'minutes', label: 'M' },
        { id: 'seconds', label: 'S' },
      ]
  return `${time?.[0]}${label[0]?.label}:${time?.[1]}${label[1]?.label}`
}

export function getLibrary(provider) {
  return provider
}

export const notationRemoved = (x) => {
  if (Math.abs(x) < 1.0) {
    let e = parseInt(x?.toString().split('e-')[1])
    if (e) {
      x *= Math.pow(10, e - 1)
      x = '0.' + new Array(e).join('0') + x?.toString()?.substring(2)
    }
  } else {
    let e = parseInt(x?.toString().split('+')[1])
    if (e > 20) {
      e -= 20
      x /= Math.pow(10, e)
      x += new Array(e + 1).join('0')
    }
  }
  return x
}

export function secondsUntilBlock(
  startBlock,
  endBlock,
  chainId = DEFAULT_NETWORK_ID
) {
  const blocksAway = endBlock - startBlock
  const { blockRate = 13.14 } = networks?.[chainId] || {}
  const secondsAway = blocksAway * blockRate
  return secondsAway
}

export function prettifySeconds(seconds, resolution) {
  if (seconds !== 0 && !seconds) {
    return ''
  }

  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)

  if (resolution === 'day') {
    return d + (d == 1 ? ' day' : ' days')
  }

  const dDisplay = d > 0 ? d + (d == 1 ? ' day, ' : ' days, ') : ''
  const hDisplay = h > 0 ? h + (h == 1 ? ' hr, ' : ' hrs, ') : ''
  const mDisplay = m > 0 ? m + (m == 1 ? ' min' : ' mins') : ''

  let result = dDisplay + hDisplay + mDisplay
  if (mDisplay === '') {
    result = result.slice(0, result.length - 2)
  }

  return result
}

export function prettyVestingPeriod(
  currentBlock,
  vestingBlock,
  chainId = DEFAULT_NETWORK_ID
) {
  if (vestingBlock === 0) {
    return ''
  }

  const seconds = secondsUntilBlock(currentBlock, vestingBlock, chainId)
  if (seconds < 0) {
    return 'Fully Vested'
  }
  return prettifySeconds(seconds)
}
