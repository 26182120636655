import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DiagonalArrowRightUpOutline } from '@styled-icons/evaicons-outline/DiagonalArrowRightUpOutline'

import config from 'monox/config'
import { getEtherscanLink, toEllipsis } from 'monox/util'
import { ubetToEth, weiToEthNum } from 'monox/constants'
import TokenImage from 'components/TokenImage'
import useWallet from 'hooks/useWallet'
import useTokenBalance from 'hooks/useTokenBalance'
import useModal from 'hooks/useModal'

import Label from 'components/Label'
import Spacer from 'components/Spacer'
import { Row } from 'components/Row'
import AnalyticsModal from 'components/AnalyticsModal'

const TokenData = ({
  token,
  index,
  setIsSearched,
  isHome = true,
  setHovered = () => {},
  sortedData = [],
  cursor = null,
  nav,
}) => {
  const { chainId } = useWallet()
  const history = useHistory()
  const { balance } = useTokenBalance(token)
  const networkName = useSelector(({ network }) => network?.NAME)?.toLowerCase()
  const isDark = useSelector(({ application }) => application.isDark)
  const networkId = useSelector(({ network }) => network.id)
  const WRAPPED_MAIN_ADDRESS = config?.[networkId || chainId]?.WRAPPED_MAIN_ADDRESS

  const [handleOpenAnalytics] = useModal(<AnalyticsModal />)

  const handleToken = (token) => {
    if (!isHome) {
      setIsSearched(false)
      handleOpenAnalytics(token)
    } else {
      history.push({
        pathname: `/analytics/${
          token?.address ?? token?.symbol
        }?network=${networkName}`,
        state: { backUrl: history.location.pathname },
      })
    }
  }

  return (
    <StyledRow
      key={index}
      onClick={() => handleToken(token)}
      style={{
        backgroundColor: index === cursor ? 'rgba(45, 196, 143, 0.05)' : '',
        borderLeft: index === cursor && '4px solid #41dea2',
      }}
      onMouseEnter={() => setHovered(sortedData)}
      onMouseLeave={() => setHovered(null)}
      nav={nav}
    >
      <TokenImage
        src={token?.logoURI}
        letter={token?.symbol?.[0]}
        height={32}
        width={32}
      />
      <Spacer size="sm" />
      <div>
        <Label text={token?.symbol} size="14" weight="bold" />
        <StyledDiv nav={nav}>
          <Label
            text={token?.name}
            size="11"
            weight="bold"
            opacity="0.3"
            style={{ marginRight: 'auto' }}
          />
          <Address isDark={isDark}>
            <Span nav={nav}>
              &nbsp;
              {token?.address && ` - `}
              &nbsp;
            </Span>
            <Link
              target="_blank"
              className="etherscan-link"
              href={getEtherscanLink(
                networkId || chainId,
                token?.address ?? WRAPPED_MAIN_ADDRESS,
                'token'
              )}
              rel="noopener noreferrer"
              style={{ justifyContent: 'left' }}
            >
              {token?.address ? `${toEllipsis(token?.address, 16)}` : ``}
              <DiagonalArrowRight />
            </Link>
          </Address>
        </StyledDiv>
      </div>
      <Label
        weight={800}
        size={13}
        text={
          weiToEthNum(balance, token?.decimals) === 0
            ? 0
            : ubetToEth(balance, token?.decimals) < 0.0001
            ? '<0.0001'
            : ubetToEth(balance, token?.decimals) || 0
        }
        style={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}
      />
    </StyledRow>
  )
}

export default TokenData

const Address = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: 11px;
  font-weight: bold;
  display: flex;
  opacity: 0.3;
  margin-right: auto;
`
const DiagonalArrowRight = styled(DiagonalArrowRightUpOutline)`
  width: 15px;
  height: 15px;
  margin-left: 0px !important;
  display: none;
`
const StyledDiv = styled(Row)`
  ${(props) =>
    props.nav &&
    `@media (max-width: 1110px) {
    flex-direction: column;
  }`}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
    justify-content: flex-start;
  `}
`

const StyledRow = styled(Row)`
  padding: 14px 31px;
  ${(props) =>
    props.nav &&
    `
  @media (max-width: 1110px) {
    padding: 14px 20px;
  }
  `}
  :hover {
    cursor: pointer;
    background-color: rgba(65, 222, 162, 0.1);
    border-left: 4px solid ${(props) => props.theme.color.border.green};
  }
  :hover ${Address} {
    color: #2eca93;
    opacity: 1;
  }
`
const Span = styled.span`
  display: block;
  ${(props) =>
    props.nav &&
    `@media (max-width: 1110px) {
    display: none;
  }`}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `}
`
const Link = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  cursor: ${(props) => (props?.isRejected ? 'default' : 'pointer')};
  text-decoration: none;
  svg {
    margin-left: 5px;
    * {
      color: #2dc48f;
    }
  }
  :hover ${DiagonalArrowRight} {
    display: block;
  }
`
