import { css } from 'styled-components'
import { black, grey, red, white } from 'theme/colors'
import logo from 'assets/img/account_logo.png'
import logo3x from 'assets/img/logo/logo@3x.png'
import accountLogo from 'assets/img/account_logo.png'

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
}

const mediaWidthTemplates = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  accumulator[size] = (a, b, c) => css`
    @media (max-width: ${MEDIA_WIDTHS[size]}px) {
      ${css(a, b, c)}
    }
  `
  return accumulator
}, {})

export const config = {
  A: {
    light: {
      borderRadius: 12,
      breakpoints: [400],
      color: {
        black,
        grey,
        red,
        primary: {
          light: '#41dba0',
          main: '#2dc48f',
          green: '#2eca93',
        },
        secondary: {
          main: '#202b65',
        },
        white,
        background: {
          main: '#f5f5f8',
          content: '#1f1f1f',
          secondary: '#ecf3f0',
          paleGrey: '#f5f5f8',
          token: '#f5f5f8',
          footer: '#41dea2',
          sidebar: 'linear-gradient(to top, #ebf4f3 101%, #f0f0f5 )',
          sidebarFocus: 'rgba(9, 12, 26, 0.5)',
        },
        button: {
          error: '#ef3d62',
          green: '#41dea2',
          main: '#41dea2',
          light: '#ef3d62',
          disable: '#abb1c5',
          hover: {
            main: '#41dba0',
            light: '#e63a5d',
            error: 'rgba(207, 53, 85, 0.3)',
          },
        },
        scroll: '#dedee0',
        font: {
          warning: '#e28156',
          error: '#ef3d62',
          green: '#41dea2',
          primary: '#2eca93',
          secondary: '#212d63',
        },
        seperator: {
          top: '#212d63',
          bottom: '#ffffff',
        },
        tertiary: {
          main: '#151d40',
        },
        redPink: '#ef3d62',
        border: {
          primary: '#d7dee8',
          green: '#41dea2',
        },
      },
      siteWidth: 1200,
      spacing: {
        1: 4,
        2: 8,
        3: 16,
        4: 24,
        5: 32,
        6: 48,
        7: 64,
        19: 150,
      },
      topBarSize: 64,
      footerSize: 64,
      flexColumnNoWrap: css`
        display: flex;
        flex-flow: column nowrap;
      `,
      flexRowNoWrap: css`
        display: flex;
        flex-flow: row nowrap;
      `,
      mediaWidth: mediaWidthTemplates,
      shadows: {
        insetFocus: ` 0 0 10px 0 rgba(65, 222, 162, 0.31), inset 6px 6px 20px 0 #d0d8e6, inset -6px -6px 20px 0 #ffffff !important`,
        inset: `inset 6px 6px 20px 0 #d0d8e6, inset -6px -6px 20px 0 #ffffff !important`,
        thin: `18px 18px 30px 0 rgba(208, 216, 230, 0.8), -18px -18px 30px 0 rgba(255, 255, 255, 0.7), -4px -4px 4px 0 rgba(255, 255, 255, 0.5) !important`,
        thick: `12px 12px 20px 0 rgba(208, 216, 230, 0.8), -12px -12px 20px 0 rgba(255, 255, 255, 0.7), -4px -4px 4px 0 #f6f8fe !important`,
        card: `10px 10px 20px 0 rgba(208, 216, 230, 0.8), -10px -10px 20px 0 rgba(255, 255, 255, 0.7), -4px -4px 4px 0 rgba(255, 255, 255, 0.5);`,
        switch: `8px 8px 20px 0 #d0d8e6, -8px -8px 20px 0 #ffffff, -1px -1px 3px 0 #ffffff`,
        hoveredBtn: `2px 2px 4px 0 #d0d8e6, -2px -2px 4px 0 #ffffff, -1px -1px 3px 0 #ffffff`,
        footerMenu: `1px 1px 0px 0 #d0d8e6, -1px -1px 0px 0 #ffffff, -1px -1px 3px 0 #ffffff`,
        button: `6px 6px 20px 0 #bcc3cf, -6px -6px 20px 0 #ffffff, -1px -1px 3px 0 #ffffff`,
        topbar: `0 5px 20px 0 #d1d9e6, -18px -18px 30px 0 rgba(255, 255, 255, 0.5)`,
        dropdown: `0 25px 40px 0 rgba(14, 19, 41, 0.2)`,
      },
      BlockLoading: `
    overflow: hidden;
    ::before {
      content: '';
      display: block;
      position: relative;
      height: 100%;
      width: 50%;
      background: linear-gradient(
        to right,
        transparent 0%,
        rgba(255, 255, 255, 0.7) 50%,
        transparent 100%
      );
      transition: all 0.3s linear;
      animation: load 2s infinite;
    }
    @keyframes load {
      from {
        left: -400px;
      }
      to {
        left: 100%;
      }
    }`,
      inputFocusBorder: `
  box-shadow: 0 0 10px 0 rgba(65, 222, 162, 0.3), inset -6px -6px 10px 0 #ffffff, inset 6px 6px 10px 0 rgba(208, 216, 230, 0.8);
  border-style: solid !important;
  border-width: 1.5px !important;
  border-image-source: linear-gradient(106deg, #41dea2 4%, #ba78f0) !important;
  background-image: linear-gradient(to bottom, #f5f5f8, #f5f5f8), linear-gradient(106deg, #41dea2 4%, #ba78f0) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  transition: all 0.3s ease-out;`,
    },
    dark: {
      borderRadius: 12,
      breakpoints: [400],
      color: {
        black,
        grey,
        red,
        primary: {
          light: '#41dba0',
          main: '#2dc48f',
          green: '#2eca93',
        },
        secondary: {
          main: '#ffffff',
        },
        white,
        background: {
          main: 'rgba(255, 255, 255, 0.05)',
          content: '#1f1f1f',
          secondary: '#ecf3f0',
          paleGrey: '#0d2f35',
          token: '#ffffff',
          footer: 'rgba(0, 35, 34, 0.85)',
          sidebar: ' rgba(255, 255, 255, 0.05)',
          sidebarFocus: 'rgba(0, 0, 0, 0.65)',
        },
        button: {
          error: '#ef3d62',
          green: '#41dea2',
          main: '#2ab886',
          light: '#ef3d62',
          disable: '#36585f',
          hover: {
            main: '#2ab886',
            light: '#e63a5d',
            error: 'rgba(207, 53, 85, 0.3)',
          },
          modal: '#599090',
        },
        scroll: '#ffffff',
        font: {
          warning: '#e28156',
          error: '#ef3d62',
          green: '#41dea2',
          primary: '#ffffff',
          secondary: '#ffffff',
        },
        seperator: {
          top: '#212d63',
          bottom: '#ffffff',
        },
        tertiary: {
          main: '#ffffff',
        },
        redPink: '#ef3d62',
        border: {
          primary: '#d7dee8',
          green: '#41dea2',
        },
      },
      siteWidth: 1200,
      spacing: {
        1: 4,
        2: 8,
        3: 16,
        4: 24,
        5: 32,
        6: 48,
        7: 64,
        19: 150,
      },
      topBarSize: 64,
      footerSize: 64,
      flexColumnNoWrap: css`
        display: flex;
        flex-flow: column nowrap;
      `,
      flexRowNoWrap: css`
        display: flex;
        flex-flow: row nowrap;
      `,
      mediaWidth: mediaWidthTemplates,
      shadows: {
        insetFocus: `0 0 10px 0 rgba(65, 222, 162, 0.31), inset 2px 2px 4px 0 rgba(10, 19, 16, 0.2)`,
        inset: `inset 2px 2px 4px 0 rgba(10, 19, 16, 0.2)`,
        thin: ``,
        thick: ``,
        card: ``,
        switch: ``,
        hoveredBtn: ``,
        button: ``,
        topbar: ``,
        dropdown: ``,
      },
      BlockLoading: `
    overflow: hidden;
    ::before {
      content: '';
      display: block;
      position: relative;
      height: 100%;
      width: 50%;
      background: linear-gradient(
        to right,
        transparent 0%,
        rgba(255, 255, 255, 0.4) 50%,
        transparent 100%
      );
      transition: all 0.3s linear;
      animation: load 2s infinite;
    }
    @keyframes load {
      from {
        left: -400px;
      }
      to {
        left: 100%;
      }
    }`,
      inputFocusBorder: `
  box-shadow: 0 0 10px 0 rgba(65, 222, 162, 0.31), inset 2px 2px 4px 0 rgba(10, 19, 16, 0.2);
  border-style: solid !important;
  border-width: 1.5px !important;
  border-image-source: linear-gradient(106deg, #41dea2 4%, #ba78f0) !important;
  background-image: linear-gradient(to bottom, #113139, #113139), linear-gradient(106deg, #41dea2 4%, #ba78f0) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  transition: all 0.3s ease-out;`,
      modalInputFocusBorder: `
  box-shadow: 0 0 10px 0 rgba(65, 222, 162, 0.31), inset 2px 2px 4px 0 rgba(10, 19, 16, 0.2);
  border-style: solid !important;
  border-width: 1.5px !important;
  border-image-source: linear-gradient(106deg, #41dea2 4%, #ba78f0) !important;
  background-image: linear-gradient(to right, #103936, #164746), linear-gradient(106deg, #41dea2 4%, #ba78f0) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  transition: all 0.3s ease-out;`,
      darkMode: `
  border: solid 1px rgba(220, 226, 235, 0.1);
  background-color: rgba(255, 255, 255, 0.05);`,
    },
  },
  B: {
    light: {
      borderRadius: 10,
      breakpoints: [400],
      color: {
        black,
        grey,
        red,
        white,
        primary: {
          main: '#00FFFF',
          light: '#0000FF',
          green: '#00FFFF',
        },
        secondary: {
          main: '#0047AB',
        },
        background: {
          paleGray: '#6F8FAF',
          content: '#1f1f1f',
          secondary: '#fbceb1',
          main: '#3F00FF',
          token: '#5D3FD3',
          footer: '#ADD8E6',
          sidebar: 'linear-gradient(to top, #1F51FF 101%, #A7C7E7 )',
          sidebarFocus: 'rgba(9, 12, 26, 0.5)',
        },
        button: {
          green: '#96DED1',
          disable: '#41dea2',
          main: '#87CEEB',
          light: '#ef3d62',
          error: '#FF5733',
          hover: {
            main: '#ff9f00',
            light: '#e63a5d',
            error: '#ff2400',
          },
        },
        scroll: '#D4AF37',
        font: {
          error: '#e28156',
          warning: '#ef3d62',
          primary: '#FF6700',
          green: '#cc5500',
          secondary: '#b7410e',
        },
        seperator: {
          top: '#212d63',
          bottom: '#ffffff',
        },
        tertiary: {
          main: '#151d40',
        },
        redPink: '#ef3d62',
        border: {
          primary: '#d7dee8',
          green: '#41dea2',
        },
      },
      siteWidth: 1200,
      spacing: {
        1: 4,
        2: 8,
        3: 16,
        4: 24,
        5: 32,
        6: 48,
        7: 64,
        19: 150,
      },
      topBarSize: 64,
      footerSize: 64,
      flexColumnNoWrap: css`
        display: flex;
        flex-flow: column nowrap;
      `,
      flexRowNoWrap: css`
        display: flex;
        flex-flow: row nowrap;
      `,
      mediaWidth: mediaWidthTemplates,
      shadows: {
        insetFocus: ` 0 0 10px 0 rgba(65, 222, 162, 0.31), inset 6px 6px 20px 0 #d0d8e6, inset -6px -6px 20px 0 #ffffff !important`,
        inset: `inset 6px 6px 20px 0 #d0d8e6, inset -6px -6px 20px 0 #ffffff !important`,
        thin: `18px 18px 30px 0 rgba(208, 216, 230, 0.8), -18px -18px 30px 0 rgba(255, 255, 255, 0.7), -4px -4px 4px 0 rgba(255, 255, 255, 0.5) !important`,
        thick: `12px 12px 20px 0 rgba(208, 216, 230, 0.8), -12px -12px 20px 0 rgba(255, 255, 255, 0.7), -4px -4px 4px 0 #f6f8fe !important`,
        card: `10px 10px 20px 0 rgba(208, 216, 230, 0.8), -10px -10px 20px 0 rgba(255, 255, 255, 0.7), -4px -4px 4px 0 rgba(255, 255, 255, 0.5);`,
        switch: `8px 8px 20px 0 #d0d8e6, -8px -8px 20px 0 #ffffff, -1px -1px 3px 0 #ffffff`,
        hoveredBtn: `2px 2px 4px 0 #d0d8e6, -2px -2px 4px 0 #ffffff, -1px -1px 3px 0 #ffffff`,
        footerMenu: `1px 1px 0px 0 #d0d8e6, -1px -1px 0px 0 #ffffff, -1px -1px 3px 0 #ffffff`,
        button: `6px 6px 20px 0 #bcc3cf, -6px -6px 20px 0 #ffffff, -1px -1px 3px 0 #ffffff`,
        topbar: `0 5px 20px 0 #d1d9e6, -18px -18px 30px 0 rgba(255, 255, 255, 0.5)`,
        dropdown: `0 25px 40px 0 rgba(14, 19, 41, 0.2)`,
      },
      BlockLoading: `
    overflow: hidden;
    ::before {
      content: '';
      display: block;
      position: relative;
      height: 100%;
      width: 50%;
      background: linear-gradient(
        to right,
        transparent 0%,
        rgba(255, 255, 255, 0.7) 50%,
        transparent 100%
      );
      transition: all 0.3s linear;
      animation: load 2s infinite;
    }
    @keyframes load {
      from {
        left: -400px;
      }
      to {
        left: 100%;
      }
    }`,
      inputFocusBorder: `
  box-shadow: 0 0 10px 0 rgba(65, 222, 162, 0.3), inset -6px -6px 10px 0 #ffffff, inset 6px 6px 10px 0 rgba(208, 216, 230, 0.8);
  border-style: solid !important;
  border-width: 1.5px !important;
  border-image-source: linear-gradient(106deg, #41dea2 4%, #ba78f0) !important;
  background-image: linear-gradient(to bottom, #f5f5f8, #f5f5f8), linear-gradient(106deg, #41dea2 4%, #ba78f0) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  transition: all 0.3s ease-out;`,
    },
    dark: {
      borderRadius: 10,
      breakpoints: [400],
      color: {
        black,
        grey,
        red,
        white,
        primary: {
          light: '#41dba0',
          main: '#ff0000',
          green: '#2eca93',
        },
        secondary: {
          main: '#888888',
        },
        background: {
          main: '#000',
          content: '#1f1f1f',
          secondary: '#444444',
          paleGrey: '#777777',
          token: '#ffffff',
          footer: 'rgba(0, 35, 34, 0.85)',
          sidebar: ' rgba(10, 10, 10, 0.5)',
          sidebarFocus: 'rgba(0, 0, 0, 0.65)',
        },
        button: {
          error: '#ef3d62',
          green: '#41dea2',
          main: '#800080',
          light: '#ef3d62',
          disable: '#36585f',
          hover: {
            main: '#2ab886',
            light: '#e63a5d',
            error: 'rgba(207, 53, 85, 0.3)',
          },
          modal: '#599090',
        },
        scroll: '#39ff14',
        font: {
          warning: '#808000',
          error: '#ef3d62',
          green: '#41dea2',
          primary: '#39ff14',
          secondary: '#7fffd4',
        },
        seperator: {
          top: '#212d63',
          bottom: '#ffffff',
        },
        tertiary: {
          main: '#192734',
        },
        redPink: '#ef3d62',
        border: {
          primary: '#d7dee8',
          green: '#41dea2',
        },
      },
      siteWidth: 1200,
      spacing: {
        1: 4,
        2: 8,
        3: 16,
        4: 24,
        5: 32,
        6: 48,
        7: 64,
        19: 150,
      },
      topBarSize: 64,
      footerSize: 64,
      flexColumnNoWrap: css`
        display: flex;
        flex-flow: column nowrap;
      `,
      flexRowNoWrap: css`
        display: flex;
        flex-flow: row nowrap;
      `,
      mediaWidth: mediaWidthTemplates,
      shadows: {
        insetFocus: `0 0 10px 0 rgba(65, 222, 162, 0.31), inset 2px 2px 4px 0 rgba(10, 19, 16, 0.2)`,
        inset: `inset 2px 2px 4px 0 rgba(10, 19, 16, 0.2)`,
        thin: ``,
        thick: ``,
        card: ``,
        switch: ``,
        hoveredBtn: ``,
        button: ``,
        topbar: ``,
        dropdown: ``,
      },
      BlockLoading: `
    overflow: hidden;
    ::before {
      content: '';
      display: block;
      position: relative;
      height: 100%;
      width: 50%;
     background-color: #5d4257;
     background-image: linear-gradient(315deg, #5d4257 0%, #a5c7b7 74%);
      transition: all 0.3s linear;
      animation: load 2s infinite;
     }
    @keyframes load {
      from {
        left: -400px;
      }
      to {
        left: 100%;
      }
    }`,
      inputFocusBorder: `
  box-shadow: 0 0 10px 0 rgba(65, 222, 162, 0.31), inset 2px 2px 4px 0 rgba(10, 19, 16, 0.2);
  border-style: solid !important;
  border-width: 1.5px !important;
  border-image-source: linear-gradient(106deg, #41dea2 4%, #ba78f0) !important;
  background-image: linear-gradient(to bottom, #113139, #113139), linear-gradient(106deg, #41dea2 4%, #ba78f0) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  transition: all 0.3s ease-out;`,
      modalInputFocusBorder: `
  box-shadow: 0 0 10px 0 rgba(65, 222, 162, 0.31), inset 2px 2px 4px 0 rgba(10, 19, 16, 0.2);
  border-style: solid !important;
  border-width: 1.5px !important;
  border-image-source: linear-gradient(106deg, #41dea2 4%, #ba78f0) !important;
  background-image: linear-gradient(to right, #103936, #164746), linear-gradient(106deg, #41dea2 4%, #ba78f0) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  transition: all 0.3s ease-out;`,
      darkMode: `
  border: solid 1px rgba(220, 226, 235, 0.1);
  background-color: rgba(100, 100, 100, 0.5);`,
    },
  },
}

export const images = {
  logo,
  logo3x,
  accountLogo,
}

export const links = {
  discord: 'https://discord.gg/shMDPEzWn8',
  telegram: 'https://t.me/MonoXOfficial',
  twitter: 'https://twitter.com/MonoXFinance',
  medium: 'https://medium.com/monoswap',
  lightPaper: 'https://docs.monox.finance/',
}
