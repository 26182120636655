import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'

import useWallet from 'hooks/useWallet'
import config from 'monox/config'
import { timeDurationMap } from 'monox/util'

import AreaChart from 'views/Analytics/components/AreaChart'
import BarChart from 'views/Analytics/components/BarChart'
import ChartPlaceholder from 'views/Swapper/components/ChartPlaceholder'
import PriceChart from 'views/Analytics/components/PriceChart'
import CardContainer from 'components/CardContainer'
import { RowBetween } from 'components/Row'

import { getAllTokenEvents, getRecentlyAdded } from 'api'

const filters = [
  { name: 'TVL', id: 'analytics.token.graph.button.1' },
  { name: 'Price', id: 'analytics.token.graph.button.3' },
  { name: 'Volume', id: 'analytics.token.graph.button.2' },
]

const ChartAnalytics = ({ currency, pool = {} }) => {
  const { chainId } = useWallet()
  const { address } = useParams()
  const filteredAddress = address?.replace(/\?.*/, '')
  const networkId = useSelector(({ network }) => network.id)
  const isDark = useSelector(({ application }) => application.isDark)
  const WRAPPED_MAIN_ADDRESS = config?.[networkId || chainId]?.WRAPPED_MAIN_ADDRESS

  const [selectedFilter, setSelectedFilter] = useState(filters[0])
  const [timeSelected, setTimeSelected] = useState('24H')
  const [isNewToken, setIsNewToken] = useState(false)
  const [recentDataLoading, setRecentDataLoading] = useState(false)
  const [events, setEvents] = useState([])
  const isPoolCreated = pool?.price && pool?.price !== '0'
  const isPoolLoaded = pool?.price

  useEffect(() => {
    if (isPoolCreated) {
      const now = dayjs()
      const selected = timeDurationMap['24H']
      const start = now.subtract(selected.interval, selected.unit).unix()
      setRecentDataLoading(true)
      getRecentlyAdded(networkId || chainId, start, 'ASC')
        .then((res) => {
          const token = res?.response?.filter(
            (token) => token.token.toLowerCase() === filteredAddress.toLowerCase()
          )
          setIsNewToken(token?.length ? true : false)
          setRecentDataLoading(false)
        })
        .catch((err) => {
          setRecentDataLoading(false)
          if (process.env.REACT_APP_DEV_ENV === 'development') {
            console.log(err)
          }
        })
    } else {
      setIsNewToken(true)
    }
  }, [address, chainId, networkId, selectedFilter, isPoolCreated])

  useEffect(() => {
    if (isPoolCreated) {
      getAllTokenEvents(networkId || chainId, pool?.pid)
        .then((res) => {
          setEvents(res?.response)
        })
        .catch(() => {
          setEvents([])
        })
    }
  }, [address, chainId, networkId, selectedFilter, pool, isPoolCreated])

  const chartData = {
    TVL: {
      chart: (
        <AreaChart
          networkId={networkId}
          timeRange={timeSelected}
          tokenAddress={
            currency?.address || (!!currency?.symbol && WRAPPED_MAIN_ADDRESS)
          }
          translateId={
            !isPoolLoaded || recentDataLoading
              ? ''
              : isPoolCreated
              ? 'analytics.token.new.tvl.chart.title'
              : 'modal.swap.select.currency.pool.warning'
          }
        />
      ),
      data: {
        newToken:
          'There needs to be more value locked to display this Liquidity chart',
        newTokenTranslateId: 'analytics.token.new.tvl.chart.title',
        noPoolToken: 'Pool is not created yet.',
        noPoolTokenTranslateId: 'modal.swap.select.currency.pool.warning',
      },
    },
    Price: {
      chart: (
        <PriceChart
          networkId={networkId}
          timeRange={timeSelected}
          tokenAddress={
            currency?.address || (!!currency?.symbol && WRAPPED_MAIN_ADDRESS)
          }
          decimals={currency?.decimals || 18}
          pid={pool?.pid}
          translateId={
            !isPoolLoaded || recentDataLoading
              ? ''
              : isPoolCreated
              ? 'analytics.token.new.price.chart.title'
              : 'modal.swap.select.currency.pool.warning'
          }
        />
      ),
      data: {
        newToken: 'We need more trading activity to display this price chart',
        newTokenTranslateId: 'analytics.token.new.price.chart.title',
        noPoolToken: 'Pool is not created yet.',
        noPoolTokenTranslateId: 'modal.swap.select.currency.pool.warning',
      },
    },
    Volume: {
      chart: (
        <BarChart
          networkId={networkId}
          timeRange={timeSelected}
          tokenAddress={
            currency?.address || (!!currency?.symbol && WRAPPED_MAIN_ADDRESS)
          }
          translateId={
            !isPoolLoaded || recentDataLoading
              ? ''
              : isPoolCreated
              ? 'analytics.token.new.volume.chart.title'
              : 'modal.swap.select.currency.pool.warning'
          }
        />
      ),
      data: {
        newToken: 'We need more trading activity to display this volume chart',
        newTokenTranslateId: 'analytics.token.new.volume.chart.title',
        noPoolToken: 'Pool is not created yet.',
        noPoolTokenTranslateId: 'modal.swap.select.currency.pool.warning',
      },
    },
  }

  return (
    <CardContainer style={{ width: '100%' }} maxWidth="1067">
      <InnerContainer>
        <ButtonRow>
          <ButtonGroup>
            {filters.map((filter) => (
              <SwitchButton
                isDark={isDark}
                key={filter.name}
                active={selectedFilter.name === filter.name}
                width="80"
                onClick={() => setSelectedFilter(filter)}
                style={{ margin: '0 15px 10px 0' }}
                data-testid={filter.id}
              >
                <FormattedMessage id={filter.id} defaultMessage={filter.name} />
              </SwitchButton>
            ))}
          </ButtonGroup>
          <ButtonGroup>
            {Object.keys(timeDurationMap).map((key) => (
              <TimeSelector
                isDark={isDark}
                key={key}
                active={key === timeSelected}
                onClick={() => setTimeSelected(key)}
                data-testid={key}
              >
                <FormattedMessage
                  id={timeDurationMap[key]?.timeRangeId}
                  defaultMessage={`${timeDurationMap[key]?.timeRangeText}`}
                />
                <Dot active={key === timeSelected} />
              </TimeSelector>
            ))}
          </ButtonGroup>
        </ButtonRow>
        {isNewToken && events?.length < 10 ? (
          <ChartPlaceholder
            text={
              !isPoolLoaded || recentDataLoading
                ? ''
                : isPoolCreated
                ? chartData[selectedFilter.name].data.newToken
                : chartData[selectedFilter.name].data.noPoolToken
            }
            translateId={
              !isPoolLoaded || recentDataLoading
                ? ''
                : isPoolCreated
                ? chartData[selectedFilter.name].data.newTokenTranslateId
                : chartData[selectedFilter.name].data.noPoolTokenTranslateId
            }
            isOnAnalytics={true}
            style={{ height: '200px' }}
          />
        ) : (
          <ChartContainer>{chartData[selectedFilter.name].chart}</ChartContainer>
        )}
      </InnerContainer>
    </CardContainer>
  )
}

const ChartContainer = styled.div`
  height: 200px;
`
const Dot = styled.div`
  width: 5px;
  height: 5px;
  background: ${({ theme }) => theme.color.secondary.main};
  border-radius: 100%;
  opacity: ${(props) => (props.active ? 1 : 0)};
`
const InnerContainer = styled.div`
  padding: 45px 85px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 45px;
  `}
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 30px;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 25px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 15px 15px;
  `}
`

const ButtonRow = styled(RowBetween)`
  align-items: center;
  margin-bottom: 10px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  flex-direction: column;
  `}
`

const SwitchButton = styled.div`
  min-width: 50px;
  cursor: pointer;
  margin: 0 15px 10px 0;
  height: 30px;
  padding: 0 18px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, active, isDark }) =>
    active && !isDark ? theme.color.primary.main : theme.color.secondary.main};
  font-size: 13px;
  font-weight: 800;
  background-color: ${(props) =>
    props.isDark && props.active
      ? 'rgba(255, 255, 255, 0.3)'
      : props.theme.color.background.main};
  :hover {
    color: ${({ theme }) => theme.color.primary.main};
  }
  ${(props) =>
    props.active
      ? `
    pointer-events:none;
    box-shadow: ${
      !props.isDark && 'inset -8px -8px 20px 0 #ffffff, inset 8px 8px 20px 0 #d1d9e6'
    };`
      : `box-shadow: ${props.theme.shadows.button}`}
`

const TimeSelector = styled.div`
  min-width: 50px;
  cursor: pointer;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  color: ${({ theme }) => theme.color.secondary.main};
  font-size: 12px;
  font-weight: 800;
  background-color: ${(props) =>
    props.isDark ? 'transparent' : props.theme.color.background.main};
  ${(props) =>
    props.active
      ? `
    pointer-events:none;`
      : ``}
`

const ButtonGroup = styled.div`
  margin-top: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.color.secondary.main};
  transition: opacity 100ms ease-out 0s;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  margin: 8px 0
  `}
`
export default ChartAnalytics
