import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { TRANSACTION_STATUS } from 'monox/util'

import Modal from 'components/Modal'
import Label from 'components/Label'
import { Row } from 'components/Row'
import StyledIconButton from 'components/StyledIconButton'
import { CloseIcon } from 'components/IconButton'

import AlertTriangle from 'assets/img/alert-triangle.svg'

const TransactionRejectModal = ({
  onDismiss,
  payload,
  message,
  translateId,
  testId,
  values,
}) => {
  const isDark = useSelector(({ application }) => application.isDark)

  return (
    <Modal maxWidth>
      <Row>
        <Label
          text="Error"
          size="16"
          weight="800"
          translateId="modal.common.transaction.reject.title"
        />
        <CloseIcon
          onClick={onDismiss}
          size="20"
          style={{ marginLeft: 'auto' }}
          data-testid="dismiss"
        />
      </Row>
      <Error isDark={isDark}>
        <Label
          text={`Transaction ${
            payload === TRANSACTION_STATUS.REJECTED ? 'rejected' : 'failed'
          }.`}
          size="16"
          weight="800"
          color="#ef3d62"
          translateId={`${
            payload === TRANSACTION_STATUS.REJECTED
              ? 'modal.common.transaction.reject.sub_title'
              : 'modal.common.transaction.fail.sub_title'
          }`}
        />
        <Label
          text={message}
          size="13"
          weight="bold"
          color="#ef3d62"
          translateId={translateId}
          values={values}
        />
        <img
          src={AlertTriangle}
          style={{
            position: 'absolute',
            bottom: '-13px',
            right: '-18px',
          }}
          alt="AlertTriangle"
        />
      </Error>
      <StyledIconButton
        fontColor="secondary"
        icon="arrow"
        block
        variant="secondary"
        style={{
          height: 42,
          boxShadow: !isDark && '0 12px 20px 0 rgba(207, 53, 85, 0.3)',
        }}
        onClick={onDismiss}
        modal={true}
        translateId="modal.common.transaction.reject.button"
        testId={testId}
      >
        Dismiss
      </StyledIconButton>
    </Modal>
  )
}

export default TransactionRejectModal

const Error = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  margin-bottom: 40px;
  border-left: 5px solid ${({ theme }) => theme.color.font.error};
  border-radius: 6px;
  background-color: ${(props) =>
    props.isDark ? 'rgba(255, 255, 255, 0.08)' : '#f4ebf0'};
  min-height: 120px;
  display: flex;
  position: relative;
  overflow: hidden;
  margin-top: 30px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  height: auto;
  padding:.5rem 15px;
  `}
`
