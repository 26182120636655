import {
  SAVE_TOKEN,
  SAVE_WALLET,
  SAVE_BALANCE,
  SAVE_PRIVATE_KEY,
  CLEAR_USER_INFO,
  SAVE_ACTIVE_POOLS,
  SAVE_RPC_ERROR,
  SAVE_PENDING_SWITCH,
  CLEAR_ACTIVE_POOLS,
} from 'state/users/constants'

export function saveToken(payload) {
  return {
    type: SAVE_TOKEN,
    payload,
  }
}

export function saveWallet(payload) {
  return {
    type: SAVE_WALLET,
    payload,
  }
}

export function saveBalance(payload) {
  return {
    type: SAVE_BALANCE,
    payload,
  }
}

export function saveRPCError(payload) {
  return {
    type: SAVE_RPC_ERROR,
    payload,
  }
}

export function savePendingSwitch(payload) {
  return {
    type: SAVE_PENDING_SWITCH,
    payload,
  }
}

export function saveActivePools(payload) {
  return {
    type: SAVE_ACTIVE_POOLS,
    payload,
  }
}

export function clearActivePools(payload) {
  return {
    type: CLEAR_ACTIVE_POOLS,
    payload,
  }
}

export function savePrivateKey(payload) {
  return {
    type: SAVE_PRIVATE_KEY,
    payload,
  }
}

export function clearUserInfo() {
  return {
    type: CLEAR_USER_INFO,
  }
}
