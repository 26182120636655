import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { NavLink, useHistory } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { ArrowIosBack } from '@styled-icons/evaicons-solid/ArrowIosBack'
import { Globe } from '@styled-icons/fluentui-system-regular/Globe'
import { ArrowDropDown } from '@styled-icons/material/ArrowDropDown'

import {
  EXPLORER_FILTER,
  isProdEnv,
  weiToEth,
  SWAP_ALLOW_CHAINS,
  STAKING_ALLOW_CHAINS,
} from 'monox/constants'
import { precise } from 'monox/util'
import { setLocale } from 'state/applications/actions'
import { setBondPurchase } from 'state/bond/actions'
import useTokenBalance from 'hooks/useTokenBalance'
import useWallet from 'hooks/useWallet'
import config from 'monox/config'

import Label from 'components/Label'
import Spacer from 'components/Spacer'
import Divider from 'components/Divider'
import LocaleButton from 'components/TopBar/components/LocaleButton'
import { Row, RowBetween } from 'components/Row'

import home from 'assets/img/home.svg'
import pool from 'assets/img/pool.svg'
import swap from 'assets/img/swapNav.svg'
import explore from 'assets/img/explore-outlined.svg'
import farm from 'assets/img/farm.svg'
import futures from 'assets/img/future.svg'
import vault from 'assets/img/vault.svg'
import bond from 'assets/img/bond.svg'

const MobileMenu = ({
  setLang,
  lang,
  languages,
  close,
  openLangMenu,
  setOpenLangMenu,
}) => {
  const dispatch = useDispatch()
  const networkName = useSelector(({ network }) => network?.NAME)?.toLowerCase()
  const networkId = useSelector(({ network }) => network.id)
  const bondList = useSelector(({ bond }) => bond?.bondDiscounts)?.[networkId] || []
  const isDark = useSelector(({ application }) => application.isDark)

  const [isExploreOpen, setIsExploreOpen] = useState(false)
  const history = useHistory()

  const { chainId } = useWallet()
  const currency = config?.[chainId]?.VCASH
  const { balance } = useTokenBalance(currency)

  const isSupported = useMemo(() => {
    return SWAP_ALLOW_CHAINS.includes(chainId || networkId)
  }, [chainId, networkId])

  const isFarmSupported = useMemo(() => {
    return STAKING_ALLOW_CHAINS.includes(chainId || networkId)
  }, [chainId, networkId])

  const handleLanguage = (lang) => {
    dispatch(setLocale(lang))
    setLang(lang)
    close()
  }

  return (
    <>
      {openLangMenu ? (
        <ScrollContainer isDark={isDark} height={220}>
          <>
            {languages.map((language, index) => (
              <Item
                onClick={() => handleLanguage(language)}
                key={index}
                first={language?.first}
                data-testid={`lang-${index}`}
              >
                <Spacer />
                {language?.image && <Spacer />}
                <Label text={language?.name} size="14" weight="800" />
                {language.community && <Community size="15" />}
                <Label
                  text={language?.abbr}
                  size="14"
                  weight="bold"
                  opacity="0.5"
                  style={{ marginLeft: 'auto' }}
                />
                <Spacer />
              </Item>
            ))}
          </>
        </ScrollContainer>
      ) : isExploreOpen ? (
        <>
          <Spacer size="sm" />
          {Object.entries(EXPLORER_FILTER ?? {}).map(([key, item], index) => (
            <Item
              key={index}
              onClick={() => {
                history.push(`/explore/${key}?network=${networkName}`)
                setIsExploreOpen(false)
                close()
              }}
            >
              <img src={item?.image?.url} width="34" height="39" alt="itemImage" />
              <Spacer />
              <div>
                <Label
                  text={item?.name}
                  size="16"
                  weight="800"
                  style={{ marginBottom: '3px' }}
                />
                <Label
                  text={item?.short_description?.[0]?.text}
                  size="12"
                  weight="800"
                  opacity="0.5"
                  style={{ lineHeight: 1.08 }}
                />
              </div>
            </Item>
          ))}
          <Spacer />
        </>
      ) : (
        <ScrollContainer isDark={isDark} height={220}>
          <StyledLink
            variant="nav"
            to={`/home?network=${networkName}`}
            onClick={close}
          >
            <img src={home} alt="home" width="17" />
            <Space />
            <Label
              text="Home"
              size="14"
              weight="bold"
              translateId="common.menu.bar_item.1"
            />
          </StyledLink>
          <Divider />
          {isSupported && (
            <>
              <StyledLink
                variant="nav"
                to={`/swap?network=${networkName}`}
                onClick={close}
              >
                <img src={swap} alt="swap" width="16" />
                <Space />
                <Label
                  text="Swap"
                  size="14"
                  weight="bold"
                  translateId="common.menu.bar_item.2"
                />
              </StyledLink>
              <Divider />
              <StyledLink
                variant="nav"
                to={`/pool?network=${networkName}`}
                onClick={close}
              >
                <img src={pool} alt="pool" width="17" />
                <Space />
                <Label
                  text="Pool"
                  size="14"
                  weight="bold"
                  translateId="common.menu.bar_item.3"
                />
              </StyledLink>
              <Divider />
              <StyledLink
                variant="nav"
                to={`/farm?network=${networkName}`}
                onClick={close}
              >
                <img src={farm} alt="farm" width="18" />
                <Space />
                <Label
                  text="Farm"
                  size="14"
                  weight="bold"
                  translateId="common.menu.bar_item.4"
                />
              </StyledLink>
              <Divider />
            </>
          )}
          <Div style={{ alignItems: 'flex-start' }}>
            <img src={bond} alt="bond" width="17" />

            <div style={{ width: '100%' }}>
              <Label
                text="Bond"
                size="14"
                weight="bold"
                style={{ marginBottom: '18px', paddingLeft: '15px' }}
                onClick={() => {
                  history.push(`/bond?network=${networkName}`)
                  close()
                }}
                hover
                pointer
              />
              <StyledRowBetween
                onClick={() => {
                  history.push(`/bond/stake?network=${networkName}`)
                  close()
                }}
                style={{ paddingLeft: '15px', alignItems: 'center' }}
              >
                <Label
                  text="Stake vUNIT"
                  size="13"
                  weight="bold"
                  translateId="bonds.stake.title"
                  style={{ padding: '2px' }}
                />
                <div>
                  <Label
                    text={weiToEth(BigNumber(balance))}
                    size="13"
                    weight="bold"
                    style={{
                      marginLeft: 'auto',
                    }}
                  />
                  <Label text="Balance" size={9} weight="800" opacity="0.35" />
                </div>
              </StyledRowBetween>
              <Label
                text="Bond ROI"
                size="13"
                opacity="0.3"
                weight="bold"
                style={{ margin: '10px 0', paddingLeft: '15px' }}
              />
              {bondList?.map((bond, index) => (
                <Item
                  key={index}
                  onClick={() => {
                    dispatch(
                      setBondPurchase({
                        chainId: networkId || chainId,
                        bondPurchase: bond,
                      })
                    )
                    history.push(`/bond/purchase?network=${networkName}`)
                    close()
                  }}
                >
                  <RowBetween>
                    <Label text={bond?.bondName} size="13" weight="bold" />
                    <Label
                      text={`${precise(bond?.bondDiscount * 100, 2)} %`}
                      size="13"
                      weight="bold"
                    />
                  </RowBetween>
                </Item>
              ))}
            </div>
          </Div>
          <Divider />

          {/* <StyledLink
            variant="nav"
            to={`/vault?network=${networkName}`}
            onClick={close}
          >
            <img src={vault} alt="vault" width="17" />
            <Space />
            <Label
              text="Vault"
              size="14"
              weight="bold"
              translateId="common.menu.bar_item.7"
            />
          </StyledLink> */}
          {/* <Divider /> */}
          {/* <StyledLink
            variant="nav"
            to={`/futures?network=${networkName}`}
            onClick={close}
          >
            <img src={futures} alt="futures" width="16" />
            <Space />
            <Label
              text="Futures"
              size="14"
              weight="bold"
              translateId="common.menu.bar_item.5"
            />
          </StyledLink>
          <Divider /> */}
          {isSupported && (
            <Div
              onClick={() => setIsExploreOpen(!isExploreOpen)}
              hover={true}
              style={{ cursor: 'pointer' }}
            >
              <img src={explore} alt="explore" width="18" />
              <Space />
              <Label
                text="Explore"
                size="14"
                weight="bold"
                translateId="common.menu.bar_item.6"
              />
              <ArrowDown />
            </Div>
          )}
        </ScrollContainer>
      )}
      <Divider />
      <Spacer />
      <RowBetween>
        {(openLangMenu || isExploreOpen) && (
          <Label
            size="14"
            weight="800"
            primary
            onClick={() => {
              setOpenLangMenu(false)
              setIsExploreOpen(false)
            }}
            testId="back"
          >
            <BackIcon />
            Back
          </Label>
        )}
        <LocaleButton
          setLang={setLang}
          lang={lang}
          languages={languages}
          mobileLayout={true}
          openLangMenu={openLangMenu}
          setOpenLangMenu={setOpenLangMenu}
        />
      </RowBetween>
      <Spacer size="sm" />
      {openLangMenu && (
        <Row style={{ justifyContent: 'center', marginBottom: '10px' }}>
          <Community size="12" />
          <Label
            weight="600"
            opacity="0.5"
            size="10"
            text="was supported by our community"
          />
        </Row>
      )}
      <Spacer size="sm" />
    </>
  )
}

export default MobileMenu

const StyledLink = styled(NavLink)`
  cursor: pointer;
  padding: 19px 9px;
  text-decoration: none;
  transition: color 0.2s ease, background-color 0.2s ease;
  display: flex;
  align-items: center;
  margin-right: auto;
  :hover {
    div {
      color: ${({ theme }) => theme.color.font.green};
    }
  }
`
const Item = styled.div`
  display: flex;
  cursor: pointer;
  padding: 10px 0 10px 15px;
  :hover {
    padding-right: 5px;
    background-color: rgba(45, 196, 143, 0.05);
    border-left: 4px solid ${(props) => props.theme.color.border.green};
  }
`
const BackIcon = styled(ArrowIosBack)`
  width: 20px;
  color: #2eca93;
  margin-top: -2px;
`
const Space = styled.div`
  height: 15px;
  width: 15px;
  min-height: 15px;
  min-width: 15px;
`

const ScrollContainer = styled.div`
  height: ${(props) => props.height}px;
  margin-bottom: -2px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: ${(props) =>
      props.isDark ? 'rgba(255,255,255,0.4)' : 'rgba(33, 45, 99, 0.4)'};
  }
`
const Community = styled(Globe)`
  margin: 0 5px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  color: ${({ theme }) => theme.color.font.green};
`
const Div = styled.div`
  padding: 19px 9px;
  text-decoration: none;
  transition: color 0.2s ease, background-color 0.2s ease;
  display: flex;
  align-items: center;
  margin-right: auto;
  ${({ hover, theme }) =>
    hover &&
    `:hover {
    div {
      color: ${theme.color.font.green};
    }
  }`}
`
const ArrowDown = styled(ArrowDropDown)`
  height: 25px;
  width: 25px;
  color: ${({ theme }) => theme.color.font.secondary};
`
const StyledRowBetween = styled(RowBetween)`
  align-items: baseline;
  cursor: pointer;
  padding: 8px 0;
  :hover {
    padding-right: 5px;
    background-color: rgba(45, 196, 143, 0.05);
    border-left: 4px solid ${(props) => props.theme.color.border.green};
  }
`
