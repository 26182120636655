import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { config } from './config'

const theme = config.A.light

export const darkTheme = config.A.dark

export const StyledLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  text-decoration: none;
`

export const StyledExternalLink = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  cursor: ${(props) => (props?.isRejected ? 'default' : 'pointer')};
  text-decoration: none;
  svg {
    margin-left: 5px;
    * {
      color: #2dc48f;
    }
  }
`

export const StyledInternalLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: #ff007a;
  font-weight: 500;
  :hover {
    text-decoration: underline;
  }
  :focus {
    outline: none;
    text-decoration: underline;
  }
  :active {
    text-decoration: none;
  }
`

export default theme
