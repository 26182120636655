import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { Globe } from '@styled-icons/fluentui-system-regular/Globe'
import { CaretDown } from '@styled-icons/ionicons-sharp/CaretDown'
import dayjs from 'dayjs'

import Label from 'components/Label'
import Spacer from 'components/Spacer'
import { setLocale } from 'state/applications/actions'
import Divider from 'components/Divider'

import scrolldown from 'assets/img/scrolldown.svg'
import scrollup from 'assets/img/scrollup.svg'

const LocaleButton = ({
  setLang,
  languages,
  lang,
  mobileLayout,
  openLangMenu,
  setOpenLangMenu,
}) => {
  const ref = useRef(null)
  const scrollRef = useRef(null)
  const dispatch = useDispatch()
  const [showScroll, setShowScroll] = useState(true)
  const isDark = useSelector(({ application }) => application.isDark)
  const locale = useSelector(({ application }) => application.locale)

  useEffect(() => {
    setLang(locale)
    dayjs.locale(locale?.id)
  }, [locale])

  useEffect(() => {
    setShowScroll(true)
  }, [openLangMenu])

  useEffect(() => {
    if (!mobileLayout) {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenLangMenu(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [ref])

  const handleLanguage = (lang) => {
    dispatch(setLocale(lang))
    setOpenLangMenu(!openLangMenu)
    setLang(lang)
  }

  const checkScrollDown = () => {
    if (scrollRef?.current?.scrollTop < 130) {
      setShowScroll(true)
    } else {
      setShowScroll(false)
    }
  }
  const scrollToDown = () =>
    scrollRef?.current?.scrollTo(0, scrollRef?.current?.scrollHeight)

  const scrollToUp = () => scrollRef?.current?.scrollTo(0, 0)

  return (
    <div ref={ref}>
      <Div
        mobileLayout={mobileLayout}
        onClick={() => {
          setOpenLangMenu(!openLangMenu)
        }}
        data-testid="mobile-layout"
      >
        <Label text={lang?.abbr} weight="bold" size="14" />
        <ArrowDown />
      </Div>
      {openLangMenu && !mobileLayout && (
        <DropDown isDark={isDark}>
          <ScrollContainer
            isDark={isDark}
            ref={scrollRef}
            onScroll={checkScrollDown}
          >
            {languages.map((language, index) => (
              <DropDownItem
                onClick={() => handleLanguage(language)}
                key={index}
                first={language?.first}
                data-testid={`language-${language.id}`}
              >
                {language?.image && <Spacer />}
                <Label text={language?.name} size="14" weight="800" />
                {language.community && <Community size="15" />}
                <Label
                  text={language?.abbr}
                  size="14"
                  weight="bold"
                  opacity="0.5"
                  style={{ marginLeft: 'auto' }}
                />
              </DropDownItem>
            ))}
          </ScrollContainer>
          <div style={{ margin: '10px  35px 0 35px', textAlign: 'center' }}>
            <div style={{ height: '18px' }}>
              {showScroll ? (
                <Box onClick={scrollToDown}>
                  <img
                    src={scrolldown}
                    height="18"
                    width="18"
                    style={{ margin: '0 auto', cursor: 'pointer' }}
                    alt="scrolldown"
                  />
                </Box>
              ) : (
                <Box onClick={scrollToUp}>
                  <img
                    src={scrollup}
                    height="19"
                    width="19"
                    style={{ margin: '0 auto', cursor: 'pointer' }}
                    alt="scrollup"
                  />
                </Box>
              )}
            </div>
            <Spacer size="sm" />
            <Divider />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <Community size="12" />
              <Label
                weight="600"
                opacity="0.5"
                size="10"
                text="was supported by our community"
              />
            </div>
          </div>
        </DropDown>
      )}
    </div>
  )
}

export default LocaleButton

const ArrowDown = styled(CaretDown)`
  height: 13px;
  width: 13px;
  margin-left: 4px;
  opacity: 0.6;
  color: ${({ theme }) => theme.color.font.secondary};
`
const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ mobileLayout }) => mobileLayout && `margin-left: auto;`}
`
const DropDown = styled.div`
  position: absolute;
  width: 260px;
  z-index: 10;
  top: 85px;
  right: 10px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.shadows.dropdown};
  ${(props) => props.isDark && props.theme.darkMode}
  background-color: ${(props) => props.theme.color.background.paleGrey};
  padding: 35px 0 20px 0;
`
const DropDownItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px 35px;
  :hover {
    background-color: rgba(45, 196, 143, 0.05);
    border-left: 4px solid ${(props) => props.theme.color.border.green};
  }
`
const ScrollContainer = styled.div`
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: ${(props) =>
      props.isDark ? 'rgba(255,255,255,0.4)' : 'rgba(33, 45, 99, 0.4)'};
  }
`
const Community = styled(Globe)`
  margin: 0 5px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  color: ${({ theme }) => theme.color.font.green};
`

const arrow = keyframes`
  0% {
      -webkit-transform: translateY(4px);
      transform: translateY(4px);
    }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
`
const Box = styled.div`
  animation: ${arrow} 3s infinite;
`
