import React, { useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useWallet from 'hooks/useWallet'
import BigNumber from 'bignumber.js'
import { toast } from 'react-toastify'

import { updateSwapTransaction } from 'state/swap/actions'
import { addTransaction } from 'state/transaction/actions'
import { AccountContext } from 'contexts/AccountProvider'
import { showToast, TRANSACTION_STATUS } from 'monox/util'
import { weiToEthString, GAS_FACTOR } from 'monox/constants'
import config from 'monox/config'

import ToastPopup, {
  TransactionFailedToast,
  TransactionStartToast,
} from 'components/ToastPopup'

const useSwapToken = () => {
  const { account, chainId } = useWallet()
  const { poolsContract, infuraContract, swapRouterContract, WRAPPEDContract } =
    useContext(AccountContext)
  const networkId = useSelector(({ network }) => network.id)
  const WRAPPED_MAIN_ADDRESS = config?.[networkId || chainId]?.WRAPPED_MAIN_ADDRESS

  const tolerance = useSelector(({ settings }) => settings.swapTolerance)

  const dispatch = useDispatch()

  const onGetAmountIn = useCallback(
    async (tokenIn, tokenOut, amountOut) => {
      try {
        const amountOutBigNum = BigNumber(10 ** tokenOut.decimals)
          .times(BigNumber(amountOut))
          .toFixed(0)
        if (swapRouterContract) {
          const tx = await swapRouterContract?.methods
            .getAmountIn(
              tokenIn?.address || WRAPPED_MAIN_ADDRESS,
              tokenOut?.address || WRAPPED_MAIN_ADDRESS,
              amountOutBigNum
            )
            .call()
          return tx
        } else if (infuraContract) {
          const tx = await infuraContract?.methods
            .getAmountIn(
              tokenIn?.address || WRAPPED_MAIN_ADDRESS,
              tokenOut?.address || WRAPPED_MAIN_ADDRESS,
              amountOutBigNum
            )
            .call()
          return tx
        }
      } catch (e) {
        if (process.env.REACT_APP_DEV_ENV === 'development') {
          console.log('onGetAmountIn error: ', e)
        }
        return false
      }
    },
    [swapRouterContract, infuraContract]
  )

  const onGetAmountOut = useCallback(
    async (tokenIn, tokenOut, amountIn) => {
      try {
        const amountInBigNum = BigNumber(10 ** tokenIn.decimals)
          .times(BigNumber(amountIn))
          .toFixed(0)
        if (swapRouterContract) {
          const tx = await swapRouterContract?.methods
            .getAmountOut(
              tokenIn?.address || WRAPPED_MAIN_ADDRESS,
              tokenOut?.address || WRAPPED_MAIN_ADDRESS,
              amountInBigNum
            )
            .call()
          return tx
        } else if (infuraContract) {
          const tx = await infuraContract?.methods
            .getAmountOut(
              tokenIn?.address || WRAPPED_MAIN_ADDRESS,
              tokenOut?.address || WRAPPED_MAIN_ADDRESS,
              amountInBigNum
            )
            .call()
          return tx
        }
      } catch (e) {
        if (process.env.REACT_APP_DEV_ENV === 'development') {
          console.log('onGetAmountOut error: ', e)
        }
        return false
      }
    },
    [swapRouterContract, infuraContract]
  )

  const dispatchUpdateSwapTrans = useCallback((tx, payload) => {
    dispatch(addTransaction(payload))
    showToast(<TransactionStartToast />, {
      autoClose: false,
    })
    dispatch(
      updateSwapTransaction({
        isPerforming: false,
        isRejected: false,
        successResult: tx,
        successEnded: false,
        status: TRANSACTION_STATUS.PENDING,
      })
    )
  }, [])

  const dispatchSuccessSwapTrans = useCallback((tx, payload) => {
    toast.dismiss()
    dispatch(addTransaction(payload))
    dispatch(
      updateSwapTransaction({
        isPerforming: false,
        isRejected: false,
        successResult: tx,
        successEnded: true,
        status: TRANSACTION_STATUS.SUCCESS,
      })
    )
    showToast(
      <ToastPopup
        fromToken={payload?.fromCurrency}
        toToken={payload?.toCurrency}
        fromAmount={payload?.fromAmount}
        toAmount={payload?.toAmount}
        chainId={chainId}
        link={tx}
        type={payload.type.toLowerCase()}
      />
    )
  }, [])

  const dispatchFailSwapTrans = useCallback((error, payload) => {
    toast.dismiss()
    let status = TRANSACTION_STATUS.FAIL
    if (
      error.message.includes('User denied transaction signature') ||
      error?.code === 4001
    ) {
      status = TRANSACTION_STATUS.REJECTED
    }
    showToast(<TransactionFailedToast status={status} />)
    dispatch(
      addTransaction({
        ...payload,
        status,
        error,
      })
    )
    dispatch(
      updateSwapTransaction({
        isPerforming: false,
        isRejected: true,
        successResult: undefined,
        successEnded: false,
        status,
      })
    )
  }, [])

  const onSwap = useCallback(
    (
      fromCurrency,
      toCurrency,
      fromAmount,
      toAmount,
      exactAmount,
      deadline,
      tempTransactionId,
      tradeVcash = 0,
      fromBalance = BigNumber(0),
      toBalance = BigNumber(0),
      gasPrice = 0
    ) => {
      const isUnWrap =
        fromCurrency?.address?.toLowerCase() ===
          WRAPPED_MAIN_ADDRESS?.toLowerCase() && !toCurrency?.address
      const isWrap =
        toCurrency?.address?.toLowerCase() === WRAPPED_MAIN_ADDRESS?.toLowerCase() &&
        !fromCurrency?.address
      const payload = {
        fromCurrency: fromCurrency?.symbol,
        toCurrency: toCurrency?.symbol,
        fromAmount: fromAmount,
        toAmount: toAmount,
        type: isWrap ? 'WRAP' : isUnWrap ? 'UNWRAP' : 'SWAP',
        status: TRANSACTION_STATUS.PENDING,
        startTime: tempTransactionId,
        chainId,
        tradeVcash,
        tradeToken: fromCurrency?.address || WRAPPED_MAIN_ADDRESS,
        tx: undefined,
      }
      try {
        const now = Math.floor(new Date().getTime() / 1000)
        const deadlineInMinutes = deadline ? parseFloat(deadline) : 20
        const amount = BigNumber(10 ** 18)
          .times(BigNumber(fromAmount))
          .toFixed(0)
        if (isWrap) {
          return WRAPPEDContract.methods
            .deposit()
            .send({
              from: account,
              value: amount,
              ...(gasPrice
                ? { gasPrice: (Number(gasPrice) * GAS_FACTOR).toFixed(0) }
                : {}),
            })
            .on('transactionHash', function (tx) {
              payload.tx = tx
              dispatchUpdateSwapTrans(tx, payload)
              return tx
            })
            .then((receipt) => {
              dispatchSuccessSwapTrans(receipt.transactionHash, {
                ...payload,
                status: TRANSACTION_STATUS.SUCCESS,
              })
              return true
            })
            .catch((error) => {
              if (
                Object.keys(error)?.[0] === 'receipt' &&
                !Object.values(error)?.[0]?.blockNumber
              ) {
                // future actions
              } else {
                dispatchFailSwapTrans(error, payload)
                return false
              }
            })
        }
        if (isUnWrap) {
          const isMaxClicked =
            parseFloat(fromAmount) >=
            parseFloat(
              weiToEthString(BigNumber(fromBalance), fromCurrency?.decimals)
            )
          const amountToSend = isMaxClicked
            ? BigNumber(fromBalance).toFixed(0)
            : amount
          return WRAPPEDContract.methods
            .withdraw(amountToSend)
            .send({
              from: account,
              ...(gasPrice
                ? { gasPrice: (Number(gasPrice) * GAS_FACTOR).toFixed(0) }
                : {}),
            })
            .on('transactionHash', function (tx) {
              payload.tx = tx
              dispatchUpdateSwapTrans(tx, payload)
              return tx
            })
            .then((receipt) => {
              dispatchSuccessSwapTrans(receipt.transactionHash, {
                ...payload,
                status: TRANSACTION_STATUS.SUCCESS,
              })
              return true
            })
            .catch((error) => {
              if (
                Object.keys(error)?.[0] === 'receipt' &&
                !Object.values(error)?.[0]?.blockNumber
              ) {
                // future actions
              } else {
                dispatchFailSwapTrans(error, payload)
                return false
              }
            })
        }

        if (exactAmount) {
          const fromAmountBigNum = BigNumber(10 ** fromCurrency?.decimals)
            .times(BigNumber(fromAmount * (1 + tolerance / 100)))
            .toFixed(0)
          const toAmountBigNum = BigNumber(10 ** toCurrency?.decimals)
            .times(BigNumber(toAmount))
            .toFixed(0)

          if (!fromCurrency?.address && toCurrency?.address) {
            return swapRouterContract?.methods
              .swapETHForExactToken(
                toCurrency.address,
                fromAmountBigNum,
                toAmountBigNum,
                account,
                now + Math.floor(deadlineInMinutes * 60)
              )
              .send({
                from: account,
                value: fromAmountBigNum,
                ...(gasPrice
                  ? { gasPrice: (Number(gasPrice) * GAS_FACTOR).toFixed(0) }
                  : {}),
              })
              .on('transactionHash', function (tx) {
                payload.tx = tx
                dispatchUpdateSwapTrans(tx, payload)
                return tx
              })
              .then((receipt) => {
                dispatchSuccessSwapTrans(receipt.transactionHash, {
                  ...payload,
                  status: TRANSACTION_STATUS.SUCCESS,
                })
                return true
              })
              .catch((error) => {
                if (
                  Object.keys(error)?.[0] === 'receipt' &&
                  !Object.values(error)?.[0]?.blockNumber
                ) {
                  // future actions
                } else {
                  dispatchFailSwapTrans(error, payload)
                  return false
                }
              })
          } else if (fromCurrency?.address && !toCurrency?.address) {
            return swapRouterContract?.methods
              .swapTokenForExactETH(
                fromCurrency.address,
                fromAmountBigNum,
                toAmountBigNum,
                account,
                now + Math.floor(deadlineInMinutes * 60)
              )
              .send({
                from: account,
                ...(gasPrice
                  ? { gasPrice: (Number(gasPrice) * GAS_FACTOR).toFixed(0) }
                  : {}),
              })
              .on('transactionHash', function (tx) {
                payload.tx = tx
                dispatchUpdateSwapTrans(tx, payload)
                return tx
              })
              .then((receipt) => {
                dispatchSuccessSwapTrans(receipt.transactionHash, {
                  ...payload,
                  status: TRANSACTION_STATUS.SUCCESS,
                })
                return true
              })
              .catch((error) => {
                if (
                  Object.keys(error)?.[0] === 'receipt' &&
                  !Object.values(error)?.[0]?.blockNumber
                ) {
                  // future actions
                } else {
                  dispatchFailSwapTrans(error, payload)
                  return false
                }
              })
          } else {
            return swapRouterContract?.methods
              .swapTokenForExactToken(
                fromCurrency.address,
                toCurrency.address,
                fromAmountBigNum,
                toAmountBigNum,
                account,
                now + Math.floor(deadlineInMinutes * 60)
              )
              .send({
                from: account,
                ...(gasPrice
                  ? { gasPrice: (Number(gasPrice) * GAS_FACTOR).toFixed(0) }
                  : {}),
              })
              .on('transactionHash', function (tx) {
                payload.tx = tx
                dispatchUpdateSwapTrans(tx, payload)
                return tx
              })
              .then((receipt) => {
                dispatchSuccessSwapTrans(receipt.transactionHash, {
                  ...payload,
                  status: TRANSACTION_STATUS.SUCCESS,
                })
                return true
              })
              .catch((error) => {
                if (
                  Object.keys(error)?.[0] === 'receipt' &&
                  !Object.values(error)?.[0]?.blockNumber
                ) {
                  // future actions
                } else {
                  dispatchFailSwapTrans(error, payload)
                  return false
                }
              })
          }
        }

        const fromAmountBigNum = BigNumber(10 ** fromCurrency?.decimals)
          .times(BigNumber(fromAmount))
          .toFixed(0)
        const toAmountBigNum = BigNumber(10 ** toCurrency.decimals)
          .times(BigNumber(toAmount * (1 - tolerance / 100)))
          .toFixed(0)
        const isMaxClicked =
          parseFloat(fromAmount) >=
          parseFloat(weiToEthString(BigNumber(fromBalance), fromCurrency?.decimals))
        const amountToSend = isMaxClicked
          ? BigNumber(fromBalance).toFixed(0)
          : fromAmountBigNum
        if (!fromCurrency?.address && toCurrency?.address) {
          return swapRouterContract?.methods
            .swapExactETHForToken(
              toCurrency.address,
              toAmountBigNum,
              account,
              now + Math.floor(deadlineInMinutes * 60)
            )
            .send({
              from: account,
              value: fromAmountBigNum,
              ...(gasPrice
                ? { gasPrice: (Number(gasPrice) * GAS_FACTOR).toFixed(0) }
                : {}),
            })
            .on('transactionHash', function (tx) {
              payload.tx = tx
              dispatchUpdateSwapTrans(tx, payload)
              return tx
            })
            .then((receipt) => {
              dispatchSuccessSwapTrans(receipt.transactionHash, {
                ...payload,
                status: TRANSACTION_STATUS.SUCCESS,
              })
              return true
            })
            .catch((error) => {
              if (
                Object.keys(error)?.[0] === 'receipt' &&
                !Object.values(error)?.[0]?.blockNumber
              ) {
                // future actions
              } else {
                dispatchFailSwapTrans(error, payload)
                return false
              }
            })
        } else if (fromCurrency?.address && !toCurrency?.address) {
          return swapRouterContract?.methods
            .swapExactTokenForETH(
              fromCurrency?.address,
              amountToSend,
              toAmountBigNum,
              account,
              now + Math.floor(deadlineInMinutes * 60)
            )
            .send({
              from: account,
              ...(gasPrice
                ? { gasPrice: (Number(gasPrice) * GAS_FACTOR).toFixed(0) }
                : {}),
            })
            .on('transactionHash', function (tx) {
              payload.tx = tx
              dispatchUpdateSwapTrans(tx, payload)
              return tx
            })
            .then((receipt) => {
              dispatchSuccessSwapTrans(receipt.transactionHash, {
                ...payload,
                status: TRANSACTION_STATUS.SUCCESS,
              })
              return true
            })
            .catch((error) => {
              if (
                Object.keys(error)?.[0] === 'receipt' &&
                !Object.values(error)?.[0]?.blockNumber
              ) {
                // future actions
              } else {
                dispatchFailSwapTrans(error, payload)
                return false
              }
            })
        } else {
          return swapRouterContract?.methods
            .swapExactTokenForToken(
              fromCurrency.address,
              toCurrency.address,
              amountToSend,
              toAmountBigNum,
              account,
              now + Math.floor(deadlineInMinutes * 60)
            )
            .send({
              from: account,
              ...(gasPrice
                ? { gasPrice: (Number(gasPrice) * GAS_FACTOR).toFixed(0) }
                : {}),
            })
            .on('transactionHash', function (tx) {
              payload.tx = tx
              dispatchUpdateSwapTrans(tx, payload)
              return tx
            })
            .then((receipt) => {
              dispatchSuccessSwapTrans(receipt.transactionHash, {
                ...payload,
                status: TRANSACTION_STATUS.SUCCESS,
              })
              return true
            })
            .catch((error) => {
              if (
                Object.keys(error)?.[0] === 'receipt' &&
                !Object.values(error)?.[0]?.blockNumber
              ) {
                // future actions
              } else {
                dispatchFailSwapTrans(error, payload)
                return false
              }
            })
        }
      } catch (err) {
        dispatch(
          updateSwapTransaction({
            isPerforming: false,
            isRejected: true,
            successResult: undefined,
          })
        )
        if (process.env.REACT_APP_DEV_ENV === 'development') {
          console.log('onSwap error: ', err)
        }
        return false
      }
    },
    [poolsContract, dispatchUpdateSwapTrans, chainId]
  )

  return { onGetAmountIn, onGetAmountOut, onSwap }
}

export default useSwapToken
