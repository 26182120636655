import { useEffect, useContext, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BigNumber from 'bignumber.js'

import useWallet from 'hooks/useWallet'
import { saveRPCError } from 'state/users/actions'
import { AccountContext } from 'contexts/AccountProvider'
import { weiToEthNum, isAddress } from 'monox/constants'

const usePool = (ERC20TokenAddress, isSwitching = 0, currencyChainId = null) => {
  const _isMounted = useRef(true)
  const [pool, setPool] = useState()
  const [poolLoading, setPoolLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const [lpLoading, setLpLoading] = useState(true)
  const [balance, setBalance] = useState(null)
  const [balanceBigNumber, setBalanceBigNumber] = useState(null)
  const [lPAmount, setLPAmount] = useState('')
  const [sharedPercent, setSharedPercent] = useState(0)
  const [totalSupply, setTotalSupply] = useState(0)
  const [pooledAmount, setPooledAmount] = useState(0)
  const [vcashOut, setVcashOut] = useState(0)
  const [vcashBalance, setVcashBalance] = useState(0)
  const [insurance, setInsurance] = useState(0)
  const [poolValue, setPoolValue] = useState(BigNumber(0))
  const networkId = useSelector(({ network }) => network.id)
  const SWAP_ROUTER_ADDRESS = useSelector(
    ({ network }) => network?.SWAP_ROUTER_ADDRESS
  )
  const POOL_ADDRESS = useSelector(({ network }) => network?.POOL_ADDRESS)
  const wallet = useSelector(({ user }) => user.wallet)
  const rpcError = useSelector(({ user }) => user.rpcError)

  const dispatch = useDispatch()
  const { account, chainId } = useWallet()
  const { swapRouterContract, poolsContract, infuraContract, infuraPoolContract } =
    useContext(AccountContext)

  const getPoolData = async () => {
    if (!account) return
    if (
      chainId !== networkId ||
      SWAP_ROUTER_ADDRESS !== swapRouterContract?._address
    )
      return
    if (currencyChainId && chainId && currencyChainId !== chainId) {
      return
    }
    if (!isAddress(ERC20TokenAddress)) return
    setPoolLoading(true)
    setDataLoading(true)
    setLpLoading(true)
    try {
      const poolData = await swapRouterContract?.methods
        ?.pools(ERC20TokenAddress)
        ?.call()
      setPoolLoading(false)
      if (poolData) {
        rpcError && dispatch(saveRPCError(false))
        if (POOL_ADDRESS !== poolsContract?._address) return
        setPool(poolData)
        const tokenInsurance = await swapRouterContract?.methods
          ?.tokenInsurance(ERC20TokenAddress)
          ?.call()
        setInsurance(weiToEthNum(BigNumber(tokenInsurance)))
        const balance = await poolsContract?.methods
          ?.balanceOf(account, poolData?.pid)
          ?.call()
        const totalSupply = await poolsContract.methods
          ?.totalSupply(poolData?.pid)
          ?.call()
        if (_isMounted.current) {
          setBalance(weiToEthNum(new BigNumber(balance)))
          setBalanceBigNumber(new BigNumber(balance))
          setLPAmount(balance)
          setTotalSupply(weiToEthNum(new BigNumber(totalSupply)))
          setSharedPercent(
            (weiToEthNum(new BigNumber(balance)) * 100) /
              weiToEthNum(new BigNumber(totalSupply))
          )
          setVcashBalance(
            weiToEthNum(BigNumber(poolData?.vcashCredit - poolData?.vcashDebt))
          )
          const poolValueData = await swapRouterContract?.methods
            ?.getPool(ERC20TokenAddress)
            ?.call()
          setDataLoading(false)
          setLpLoading(false)
          if (poolValueData) {
            setPoolValue(BigNumber(poolValueData?.poolValue))
          }
        }
        if (new BigNumber(balance) < 1) return
        const result = await swapRouterContract?.methods
          ?._removeLiquidity(account, ERC20TokenAddress, balance)
          ?.call()
        if (_isMounted.current) {
          setPooledAmount(weiToEthNum(new BigNumber(result?.tokenOut)))
          setVcashOut(weiToEthNum(new BigNumber(result?.vcashOut)))
        }
      }
    } catch (e) {
      if (process.env.REACT_APP_DEV_ENV === 'development') {
        console.log(e)
      }
      !!e.code && !rpcError && dispatch(saveRPCError(true))
      setPool(null)
      setPoolLoading(false)
      setDataLoading(false)
    }
  }

  const getInfuraPoolData = async () => {
    if (wallet && chainId !== networkId) return
    setPoolLoading(true)
    setDataLoading(true)
    try {
      const infuraPoolData = await infuraContract?.methods
        ?.pools(ERC20TokenAddress)
        ?.call()
      setPoolLoading(false)
      if (infuraPoolData) {
        rpcError && dispatch(saveRPCError(false))
        setPool(infuraPoolData)
        const tokenInsurance = await infuraContract?.methods
          ?.tokenInsurance(ERC20TokenAddress)
          ?.call()
        setInsurance(weiToEthNum(BigNumber(tokenInsurance)))
        const totalSupply = await infuraPoolContract?.methods
          ?.totalSupply(infuraPoolData?.pid)
          ?.call()
        setTotalSupply(weiToEthNum(new BigNumber(totalSupply)))
        const poolValueData = await infuraContract?.methods
          ?.getPool(ERC20TokenAddress)
          ?.call()
        setDataLoading(false)
        if (poolValueData) {
          setPoolValue(BigNumber(poolValueData?.poolValue))
        }
      }
    } catch (err) {
      if (process.env.REACT_APP_DEV_ENV === 'development') {
        console.log(err)
      }
      !!err?.code && !rpcError && dispatch(saveRPCError(true))
      setPool(null)
      setPoolLoading(false)
      setDataLoading(false)
    }
  }

  useEffect(() => {
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (poolsContract && ERC20TokenAddress && wallet) {
      !isSwitching && getPoolData()
    } else if (infuraContract && ERC20TokenAddress && !wallet) {
      !isSwitching && getInfuraPoolData()
    }
  }, [
    poolsContract,
    infuraContract,
    ERC20TokenAddress,
    account,
    networkId,
    isSwitching,
  ])

  return {
    pool,
    balance,
    balanceBigNumber,
    lPAmount,
    sharedPercent,
    totalSupply,
    pooledAmount,
    poolValue,
    vcashOut,
    vcashBalance,
    insurance,
    getPoolData,
    poolLoading,
    dataLoading,
    lpLoading,
  }
}

export default usePool
