import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Label from 'components/Label'
import Spacer from 'components/Spacer'

import region from 'assets/img/region.webp'
import regionDark from 'assets/img/region-dark.webp'

const RestrictedRegion = () => {
  const isDark = useSelector(({ application }) => application.isDark)

  return (
    <Container>
      <img src={isDark ? regionDark : region} width="892" alt="region" />
      <Contain>
        <Label
          text="Service Not Available in Your Region"
          size="30"
          weight="800"
          align="center"
        />
        <Spacer />
        <Label
          text="Sorry! For compliance reasons, this service is not accessible in your area. Use of VPN, Tor, proxies or other means to circumvent this restriction is a violation of our Terms of Service."
          size="18"
          weight="800"
          align="center"
          opacity="0.5"
          style={{ lineHeight: 1.5 }}
        />
      </Contain>
    </Container>
  )
}

export default RestrictedRegion

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 95vh;
`
const Contain = styled.div`
  margin-top: -150px;
  max-width: 575px;
`
