import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { DownArrowAlt } from '@styled-icons/boxicons-regular/DownArrowAlt'
import { DiagonalArrowRightUpOutline } from '@styled-icons/evaicons-outline/DiagonalArrowRightUpOutline'

import useSwapToken from 'hooks/useSwapToken'
import useWallet from 'hooks/useWallet'
import {
  getEtherscanLink,
  toEllipsis,
  precise,
  preciseNotationRemove,
} from 'monox/util'
import { DISPLAY_MIN_VALUE, getGasPrice } from 'monox/constants'
import config from 'monox/config'
import { updateSwapTransaction } from 'state/swap/actions'
import { StyledExternalLink } from 'theme'

import { Warnings } from 'views/Swapper/components/SwapperCard'
import Modal from 'components/Modal'
import Spacer from 'components/Spacer'
import TransactionRejectModal from 'components/TransactionRejectModal'
import TransactionSuccessModal from 'components/TransactionSuccessModal'
import TokenImage from 'components/TokenImage'
import Label from 'components/Label'
import StyledIconButton from 'components/StyledIconButton'
import { CloseIcon } from 'components/IconButton'
import Checkbox from 'components/CheckBox'

import DarkRoundSwapIcon from 'assets/img/dark-swap-round.svg'
import WarningImg from 'assets/img/alert-warning.svg'

const ConfirmOrderModal = ({
  onDismiss,
  fromToken,
  toToken,
  fromAmount,
  toAmount,
  exactAmount,
  deadline,
  tradeVcash = 0,
  onCloseModal,
  onFinishTransaction,
  fromBalance = BigNumber(0),
  toBalance = BigNumber(0),
  priceImpact,
}) => {
  const [checked, setChecked] = useState(false)
  const dispatch = useDispatch()
  const { chainId, ethereum } = useWallet()
  const { onSwap } = useSwapToken()
  const { isPerforming, isRejected, successResult, status } = useSelector(
    ({ swap }) => swap
  )
  const networkId = useSelector(({ network }) => network.id)
  const isDark = useSelector(({ application }) => application.isDark)
  const WRAPPED_MAIN_ADDRESS = config?.[networkId || chainId]?.WRAPPED_MAIN_ADDRESS
  const tolereance = useSelector(({ settings }) => settings.swapTolerance)
  const isWrap =
    (fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address) ||
    (toToken?.address === WRAPPED_MAIN_ADDRESS && !fromToken?.address)

  const handleConfirm = useCallback(async () => {
    const tempTransactionId = +new Date()
    const gasPrice = ethereum ? await getGasPrice(ethereum) : 0
    dispatch(
      updateSwapTransaction({
        isPerforming: true,
        isRejected: false,
        successResult: undefined,
        successEnded: false,
        status: undefined,
      })
    )
    onSwap(
      fromToken,
      toToken,
      fromAmount,
      toAmount,
      exactAmount,
      deadline,
      tempTransactionId,
      tradeVcash,
      fromBalance,
      toBalance,
      gasPrice
    )
      .then((result) => {
        !!result && onFinishTransaction()
      })
      .catch(() => {
        dispatch(
          updateSwapTransaction({
            isPerforming: false,
            isRejected: true,
            successResult: undefined,
            successEnded: false,
            status: undefined,
          })
        )
      })
  })

  const handleCloseModal = () => {
    onCloseModal()
    onDismiss()
  }
  if (isRejected) {
    const message = `${
      !fromToken?.address && toToken?.address === WRAPPED_MAIN_ADDRESS
        ? 'Wrap'
        : fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address
        ? 'Unwrap'
        : 'Swap'
    } ${fromAmount} ${fromToken?.symbol} ${
      (!fromToken?.address && toToken?.address === WRAPPED_MAIN_ADDRESS) ||
      (fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address)
        ? 'to'
        : 'for'
    } ${toAmount} ${toToken?.symbol}`
    const translateId = `modal.common.transaction.reject.${
      !fromToken?.address && toToken?.address === WRAPPED_MAIN_ADDRESS
        ? 'wrap'
        : fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address
        ? 'unwrap'
        : 'swap'
    }`

    return (
      <TransactionRejectModal
        onDismiss={onDismiss}
        message={message}
        translateId={translateId}
        values={{
          fromAmount,
          fromSymbol: fromToken?.symbol,
          toAmount,
          toSymbol: toToken?.symbol,
        }}
        testId={`${
          !fromToken?.address && toToken?.address === WRAPPED_MAIN_ADDRESS
            ? 'wrap'
            : fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address
            ? 'unwrap'
            : 'swap'
        }`}
        payload={status}
      />
    )
  }
  if (successResult) {
    return (
      <TransactionSuccessModal
        token={toToken}
        onDismiss={handleCloseModal}
        payload={successResult}
      />
    )
  }

  return (
    <Modal>
      <Row>
        <Label
          text={
            !fromToken?.address && toToken?.address === WRAPPED_MAIN_ADDRESS
              ? 'Confirm Wrap'
              : fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address
              ? 'Confirm Unwrap'
              : 'Swap'
          }
          weight="800"
          size="16"
          translateId={`modal.swap.confirm.swap.title.${
            !fromToken?.address && toToken?.address === WRAPPED_MAIN_ADDRESS
              ? 'wrap'
              : fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address
              ? 'unwrap'
              : 'swap'
          }`}
          testId={`${
            !fromToken?.address && toToken?.address === WRAPPED_MAIN_ADDRESS
              ? 'wrap'
              : fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address
              ? 'unwrap'
              : 'swap'
          }`}
        />
        <CloseIcon
          onClick={onDismiss}
          size="20"
          style={{ marginLeft: 'auto' }}
          data-testid="dismiss"
        />
      </Row>
      <Spacer />
      <SwapRow isDark={isDark}>
        <Row style={{ width: '100%' }}>
          <TokenImage
            src={fromToken?.logoURI}
            height="32"
            width="32"
            letter={fromToken?.symbol && fromToken?.symbol?.[0]}
          />
          <Spacer />
          <Column>
            <Token>
              <Label size={14} text={fromToken?.name} weight="800" />
              <Label
                size={14}
                text={
                  fromAmount > DISPLAY_MIN_VALUE
                    ? precise(fromAmount, 6)
                    : fromAmount
                }
                weight="800"
                testId="swap-from-amount"
              />
            </Token>
            <Row>
              <Label
                text={`${fromToken?.symbol} `}
                size={11}
                opacity={0.3}
                weight="bold"
              />
              &nbsp;
              <StyledExternalLink
                href={getEtherscanLink(chainId, fromToken?.address, 'token')}
                target="_blank"
                style={{ justifyContent: 'flex-start' }}
              >
                {fromToken?.address && (
                  <>
                    <Label
                      color="#2eca93"
                      size={11}
                      weight="bold"
                      text={
                        fromToken?.address
                          ? `- ${toEllipsis(fromToken?.address, 16)}`
                          : ``
                      }
                    />
                    <DiagonalArrowRight />
                  </>
                )}
              </StyledExternalLink>
            </Row>
          </Column>
        </Row>
      </SwapRow>
      {isDark ? (
        <img
          src={DarkRoundSwapIcon}
          width="27"
          height="27"
          style={{ margin: '10px auto' }}
          alt="DarkRoundSwapIcon"
        />
      ) : (
        <DownArrow />
      )}
      <SwapRow isDark={isDark}>
        <Row style={{ width: '100%' }}>
          <TokenImage
            src={toToken?.logoURI}
            height="32"
            width="32"
            letter={toToken?.symbol && toToken?.symbol?.[0]}
          />
          <Spacer />
          <Column>
            <Token>
              <Label text={toToken?.name} size="14" weight="800" />
              <Label
                text={toAmount > DISPLAY_MIN_VALUE ? precise(toAmount, 6) : toAmount}
                size="14"
                weight="800"
                testId="swap-to-amount"
              />
            </Token>
            <Row>
              <Label
                text={`${toToken?.symbol} `}
                size={11}
                opacity={0.3}
                weight="800"
              />
              &nbsp;
              <StyledExternalLink
                href={getEtherscanLink(chainId, toToken?.address, 'token')}
                target="_blank"
                style={{ justifyContent: 'flex-start' }}
              >
                {toToken?.address && (
                  <>
                    <Label
                      color="#2eca93"
                      size={11}
                      text={
                        toToken?.address
                          ? `- ${toEllipsis(toToken?.address, 16)}`
                          : ``
                      }
                    />
                    <DiagonalArrowRight />
                  </>
                )}
              </StyledExternalLink>
            </Row>
          </Column>
        </Row>
      </SwapRow>
      <Spacer />
      <Label
        size="13"
        opacity="0.5"
        weight="800"
        translateId={
          (!fromToken?.address && toToken?.address === WRAPPED_MAIN_ADDRESS) ||
          (fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address)
            ? ''
            : exactAmount
            ? `modal.swap.confirm.swap.info.2`
            : `modal.swap.confirm.swap.info.1`
        }
        values={{
          amount:
            (!fromToken?.address && toToken?.address === WRAPPED_MAIN_ADDRESS) ||
            (fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address)
              ? ''
              : exactAmount
              ? preciseNotationRemove(
                  fromAmount * (1 + (isWrap ? 0 : tolereance) / 100),
                  6
                )
              : preciseNotationRemove(
                  toAmount * (1 - (isWrap ? 0 : tolereance) / 100),
                  6
                ),
          symbol:
            (!fromToken?.address && toToken?.address === WRAPPED_MAIN_ADDRESS) ||
            (fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address)
              ? ''
              : exactAmount
              ? fromToken?.symbol
              : toToken?.symbol,
        }}
      >
        {(!fromToken?.address && toToken?.address === WRAPPED_MAIN_ADDRESS) ||
        (fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address)
          ? ''
          : exactAmount
          ? `Input is estimated. You will sell at most ${preciseNotationRemove(
              fromAmount * (1 + (isWrap ? 0 : tolereance) / 100),
              6
            )} ${fromToken?.symbol} or the transaction will revert.`
          : `Output is estimated. You will receive at least ${preciseNotationRemove(
              toAmount * (1 - (isWrap ? 0 : tolereance) / 100),
              6
            )} ${toToken?.symbol} or the transaction will revert.`}
      </Label>
      {priceImpact > 10 && (
        <>
          <Warnings isDark={isDark}>
            <Contant>
              <Label
                text="Warning: price impact > 10%"
                size="16"
                weight="bold"
                color="#e28156"
                align="left"
                translateId="swap.price.impact.title"
              />
              <Label
                text="The swap has a price impact of at least 10%. Please check the checkbox below to continue with this swap."
                size="13"
                weight="bold"
                color="#e28156"
                align="left"
                translateId="swap.price.impact.content"
              />
            </Contant>
            <img
              src={WarningImg}
              style={{
                position: 'absolute',
                bottom: '-12px',
                right: '-11px',
              }}
              alt="WarningImg"
            />
          </Warnings>
          <Spacer />
          <Checkbox
            label="I accept the price impact"
            value={checked}
            checked={checked}
            onChange={() => setChecked(!checked)}
            translateId="swap.price.impact.check"
            variant="primary"
            size="13"
          />
        </>
      )}

      <Spacer />
      <StyledIconButton
        block
        disabled={(isPerforming && true) || (priceImpact > 10 && !checked)}
        shadow
        variant="primary"
        icon="arrow"
        isPerforming={isPerforming}
        isConfirmSwap={true}
        onClick={handleConfirm}
        modal={true}
        translateId={`modal.swap.confirm.swap.button.${
          !fromToken?.address && toToken?.address === WRAPPED_MAIN_ADDRESS
            ? 'wrap'
            : fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address
            ? 'unwrap'
            : 'swap'
        }`}
        testId={`${
          !fromToken?.address && toToken?.address === WRAPPED_MAIN_ADDRESS
            ? 'wrap'
            : fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address
            ? 'unwrap'
            : 'swap'
        }`}
      >
        {!fromToken?.address && toToken?.address === WRAPPED_MAIN_ADDRESS
          ? 'Confirm Wrap'
          : fromToken?.address === WRAPPED_MAIN_ADDRESS && !toToken?.address
          ? 'Confirm Unwrap'
          : 'Confirm Swap'}
      </StyledIconButton>
      {isDark && <Spacer />}
    </Modal>
  )
}

const Row = styled.div`
  display: flex;
  align-items: center;
`
const SwapRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  border-radius: ${(props) => props.isDark && '6px'};
  background-color: ${(props) =>
    props.isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(64, 221, 161, 0.05)'};
  box-sizing: border-box;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Token = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
  `}
`
const DownArrow = styled(DownArrowAlt)`
  height: 20px;
  width: 20px;
  padding: 5px;
  margin: auto;
  cursor: pointer;
`
const DiagonalArrowRight = styled(DiagonalArrowRightUpOutline)`
  width: 15px;
  height: 15px;
  margin-left: 0px !important;
`
const Contant = styled.div`
  display: block;
  align-items: center;
  padding: 20px 50px 20px 25px;
`

export default ConfirmOrderModal
