import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BigNumber from 'bignumber.js'

import { weiToEthNum } from 'monox/constants'
import { savePoolList } from 'state/pools/actions'
import useWallet from 'hooks/useWallet'

import { getPoolList } from 'api'

const usePoolList = (fetch = true) => {
  const _isMounted = useRef(true)
  const networkId = useSelector(({ network }) => network.id)
  const wallet = useSelector(({ user }) => user.wallet)
  const [poolList, setPoolList] = useState([])
  const [totalPoolList, setTotalPoolList] = useState([])
  const [loading, setLoading] = useState(false)
  const { chainId } = useWallet()
  const dispatch = useDispatch()

  const getPoolData = useCallback(() => {
    setLoading(true)
    try {
      getPoolList(networkId || chainId).then((poolList) => {
        if (_isMounted.current) {
          setLoading(false)
          if (!Array.isArray(poolList?.response)) {
            setTotalPoolList([])
            setPoolList([])
          } else {
            setTotalPoolList(poolList?.response || [])
            const poolData = poolList?.response?.filter(
              (item) => weiToEthNum(BigNumber(item?.tokenBalance)) > 0
            )
            setPoolList(poolData || [])
            dispatch(
              savePoolList({
                chainId: networkId || chainId,
                pools: poolData,
              })
            )
          }
        }
      })
    } catch (err) {
      setLoading(false)
      setPoolList([])
    }
  }, [chainId, networkId])

  useEffect(() => {
    if (!chainId && wallet) {
      setLoading(false)
      return
    }
    fetch && getPoolData()
  }, [chainId, networkId])

  useEffect(() => {
    return () => {
      _isMounted.current = false
    }
  }, [])

  return { poolList, loading, totalPoolList, getPoolData }
}

export default usePoolList
