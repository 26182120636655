import React, { useContext, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { ThemeContext } from 'styled-components'

import { StyledLink, StyledExternalLink } from 'theme'
import { images } from 'theme/config'

const Button = ({
  children,
  disabled,
  href,
  onClick,
  size,
  text,
  to,
  bg,
  variant,
  block,
  fontColor,
  hover = true,
  style = {},
  translateId,
  testId,
  values,
  account = false,
}) => {
  const { color, spacing } = useContext(ThemeContext)

  const FormattedText = () =>
    translateId ? (
      <FormattedMessage
        id={translateId}
        defaultMessage={text ?? children}
        values={values}
      />
    ) : (
      text ?? children
    )

  let buttonColor = ''

  switch (variant) {
    case 'secondary':
      buttonColor = color.grey[500]
      break
    default:
      buttonColor = bg ?? color.button.main
  }

  switch (fontColor) {
    case 'secondary':
      fontColor = color.grey[500]
      break
    case 'primary':
      fontColor = color.primary.main
      break
    default:
  }

  let boxShadow
  let buttonSize
  let buttonPadding
  let fontSize

  switch (size) {
    case 'sm':
      boxShadow = `4px 4px 8px ${color.grey[300]},
        -8px -8px 16px ${color.grey[100]}FF;`
      buttonPadding = spacing[3]
      buttonSize = 36
      fontSize = 14
      break
    case 'lg':
      boxShadow = `6px 6px 12px ${color.grey[300]},
        -12px -12px 24px ${color.grey[100]}ff;`
      buttonPadding = spacing[4]
      buttonSize = 72
      fontSize = 16
      break
    case 'md':
      boxShadow = `6px 6px 12px ${color.grey[300]},
        -12px -12px 24px -2px ${color.grey[100]}ff;`
      buttonPadding = spacing[4]
      buttonSize = 42
      fontSize = 14
      break
    default:
      boxShadow = `6px 6px 12px ${color.grey[300]},
        -12px -12px 24px -2px ${color.grey[100]}ff;`
      buttonPadding = spacing[4]
      buttonSize = 46
      fontSize = 16
  }

  const ButtonChild = useMemo(() => {
    if (to) {
      return <StyledLink to={to}>{FormattedText()}</StyledLink>
    } else if (href) {
      return (
        <StyledExternalLink href={href} target="__blank">
          {FormattedText()}
        </StyledExternalLink>
      )
    } else if (account) {
      return (
        <>
          <img
            src={images.accountLogo}
            style={{ margin: '0 5px 2px 0', width: '20px' }}
            alt=""
          />
          {text}
        </>
      )
    } else {
      return FormattedText()
    }
  }, [href, text, to])

  return (
    <>
      <StyledButton
        boxShadow={boxShadow}
        color={buttonColor}
        disabled={disabled}
        fontSize={fontSize}
        onClick={onClick}
        padding={buttonPadding}
        size={buttonSize}
        block={block}
        fontColor={fontColor}
        style={style}
        hover={hover}
        data-testid={testId}
      >
        {ButtonChild}
      </StyledButton>
    </>
  )
}

const StyledButton = styled.button`
  align-items: center;
  background-color: ${(props) =>
    `${!props.disabled ? props.color : `${props.color}a1`}`};
  border: 1px solid
    ${(props) => (!props.disabled ? props.color : `${props.color}11`)};
  border-radius: 4px;
  color: ${(props) => (!props.color ? props.color : props.fontColor ?? `#fff`)};
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.fontSize}px;
  font-weight: 700;
  height: ${(props) => props.size}px;
  justify-content: center;
  outline: none;
  padding-left: ${(props) => props.padding}px;
  padding-right: ${(props) => props.padding}px;
  pointer-events: ${(props) => (!props.disabled ? undefined : 'none')};
  width: ${(props) => props.block && '100%'};
  transition: background-color 0.3s ease-out;
  &:hover {
    background: ${(props) => props.hover && props.theme.color.button.main};
    border-color: ${(props) => props.hover && props.theme.color.button.main};
    color: #ffffff !important;
  }
  :disabled: {
    background: ${({ theme }) => theme.color.background.main};
    border-color: ${({ theme }) => theme.color.background.main};
  }
`

export const SwitchButton = styled.div`
  display: felx;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  width: ${(props) => `${props.width ?? 65}px`};
  height: ${(props) => `${props.height ?? 27}px`};
  font-size: 13px;
  text-align: center;
  border-radius: 4px;
  font-weight: 800;
  line-height: 2.3;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.shadows.switch};
  color: ${({ theme, selected }) =>
    selected ? 'rgba(33, 45, 99, 0.3)' : theme.color.secondary.main};
  background-color: ${({ theme }) => theme.color.background.main};
  &:hover {
    color: ${({ theme }) => theme.color.primary.main};
    box-shadow: ${({ theme }) => theme.shadows.hoveredBtn};
  }
`

export const HomePageButton = styled.button`
  padding: 9px 27px;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.button};
  background-color: ${({ theme }) => theme.color.primary.green};
  font-size: 14px;
  font-weight: 800;
  font-stretch: normal;
  text-align: center;
  cursor: pointer;
  color: ${({ theme, primary }) =>
    primary ? theme.color.white : theme.color.font.secondary};
  border: none;
  :hover {
    color: white;
  }
`

export const BondPageButton = styled.div`
  width: 40%;
  @media (min-width: 580px) {
    padding: 5px 10px;
  }
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.button};
  background-color: ${({ theme }) => theme.color.primary.green};
  font-size: 14px;
  font-weight: 800;
  font-stretch: normal;
  text-align: center;
  cursor: pointer;
  color: ${({ theme, primary }) =>
    primary ? theme.color.white : theme.color.font.secondary};
  border: none;
  :hover {
    color: white;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

export const LightPaperButton = styled.button`
  padding: 9px 27px;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.button};
  background-color: ${({ theme }) => theme.color.primary.green};
  background-image: linear-gradient(to top, #55b2ff, #7586f8);
  font-size: 14px;
  font-weight: 800;
  font-stretch: normal;
  text-align: center;
  cursor: pointer;
  color: ${({ theme, primary }) =>
    primary ? theme.color.white : theme.color.font.secondary};
  border: none;
  :hover {
    color: white;
  }
`

export default Button
