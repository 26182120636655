import React, { useContext, useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import BigNumber from 'bignumber.js'
import { useHistory } from 'react-router-dom'
import { TelegramShareButton, TwitterShareButton } from 'react-share'
import uniqBy from 'lodash/uniqBy'
import ReactTooltip from 'react-tooltip'
import { FormattedMessage, useIntl } from 'react-intl'
import { DiagonalArrowRightUpOutline } from '@styled-icons/evaicons-outline/DiagonalArrowRightUpOutline'

import useWallet from 'hooks/useWallet'
import { weiToEthNum, DISPLAY_MIN_VALUE } from 'monox/constants'
import {
  toEllipsis,
  getEtherscanLink,
  notationRemoved,
  precise,
  showToast,
} from 'monox/util'
import config from 'monox/config'
import uniswapTokens from 'monox/uniswap_all_tokens_list'
import { StyledExternalLink } from 'theme'

import { AutoColumn } from 'components/Column'
import { RowBetween, RowFixed } from 'components/Row'
import QuestionHelper from 'components/QuestionHelper'
import Label from 'components/Label'
import Loader from 'components/Loader'

import TwitterIcon from 'assets/img/twitterIcon.svg'
import HoveredTwitterIcon from 'assets/img/hoverTwitter.svg'
import darkModeTwitterIcon from 'assets/img/twitter-darkmode.svg'
import TelegramIcon from 'assets/img/telegramIcon.svg'
import HoveredTelegramIcon from 'assets/img/hoverTelegram.svg'
import darkModeTelegramIcon from 'assets/img/telegram-darkmode.svg'
import CopyLink from 'assets/img/copyLink.svg'
import HoveredCopyLink from 'assets/img/hoverCopyLink.svg'
import darkModeLinkIcon from 'assets/img/link-darkmode.svg'

const SwapWidget = ({
  fromToken,
  toToken,
  fromAmount,
  fromPoolData,
  toAmount,
  toPoolData,
  code,
}) => {
  const { chainId, account } = useWallet()
  const { origin, pathname } = window.location
  const history = useHistory()
  const [hoveredLink, setHoveredLink] = useState(false)
  const [hoveredTelegram, setHoveredTelegram] = useState(false)
  const [hoveredTwitter, setHoveredTwitter] = useState(false)
  const [referralTip, setReferralTip] = useState(true)
  const tolerance = useSelector(({ settings }) => settings.swapTolerance)
  const networkId = useSelector(({ network }) => network.id)
  const isDark = useSelector(({ application }) => application.isDark)
  const networkName = config?.[networkId || chainId]?.NAME?.toLowerCase()
  const VCASH_ADDRESS = config?.[networkId || chainId]?.VCASH?.address
  const MONOData = config?.[networkId || chainId]?.MONO
  const WRAPPED_MAIN_ADDRESS = config?.[networkId || chainId]?.WRAPPED_MAIN_ADDRESS
  const intl = useIntl()

  const shareUrl = `${origin}${pathname}?network=${networkName}${
    account ? `&ref=${code}` : ''
  }`

  const fromPrice =
    fromToken?.address === VCASH_ADDRESS
      ? 1
      : weiToEthNum(BigNumber(fromPoolData?.price), 36 - fromToken?.decimals)

  const toPrice =
    toToken?.address === VCASH_ADDRESS
      ? 1
      : weiToEthNum(BigNumber(toPoolData?.price), 36 - toToken?.decimals)

  const { color } = useContext(ThemeContext)

  useEffect(() => {
    if (hoveredLink) {
      setReferralTip(true)
    } else {
      setReferralTip(false)
    }
  }, [hoveredLink])

  const priceImpact = useMemo(() => {
    const ratio = fromPrice / toPrice
    if ((toAmount === 0 && !!fromAmount) || (fromAmount === 0 && !!toAmount))
      return '∞'
    if (ratio > 0) {
      const impact = (Math.abs(ratio - toAmount / fromAmount) * 100) / ratio - 0.3
      if (impact >= 100) return ''
      else if (impact >= 0.1) return impact.toFixed(2)
      else return '< 0.01'
    } else return ''
  }, [fromPrice, toPrice, fromAmount, toAmount])

  const handleRouteChange = () => {
    history.push({
      pathname: `/analytics/${
        fromToken?.address ? fromToken?.address : WRAPPED_MAIN_ADDRESS
      }`,
      search: `?network=${networkName}`,
      state: { backUrl: history.location.pathname },
    })
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl)
    showToast(
      <div style={{ width: '220px' }}>
        <Label
          text={account ? 'Referral Link Copied' : 'Link Copied'}
          translateId={
            account
              ? 'common.link.referral.copied.label'
              : 'common.link.copied.label'
          }
          size="13"
          weight="800"
          opacity="0.8"
        />
      </div>,
      {
        toastId: 'copy-link',
      }
    )
    setReferralTip(false)
  }

  const tokenList = uniqBy(
    [...uniswapTokens.tokens, VCASH_ADDRESS, MONOData].filter(
      (t) => t?.chainId === networkId && t?.address
    ),
    'address'
  )

  const addressTokenList = tokenList.map((item) => item?.address)

  const availableTokens =
    toToken &&
    (addressTokenList?.includes(toToken?.address) ||
      !toToken?.address ||
      toToken?.address === VCASH_ADDRESS?.address)

  const referralLabel = intl.formatMessage({
    id: account ? 'common.link.referral.label' : 'common.link.label',
    defaultMessage: account ? 'Copy referral link' : 'Copy Link',
  })

  const telegramLabel = intl.formatMessage({
    id: 'common.link.telegram.label',
    defaultMessage: 'Telegram',
  })
  const twitterLabel = intl.formatMessage({
    id: 'common.link.twitter.label',
    defaultMessage: 'Twitter',
  })

  const minimumReceived = precise(toAmount * (1 - tolerance / 100), 6)

  if (!parseFloat(fromAmount) || !parseFloat(toAmount)) return ''

  return fromToken || toToken ? (
    fromAmount >= 0 && toAmount >= 0 && fromAmount !== toAmount ? (
      <WidgetCard isDark={isDark}>
        <AutoColumn style={{ padding: '18px' }}>
          {!account || code ? (
            <RowAround>
              <TwitterShareButton
                title={
                  availableTokens
                    ? `Check out ${toToken?.symbol} on MonoX!`
                    : 'Check out this project on MonoX!'
                }
                url={shareUrl}
              >
                <RoundButton
                  isDark={isDark}
                  data-testid="twitter"
                  onMouseEnter={() => setHoveredTwitter(true)}
                  onMouseLeave={() => setHoveredTwitter(false)}
                  data-tip={twitterLabel}
                  data-for="twitter"
                >
                  <ReactTooltip id="twitter" effect="solid" place="bottom" />
                  {hoveredTwitter ? (
                    <img src={HoveredTwitterIcon} width={15} alt="twiter icon" />
                  ) : (
                    <img
                      src={isDark ? darkModeTwitterIcon : TwitterIcon}
                      width={15}
                      alt="twiter icon"
                    />
                  )}
                </RoundButton>
              </TwitterShareButton>
              <TelegramShareButton
                title={
                  availableTokens
                    ? `Check out ${toToken?.symbol} on MonoX!`
                    : 'Check out this project on MonoX!'
                }
                url={shareUrl}
              >
                <RoundButton
                  isDark={isDark}
                  data-testid="telegram"
                  onMouseEnter={() => setHoveredTelegram(true)}
                  onMouseLeave={() => setHoveredTelegram(false)}
                  data-tip={telegramLabel}
                  data-for="telegram"
                >
                  <ReactTooltip id="telegram" effect="solid" place="bottom" />
                  {hoveredTelegram ? (
                    <img src={HoveredTelegramIcon} width={15} alt="twiter icon" />
                  ) : (
                    <img
                      src={isDark ? darkModeTelegramIcon : TelegramIcon}
                      width={15}
                      alt="twiter icon"
                    />
                  )}
                </RoundButton>
              </TelegramShareButton>
              <RoundButton
                isDark={isDark}
                onClick={handleCopyLink}
                data-tip
                data-for="referral"
                data-testid="copy-link"
                onMouseEnter={() => setHoveredLink(true)}
                onMouseLeave={() => setHoveredLink(false)}
              >
                <ReactTooltip
                  id="referral"
                  effect="solid"
                  place="bottom"
                  getContent={() => (referralTip ? referralLabel : '')}
                />
                {hoveredLink ? (
                  <img src={HoveredCopyLink} width={15} alt="copy link" />
                ) : (
                  <img
                    src={isDark ? darkModeLinkIcon : CopyLink}
                    width={15}
                    alt="copy link"
                  />
                )}
              </RoundButton>
            </RowAround>
          ) : (
            <Loader size="15" style={{ margin: 'auto' }} />
          )}
          <Row isDark={isDark}>
            <RowFixed>
              <Label
                text="Slippage Tolerance"
                opacity={0.5}
                size={11}
                weight="bold"
                translateId="swap.widget.row.1.title"
              />
            </RowFixed>
            <RowFixed justify="flex-end">
              <Label text={`${tolerance} %`} size={11} weight="bold" />
            </RowFixed>
          </Row>
          <Row isDark={isDark}>
            <RowFixed>
              <Label
                text="Minimum received"
                opacity={0.5}
                size={11}
                weight="bold"
                translateId="swap.widget.row.2.title"
              />
              <QuestionHelper
                id="received"
                translateId="swap.widget.row.2.helper"
                text="Your transaction will revert if there is a large unfavorable price movement before it is confirmed"
              />
            </RowFixed>
            <RowFixed justify="flex-end">
              <Label
                text={`${
                  minimumReceived >= DISPLAY_MIN_VALUE
                    ? minimumReceived
                    : notationRemoved(minimumReceived)
                } ${toToken?.symbol}`}
                size={11}
                weight="bold"
              />
            </RowFixed>
          </Row>
          <Row isDark={isDark}>
            <RowFixed justify="start">
              <Label
                text="Price Impact"
                opacity={priceImpact >= 5 ? 1 : 0.5}
                color={priceImpact >= 5 ? color.redPink : null}
                size={11}
                weight={priceImpact >= 5 ? 800 : 'bold'}
                translateId="swap.widget.row.3.title"
              />
              <QuestionHelper
                id="price-impact"
                translateId="swap.widget.row.3.helper"
                text="The difference between the market price and estimated price due to trade size"
              />
            </RowFixed>
            <RowFixed justify="flex-end" style={{ color: color.redPink }}>
              <Label
                text={`${fromAmount && toAmount && priceImpact}%`}
                color={priceImpact >= 5 ? color.redPink : null}
                size={11}
                weight={priceImpact >= 5 ? 800 : 'bold'}
              />
            </RowFixed>
          </Row>
          <Row isDark={isDark}>
            <RowFixed justify="start">
              <Label
                text="Liquidity Provider Fee"
                opacity={0.5}
                size={11}
                weight="bold"
                translateId="swap.widget.row.4.title"
              />
              <QuestionHelper
                id="provider"
                translateId="swap.widget.row.4.helper"
                text=" A portion of each trade (0.3%) goes to liquidity providers as a protocol incentive"
              />
            </RowFixed>
            <RowFixed justify="flex-end">
              <Label
                text={
                  precise(fromAmount * 0.003, 6) < DISPLAY_MIN_VALUE
                    ? `<${DISPLAY_MIN_VALUE} ${fromToken?.symbol}`
                    : `${precise(fromAmount * 0.003, 6)} ${fromToken?.symbol}`
                }
                size={11}
                weight="bold"
              />
            </RowFixed>
          </Row>
          {fromToken?.address && (
            <Row isDark={isDark} last={fromToken?.address ? false : true}>
              <RowFixed>
                <Label
                  text={`${fromToken?.symbol} Address`}
                  opacity={0.5}
                  size={11}
                  weight="bold"
                  translateId="swap.widget.row.5.title"
                  values={{ currency: fromToken?.symbol }}
                />
              </RowFixed>
              <RowFixed justify="flex-end">
                <Label size={11} weight="800">
                  <Address>
                    <StyledExternalLink
                      target="_blank"
                      href={getEtherscanLink(chainId, fromToken?.address, 'address')}
                      rel="noopener noreferrer"
                      style={{ justifyContent: 'left' }}
                    >
                      <Label
                        weight={800}
                        color="#2eca93"
                        size={11}
                        text={
                          fromToken?.address
                            ? `${toEllipsis(fromToken?.address, 16)}`
                            : ``
                        }
                        style={{ lineHeight: 1.6 }}
                      />
                      <GoTo />
                    </StyledExternalLink>
                  </Address>
                </Label>
              </RowFixed>
            </Row>
          )}
          {toToken?.address && (
            <Row isDark={isDark}>
              <RowFixed>
                <Label
                  text={`${toToken?.symbol} Address`}
                  opacity={0.5}
                  size={11}
                  weight="bold"
                  translateId="swap.widget.row.6.title"
                  values={{ currency: toToken?.symbol }}
                />
              </RowFixed>
              <RowFixed justify="flex-end">
                <Label size={11} weight="800">
                  <Address>
                    <StyledExternalLink
                      target="_blank"
                      href={getEtherscanLink(chainId, toToken?.address, 'address')}
                      rel="noopener noreferrer"
                      style={{ justifyContent: 'left' }}
                    >
                      <Label
                        weight={800}
                        color="#2eca93"
                        size={11}
                        text={
                          toToken?.address
                            ? `${toEllipsis(toToken?.address, 16)}`
                            : ``
                        }
                        style={{ lineHeight: 1.6 }}
                      />
                      <GoTo />
                    </StyledExternalLink>
                  </Address>
                </Label>
              </RowFixed>
            </Row>
          )}
          {fromToken?.address?.toLowerCase() !== VCASH_ADDRESS?.toLowerCase() ? (
            <ViewPair onClick={handleRouteChange} data-testid="token-analytics">
              <Span>
                <FormattedMessage
                  id="swap.widget.button"
                  defaultMessage="View Token Analytics"
                />
              </Span>
            </ViewPair>
          ) : (
            ''
          )}
        </AutoColumn>
      </WidgetCard>
    ) : (
      <WidgetCard isDark={isDark}>
        <AutoColumn style={{ padding: '18px' }}>
          {!account || code ? (
            <RowAround>
              <TwitterShareButton
                title={
                  availableTokens
                    ? `Check out ${toToken?.symbol} on MonoX!`
                    : 'Check out this project on MonoX!'
                }
                url={shareUrl}
              >
                <RoundButton
                  isDark={isDark}
                  data-testid="twitter"
                  onMouseEnter={() => setHoveredTwitter(true)}
                  onMouseLeave={() => setHoveredTwitter(false)}
                  data-tip={twitterLabel}
                  data-for="twitter"
                >
                  <ReactTooltip id="twitter" effect="solid" place="bottom" />
                  {hoveredTwitter ? (
                    <img src={HoveredTwitterIcon} width={15} alt="twiter icon" />
                  ) : (
                    <img
                      src={isDark ? darkModeTwitterIcon : TwitterIcon}
                      width={15}
                      alt="twiter icon"
                    />
                  )}
                </RoundButton>
              </TwitterShareButton>
              <TelegramShareButton
                title={
                  availableTokens
                    ? `Check out ${toToken?.symbol} on MonoX!`
                    : 'Check out this project on MonoX!'
                }
                url={shareUrl}
              >
                <RoundButton
                  isDark={isDark}
                  data-testid="telegram"
                  onMouseEnter={() => setHoveredTelegram(true)}
                  onMouseLeave={() => setHoveredTelegram(false)}
                  data-tip={telegramLabel}
                  data-for="telegram"
                >
                  <ReactTooltip id="telegram" effect="solid" place="bottom" />
                  {hoveredTelegram ? (
                    <img src={HoveredTelegramIcon} width={15} alt="twiter icon" />
                  ) : (
                    <img
                      src={isDark ? darkModeTelegramIcon : TelegramIcon}
                      width={15}
                      alt="twiter icon"
                    />
                  )}
                </RoundButton>
              </TelegramShareButton>
              <RoundButton
                isDark={isDark}
                onClick={handleCopyLink}
                data-tip
                data-for="referral"
                data-testid="copy-link"
                onMouseEnter={() => setHoveredLink(true)}
                onMouseLeave={() => setHoveredLink(false)}
              >
                <ReactTooltip
                  id="referral"
                  effect="solid"
                  place="bottom"
                  getContent={() => (referralTip ? referralLabel : '')}
                />
                {hoveredLink ? (
                  <img src={HoveredCopyLink} width={15} alt="copy link" />
                ) : (
                  <img
                    src={isDark ? darkModeLinkIcon : CopyLink}
                    width={15}
                    alt="copy link"
                  />
                )}
              </RoundButton>
            </RowAround>
          ) : (
            <Loader size="15" style={{ margin: 'auto' }} />
          )}
        </AutoColumn>
      </WidgetCard>
    )
  ) : null
}

export default SwapWidget

const WidgetCard = styled.div`
  border-radius: 15px;
  box-shadow: ${(props) =>
    props.isDark ? '' : '3px 3px 8px 0 rgba(209, 217, 230, 0.5)'};
  background-color: ${(props) => props.isDark && 'rgba(255, 255, 255, 0.02)'};
  width: 320px;
  margin: 0 auto;
  transform-origin: 100% 0;
  animation: swap-widget 0.5s;
  @keyframes swap-widget {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
  :after {
    position: absolute;
    content: '';
    bottom: -100px;
    height: 40px;
    width: 1px;
  }`}
`

const Row = styled(RowBetween)`
  padding: 5px 0;
  ${(props) =>
    props.last
      ? 'border:none'
      : props.isDark
      ? 'border-bottom: solid 1px rgba(255,255,255,0.15);'
      : 'border-bottom:1px solid #d7dee8'}
`
const RowAround = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
`

const ViewPair = styled.div`
  margin-top:10px;
  border-radius: 4px;
  display: flex;
  height: 26px;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-out;
  background-color: ${(props) => props.theme.color.button.main};; 
  &:hover {
    box-shadow: 0 6px 21px 0 #d1d9e6; !important;
  }
`
const Span = styled.div`
  font-size: 11px;
  font-weight: 800;
  color: #ffffff;
  margin: 5px 0;
`

const GoTo = styled(DiagonalArrowRightUpOutline)`
  width: 15px;
  height: 15px;
  margin-left: 0px !important;
`

const Address = styled.div`
  color: #2eca93;
  font-size: 11px;
  font-weight: 400;
  display: flex;
  :hover {
    color: ${({ theme }) => theme.color.font.primary};
    opacity: 1;
    font-weight: 800;
    .etherscan-link {
      display: inline-block;
    }
  }
  .etherscan-link {
    display: none;
  }
`

const RoundButton = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border-radius: 50%;
  cursor: pointer;
  border: ${(props) =>
    props.isDark ? '1px solid rgba(255, 255, 255, 0.02)' : '1px solid #d7dee8'};
  background-color: ${(props) => props.isDark && 'rgba(255, 255, 255, 0.02)'};
  :hover {
    border: 1px solid ${(props) => props.theme.color.border.green};
  }
`
