import {
  SET_BOND_DISCOUNTS,
  SET_BOND_PURCHASE,
  SET_BOND_TREASURY,
  SET_BOND_TREASURY_FROM_API,
  CLEAR_BOND_DATA_LIST,
} from './constants'
import { supportedChainIds } from 'monox/constants'
import { arrayToObject } from 'monox/util'

const initialState = {
  bondPurchase: arrayToObject(supportedChainIds, null, {}),
  bondDiscounts: arrayToObject(supportedChainIds, null, []),
  bondTotalTreasury: arrayToObject(supportedChainIds, null, 0),
  bondTotalTreasuryFromAPI: arrayToObject(supportedChainIds, null, 0),
}

const bondReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOND_DISCOUNTS:
      return {
        ...state,
        bondDiscounts: {
          ...state.bondDiscounts,
          [action.payload.chainId]: action?.payload?.bondDiscounts,
        },
      }
    case SET_BOND_PURCHASE:
      return {
        ...state,
        bondPurchase: {
          ...state.bondPurchase,
          [action.payload.chainId]: action?.payload?.bondPurchase,
        },
      }
    case SET_BOND_TREASURY:
      return {
        ...state,
        bondTotalTreasury: {
          ...state.bondTotalTreasury,
          [action.payload.chainId]: action?.payload?.bondTotalTreasury,
        },
      }
    case SET_BOND_TREASURY_FROM_API:
      return {
        ...state,
        bondTotalTreasuryFromAPI: {
          ...state.bondTotalTreasuryFromAPI,
          [action.payload.chainId]: action?.payload?.bondTotalTreasuryFromAPI,
        },
      }
    case CLEAR_BOND_DATA_LIST:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default bondReducer
