import React, { createContext, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import PrivateKeyProvider from 'truffle-privatekey-provider'
import { getWithExpiry, setWithExpiry } from 'storage'

import { saveWallet, savePrivateKey, clearUserInfo } from 'state/users/actions'
import { clearPoolList } from 'state/pools/actions'
import { clearNetwork } from 'state/network/actions'
import { clearTransactions } from 'state/transaction/actions'
import {
  clearBondDataList,
  setBondTotalTreasury,
  setBondDiscounts,
  setBondPurchase,
} from 'state/bond/actions'
import { saveProvider } from 'state/provider/actions'
import { setVcashPrice } from 'state/applications/actions'
import { checkIfUserConsented, getVCASHPrice, checkIfHackedUser } from 'api'

import {
  weiToEthNum,
  getLPToken,
  supportedChainIds,
  getPoolContract,
  getStakeContract,
  getSwapContract,
  getSwapRouterContract,
  getOldSwapContract,
  getFutureContract,
  getERC20Token,
  getVCASHToken,
  getVaultContract,
  getWRAPPEDContract,
  getStakedvUNITContract,
  getBondContract,
  getReserveContract,
  getBondStakeContract,
  getBondCalculator,
  getBondTreasury,
  getBondStakeHelperContract,
  TRIGGER_DATE,
  ETHERSCAN_CHAINS,
  FUTURE_ALLOW_CHAINS,
  VAULT_ALLOW_CHAINS,
  BOND_STAKE_ALLOW_CHAINS,
  getCurrentBlock,
} from 'monox/constants'
import config, { BOND_LIST } from 'monox/config'

import useWallet from 'hooks/useWallet'
import { useInactiveListener } from 'hooks/web3'

export const AccountContext = createContext({
  poolsContract: null,
  swapContract: null,
  swapRouterContract: null,
  stakeContract: null,
  futureContract: null,
  infuraContract: null,
  vaultContract: null,
  svUNITContract: null,
  bondCalcContract: null,
  bondTreasuryContract: null,
  bondStakeContract: null,
  bondStakeHelperContract: null,
  bondContracts: {},
  bondReserveContracts: {},
  vCASHToken: null,
  tokenList: null,
  currentBlock: null,
  isHackedUser: false,
  userHackedData: [],
  connect: () => {},
})

const AccountProvider = ({ children }) => {
  const { ethereum, account, status, connect, chainId } = useWallet()
  const networkId = useSelector(({ network }) => network.id)
  const SWAP_ADDRESS = useSelector(({ network }) => network?.SWAP_ADDRESS)
  const SWAP_ROUTER_ADDRESS = useSelector(
    ({ network }) => network.SWAP_ROUTER_ADDRESS
  )
  const STAKING_ADDRESS = useSelector(({ network }) => network?.STAKING_ADDRESS)
  const POOL_ADDRESS = useSelector(({ network }) => network?.POOL_ADDRESS)
  const FUTURE_ADDRESS = useSelector(({ network }) => network?.FUTURE_ADDRESS)
  const STAKED_MONO_ADDRESS = config?.[chainId || networkId]?.dMONO?.address
  const STAKED_VUNIT_ADDRESS = config?.[chainId || networkId]?.STAKED_VUNIT_ADDRESS
  const BOND_CALCULATOR_ADDRESS =
    config?.[networkId || chainId]?.BOND_CALCULATOR_ADDRESS
  const BOND_TREASURY_ADDRESS = config?.[networkId || chainId]?.TREASURY_ADDRESS
  const BOND_STAKING_ADDRESS = config?.[chainId || networkId]?.BOND_STAKING_ADDRESS
  const BOND_STAKING_HELPER_ADDRESS =
    config?.[chainId || networkId]?.BOND_STAKING_HELPER_ADDRESS
  const WRAPPED_MAIN_ADDRESS = useSelector(
    ({ network }) => network.WRAPPED_MAIN_ADDRESS
  )
  const VCASH_ADDRESS = useSelector(({ network }) => network?.VCASH)?.address
  const NETWORK_URL = useSelector(({ network }) => network.NETWORK_URL)
  const WSS_URL = useSelector(({ network }) => network.WSS_URL)
  const bondList = BOND_LIST[account ? chainId : networkId] || []
  const existingBondDataList =
    useSelector(({ bond }) => bond?.bondDiscounts)?.[
      account ? chainId : networkId
    ] || []
  const bondPurchase =
    useSelector(({ bond }) => bond?.bondPurchase)?.[networkId || chainId] || {}

  const [poolsContract, setPoolsContract] = useState(null)
  const [swapContract, setSwapContract] = useState(null)
  const [swapRouterContract, setSwapRouterContract] = useState(null)
  const [stakeContract, setStakeContract] = useState(null)
  const [futureContract, setFutureContract] = useState(null)
  const [vaultContract, setVaultContract] = useState(null)
  const [svUNITContract, setSvUNITContract] = useState(null)
  const [bondCalcContract, setBondCalcContract] = useState(null)
  const [bondTreasuryContract, setBondTreasuryContract] = useState(null)
  const [bondStakeContract, setBondStakeContract] = useState(null)
  const [bondStakeHelperContract, setBondStakeHelperContract] = useState(null)
  const [infuraContract, setInfuraContract] = useState(null)
  const [infuraPoolContract, setInfuraPoolContract] = useState(null)
  const [bondContracts, setBondContracts] = useState({})
  const [bondReserveContracts, setBondReserveContracts] = useState({})
  const [vCASHToken, setVCASHToken] = useState(null)
  const [WRAPPEDContract, setWRAPPEDContract] = useState(null)
  const [currentBlock, setCurrentBlock] = useState(null)
  const [isHackedUser, setIsHackedUser] = useState(false)
  const [userHackedData, setUserHackedData] = useState([])

  const dispatch = useDispatch()
  const wallet = useSelector(({ user }) => user.wallet)
  const privateKey = useSelector(({ user }) => user.privateKey)
  const provider = useSelector(({ provider }) => provider.provider)
  const vcashPrice =
    useSelector(({ application }) => application?.vcashPrice)?.[networkId] || 1

  const trigger_date = getWithExpiry('trigger_date')

  const fetchVCASHPrice = useCallback(
    (networkId) => {
      try {
        getVCASHPrice(networkId || chainId).then((res) => {
          if (res?.result && res?.response) {
            const updatedPrice = res?.response?.price
            updatedPrice !== vcashPrice &&
              dispatch(
                setVcashPrice({
                  chainId: networkId || chainId,
                  vcashPrice: updatedPrice,
                })
              )
            fetchBondDataList()
          }
        })
      } catch (err) {
        if (process.env.REACT_APP_DEV_ENV === 'development') {
          console.log('error while fetching vUNIT data from backend')
        }
      }
    },
    [networkId]
  )

  useEffect(() => {
    if (!trigger_date || TRIGGER_DATE !== trigger_date) {
      dispatch(clearUserInfo()) // remove redux_localstorage_simple_user
      dispatch(saveWallet(wallet))
      dispatch(clearPoolList()) // remove redux_localstorage_simple_pools
      dispatch(clearNetwork()) // initiallize redux_localstorage_simple_network
      dispatch(clearBondDataList()) // initiallize redux_localstorage_simple_network
      supportedChainIds.forEach((chainId) =>
        dispatch(clearTransactions({ chainId }))
      ) // clear trasnactions cache
      setWithExpiry('trigger_date', TRIGGER_DATE, 0)
    }
  }, [trigger_date])

  useInactiveListener()

  useEffect(() => {
    if (!privateKey && !wallet) {
      const new_provider = !!WSS_URL
        ? new Web3.providers.WebsocketProvider(WSS_URL, {
            reconnect: {
              auto: true,
              delay: 3000, // ms
              maxAttempts: 10,
              onTimeout: false,
            },
          })
        : new Web3.providers.HttpProvider(NETWORK_URL)
      dispatch(savePrivateKey({ chainId: chainId, privateKey: undefined }))
      dispatch(saveProvider(new_provider))
    } else if (privateKey) {
      const new_provider = new PrivateKeyProvider(privateKey, NETWORK_URL)
      dispatch(saveProvider(new_provider))
    }
    if (!bondPurchase?.token1) {
      dispatch(
        setBondPurchase({
          chainId: networkId || chainId,
          bondPurchase: bondList?.[0] || {},
        })
      )
    }
  }, [dispatch, networkId || chainId])

  useEffect(() => {
    !wallet && provider && loadInfuraAccount()
  }, [provider, wallet])

  useEffect(() => {
    if (!infuraContract && !wallet) {
      loadInfuraAccount()
    }
  }, [infuraContract])

  useEffect(() => {
    window.addEventListener('load', connectToAccount)

    return () => {
      window.removeEventListener('load', connectToAccount)
    }
  }, [wallet])

  useEffect(() => {
    if (status) {
      loadAccount()
    }
  }, [status, provider, chainId, networkId])

  useEffect(() => {
    if (networkId) {
      fetchVCASHPrice(networkId)
    }
    const interval = setInterval(() => {
      if (networkId) {
        fetchVCASHPrice(networkId)
      }
    }, 1000 * 60 * 10)
    return () => clearInterval(interval)
  }, [networkId])

  const hanldeCheckIfHackedUser = async (account, chainId, query) => {
    try {
      checkIfHackedUser(account, chainId, query).then((res) => {
        if (res?.result && Array.isArray(res?.response)) {
          if (res?.response?.length > 0) {
            setUserHackedData(res?.response)
            checkIfUserConsented(account, chainId).then((res) => {
              if (res?.result && res?.response?.length === 0) setIsHackedUser(true)
              else setIsHackedUser(false)
            })
          } else setIsHackedUser(false)
        }
      })
    } catch (err) {
      if (process.env.REACT_APP_DEV_ENV === 'development') {
        console.log('error while fetching user hacked data')
      }
    }
  }

  useEffect(() => {
    if (!account || !chainId) {
      !!!isHackedUser && setIsHackedUser(false)
      return
    }
    const query = ETHERSCAN_CHAINS.includes(chainId) ? 'eth' : 'polygon'
    // hanldeCheckIfHackedUser(account, chainId, query)
  }, [account, chainId])

  const connectToAccount = () => {
    if (!status && wallet) {
      connect(wallet)
    }
  }

  const loadAccount = async () => {
    if (chainId !== networkId && networkId) return
    if (ethereum) {
      const poolsContract = getPoolContract(ethereum, POOL_ADDRESS)
      const swapContract = getSwapContract(ethereum, SWAP_ADDRESS)
      const oldSwapContract = getOldSwapContract(ethereum, SWAP_ADDRESS)
      const swapRouterContract = getSwapRouterContract(ethereum, SWAP_ROUTER_ADDRESS)
      const stakeContract = getStakeContract(ethereum, STAKING_ADDRESS)
      const vCASHToken = getVCASHToken(ethereum, VCASH_ADDRESS)
      setPoolsContract(poolsContract)
      setStakeContract(stakeContract)
      setSwapContract(swapContract)
      setSwapRouterContract(swapRouterContract)
      setVCASHToken(vCASHToken)
      const WrappedContract = getWRAPPEDContract(ethereum, WRAPPED_MAIN_ADDRESS)
      setWRAPPEDContract(WrappedContract)
      if (FUTURE_ALLOW_CHAINS.includes(chainId)) {
        // future contract address only provided on kovan now
        const futureContract = getFutureContract(ethereum, FUTURE_ADDRESS)
        setFutureContract(futureContract)
      }
      if (VAULT_ALLOW_CHAINS.includes(chainId)) {
        // vault contract address only provided on kovan now
        const vaultContract = getVaultContract(ethereum, STAKED_MONO_ADDRESS)
        setVaultContract(vaultContract)
      }
      if (BOND_STAKE_ALLOW_CHAINS.includes(chainId) && bondList?.length > 0) {
        const bondCalcContract = getBondCalculator(ethereum, BOND_CALCULATOR_ADDRESS)
        setBondCalcContract(bondCalcContract)
        const bondTreasuryContract = getBondTreasury(ethereum, BOND_TREASURY_ADDRESS)
        setBondTreasuryContract(bondTreasuryContract)
        const bondStakeContract = getBondStakeContract(
          ethereum,
          BOND_STAKING_ADDRESS
        )
        setBondStakeContract(bondStakeContract)
        const bondStakeHelperContract = getBondStakeHelperContract(
          ethereum,
          BOND_STAKING_HELPER_ADDRESS
        )
        setBondStakeHelperContract(bondStakeHelperContract)
        const svUNITContract = getStakedvUNITContract(ethereum, STAKED_VUNIT_ADDRESS)
        setSvUNITContract(svUNITContract)
        let bondData = {}
        let bondReserveData = {}
        for (const data of bondList) {
          bondData[data.bondAddress] = getBondContract(
            ethereum,
            data.bondAddress,
            data?.bondABI
          )
          bondReserveData[data.bondAddress] = getReserveContract(
            ethereum,
            data.reserveAddress
          )
        }
        setBondContracts(bondData)
        setBondReserveContracts(bondReserveData)
      }
      const currentBlock = await getCurrentBlock(ethereum)
      setCurrentBlock(currentBlock)
    }
  }

  const loadInfuraAccount = async () => {
    if (provider) {
      const swapContract = getSwapContract(provider, SWAP_ADDRESS)
      const swapRouterContract = getSwapRouterContract(provider, SWAP_ROUTER_ADDRESS)
      const poolContract = getPoolContract(provider, POOL_ADDRESS)
      setInfuraContract(swapContract)
      !ethereum && setSwapContract(swapContract)
      !ethereum && setSwapRouterContract(swapRouterContract)
      setInfuraPoolContract(poolContract)
      if (poolContract) {
        setPoolsContract(poolContract)
      }
      const WrappedContract = getWRAPPEDContract(provider, WRAPPED_MAIN_ADDRESS)
      setWRAPPEDContract(WrappedContract)
      if (FUTURE_ALLOW_CHAINS.includes(networkId)) {
        // future contract address only provided on kovan now
        const futureContract = getFutureContract(provider, FUTURE_ADDRESS)
        setFutureContract(futureContract)
      }
      if (VAULT_ALLOW_CHAINS.includes(networkId)) {
        // vault contract address only provided on kovan now
        const vaultContract = getVaultContract(provider, STAKED_MONO_ADDRESS)
        setVaultContract(vaultContract)
      }
      if (BOND_STAKE_ALLOW_CHAINS.includes(networkId) && bondList?.length > 0) {
        const bondCalcContract = getBondCalculator(provider, BOND_CALCULATOR_ADDRESS)
        setBondCalcContract(bondCalcContract)
        const bondTreasuryContract = getBondTreasury(provider, BOND_TREASURY_ADDRESS)
        setBondTreasuryContract(bondTreasuryContract)
        const bondStakeContract = getBondStakeContract(
          provider,
          BOND_STAKING_ADDRESS
        )
        setBondStakeContract(bondStakeContract)
        const bondStakeHelperContract = getBondStakeHelperContract(
          provider,
          BOND_STAKING_HELPER_ADDRESS
        )
        setBondStakeHelperContract(bondStakeHelperContract)
        const svUNITContract = getStakedvUNITContract(provider, STAKED_VUNIT_ADDRESS)
        setSvUNITContract(svUNITContract)
        let bondData = {}
        let bondReserveData = {}
        for (const data of bondList) {
          bondData[data.bondAddress] = getBondContract(
            provider,
            data?.bondAddress,
            data?.bondABI
          )
          bondReserveData[data.bondAddress] = getReserveContract(
            provider,
            data.reserveAddress
          )
        }
        setBondContracts(bondData)
        setBondReserveContracts(bondReserveData)
      }
      const currentBlock = await getCurrentBlock(provider)
      setCurrentBlock(currentBlock)
    }
  }

  const getAllowance = async (ERC20TokenAddress, isSwap = true, isVault = false) => {
    if (!ethereum || !ERC20TokenAddress) return
    if (!STAKED_MONO_ADDRESS && isVault) return 0
    const ERC20Token = getERC20Token(ethereum, ERC20TokenAddress)
    const allowance = await ERC20Token?.methods
      ?.allowance(
        account,
        isSwap ? SWAP_ADDRESS : isVault ? STAKED_MONO_ADDRESS : FUTURE_ADDRESS
      )
      .call()
    return allowance
  }

  const getAllowanceByContractAddress = async (
    ERC20TokenAddress,
    contractAddress = BOND_STAKING_HELPER_ADDRESS,
    currencyChainId = null
  ) => {
    if (!ethereum || !ERC20TokenAddress) return
    if (
      ethereum?.chainId &&
      currencyChainId &&
      currencyChainId !== parseInt(ethereum?.chainId)
    )
      return
    const ERC20Token = getERC20Token(ethereum, ERC20TokenAddress)
    const allowance = await ERC20Token?.methods
      ?.allowance(account, contractAddress)
      .call()
    return allowance
  }

  const getToken = useCallback(
    async (ERC20TokenAddress, isVault = false) => {
      if (ethereum) {
        return isVault
          ? getVaultContract(ethereum, ERC20TokenAddress)
          : getERC20Token(ethereum, ERC20TokenAddress)
      } else if (!wallet) {
        const provider = !!WSS_URL
          ? new Web3.providers.WebsocketProvider(WSS_URL, {
              reconnect: {
                auto: true,
                delay: 3000, // ms
                maxAttempts: 10,
                onTimeout: false,
              },
            })
          : new Web3.providers.HttpProvider(NETWORK_URL)
        return getERC20Token(provider, ERC20TokenAddress)
      }
    },
    [ethereum, WSS_URL, wallet]
  )

  const fetchBondDataList = useCallback(async () => {
    let totalTreasuryBalance = 0
    if (Object.keys(bondContracts)?.length === 0) {
      return
    }
    if (Object.keys(bondContracts)?.length !== bondList.length) {
      return
    }
    if (networkId !== bondList?.[0]?.token1?.chainId) {
      return
    }
    const bondListData = await Promise.all(
      bondList?.map(async (item) => {
        try {
          const {
            token1: currency,
            token2: currency2,
            isWethBond,
            isLP,
            isvUnitBond,
            reserveAddress,
          } = item
          const LPTokenContract = isLP
            ? getLPToken(account ? ethereum : provider, reserveAddress)
            : null
          const [bondPrice, totalValue, token0, reserves, vUnitBondReceiveBig] =
            await Promise.all([
              !isLP
                ? bondContracts[item?.bondAddress]?.methods?.bondPriceInUSD().call()
                : bondContracts[item?.bondAddress]?.methods?.bondPrice().call(),
              isLP
                ? bondCalcContract?.methods?.getTotalValue(reserveAddress).call()
                : 1,
              isLP ? LPTokenContract?.methods?.token0().call() : 1,
              isLP ? LPTokenContract?.methods?.getReserves().call() : {},
              isvUnitBond
                ? bondContracts[item?.bondAddress]?.methods
                    ?.payoutFor(new BigNumber(10 ** 18).toFixed(0))
                    .call()
                : 1,
            ])
          const { _reserve0, _reserve1 } = reserves
          const stableReserve = token0 === VCASH_ADDRESS ? _reserve1 : _reserve0
          const bondPriceInUSD = isLP
            ? (2 *
                weiToEthNum(BigNumber(stableReserve), currency2?.decimals) *
                Number(bondPrice)) /
              100 /
              weiToEthNum(BigNumber(totalValue))
            : weiToEthNum(BigNumber(bondPrice), currency?.decimals)
          const bondDiscount = isvUnitBond
            ? weiToEthNum(new BigNumber(vUnitBondReceiveBig)) - 1
            : (vcashPrice - bondPriceInUSD) / bondPriceInUSD
          let ethPrice = isWethBond
            ? await bondContracts[item?.bondAddress]?.methods?.assetPrice().call()
            : 1
          ethPrice = Number(ethPrice?.toString()) / Math.pow(10, 8)
          const treasuryBalance = await bondReserveContracts[
            item?.bondAddress
          ]?.methods
            .balanceOf(BOND_TREASURY_ADDRESS)
            .call()
          totalTreasuryBalance +=
            (isWethBond ? ethPrice : 1) *
            weiToEthNum(BigNumber(treasuryBalance), currency?.decimals)
          const result = existingBondDataList.find(
            ({ bondAddress }) => bondAddress === item?.bondAddress
          )
          return {
            ...item,
            bondPrice:
              (isvUnitBond ? bondPriceInUSD * vcashPrice : bondPriceInUSD) ||
              result?.bondPrice,
            bondDiscount: bondDiscount ?? result?.bondDiscount,
            purchased:
              (isWethBond ? ethPrice : 1) *
              weiToEthNum(BigNumber(treasuryBalance), currency?.decimals),
          }
        } catch (err) {
          if (process.env.REACT_APP_DEV_ENV === 'development') {
            console.log(`${item?.bondRoute} bond data error`, err)
          }
          const result = existingBondDataList.find(
            ({ bondAddress }) => bondAddress === item?.bondAddress
          )
          const mockBond = {
            ...item,
            bondPrice: 0,
            bondDiscount: 0,
            purchased: 0,
          }
          return result ?? mockBond
        }
      })
    )
    dispatch(
      setBondDiscounts({
        chainId: networkId || chainId,
        bondDiscounts: bondListData,
      })
    )
    dispatch(
      setBondTotalTreasury({
        chainId: networkId || chainId,
        bondTotalTreasury: totalTreasuryBalance,
      })
    )
  }, [bondList, bondContracts, bondTreasuryContract, vcashPrice, provider])

  return (
    <AccountContext.Provider
      value={{
        poolsContract,
        swapContract,
        swapRouterContract,
        stakeContract,
        futureContract,
        infuraContract,
        infuraPoolContract,
        vaultContract,
        svUNITContract,
        bondCalcContract,
        bondTreasuryContract,
        bondStakeContract,
        bondStakeHelperContract,
        vCASHToken,
        WRAPPEDContract,
        bondContracts,
        bondReserveContracts,
        currentBlock,
        isHackedUser,
        userHackedData,
        loadAccount,
        fetchBondDataList,
        getAllowance,
        getAllowanceByContractAddress,
        fetchVCASHPrice,
        getToken,
        setIsHackedUser,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export default AccountProvider
