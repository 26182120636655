import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { FormattedMessage, useIntl } from 'react-intl'
import { Check2Circle } from '@styled-icons/bootstrap/Check2Circle'
import { Cross } from '@styled-icons/entypo/Cross'

import {
  getEtherscanLink,
  precise,
  TRANSACTION_STATUS,
  getNetworkScanName,
  notationRemoved,
} from 'monox/util'
import { DISPLAY_MIN_VALUE, SOCIALLINKS } from 'monox/constants'
import useModal from 'hooks/useModal'

import Label from 'components/Label'
import { RowBetween } from 'components/Row'
import NetworkListModal from 'components/NetworkListModal'

const ToastPopup = ({
  fromToken,
  toToken,
  fromAmount,
  toAmount,
  link,
  chainId,
  type = 'swap',
  isValut = false,
}) => {
  const intl = useIntl()
  const ruCustomToastScript = () => {
    let value = fromAmount % 1
    value = value < 0.5 ? Math.floor(fromAmount) : Math.ceil(fromAmount)

    if (intl.locale === 'ru' || intl.locale === 'uk') {
      if (value === 11 || value === 12 || value === 13) {
        return 'токена'
      } else if (value <= 20 && value >= 5) {
        return intl.locale === 'ru' ? 'токенов' : 'токенів'
      } else if (value % 10 === 1) {
        return 'токен'
      } else if (value % 10 === 2 || value % 10 === 3) {
        return 'токена'
      } else {
        return 'токен'
      }
    }
    return ''
  }

  const amountToShow =
    precise(fromAmount, 6) >= DISPLAY_MIN_VALUE
      ? precise(fromAmount, 6)
      : notationRemoved(fromAmount)

  return (
    <PopUp>
      {type === 'swap' && (
        <InfoLabel>
          <FormattedMessage
            id="toast.popup.swap"
            defaultMessage={`Swap ${precise(
              fromAmount,
              6
            )} ${fromToken} for ${precise(toAmount, 6)} ${toToken}`}
            values={{
              fromAmount: precise(fromAmount, 6),
              fromToken: fromToken,
              toAmount: precise(toAmount, 6),
              toToken: toToken,
            }}
          />
        </InfoLabel>
      )}
      {type === 'wrap' && (
        <InfoLabel>
          <FormattedMessage
            id="toast.popup.wrap"
            defaultMessage={`Wrap ${precise(
              fromAmount,
              6
            )} ${fromToken} to ${precise(toAmount, 6)} ${toToken}`}
            values={{
              fromAmount: precise(fromAmount, 6),
              fromToken: fromToken,
              toAmount: precise(toAmount, 6),
              toToken: toToken,
            }}
          />
        </InfoLabel>
      )}
      {type === 'unwrap' && (
        <InfoLabel>
          <FormattedMessage
            id="toast.popup.unwrap"
            defaultMessage={`Unwrap ${precise(
              fromAmount,
              6
            )} ${fromToken} to ${precise(toAmount, 6)} ${toToken}`}
            values={{
              fromAmount: precise(fromAmount, 6),
              fromToken: fromToken,
              toAmount: precise(toAmount, 6),
              toToken: toToken,
            }}
          />
        </InfoLabel>
      )}
      {type === 'add' && (
        <InfoLabel>
          <FormattedMessage
            id="toast.popup.add"
            defaultMessage={`Add ${precise(
              fromAmount,
              6
            )} ${fromToken} to ${fromToken} pool`}
            values={{
              fromAmount: precise(fromAmount, 6),
              fromToken: fromToken,
            }}
          />
        </InfoLabel>
      )}
      {type === 'remove' && (
        <InfoLabel>
          <FormattedMessage
            id="toast.popup.remove"
            defaultMessage={`Remove ${precise(
              fromAmount,
              6
            )} ${fromToken} LP tokens from ${fromToken} pool`}
            values={{
              fromAmount: precise(fromAmount, 6),
              fromToken: fromToken,
              tokens: ruCustomToastScript(),
            }}
          />
        </InfoLabel>
      )}
      {type === 'create' && (
        <InfoLabel>
          <FormattedMessage
            id="toast.popup.create"
            defaultMessage={` ${fromToken} pool created with  ${precise(
              fromAmount,
              6
            )} ${fromToken}`}
            values={{
              fromAmount: precise(fromAmount, 6),
              fromToken: fromToken,
              tokens: ruCustomToastScript(),
            }}
          />
        </InfoLabel>
      )}
      {type === 'redeem' && (
        <InfoLabel>
          <FormattedMessage
            id="toast.popup.redeem"
            defaultMessage={`Redeem ${precise(fromAmount, 6)} ${fromToken}`}
            values={{
              amount: precise(fromAmount, 6),
              futureToken: fromToken,
            }}
          />
        </InfoLabel>
      )}
      {type === 'stake' && (
        <InfoLabel>
          <FormattedMessage
            id="toast.popup.stake"
            defaultMessage={`Stake ${amountToShow} ${fromToken} ${
              isValut ? '' : 'LP'
            }`}
            values={{
              fromAmount: amountToShow,
              fromToken: fromToken,
            }}
          />
        </InfoLabel>
      )}
      {type === 'bond' && (
        <InfoLabel>
          <FormattedMessage
            id="toast.popup.bond"
            defaultMessage={`Bond ${amountToShow} ${fromToken} ${
              isValut ? '' : 'LP'
            }`}
            values={{
              fromAmount: amountToShow,
              fromToken: fromToken,
            }}
          />
        </InfoLabel>
      )}
      {type === 'harvest' && (
        <InfoLabel>
          <FormattedMessage
            id="toast.popup.harvest"
            defaultMessage={`Harvest ${precise(fromAmount, 6)} ${fromToken} LP`}
            values={{
              fromAmount: precise(fromAmount, 6),
              fromToken: fromToken,
            }}
          />
        </InfoLabel>
      )}
      {type === 'unstake' && (
        <InfoLabel>
          <FormattedMessage
            id="toast.popup.unstake"
            defaultMessage={`Unstake ${amountToShow} ${fromToken} LP`}
            values={{
              fromAmount: amountToShow,
              fromToken: fromToken,
            }}
          />
        </InfoLabel>
      )}
      <Link href={getEtherscanLink(chainId, link, 'transaction')} target="__blank">
        <FormattedMessage
          id={`toast.approve.stake.${getNetworkScanName(chainId)?.toLowerCase()}`}
          defaultMessage={`View on ${getNetworkScanName(chainId)}`}
        />
      </Link>
    </PopUp>
  )
}

export const ApproveStakeToast = ({ poolData, link, chainId }) => {
  return (
    <>
      <Check2Circle color="#fff" size="24" style={{ marginRight: 10 }} />
      <Div>
        <InfoLabel>
          <FormattedMessage
            id="toast.approve.stake.label"
            defaultMessage={`Approve ${poolData?.symbol} LP`}
            values={{ symbol: poolData?.symbol }}
          />
        </InfoLabel>
        <Link href={getEtherscanLink(chainId, link, 'transaction')} target="__blank">
          <FormattedMessage
            id={`toast.approve.stake.${getNetworkScanName(chainId)?.toLowerCase()}`}
            defaultMessage={`View on ${getNetworkScanName(chainId)}`}
          />
        </Link>
      </Div>
    </>
  )
}

export const TransactionStartToast = () => {
  const [timePassed, setTimePassed] = useState(1)

  useEffect(() => {
    let id = setInterval(() => {
      setTimePassed((prevState) => prevState + 1)
    }, 1000)
    return () => {
      clearInterval(id)
    }
  }, [])

  return (
    <>
      <Div>
        <InfoLabel>
          <FormattedMessage
            id="toast.transaction.started"
            defaultMessage="Your transaction has started"
          />
        </InfoLabel>
        <InfoLabel style={{ marginTop: 8 }}>
          {dayjs().format('hh:mm A')} - {timePassed}{' '}
          <FormattedMessage
            defaultMessage="sec"
            id="toast.transaction.started.time.per"
          />
        </InfoLabel>
      </Div>
    </>
  )
}

export const TransactionFailedToast = (status) => {
  return (
    <Div>
      <InfoLabel>
        <FormattedMessage
          id={`toast.transaction.${
            status === TRANSACTION_STATUS.REJECTED ? 'rejected' : 'failed'
          }`}
          defaultMessage={`Your transaction ${
            status === TRANSACTION_STATUS.REJECTED ? 'rejected' : 'failed'
          }`}
        />
      </InfoLabel>
    </Div>
  )
}

export const WrongNetworkToast = ({ closeToast }) => {
  const [handleNetworkClick] = useModal(<NetworkListModal />)

  return (
    <Toast>
      <Label size="13" weight="800" color="#fff">
        <RowBetween style={{ position: 'relative', cursor: 'auto' }}>
          <span style={{ marginRight: '20px', lineHeight: 1.17 }}>
            <FormattedMessage
              id="common.wrong.network.toast"
              defaultMessage="Wrong Network"
            />
          </span>
          <Box>
            <Close color="#fff" size="24" onClick={closeToast} data-testid="close" />
          </Box>
        </RowBetween>
      </Label>
      <div style={{ lineHeight: 1.17, marginRight: '20px', marginTop: '5px' }}>
        <Span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={handleNetworkClick}
        >
          <FormattedMessage
            defaultMessage="Change your app network"
            id="common.wrong.network.toast.description.0"
          />
        </Span>
        &nbsp;
        <Span>
          <FormattedMessage
            defaultMessage=" or "
            id="common.wrong.network.toast.description.1"
          />
        </Span>
        &nbsp;
        <Span>
          <a
            style={{ color: '#fff', cursor: 'pointer', whiteSpace: 'nowrap' }}
            target="_blank"
            href={SOCIALLINKS?.changeNetwork}
            rel="noopener noreferrer"
          >
            <FormattedMessage
              defaultMessage="read the docs"
              id="common.wrong.network.toast.description.2"
            />
          </a>
        </Span>
      </div>
    </Toast>
  )
}

export const WrongRPCToast = ({ closeToast }) => {
  return (
    <Toast>
      <Label size="13" weight="800" color="#fff">
        <RowBetween style={{ position: 'relative', cursor: 'auto' }}>
          <span style={{ marginRight: '20px', lineHeight: 1.17 }}>
            <FormattedMessage
              id="common.wrong.rpc.toast"
              defaultMessage="Wrong RPC"
            />
          </span>
          <Box>
            <Close color="#fff" size="24" onClick={closeToast} data-testid="close" />
          </Box>
        </RowBetween>
      </Label>
      <div style={{ lineHeight: 1.17, marginRight: '20px', marginTop: '5px' }}>
        <Span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          <FormattedMessage
            defaultMessage="Something went wrong with current RPC."
            id="common.wrong.rpc.toast.description.0"
          />
        </Span>
        &nbsp;
        <Span>
          <FormattedMessage
            defaultMessage="Please "
            id="common.wrong.rpc.toast.description.1"
          />
        </Span>
        &nbsp;
        <Span>
          <a
            style={{ color: '#fff', cursor: 'pointer', whiteSpace: 'nowrap' }}
            target="_blank"
            href={SOCIALLINKS?.rpcSetting}
            rel="noopener noreferrer"
          >
            <FormattedMessage
              weight="500"
              defaultMessage="try with a different RPC url"
              id="common.wrong.rpc.toast.description.2"
            />
          </a>
        </Span>
      </div>
    </Toast>
  )
}

export const NotEnoughGasToast = ({ closeToast, mainCurrency }) => {
  return (
    <Toast>
      <Label size="13" weight="800" color="#fff">
        <RowBetween style={{ position: 'relative', cursor: 'auto' }}>
          <span style={{ marginRight: '20px', lineHeight: 1.17 }}>
            <FormattedMessage
              id="common.main_currency.balance.warning"
              values={mainCurrency}
              defaultMessage={`Not enough ${mainCurrency} to account for gas prices, Please add more to your wallet`}
            />
          </span>
          <Box>
            <Close color="#fff" size="24" onClick={closeToast} data-testid="close" />
          </Box>
        </RowBetween>
      </Label>
    </Toast>
  )
}

const Toast = styled.div`
  width: 245px;
  height: 65px;
  margin: -8px;
  cursor: auto;
  padding: 14px 20px;
  object-fit: contain;
  border-radius: 4px;
  background-image: linear-gradient(108deg, #ef3d62 3%, #ec4141);
`
const Box = styled.div`
  width: 30px;
  height: 20px;
  position: absolute;
  right: -5px;
  top: 3px;
`
const Close = styled(Cross)`
  color: #fff;
  margin-left: 8px;
  cursor: pointer;
  margin-top: -7px;
  height: 20px;
  width: 20px;
`
const PopUp = styled.div`
  margin: 15px 20px;
`

const Div = styled.div`
  display: flex;
  flex-direction: column;
`

const InfoLabel = styled.div`
  font-size: 16px;
  color: #fff;
  font-weight: 800;
  font-size: 13px;
`

const Link = styled.a`
  box-sizing: border-box;
  font-weight: 800;
  font-size: 14px;
  color: #fff;
  padding: 10px 0;
  &:hover {
    cursor: pointer;
  }
`
const Span = styled.span`
  color: #ffffff;
  opacity: 0.5;
  font-size: 12px;
  font-weight: 800;
`

export default ToastPopup
