import {
  SAVE_POOL_LIST,
  SET_POOL_LOADING,
  CLEAR_POOL_LIST,
} from 'state/pools/constants'

export function savePoolList(payload) {
  return { type: SAVE_POOL_LIST, payload }
}

export function setPoolsLoading(payload) {
  return { type: SET_POOL_LOADING, payload }
}

export function clearPoolList() {
  return { type: CLEAR_POOL_LIST }
}
