import {
  SET_LOCALE,
  SET_MODE,
  SET_VCASH_PRICE,
  UPDATE_APPLICATION,
} from 'state/applications/constants'
import { MAIN_LANGUAGE } from 'monox/config'
import { supportedChainIds } from 'monox/constants'
import { arrayToObject } from 'monox/util'

const vcashPriceObject = arrayToObject(supportedChainIds, null, 1) // can change default vunit Price here by changing third param
const initialState = {
  isUpdated: false,
  isDark: false,
  locale: MAIN_LANGUAGE,
  vcashPrice: vcashPriceObject,
}

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_APPLICATION:
      return {
        ...state,
        isUpdated: !state.isUpdated,
      }
    case SET_MODE:
      return {
        ...state,
        isDark: !state.isDark,
      }
    case SET_LOCALE:
      return {
        ...state,
        locale: action.payload,
      }
    case SET_VCASH_PRICE:
      return {
        ...state,
        vcashPrice: {
          ...state.vcashPrice,
          [action.payload.chainId]: action?.payload?.vcashPrice,
        },
      }
    default:
      return {
        isUpdated: state?.isUpdated || initialState?.isUpdated,
        isDark: state?.isDark || initialState?.isDark,
        vcashPrice: { ...initialState?.vcashPrice, ...state?.vcashPrice },
        locale: state?.locale || initialState?.locale,
      }
  }
}

export default applicationReducer
