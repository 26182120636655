import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import useWallet from 'hooks/useWallet'
import useAddTokenToMetamask from 'hooks/useAddTokenToMetamask'
import { getEtherscanLink, getNetworkScanName } from 'monox/util'

import { Row, RowCenter } from 'components/Row'
import { CloseIcon } from 'components/IconButton'
import Label from 'components/Label'
import StyledIconButton from 'components/StyledIconButton'
import Modal from 'components/Modal'
import Spacer from 'components/Spacer'

import txSubmit from 'assets/img/tx-submit.svg'

const TransactionSuccessModal = ({ onDismiss, payload, redirectUrl, token }) => {
  const { chainId } = useWallet()
  const networkName = useSelector(({ network }) => network?.NAME)?.toLowerCase()
  const isDark = useSelector(({ application }) => application.isDark)
  const history = useHistory()
  const { addToken } = useAddTokenToMetamask(token)

  const handleDismiss = () => {
    if (redirectUrl) {
      history.push(`${redirectUrl}?network=${networkName}`)
    }
    onDismiss()
  }

  return (
    <Modal maxWidth>
      <Row>
        <CloseIcon
          onClick={handleDismiss}
          style={{ marginLeft: 'auto' }}
          data-testid="dismiss"
        />
      </Row>
      <RowCenter>
        <Image src={txSubmit} />
      </RowCenter>
      <RowCenter>
        <Label
          weight={800}
          size={18}
          text="Transaction Submitted"
          translateId="modal.common.transaction.submit.title"
        />
      </RowCenter>
      <Spacer />
      <Link
        href={getEtherscanLink(chainId, payload, 'transaction')}
        target="__blank"
        isDark={isDark}
      >
        <FormattedMessage
          id={`modal.common.transaction.submit.link.${getNetworkScanName(
            chainId
          )?.toLowerCase()}`}
          defaultMessage={`View on ${getNetworkScanName(chainId)}`}
        />
      </Link>
      <Spacer />
      {token?.address && token?.symbol && (
        <Row>
          <Link
            onClick={addToken}
            target="__blank"
            isDark={isDark}
            data-testid="add-token"
          >
            <FormattedMessage
              id="modal.common.transaction.submit.link.metamask.add"
              defaultMessage={`Add ${token?.symbol} to Metamask`}
              values={{ symbol: token?.symbol }}
            />
          </Link>
        </Row>
      )}
      <Spacer size="sm" />
      <Spacer />
      <StyledIconButton
        onClick={handleDismiss}
        variant="primary"
        icon="arrow"
        block
        modal={true}
        translateId="modal.common.transaction.submit.button"
        testId="close"
      >
        Close
      </StyledIconButton>
    </Modal>
  )
}

export default TransactionSuccessModal

const Image = styled.img`
  width: 142px;
  height: 172px;
  object-fit: contain;
  margin: 0 26px 10px;
`

const Link = styled.a`
  background: ${({ theme, isDark }) => isDark && theme.color.background.main};
  box-sizing: border-box;
  font-weight: 800;
  font-size: 14px;
  color: ${({ theme }) => theme.color.secondary.main};
  border-radius: ${(props) => (props.isDark ? '6px' : '27px')};
  box-shadow: ${({ theme }) => theme.shadows.button};
  margin: auto;
  padding: 10px 22px;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`
