import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import config from 'monox/config'
import { FREE_INFURA_URL, supportedChainIds, NetworkId } from 'monox/constants'

export const injected = new InjectedConnector({
  supportedChainIds,
})

const rpcConfigData = () =>
  supportedChainIds.reduce((data, item) => {
    data[item] = config?.[item]?.NETWORK_URL
    return data
  }, {})

export const walletconnect = new WalletConnectConnector({
  supportedChainIds,
  rpc: {
    ...rpcConfigData(),
    [NetworkId.TESTNET_KOVAN]: FREE_INFURA_URL[NetworkId.TESTNET_KOVAN],
    [NetworkId.TESTNET_GOERLI]: FREE_INFURA_URL[NetworkId.TESTNET_GOERLI],
    [NetworkId.POLYGON_TESTNET]: FREE_INFURA_URL[NetworkId.POLYGON_TESTNET],
  },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 12000,
})

// mainnet only
export const walletlink = new WalletLinkConnector({})

export const connectorsByName = {
  injected: injected,
  walletconnect: walletconnect,
  walletlink: walletlink,
}
