import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { AccountContext } from 'contexts/AccountProvider'
import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import SimpleBar from 'simplebar-react'

import 'simplebar/dist/simplebar.min.css'

import {
  EXPLORER_FILTER,
  EXPLORER_FILTER_ETH,
  weiToEth,
  weiToEthNum,
  SOCIALLINKS,
  SWAP_ALLOW_CHAINS,
  STAKING_ALLOW_CHAINS,
  isProdEnv,
} from 'monox/constants'
import { precise } from 'monox/util'
import useWindowSize from 'hooks/useWindowSize'
import useTokenBalance from 'hooks/useTokenBalance'
import useWallet from 'hooks/useWallet'
import config from 'monox/config'

import SidebarItem from 'components/Sidebar/SidebarItem'
import Spacer from 'components/Spacer'
import Label from 'components/Label'
import { RowBetween, Row, RowCenter } from 'components/Row'

import home from 'assets/img/sidebar/home.svg'
import swap from 'assets/img/sidebar/swap.svg'
import pool from 'assets/img/sidebar/pool.svg'
import farm from 'assets/img/sidebar/farm.svg'
import future from 'assets/img/sidebar/future.svg'
import vault from 'assets/img/sidebar/vault.svg'
import bond from 'assets/img/sidebar/bond.svg'
import explore from 'assets/img/sidebar/explore.svg'
import activeHome from 'assets/img/sidebar/active-home.svg'
import activeSwap from 'assets/img/sidebar/active-swap.svg'
import activePool from 'assets/img/sidebar/active-pool.svg'
import activeFarm from 'assets/img/sidebar/active-farm.svg'
import activeFuture from 'assets/img/sidebar/active-future.svg'
import activeVault from 'assets/img/sidebar/active-vault.svg'
import activeBond from 'assets/img/sidebar/active-bond.svg'
import activeExplore from 'assets/img/sidebar/active-explore.svg'
import TwitterIcon from 'assets/img/sidebar/footer/twitter.svg'
import TwitterDarkIcon from 'assets/img/sidebar/footer/twitter-dark.svg'
import TelegramIcon from 'assets/img/sidebar/footer/telegram.svg'
import TelegramDarkIcon from 'assets/img/sidebar/footer/telegram-dark.svg'
import MediumIcon from 'assets/img/sidebar/footer/medium.svg'
import MediumDarkIcon from 'assets/img/sidebar/footer/medium-dark.svg'
import DiscordIcon from 'assets/img/sidebar/footer/discord.svg'
import DiscordDarkIcon from 'assets/img/sidebar/footer/discord-dark.svg'
import { images, links } from 'theme/config'
import { setBondPurchase } from 'state/bond/actions'

const Sidebar = ({ isSidebar, showSidebar, setShowSidebar }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const location = useLocation()
  const isDark = useSelector(({ application }) => application.isDark)
  const networkId = useSelector(({ network }) => network.id)
  const bondList = useSelector(({ bond }) => bond?.bondDiscounts)?.[networkId] || []
  const networkName = useSelector(({ network }) => network?.NAME)?.toLowerCase()
  const [isExploreOpen, setIsExploreOpen] = useState(false)

  const { chainId } = useWallet()
  const currency = config?.[chainId]?.VCASH
  const { balance } = useTokenBalance(currency)

  const handleBlur = () => {
    setShowSidebar(false)
  }

  const isSupported = useMemo(() => {
    return SWAP_ALLOW_CHAINS.includes(chainId || networkId)
  }, [chainId, networkId])

  const isFarmSupported = useMemo(() => {
    return STAKING_ALLOW_CHAINS.includes(chainId || networkId)
  }, [chainId, networkId])

  return (
    <OutsideClickHandler onOutsideClick={handleBlur}>
      <SidebarItems isDark={isDark} showSidebar={showSidebar} isSidebar={isSidebar}>
        <StyledSimpleBar isdark={isDark.toString()}>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>
              <Link href="/">
                <img width="66" src={images.logo3x} alt="logo" />
                <Label
                  text="MonoX Protocol"
                  size="22"
                  weight={800}
                  color={!isDark ? '#2eca93' : ''}
                  style={{ marginTop: '10px' }}
                />
              </Link>
              <SidebarItem
                text="Home"
                img={home}
                activeImg={activeHome}
                to="/home"
                width="17"
                translateId="common.menu.bar_item.1"
              />
              {isSupported && (
                <>
                  <SidebarItem
                    text="Swap"
                    img={swap}
                    activeImg={activeSwap}
                    to={`/swap?network=${networkName}`}
                    width="16"
                    translateId="common.menu.bar_item.2"
                  />
                  <SidebarItem
                    text="Pool"
                    img={pool}
                    activeImg={activePool}
                    to={`/pool?network=${networkName}`}
                    width="17"
                    translateId="common.menu.bar_item.3"
                  />
                </>
              )}
              {isFarmSupported && (
                <SidebarItem
                  text="Farm"
                  img={farm}
                  activeImg={activeFarm}
                  to={`/farm?network=${networkName}`}
                  width="18"
                  translateId="common.menu.bar_item.4"
                />
              )}
              <SidebarItem
                text="Bond"
                img={bond}
                activeImg={activeBond}
                to={`/bond?network=${networkName}`}
                dropdown={true}
                link={true}
                isOpen={true}
                translateId="common.menu.bar_item.8"
              >
                <HoveredRow
                  onClick={() => history.push(`/bond/stake?network=${networkName}`)}
                >
                  <div
                    style={{
                      width: '17px',
                      marginRight: '24px',
                    }}
                  ></div>
                  <StyledRowBetween
                    style={{ alignItems: 'center', cursor: 'pointer' }}
                  >
                    <Label
                      text="Stake vUNIT"
                      size="13"
                      weight="bold"
                      translateId="bonds.stake.title"
                      style={{ padding: '2px' }}
                    />
                    <Div>
                      <Label
                        text={precise(weiToEth(BigNumber(balance)), 3)}
                        size={width <= 1200 ? 12 : 13}
                        weight="bold"
                        style={{
                          marginLeft: width > 1200 ? 'auto' : '5px',
                          opacity: width <= 1200 && '0.7',
                        }}
                      />
                      <Label
                        text={width <= 1200 ? 'Balance: ' : 'Balance '}
                        size={width <= 1200 ? 12 : 9}
                        weight="800"
                        opacity="0.35"
                        translateId="vault.balance"
                      />
                    </Div>
                  </StyledRowBetween>
                </HoveredRow>
                <Row style={{ padding: '0px 32px' }}>
                  <div
                    style={{
                      width: '17px',
                      marginRight: '24px',
                    }}
                  ></div>
                  <Label
                    text="Bond ROI"
                    size="13"
                    opacity="0.5"
                    weight="800"
                    style={{ marginBottom: '10px' }}
                    translateId="bonds.table.col.discounts"
                  />
                </Row>
                {bondList?.map((bond, index) => (
                  <Item
                    key={index}
                    onClick={() => {
                      dispatch(
                        setBondPurchase({
                          chainId: networkId || chainId,
                          bondPurchase: bond,
                        })
                      )
                      history.push(
                        `/bond/purchase/${bond?.bondRoute}?network=${networkName}`
                      )
                    }}
                  >
                    <Row style={{ padding: '10px 36px' }}>
                      <div
                        style={{
                          width: '17px',
                          marginRight: '24px',
                        }}
                      />
                      <Row
                        style={{
                          flexDirection: width <= 1200 && 'column',
                          alignItems: width <= 1200 && 'flex-start',
                        }}
                      >
                        <Label text={bond?.bondName} size="13" weight="bold" />
                        <Label
                          text={`${precise(bond?.bondDiscount * 100, 2)} %`}
                          size={width <= 1200 ? 12 : 13}
                          weight="bold"
                          style={{
                            marginLeft: width > 1200 && 'auto',
                            opacity: width <= 1200 && '0.7',
                            minWidth: 'fit-content',
                          }}
                        />
                      </Row>
                    </Row>
                  </Item>
                ))}
              </SidebarItem>

              {/* <SidebarItem
                text="Vault"
                img={vault}
                activeImg={activeVault}
                to={`/vault?network=${networkName}`}
                width="17"
                translateId="common.menu.bar_item.7"
              /> */}
              {/* <SidebarItem
                text="Futures"
                img={future}
                activeImg={activeFuture}
                to={`/futures?network=${networkName}`}
                width="16"
                translateId="common.menu.bar_item.5"
              /> */}
              {isSupported && (
                <SidebarItem
                  text="Explore"
                  img={explore}
                  activeImg={activeExplore}
                  to={`/explore?network=${networkName}`}
                  dropdown={true}
                  isOpen={isExploreOpen}
                  setIsOpen={setIsExploreOpen}
                  width="18"
                  translateId="common.menu.bar_item.6"
                >
                  {Object.entries(
                    networkName === 'ethereum'
                      ? EXPLORER_FILTER_ETH
                      : EXPLORER_FILTER ?? {}
                  ).map(([key, item], index) => (
                    <Item
                      key={index}
                      onClick={() => {
                        if (history.location.pathname !== `/explore/${key}`) {
                          history.push(`/explore/${key}?network=${networkName}`)
                        }
                        setIsExploreOpen(false)
                      }}
                    >
                      <Row style={{ padding: '10px 36px' }}>
                        <img
                          src={item?.image?.url}
                          width="19"
                          style={{
                            marginRight: '24px',
                          }}
                          alt="sidebarImage"
                        />
                        <Spacer size="sm" />
                        <div>
                          <Label
                            text={item?.name}
                            size="14"
                            weight="800"
                            opacity="0.6"
                            style={{ marginBottom: '3px' }}
                          />

                          <Label
                            text={item?.short_description?.[0]?.text}
                            size="12"
                            weight="800"
                            opacity="0.3"
                            style={{ lineHeight: 1.08 }}
                          />
                        </div>
                      </Row>
                    </Item>
                  ))}
                </SidebarItem>
              )}
            </div>
            <Spacer size="lg" />
            <Spacer size="lg" />
            <div style={{ marginTop: 'auto' }}>
              <StyledRow>
                <FooterIcon
                  src={isDark ? TwitterIcon : TwitterDarkIcon}
                  onClick={() => {
                    window.open(SOCIALLINKS?.twitter)
                  }}
                  data-testid="twitter"
                  isDark={isDark}
                />
                <FooterIcon
                  src={isDark ? TelegramIcon : TelegramDarkIcon}
                  onClick={() => {
                    window.open(SOCIALLINKS?.telegram)
                  }}
                  data-testid="telegram"
                  isDark={isDark}
                />
                <FooterIcon
                  src={isDark ? DiscordIcon : DiscordDarkIcon}
                  onClick={() => {
                    window.open(SOCIALLINKS?.discord)
                  }}
                  data-testid="discord"
                  isDark={isDark}
                />
                <FooterIcon
                  src={isDark ? MediumIcon : MediumDarkIcon}
                  onClick={() => {
                    window.open(SOCIALLINKS?.medium)
                  }}
                  data-testid="medium"
                  isDark={isDark}
                />
              </StyledRow>
              <Spacer />
              <RowCenter>
                <Lightpaper
                  onClick={() => {
                    window.open(SOCIALLINKS?.lightPaper)
                  }}
                  data-testid="lightpaper"
                >
                  Light paper
                </Lightpaper>
              </RowCenter>
              <RowCenter style={{ margin: '30px 0' }}>
                <Label
                  text="© 2022 All rights reserved."
                  weight="bold"
                  size="14"
                  opacity="0.4"
                />
              </RowCenter>
            </div>
          </div>
        </StyledSimpleBar>
      </SidebarItems>
    </OutsideClickHandler>
  )
}

export default Sidebar

const StyledSimpleBar = styled(SimpleBar)`
  height: 100%;
  width: 100%;
  .simplebar-scrollbar {
    width: 8px;
  }
  .simplebar-track.simplebar-vertical {
    width: 8px;
  }
  .simplebar-scrollbar:before {
    background-color: ${(props) =>
      props.isdark === 'true' ? 'rgba(255,255,255,0.4)' : 'rgba(33, 45, 99, 0.4)'};
  }
  .simplebar-visible: before {
    background-color: ${(props) =>
      props.isdark === 'true' ? 'rgba(255,255,255,0.4)' : 'rgba(33, 45, 99, 0.4)'};
  }
`

const SidebarItems = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 286px;
  background: ${({ theme }) => theme.color.background.sidebar};
  ${({ showSidebar, isSidebar, theme }) =>
    `background: ${
      !isSidebar &&
      showSidebar &&
      `-webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  box-shadow: 10px 0 30px 0 rgba(0, 0, 0, 0.3)`
    }`}}
  z-index: 99;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: 215px;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
  display:none;
  `}
`

const Item = styled.div`
  display: flex;
  align-items: center;
  width: calc(100%-4px);
  :hover {
    background-color: rgba(45, 196, 143, 0.05);
    border-left: 4px solid ${(props) => props.theme.color.border.green};
    cursor: pointer;
  }
`
const StyledRow = styled(RowBetween)`
  padding: 0 40px;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    padding: 0 30px;
  `}
`

const FooterIcon = styled.img`
  height: 18px;
  cursor: pointer;
  opacity: ${({ isDark }) => (isDark ? 0.6 : 0.8)};
`
const Lightpaper = styled.div`
  height: 19px;
  font-size: 14px;
  cursor: pointer;
  color: ${(props) => props.theme.color.secondary.main};
  font-weight: bold;
  margin: 0 15px;
  text-decoration: underline;
`

const Link = styled.a`
  position: relative;
  margin: 33px 0 52px 0;
  background-color: transparent;
  border: none;
  outline: none !important;
  text-decoration: none;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`
const Div = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    flex-direction: row-reverse;
  }
`
const StyledRowBetween = styled(RowBetween)`
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
const HoveredRow = styled(Row)`
  margin-bottom: 10px;
  padding: 5px 36px;
  :hover {
    background-color: rgba(45, 196, 143, 0.05);
    border-left: 4px solid ${(props) => props.theme.color.border.green};
    cursor: pointer;
  }
`
