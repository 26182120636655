import React from 'react'
import { useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'

const Modal = ({
  children,
  width,
  maxWidth,
  responsive = true,
  style = {},
  innerStyle = {},
  autoWidth = false,
}) => {
  const isDark = useSelector(({ application }) => application.isDark)

  return (
    <StyledResponsiveWrapper
      width={width}
      isDark={isDark}
      maxWidth={maxWidth}
      style={style}
      responsive={responsive}
      autoWidth={autoWidth}
    >
      <StyledModal isDark={isDark} style={innerStyle}>
        {children}
      </StyledModal>
    </StyledResponsiveWrapper>
  )
}

const mobileKeyframes = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
`

export const StyledResponsiveWrapper = styled.div`
  ${(props) =>
    props.isDark &&
    `
    background-image: linear-gradient(to bottom right, #003736 , #003C3B,#004E4D,#005351,#005957);
  border-radius:39px;`}
  align-items: center;
  ${({ responsive, maxWidth }) => `
    max-width:${!responsive && maxWidth}px;
  `}
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  overflow-y: auto;
  width: ${(props) => (props.width ? props.width : props.responsive && '450')}px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex: 1;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    max-height: calc(100% - ${(props) => props.theme.spacing[4]}px);
    animation: ${mobileKeyframes} 0.3s forwards ease-out;
  }
  ${({ theme, responsive, autoWidth, width }) => theme.mediaWidth.upToMedium`
    width: ${autoWidth ? width ?? '450px' : responsive && '400px'}
  `}
  ${({ theme, responsive, autoWidth }) => theme.mediaWidth.upToSmall`
    width: ${autoWidth ? '90%' : responsive && '350px'}
  `}
  ${({ theme, responsive, autoWidth }) => theme.mediaWidth.upToExtraSmall`
    width: ${autoWidth ? '100%' : responsive && '300px'}
  `}
`

export const StyledModal = styled.div`
  padding: 40px;
  background: ${(props) => !props.isDark && props.theme.color.background.main};
  box-sizing: border-box;
  border-radius: 39px;
  box-shadow: ${(props) =>
    !props.isDark && `inset 1px 1px 0px ${props.theme.color.grey[100]}`};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 0;
  overflow: hidden;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 30px;
  `}
`

export default Modal
