import locale_en from 'i18n/translations/en.json'
import locale_es from 'i18n/translations/es.json'
import locale_zh from 'i18n/translations/zh.json'
import locale_tr from 'i18n/translations/tr.json'
import locale_ru from 'i18n/translations/ru.json'
import locale_ko from 'i18n/translations/ko.json'
import locale_ua from 'i18n/translations/ua.json'
import locale_id from 'i18n/translations/id.json'
import locale_lv from 'i18n/translations/lv.json'
import locale_vi from 'i18n/translations/vi.json'
import locale_ja from 'i18n/translations/ja.json'

export const languages = [
  { id: 'en', abbr: 'EN', name: 'English', first: true, community: false },
  { id: 'es', abbr: 'ES', name: 'Español', community: false },
  { id: 'tr', abbr: 'TR', name: 'Türkçe', community: false },
  { id: 'zh', abbr: 'ZH', name: '繁體中文', community: false },
  { id: 'ru', abbr: 'RU', name: 'Русский', community: false },
  { id: 'ko', abbr: 'KO', name: '한국어', community: false },
  { id: 'uk', abbr: 'UA', name: 'Українська', community: false },
  { id: 'id', abbr: 'ID', name: 'Bahasa Indonesia', community: true },
  { id: 'lv', abbr: 'LV', name: 'Latviešu', community: true },
  { id: 'vi', abbr: 'VI', name: 'Tiếng Việt', community: false },
  { id: 'ja', abbr: 'JP', name: '日本語', community: false },
]

export const localeData = {
  en: locale_en,
  es: locale_es,
  tr: locale_tr,
  zh: locale_zh,
  ru: locale_ru,
  ko: locale_ko,
  uk: locale_ua,
  id: locale_id,
  lv: locale_lv,
  vi: locale_vi,
  ja: locale_ja,
}

export const ManualTransWord = {
  en: {
    months: 'months',
    hours: 'hours',
    reject: 'REJECTED',
    fail: 'FAILED',
    learnMore: 'Buy $MONO here!',
  },
  es: {
    months: 'meses',
    hours: 'horas',
    reject: 'RECHAZADA',
    fail: 'FALLIDA',
    learnMore: 'Buy $MONO here!',
  },
  ko: {
    months: '월',
    hours: '시간:',
    reject: '거부됨',
    fail: '실패한',
    learnMore: 'Buy $MONO here!',
  },
  ru: {
    months: 'месяцы',
    hours: 'часы',
    reject: 'ОТКЛОНЕННЫЙ',
    fail: 'НЕ СМОГЛИ',
    learnMore: 'Buy $MONO here!',
  },
  tr: {
    months: 'ay',
    hours: 'saat',
    reject: 'REDDEDİLMİŞ',
    fail: 'ARIZALI',
    learnMore: 'Buy $MONO here!',
  },
  uk: {
    months: 'місяці',
    hours: 'години',
    reject: 'Відхилено',
    fail: 'НЕ ПОВНІЖНА',
    learnMore: 'Buy $MONO here!',
  },
  id: {
    months: 'bulan',
    hours: 'jam',
    reject: 'menolak',
    fail: 'gagal',
    learnMore: 'Buy $MONO here!',
  },
  zh: {
    months: '月',
    hours: '小時',
    reject: '拒絕了',
    fail: '失敗的',
    learnMore: 'Buy $MONO here!',
  },
  lv: {
    months: 'mēnešus',
    hours: 'stundas',
    reject: 'noraidīt',
    fail: 'neizdoties',
    learnMore: 'Buy $MONO here!',
  },
  vi: {
    months: 'tháng',
    hours: 'giờ',
    reject: 'Từ chối',
    fail: 'Thất bại',
    learnMore: 'Buy $MONO here!',
  },
  ja: {
    months: '月',
    hours: '時間',
    reject: '拒否されました',
    fail: '失敗した',
    learnMore: 'Buy $MONO here!',
  },
}
