import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import { getAddress } from '@ethersproject/address'
import { precise } from 'monox/util'

import ERC20Json from 'monox/abi/ERC20.json'
import { abi as ierc20Abi } from 'monox/abi/IERC20.json'
import VCASHJson from 'monox/abi/VCASH.json'
import MonoswapStakingAbi from 'monox/abi/Staking.json'
import OldSwapAbi from 'monox/abi/OldSwap.json'
import NewSwapAbi from 'monox/abi/NewSwap.json'
import NewSwapRouterAbi from 'monox/abi/NewSwapRouter.json'
import NewPoolAbi from 'monox/abi/NewPool.json'
import FutureAbi from 'monox/abi/FUTURE.json'
import WETHPoolAbi from 'monox/abi/WETH.json'
import DVaultAbi from 'monox/abi/dMONO.json'
import svUNTAbi from 'monox/abi/bond/svUNIT.json'
import bondStakeAbi from 'monox/abi/bond/BondStakeContract.json'
import bondStakeHelperAbi from 'monox/abi/bond/BondStakeHelperContract.json'
import BondCalcContractAbi from 'monox/abi/bond/BondCalcContract.json'
import BondTreasuryContractAbi from 'monox/abi/bond/BondTreasuryContract.json'
import BondContractAbi from 'monox/abi/bond/BondContract.json'
import LPContractAbi from 'monox/abi/bond/LPToken.json'
import config from 'monox/config'
import allTokensList from 'monox/uniswap_all_tokens_list.json'

import MetamaskWallet from 'assets/img/wallet-icon/metamask-wallet.svg'
import CoinbaseWallet from 'assets/img/wallet-icon/coinbase-wallet.svg'
import MobileWallet from 'assets/img/wallet-icon/mobile-wallet.svg'
import kovan from 'assets/img/kovan.png'
import mumbai from 'assets/img/mumbai.png'
import bsc from 'assets/img/bsc.png'
import moonbeam from 'assets/img/moonbeam.svg'
import fuji from 'assets/img/fuji.png'
import ethereum from 'assets/img/Ethereum.svg'
import polygon from 'assets/img/Polygon.svg'
import goerli from 'assets/img/goerli.jpg'
import harmony from 'assets/img/harmony.png'
import arbitrum from 'assets/img/arbitrum.svg'
import MostActive from 'assets/img/most-active.png'
import BiggestGains from 'assets/img/biggest-gains.png'
import RecentAdded from 'assets/img/recently-added.png'
import BiggestDrops from 'assets/img/biggest-drops.png'

const ERC20Abi = ERC20Json.abi
const VCASHAbi = VCASHJson.abi
const StakingAbi = MonoswapStakingAbi

export const NetworkId = {
  MAINNET: 1, // Ethereum Mainnet
  TESTNET_KOVAN: 42,
  TESTNET_GOERLI: 5,
  POLYGON: 137,
  POLYGON_TESTNET: 80001, // Mumbai
  AVALANCHE: 43114,
  AVALANCHE_TESTNET: 43113, // Avalancche Fuji testnet
  ARBITRUM: 42161,
  TESTNET_ARBITRUM: 421611,
  TESTNET_HARMONY: 1666700000,
  BINANCE: 56,
  BINANCE_TESTNET: 97,
  MOONBEAM: 1284,
  MOONBEAM_TESTNET: 1287, // Moonbase alpha testnet
}

export const EXPLORER_FILTER_ETH = {
  'most-active': {
    image: { url: MostActive },
    name: 'Most Active',
    short_description: [{ text: 'Top 100 tokens by trading volume' }],
    tableHeading: 'Most Active',
  },
}

export const EXPLORER_FILTER = {
  'most-active': {
    image: { url: MostActive },
    name: 'Most Active',
    short_description: [{ text: 'Top 100 tokens by trading volume' }],
    tableHeading: 'Most Active',
  },

  'biggest-gains': {
    image: { url: BiggestGains },
    name: 'Biggest Gains',
    short_description: [{ text: 'Top gains in the last 24 hours' }],
    tableHeading: 'Biggest Gains (24H)',
  },
  'biggest-drops': {
    image: { url: BiggestDrops },
    name: 'Biggest Drops',
    short_description: [{ text: 'Top drops in the last 24 hours' }],
    tableHeading: 'Biggest Drops (24H)',
  },
  'recently-added': {
    image: { url: RecentAdded },
    name: 'Recently Added',
    short_description: [{ text: 'Tokens added in the past 24 hours' }],
    tableHeading: 'Recently Added (24H)',
  },
}

export const DEFAULT_LIST_OF_LISTS = []

export const allTokens = allTokensList
export const lpToken = config?.[1].VCASH
export const monoTokenKovan = config?.[1].MONO
export const monoTokenMumbai = config?.[137].MONO

export const allTokensDict = {}
for (let t of allTokens.tokens) {
  allTokensDict[t?.address?.toLowerCase()] = t
}

export const getVCASHToken = (provider, address) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(VCASHAbi, address)
  return contract
}

export const getCurrentChainId = async (provider) => {
  const web3 = new Web3(provider)
  const currentChainId = await web3.eth.getChainId()
  return currentChainId
}

export const getCurrentBlock = async (provider) => {
  const web3 = new Web3(provider)
  const currentBlock = await web3.eth.getBlockNumber()
  return currentBlock
}

export const getGasPrice = async (provider) => {
  const web3 = new Web3(provider)
  const gasPrice = await web3.eth.getGasPrice()
  return gasPrice
}

export const weiToEth = (balance, decimals = 18) => {
  const displayBalance = balance
    .dividedBy(new BigNumber(10).pow(decimals))
    .toNumber()
  return Math.floor((displayBalance + Number.EPSILON) * 10000) / 10000
}

export const weiToEthNum = (balance, decimals = 18) => {
  const displayBalance = BigNumber(balance).dividedBy(
    new BigNumber(10).pow(decimals)
  )
  return displayBalance.toNumber()
}

export const weiToEthString = (balance, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  return displayBalance.toString()
}

export const weiToEthLongString = (balance, decimals = 18, showLength = 18) => {
  if (weiToEthNum(balance, decimals) >= DISPLAY_MIN_VALUE)
    return precise(weiToEthNum(balance, decimals), showLength)
  else if (weiToEthNum(balance, decimals) === 0) return 0
  else {
    const zeroCounts = Math.max(
      decimals - new BigNumber(balance).toFixed(0)?.toString()?.length,
      0
    )

    const displayBalance =
      '0.' +
      Array(zeroCounts).fill('0').join('') +
      balance?.toString().slice(0, showLength)
    return displayBalance?.replace(/(\.0+|0+)$/, '')
  }
}
export const ubetToEth = weiToEth

export const removeNotation = (value, showLength = 18) => {
  if (!value) return '0'
  if (Math.abs(value) < 1.0) {
    let e = parseInt(value.toString().split('e-')[1])
    if (e) {
      value *= Math.pow(10, e - 1)
      value =
        '0.' +
        new Array(e).join('0') +
        value.toString().substring(2).slice(0, showLength)
    }
  } else {
    let e = parseInt(value.toString().split('+')[1])
    if (e > 20) {
      e -= 20
      value /= Math.pow(10, e)
      value += new Array(e + 1).join('0')
    }
  }
  return value
}

export const getERC20Token = (provider, address) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(ERC20Abi, address)
  return contract
}

export const getLPToken = (provider, address) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(LPContractAbi, address)
  return contract
}

export const getPoolContract = (provider, address) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(NewPoolAbi, address)
  return contract
}

export const getOldSwapContract = (provider, address) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(OldSwapAbi, address)
  return contract
}

export const getSwapContract = (provider, address) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(NewSwapAbi, address)
  return contract
}

export const getSwapRouterContract = (provider, address) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(NewSwapRouterAbi, address)
  return contract
}

export const getFutureContract = (provider, address) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(FutureAbi, address)
  return contract
}

export const getWRAPPEDContract = (provider, address) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(WETHPoolAbi, address)
  return contract
}

export const getStakeContract = (provider, address) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(StakingAbi, address)
  return contract
}

export const getVaultContract = (provider, address, ABI = DVaultAbi) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(ABI, address)
  return contract
}

export const getStakedvUNITContract = (provider, address, ABI = svUNTAbi) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(ABI, address)
  return contract
}

export const getBondStakeContract = (provider, address, ABI = bondStakeAbi) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(ABI, address)
  return contract
}

export const getBondStakeHelperContract = (
  provider,
  address,
  ABI = bondStakeHelperAbi
) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(ABI, address)
  return contract
}

export const getBondCalculator = (provider, address, ABI = BondCalcContractAbi) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(ABI, address)
  return contract
}

export const getBondTreasury = (
  provider,
  address,
  ABI = BondTreasuryContractAbi
) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(ABI, address)
  return contract
}

export const getBondContract = (provider, address, ABI = BondContractAbi) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(ABI, address)
  return contract
}

export const getReserveContract = (provider, address, ABI = ierc20Abi) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(ABI, address)
  return contract
}

export const getBalance = async (provider, currency, userAddress) => {
  if (!currency?.address && currency?.symbol) {
    const web3 = new Web3(provider)
    const balance = await web3.eth.getBalance(userAddress)
    return balance
  } else if (currency?.address) {
    const lpContract = getERC20Token(provider, currency?.address)
    try {
      const balance = await lpContract?.methods?.balanceOf(userAddress).call()
      return balance
    } catch (e) {
      return '0'
    }
  }
}

const ENS_NAME_REGEX = /^(([a-zA-Z0-9]+\.)+)eth(\/.*)?$/

export const parseENSAddress = (ensAddress) => {
  const match = ensAddress.match(ENS_NAME_REGEX)
  if (!match) return undefined
  return { ensName: `${match[1].toLowerCase()}eth`, ensPath: match[3] }
}

export const isAddress = (value) => {
  try {
    return getAddress(value)
  } catch (err) {
    return false
  }
}

export const SUPPORTED_WALLETS = {
  METAMASK: {
    connector: 'injected',
    name:
      window.web3 || window.ethereum
        ? 'Connect MetaMask Wallet'
        : 'Install MetaMask',
    translateId:
      window.web3 || window.ethereum
        ? 'modal.common.wallet.connect.metamask'
        : 'modal.common.wallet.connect.install',
    iconName: MetamaskWallet,
    description: 'Easy-to-use browser extension.',
  },
  WALLET_CONNECT: {
    connector: 'walletconnect',
    name: 'Connect Mobile Wallet',
    translateId: 'modal.common.wallet.connect.mobile',
    iconName: MobileWallet,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    mobile: true,
  },
  WALLET_LINK: {
    connector: 'walletlink',
    name: 'Connect Coinbase Wallet',
    translateId: 'modal.common.wallet.connect.coinbase',
    iconName: CoinbaseWallet,
    description: 'Use Coinbase Wallet app on mobile device',
  },
}

export const networks = {
  // needs to be added before prod release
  [NetworkId?.MAINNET]: {
    name: 'Ethereum',
    network: 'ethereum', // network property is used for api endpoint
    chainId: NetworkId?.MAINNET,
    image: ethereum,
    color: 'rgba(133, 164, 230, 0.15)',
    fontColor: '#6335c9',
    blockExplorer: 'https://etherscan.io',
    blockRate: 13.14,
    scanName: 'Etherscan',
  },
  [NetworkId?.POLYGON]: {
    name: 'Polygon',
    chainName: 'Polygon Mainnet',
    chainId: NetworkId?.POLYGON,
    network: 'polygon',
    color: 'rgba(133, 164, 230, 0.15)',
    fontColor: '#2b6df0',
    image: polygon,
    blockExplorer: 'https://polygonscan.com',
    blockRate: 2.3,
    scanName: 'Polygonscan',
  },
  [NetworkId?.AVALANCHE]: {
    name: 'Avalanche',
    chainName: 'Avalanche',
    chainId: NetworkId?.AVALANCHE,
    network: 'avalanche',
    color: 'rgba(133, 164, 230, 0.15)',
    fontColor: '#e84142',
    image: fuji,
    blockExplorer: 'https://snowtrace.io',
    blockRate: 2,
    scanName: 'Snowtrace',
  },
  [NetworkId?.POLYGON_TESTNET]: {
    name: 'Mumbai',
    chainName: 'Polygon Testnet',
    chainId: NetworkId?.POLYGON_TESTNET,
    network: 'mumbai',
    color: 'rgba(133, 164, 230, 0.15)',
    fontColor: '#2b6df0',
    image: mumbai,
    blockExplorer: 'https://mumbai.polygonscan.com',
    blockRate: 7.4,
    scanName: 'Polygonscan',
  },
  [NetworkId?.TESTNET_KOVAN]: {
    name: 'Kovan',
    chainName: 'Ethereum Testnet',
    chainId: NetworkId?.TESTNET_KOVAN,
    network: 'kovan',
    color: 'rgba(133, 164, 230, 0.15)',
    fontColor: '#6335c9',
    image: kovan,
    blockExplorer: 'https://kovan.etherscan.io/',
    blockRate: 4,
    scanName: 'Etherscan',
  },
  [NetworkId?.AVALANCHE_TESTNET]: {
    name: 'FUJI',
    chainName: 'AVALANCHE Testnet',
    chainId: NetworkId?.AVALANCHE_TESTNET,
    network: 'fuji',
    color: 'rgba(133, 164, 230, 0.15)',
    fontColor: '#e84142',
    image: fuji,
    blockExplorer: 'https://testnet.snowtrace.io',
    blockRate: 2,
    scanName: 'Snowtrace',
  },
  [NetworkId?.TESTNET_GOERLI]: {
    name: 'Goerli',
    chainName: 'Ethereum Testnet',
    chainId: NetworkId?.TESTNET_GOERLI,
    network: 'goerli',
    color: 'rgba(30, 31, 27, 0.15)',
    fontColor: '#635b4f',
    image: goerli,
    blockExplorer: 'https://goerli.etherscan.io',
    blockRate: 15,
    scanName: 'Etherscan',
  },
  [NetworkId?.TESTNET_HARMONY]: {
    name: 'Harmony Testnet',
    chainName: 'Harmony Testnet',
    chainId: NetworkId?.TESTNET_HARMONY,
    network: 'harmony1',
    color: 'rgba(30, 31, 27, 0.15)',
    fontColor: '#00aee9',
    image: harmony,
    blockExplorer: 'https://explorer.pops.one',
    blockRate: 2,
    scanName: 'Harmony',
  },
  [NetworkId?.ARBITRUM]: {
    name: 'Arbitrum',
    chainName: 'Arbitrum',
    chainId: NetworkId?.ARBITRUM,
    network: 'arbitrum',
    color: 'rgba(30, 31, 27, 0.15)',
    fontColor: '#635b4f',
    image: arbitrum,
    blockExplorer: 'https://arbiscan.io',
    blockRate: 13,
    scanName: 'Arbiscan',
  },
  [NetworkId?.TESTNET_ARBITRUM]: {
    name: 'Arbitrum Rinkeby',
    chainName: 'Arbitrum Rinkeby',
    chainId: NetworkId?.TESTNET_ARBITRUM,
    network: 'arbirinkeby',
    color: 'rgba(30, 31, 27, 0.15)',
    fontColor: '#635b4f',
    image: arbitrum,
    blockExplorer: 'https://testnet.arbiscan.io',
    blockRate: 15,
    scanName: 'Arbiscan',
  },
  [NetworkId?.BINANCE]: {
    name: 'BSC',
    chainName: 'Polygon Testnet',
    chainId: NetworkId?.BINANCE,
    network: 'bsc',
    color: 'rgba(133, 164, 230, 0.15)',
    fontColor: '#f0b90b',
    image: bsc,
    blockExplorer: 'https://bscscan.com',
    blockRate: 3,
    scanName: 'Bscscan',
  },
  [NetworkId?.BINANCE_TESTNET]: {
    name: 'BSC Testnet',
    chainId: NetworkId?.BINANCE_TESTNET,
    network: 'bsctestnet',
    color: 'rgba(133, 164, 230, 0.15)',
    fontColor: '#f0b90b',
    image: bsc,
    blockExplorer: 'https://testnet.bscscan.com',
    blockRate: 3,
    scanName: 'Bscscan',
  },
  [NetworkId?.MOONBEAM]: {
    name: 'Moonbeam',
    chainId: NetworkId?.MOONBEAM,
    network: 'moonbeam',
    color: 'rgba(133, 164, 230, 0.15)',
    fontColor: '#e84142',
    image: moonbeam,
    blockExplorer: 'https://moonbeam.moonscan.io',
    blockRate: 12,
    scanName: 'Moonbeam',
  },
  [NetworkId?.MOONBEAM_TESTNET]: {
    name: 'Moonbase Alpha',
    chainId: NetworkId?.MOONBEAM_TESTNET,
    network: 'moonbase',
    color: 'rgba(133, 164, 230, 0.15)',
    fontColor: '#e84142',
    image: moonbeam,
    blockExplorer: 'https://moonbase.moonscan.io',
    blockRate: 20,
    scanName: 'Moonbeam',
  },
}

export const MAINNNET_BACKEND_URL = 'https://api.monox.finance/'
export const TESTNET_BACKEND_URL = 'https://api-rc.monox.finance/'
export const MAINNET_CHAINDS = [
  NetworkId?.POLYGON,
  NetworkId?.AVALANCHE,
  NetworkId?.ARBITRUM,
  NetworkId?.BINANCE,
  NetworkId?.MOONBEAM,
] // When only need to show TEST NETS make it empty array
export const TESTNET_CHAINDS = [
  NetworkId?.POLYGON_TESTNET,
  NetworkId?.TESTNET_KOVAN,
  NetworkId?.AVALANCHE_TESTNET,
  NetworkId?.TESTNET_HARMONY,
  NetworkId?.TESTNET_ARBITRUM,
  NetworkId?.BINANCE_TESTNET,
  NetworkId?.MOONBEAM_TESTNET,
] // When only need to show Main NETS make it empty array
export const supportedChainIds = [...MAINNET_CHAINDS, ...TESTNET_CHAINDS] // sync with config
export const DELAY_MS = 70
export const DISPLAY_MIN_VALUE = 0.000001
export const MAX_GAS_ESTIMATE = 0.04
export const isProdEnv = process?.env?.REACT_APP_DEV_ENV?.includes('prod') || false
export const DEFAULT_NETWORK_ID = isProdEnv
  ? NetworkId?.POLYGON
  : // : NetworkId?.POLYGON_TESTNET
    NetworkId?.POLYGON
export const ETHERSCAN_CHAINS = [
  NetworkId?.MAINNET,
  NetworkId?.TESTNET_GOERLI,
  NetworkId?.TESTNET_KOVAN,
]
export const POLYGON_CHAINS = [NetworkId?.POLYGON, NetworkId?.POLYGON_TESTNET]
export const AVALANCHE_CHAINS = [NetworkId?.AVALANCHE, NetworkId?.AVALANCHE_TESTNET]
export const MOONBEAM_CHAINS = [NetworkId?.MOONBEAM, NetworkId?.MOONBEAM_TESTNET]
export const ARBITRUM_CHAINS = [NetworkId?.ARBITRUM, NetworkId?.TESTNET_ARBITRUM]
export const BINANCE_CHAINS = [NetworkId?.BINANCE, NetworkId?.BINANCE_TESTNET]
export const SWAP_ALLOW_CHAINS = [NetworkId?.POLYGON_TESTNET, NetworkId?.POLYGON]
export const STAKING_ALLOW_CHAINS = [NetworkId?.POLYGON_TESTNET, NetworkId?.POLYGON]
export const VAULT_ALLOW_CHAINS = [NetworkId?.TESTNET_KOVAN]
export const FUTURE_ALLOW_CHAINS = [NetworkId?.TESTNET_KOVAN]
export const BOND_STAKE_ALLOW_CHAINS = [
  NetworkId?.POLYGON,
  NetworkId?.POLYGON_TESTNET,
  NetworkId?.TESTNET_KOVAN,
  NetworkId?.AVALANCHE,
  NetworkId?.AVALANCHE_TESTNET,
  NetworkId?.TESTNET_HARMONY,
  NetworkId?.TESTNET_ARBITRUM,
  NetworkId?.ARBITRUM,
  NetworkId?.BINANCE_TESTNET,
  NetworkId?.BINANCE,
  NetworkId?.MOONBEAM_TESTNET,
  NetworkId?.MOONBEAM,
]

export const FREE_INFURA_URL = {
  [NetworkId?.TESTNET_KOVAN]:
    'https://kovan.infura.io/v3/943bd488f91d453cbc3d5203a35ed325',
  [NetworkId?.TESTNET_GOERLI]:
    'https://goerli.infura.io/v3/943bd488f91d453cbc3d5203a35ed325',
  [NetworkId?.POLYGON_TESTNET]:
    'https://polygon-mumbai.infura.io/v3/943bd488f91d453cbc3d5203a35ed325',
}
export const PENDING_TX_FETCH_INTERVAL = 5000 // 5s
export const targetTimeStamp = 1646344241

export const IMAGEURL = {
  WETH: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  WMATIC:
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png',
  AVAX: 'https://raw.githubusercontent.com/ava-labs/bridge-tokens/main/avalanche-tokens/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/logo.png',
  BNB: 'https://user-images.githubusercontent.com/87996012/163583547-2d326b68-3c99-4b1b-bd0f-f07a3e815e48.jpg',
  GLMR: 'https://user-images.githubusercontent.com/59371077/163578848-f6961c3b-841f-4b42-997f-4afa2bae6211.jpg',
  USDT: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
  USDC: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
  VUNIT:
    'https://user-images.githubusercontent.com/57688920/112673530-e3da8980-8e75-11eb-99fc-3788ad5e8f79.png',
  MONO: 'https://user-images.githubusercontent.com/59371077/119623627-91482a00-be32-11eb-8160-954010ddbaac.png',
  BUSD: 'https://user-images.githubusercontent.com/59371077/163460971-eca0bf94-d693-416b-8c7a-f3262b722e50.png',
  ARETH:
    'https://user-images.githubusercontent.com/57688920/159543901-120059db-cc17-4cb1-856e-9e7137252ca7.jpg',
  ONE: 'https://user-images.githubusercontent.com/59371077/163600359-5de6e8e5-8a23-4183-a4e3-521c93403837.png',
}

export const GETLPTOKENLINK = {
  KOVAN:
    'https://app.uniswap.org/#/add/v2/0x59c988F2F27c5C9692b2a0dF84fA1C468E9A02dD/0x6547427F900ad4BF7C450332026A46394520Efe2?chain=kovan',
  POLYGON:
    'https://app.sushi.com/add/0x632fbF85F77978437073a8CE5CEEC29e3209514c/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  GETVUNIL: 'https://monox.finance/swap/MATIC/vUNIT?network=polygon',
}

export const WSS_URL = {
  Ethereum: 'wss://ws-nd-059-969-932.p2pify.com/2a51f98032eac240db897f39fee4f28b',
  Polygon: '',
  Kovan: 'wss://kovan-rpc-wss.monox.finance',
  Goerli: 'wss://goerli-rpc-wss.monox.finance',
  Fuji: 'wss://api.avax-test.network/ext/bc/C/ws',
  Mumbai:
    'wss://naughty-blackwell:waffle-sprawl-math-used-ripple-snarl@ws-nd-311-035-380.p2pify.com',
  Harmony: 'wss://api.s0.b.hmny.io',
  Arbitrum: 'wss://arb1.arbitrum.io/ws',
  Arbirinkeby: 'wss://rinkeby.arbitrum.io/ws',
  BSC: '',
  BSCTestnet: '',
  Moonbeam: 'wss://wss.api.moonbeam.network',
  Moonbase: 'wss://wss.api.moonbase.moonbeam.network',
  Avalanche:
    'wss://rpc.ankr.com/avalanche/ws/67aae01450ddfae267cecaf7a021a2aebbf6be570816d9aabf19d3c735132ebe',
}

export const NETWORK_URL = {
  Ethereum: 'https://nd-059-969-932.p2pify.com/2a51f98032eac240db897f39fee4f28b',
  Polygon:
    'https://rpc.ankr.com/polygon/67aae01450ddfae267cecaf7a021a2aebbf6be570816d9aabf19d3c735132ebe',
  Kovan: 'https://kovan-rpc.monox.finance',
  Goerli: 'https://goerli-rpc.monox.finance',
  Fuji: 'https://api.avax-test.network/ext/bc/C/rpc',
  Mumbai:
    'https://naughty-blackwell:waffle-sprawl-math-used-ripple-snarl@nd-311-035-380.p2pify.com',
  Harmony: 'https://api.s0.b.hmny.io',
  Arbitrum: 'https://arb1.arbitrum.io/rpc',
  Arbirinkeby: 'https://rinkeby.arbitrum.io/rpc',
  BSC: 'https://rpc.ankr.com/bsc/67aae01450ddfae267cecaf7a021a2aebbf6be570816d9aabf19d3c735132ebe',
  BSCTestnet: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  Moonbeam: 'https://rpc.api.moonbeam.network',
  Moonbase: 'https://rpc.api.moonbase.moonbeam.network',
  Avalanche:
    'https://rpc.ankr.com/avalanche/67aae01450ddfae267cecaf7a021a2aebbf6be570816d9aabf19d3c735132ebe',
}

export const SOCIALLINKS = {
  discord: 'https://discord.gg/shMDPEzWn8',
  telegram: 'https://t.me/MonoXOfficial',
  twitter: 'https://twitter.com/MonoXFinance',
  medium: 'https://medium.com/monoswap',
  lightPaper: 'https://docs.monox.finance/',
  changeNetwork: 'https://docs.monox.finance/getting-started/changing-network',
  rpcSetting:
    'https://docs.monox.finance/getting-started/getting-started/how-to-change-rpc-setting',
  vunitDoc: 'https://docs.monox.finance/getting-started/vcash-stablecoin',
  learnMore: 'https://docs.monox.finance/getting-started/launch-+-airdrop#part-two',
  liquidityDoc: 'https://docs.monox.finance/getting-started/liquidity',
  lightPaperLink: 'https://docs.monox.finance/library/lightpaper',
}

export const GAS_FACTOR = isProdEnv ? 1.1 : 1
export const TRIGGER_DATE = 20220533 // keep this at the line of file for convenience
