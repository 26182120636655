import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { removeNotation } from 'monox/constants'

import { RowBetween, Row } from 'components/Row'
import Divider from 'components/Divider'
import Label from 'components/Label'
import Input from 'components/Input'
import Spacer from 'components/Spacer'
import QuestionHelper from 'components/QuestionHelper'
import { CloseIcon } from 'components/IconButton'
import Modal from 'components/Modal'
import StyledIconButton from 'components/StyledIconButton'

const preDefinedValues = [0.1, 0.5, 1]

const TransactionSettingModal = ({
  tolerance,
  setTolerance,
  setDeadline,
  initDeadline,
  onDismiss,
}) => {
  const [focus, setFocus] = useState('')
  const [tempTolerance, setTempTolerance] = useState(tolerance)
  const [tempDeadline, setTempDeadline] = useState(initDeadline)
  const isDark = useSelector(({ application }) => application.isDark)

  const warningTolerance = useMemo(() => {
    if (tempTolerance > 5 && tempTolerance < 50) {
      return 'Your transaction may be frontrun'
    }
    return ''
  }, [tempTolerance])

  const warningDeadline = useMemo(() => {
    if (tempDeadline && tempDeadline < 1) {
      setTimeout(function () {
        setTempDeadline(20)
      }, 2500)
      return {
        msg: 'Your transaction may fail if the deadline is less than one minute',
        id: 'modal.common.settings.deadline.warning',
      }
    }

    return ''
  }, [tempDeadline])

  const handleTolerance = (value) => {
    if (typeof value == 'number') {
      setTempTolerance(value)
      return
    }
    if (!value || value?.match(/^\d{0,}(\.\d{0,18})?$/)) {
      if (value === '.') {
        setTempTolerance(value)
      } else if (value && value.slice(-1) === '.') {
        setTempTolerance(`${parseFloat(value.slice(0, value.length - 1))}.`)
      } else {
        setTempTolerance(value)
      }
    }
  }

  const handleInputChange = (value) => {
    if (typeof value == 'number') {
      setTempDeadline(value)
    } else if (!value || value?.match(/^\d{0,}(\.\d{0,18})?$/)) {
      if (value === '.') {
        setTempDeadline(value)
        return
      } else if (value && value.slice(-1) === '.') {
        setTempDeadline(`${parseFloat(value?.slice(0, value?.length - 1))}.`)
      } else {
        if (
          value &&
          parseFloat(value) < 1 &&
          parseFloat(value) !== value &&
          value?.slice(-1) !== '0'
        ) {
          setTempDeadline(
            parseFloat(value)
              ?.toFixed(value?.length - 1)
              ?.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1')
          )
        } else {
          setTempDeadline(
            typeof value == 'number'
              ? value
              : value === '0'
              ? '0'
              : value?.replace(/^0+/, '')?.replace(/^\./, '0.')
          )
        }
      }
    }
    if (isNaN(value) || value === 0) {
      setTempDeadline(0)
      return
    }
  }

  const handleConfirm = () => {
    setDeadline(
      tempDeadline
        ? Number(tempDeadline) <= 4320
          ? Number(tempDeadline)
          : Number(tempDeadline?.toString()?.substring(0, 3))
        : initDeadline
    )
    setTolerance(tempTolerance)
    onDismiss()
  }

  return (
    <Modal>
      <RowBetween>
        <Label
          weight={800}
          size={16}
          text="
            Transaction Settings"
          translateId="modal.common.settings.title"
        />
        <CloseIcon onClick={onDismiss} data-testid="dismiss" />
      </RowBetween>
      <Spacer size="md" />
      <Divider />
      <Spacer size="md" />
      <Row>
        <Label
          weight={800}
          size={14}
          text="Slippage Tolerance"
          translateId="modal.common.settings.tolerance"
        />
        <QuestionHelper
          id="tolerance"
          text=" Your transaction will revert if the price changes unfavorably by more than this percentage"
          translateId="modal.common.settings.tolerance.helper"
        />
      </Row>
      <InputRow>
        {preDefinedValues.map((item, i) => {
          return (
            <StyledValueSelector
              isDark={isDark}
              key={item}
              active={item === tempTolerance}
              onClick={() => handleTolerance(item)}
              data-testid={`item-${i}`}
            >
              {item}%
            </StyledValueSelector>
          )
        })}
        <RowInputSmall focus={focus === 'tolerance'} isDark={isDark}>
          <Input
            placeholder={tempTolerance.toString()}
            value={tempTolerance}
            type="number"
            onChange={(e) => handleTolerance(e.target.value)}
            onFocus={() => setFocus('tolerance')}
            onBlur={() => setFocus('')}
            style={{ marginLeft: '15px' }}
            testId={'tolerance'}
          />
          <Label text="%" weight={800} size={12} style={{ marginRight: '15px' }} />
        </RowInputSmall>
      </InputRow>
      {tempTolerance <= 0 && (
        <Label
          text="Your transaction may fail"
          size={12}
          color="#f3851e"
          style={{ marginTop: 10 }}
          translateId="modal.common.settings.transaction.fail"
        />
      )}
      {tempTolerance >= 50 && (
        <Label
          text="Enter a valid slippage percentage"
          size={12}
          color="#f3851e"
          style={{ marginTop: 10 }}
          translateId="modal.common.settings.transaction.fail.invalid"
        />
      )}
      <Label
        text={warningTolerance}
        size={12}
        color="#f3851e"
        style={{ marginTop: 10 }}
        translateId="modal.common.settings.transaction.fail.tolerance"
      />
      <Spacer size="md" />
      <Row>
        <Label
          weight={800}
          size={14}
          text="Transaction Deadline"
          translateId="modal.common.settings.deadline"
        />
        <QuestionHelper
          id="deadline"
          text=" Your transaction will revert if it is pending for more than this long."
          translateId="modal.common.settings.deadline.helper"
        />
      </Row>
      <RowInput focus={focus === 'deadline'} isDark={isDark}>
        <Input
          placeholder={'Transaction Deadline'}
          value={removeNotation(tempDeadline)}
          type="number"
          onChange={(e) => handleInputChange(e.target.value)}
          onFocus={() => setFocus('deadline')}
          onBlur={() => setFocus('')}
          style={{ marginLeft: '30px' }}
          testId="deadline"
        />
        <Label
          text="minutes"
          opacity={0.5}
          weight={800}
          size={14}
          style={{ marginRight: '30px' }}
          translateId="modal.common.settings.time_interval"
        />
      </RowInput>
      <Label
        text={warningDeadline?.msg}
        size={12}
        color="#f3851e"
        style={{ marginBottom: 10 }}
        translateId={warningDeadline?.id}
      />
      <Spacer size="md" />
      <StyledIconButton
        block
        icon="arrow"
        disabled={tempTolerance <= 0 || tempTolerance >= 50}
        variant="primary"
        onClick={handleConfirm}
        modal={true}
        translateId={`modal.common.settings.button.${
          tempTolerance <= 0 ? '2' : '1'
        }`}
        testId={tempTolerance <= 0 ? 'zero-tolerance' : 'save-settings'}
      >
        {tempTolerance <= 0 ? 'Slippage tolerance cannot be 0' : 'Save Settings'}
      </StyledIconButton>
      <Spacer size="sm" />
    </Modal>
  )
}

export default TransactionSettingModal

const InputRow = styled(RowBetween)`
  margin-top: 18px;
  flex-wrap: wrap;
`
const RowInput = styled(Row)`
  box-shadow: ${({ theme }) => theme.shadows.inset};
  border-radius: 50px;
  height: 50px;
  align-items: center;
  margin-top: 15px;
  background-color: ${(props) => props.isDark && 'rgba(0, 0, 0, 0.05)'};
  ${(props) =>
    props.focus &&
    (props.isDark
      ? props.theme.modalInputFocusBorder
      : props.theme.inputFocusBorder)}
  input {
    padding: 0;
  }
`

const RowInputSmall = styled(RowInput)`
  height: 50px;
  height: 40px;
  width: 145px;
  margin-top: 0;
  background-color: ${(props) => props.isDark && 'rgba(0, 0, 0, 0.05)'};
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 15px;
  width: 100%
  `}
`

const StyledValueSelector = styled.div`
  cursor: pointer;
  width: 50px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 800;
  color: ${({ theme }) => theme.color.secondary.main};
  :hover {
    color: ${({ theme }) => theme.color.primary.main};
    box-shadow: ${({ theme }) => theme.shadows.hoveredBtn};
  }
  ${(props) =>
    props.active
      ? `
      color:${
        props.isDark
          ? props.theme.color.font.primary
          : props.theme.color.primary.main
      };
      pointer-events:none;
      box-shadow: ${!props.isDark && props.theme.shadows.inset};
      background-color: ${props.isDark && ' rgba(255, 255, 255, 0.3)'}
      `
      : `box-shadow: ${({ theme }) => theme.shadows.button};
    background-color: ${props.theme.color.background.main};`}
`
