import React, { useEffect, useState, useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'

import usePool from 'hooks/usePool'
import useWalletHook from 'hooks/useWallet'
import useSearchToken from 'hooks/useSearchToken'
import config from 'monox/config'
import { lpToken, SOCIALLINKS } from 'monox/constants'
import { AccountContext } from 'contexts/AccountProvider'

import Header from 'views/Analytics/components/Header'
import ChartAnalytics from 'views/Analytics/components/Chart'
import DataCard from 'views/Analytics/components/DataCard'
import { CloseIcon } from 'components/IconButton'
import StyledIconButton from 'components/StyledIconButton'

import { getAnalysisData } from 'api'

const AnalyticsModal = ({ payload, onDismiss }) => {
  const history = useHistory()
  const [data, setData] = useState({})
  const dialogWidth = 1200
  const [fetching, setFetching] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)
  const [isTokenInvalid, setIsTokenInvalid] = useState(false)
  const { chainId } = useWalletHook()
  const isDark = useSelector(({ application }) => application.isDark)
  const networkName = useSelector(({ network }) => network?.NAME)?.toLowerCase()
  const networkId = useSelector(({ network }) => network.id)
  const { poolsContract, infuraContract } = useContext(AccountContext)
  const [currency, setCurrency] = useState(null)
  const { onGetToken } = useSearchToken()

  const WRAPPED_MAIN_ADDRESS = config?.[networkId || chainId]?.WRAPPED_MAIN_ADDRESS

  const address = payload?.address ?? WRAPPED_MAIN_ADDRESS

  const { pool, poolValue, sharedPercent } = usePool(
    currency?.address || (!!currency?.symbol && WRAPPED_MAIN_ADDRESS)
  )

  const setToken = useCallback(async () => {
    const indexToRemove = address?.indexOf('?')
    const ts = await onGetToken(
      indexToRemove > 0 ? address.substring(0, indexToRemove) : address
    )

    if (ts && Array.isArray(ts) && ts.length > 0) {
      setIsTokenInvalid(false)
      const findIndex = ts.findIndex((item) => !!item.logoURI)
      if (findIndex > 0) {
        setCurrency(ts[findIndex])
      } else {
        setCurrency(ts?.[0])
      }
    } else if (ts?.isInvalidToken) {
      setCurrency(null)
      setIsTokenInvalid(true)
    }
  }, [address, onGetToken])

  useEffect(() => {
    if (address === lpToken?.address) {
      window.location.href = SOCIALLINKS?.vunitDoc
      return
    }
    if (address && (poolsContract || infuraContract)) {
      setToken()
    }
  }, [address, poolsContract, infuraContract])

  useEffect(() => {
    const fetchData = async () => {
      const now = dayjs()
      const start = now.subtract(1, 'day').unix()
      const res = await getAnalysisData(
        networkId || chainId,
        currency.address || WRAPPED_MAIN_ADDRESS,
        start
      )
      if (res && res.result) {
        setData(res.response)
      }
      setDataFetched(true)
      setFetching(false)
    }
    if (currency && !dataFetched && !fetching && (networkId || chainId)) {
      setFetching(true)
      fetchData()
    }
  }, [currency, dataFetched, fetching, chainId])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  const handleClose = () => {
    onDismiss()
  }

  return (
    <OutsideClickHandler onOutsideClick={handleClose}>
      <Modal>
        <ModalDialog dialogWidth={dialogWidth}>
          <ModalContent isDark={isDark}>
            <Content isDark={isDark}>
              <HelmetProvider>
                <Container>
                  <Helmet>
                    <title>Analytics | MonoX</title>
                  </Helmet>
                  <Div>
                    <Header
                      currency={currency}
                      pool={pool}
                      isTokenInvalid={isTokenInvalid}
                      modal={true}
                      onDismiss={onDismiss}
                    />
                    <CloseIcon
                      onClick={onDismiss}
                      style={{ padding: '5px 0 0 20px' }}
                      size="30"
                    />
                  </Div>
                  <Spacer height={45} />
                  {pool && pool?.price !== '0' && (
                    <DataCard
                      currency={currency}
                      pool={pool}
                      poolValue={poolValue}
                      sharedPercent={sharedPercent}
                      data={data}
                    />
                  )}
                  <Spacer height={45} />
                  <ChartAnalytics currency={currency} pool={pool} />
                  <Spacer height={60} />
                  <StyledIconButton
                    block
                    icon="arrow"
                    variant="primary"
                    onClick={() => {
                      onDismiss()
                      history.push({
                        pathname: `/analytics/${
                          address ?? WRAPPED_MAIN_ADDRESS
                        }?network=${networkName}`,
                        state: { backUrl: history.location.pathname },
                      })
                    }}
                    modal={true}
                    style={{
                      maxWidth: '450px',
                      fontSize: '16px',
                      fontWeight: 800,
                    }}
                    translateId="recommended.view"
                  >
                    View more details
                  </StyledIconButton>
                </Container>
              </HelmetProvider>
            </Content>
          </ModalContent>
        </ModalDialog>
      </Modal>
    </OutsideClickHandler>
  )
}

export default AnalyticsModal

const Spacer = styled.div`
  height: ${(props) => props.height ?? '80'}px;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 50px;
  `}
`
const Div = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`
const Container = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  max-width: 1067px;
  margin: 0 auto;
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
`

const ModalDialog = styled.div`
  height: calc(100% - 3.5rem);
  max-width: ${(props) => props.dialogWidth}px;
  margin: 1.75rem auto;
  @media (max-width: ${(props) => props.dialogWidth + 50}px) {
    margin: 1.75rem;
  }
`

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 39px;
  max-height: 100%;
  overflow: hidden;
  background: ${({ isDark, theme }) =>
    isDark
      ? `linear-gradient(
    to bottom right,
    #003736,
    #003c3b,
    #004e4d,
    #005351,
    #005957
  )`
      : theme.color.background.main};
`
const Content = styled.div`
  position: relative;
  flex: 1 1 auto;
  margin: 3rem 0;
  padding: 0 3rem;
  overflow-y: auto;
  height: 100%;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: ${(props) => props.theme.color.background.content};'};
  }
`
