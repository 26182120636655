import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Flex } from 'rebass'
import Spinner from 'react-svg-spinner'
import BigNumber from 'bignumber.js'
import uniqBy from 'lodash/uniqBy'
import { useIntl } from 'react-intl'
import { DiagonalArrowRightUpOutline } from '@styled-icons/evaicons-outline/DiagonalArrowRightUpOutline'

import useWallet from 'hooks/useWallet'
import useSearchToken from 'hooks/useSearchToken'
import useTokenBalance from 'hooks/useTokenBalance'
import usePoolList from 'hooks/usePoolList'
import useContributedPoolList from 'hooks/useContributedPoolList'
import useModal from 'hooks/useModal'
import { saveToken } from 'state/users/actions'
import { saveTokens, saveFilteredTokens } from 'state/tokens/actions'
import { uriToHttp } from 'monox/getTokenList'
import config from 'monox/config'
import { ubetToEth, weiToEthNum } from 'monox/constants'
import { toEllipsis, getEtherscanLink } from 'monox/util'
import { StyledExternalLink } from 'theme'
import { AccountContext } from 'contexts/AccountProvider'

import Label from 'components/Label'
import { RowBetween } from 'components/Row'
import { CloseIcon } from 'components/IconButton'
import ImportTokenModal from 'components/ImportTokenModal'
import { StyledResponsiveWrapper, StyledModal } from 'components/Modal'
import Spacer from 'components/Spacer'
import TokenImage from 'components/TokenImage'
import { Warnings } from 'views/Swapper/components/SwapperCard'

import search from 'assets/img/search.svg'
import darkModeSearch from 'assets/img/darkmode-search.svg'
import WarningImg from 'assets/img/alert-warning.svg'

const CurrencyItem = ({ index, data, disabled }) => {
  const {
    sortedData,
    poolList,
    handleCurrency,
    chainId,
    WRAPPED_MAIN_ADDRESS,
    poolWarning,
  } = data
  const networkId = useSelector(({ network }) => network.id)
  const isDark = useSelector(({ application }) => application.isDark)
  const vCASHData = config?.[networkId || chainId]?.VCASH
  const VCASH_ADDRESS = vCASHData?.address
  const token = sortedData[index]

  const poolData = poolList.find(
    (item) => item?.token?.toLowerCase() === token?.address?.toLowerCase()
  )

  const isActive =
    (poolData && poolData?.status) ||
    poolList?.length === 0 ||
    token?.address === VCASH_ADDRESS ||
    !token?.address
      ? true
      : false

  const { balance } = useTokenBalance(token)

  return (
    <>
      <CurrencyConatiner
        isDark={isDark}
        onClick={() => handleCurrency(token, isActive)}
        key={token?.address || 'eth'}
        disabled={disabled || !isActive}
        data-testid={`currency-container-${index}`}
      >
        <Flex
          flexDirection="row"
          alignItems="flex-start"
          style={{ padding: '11px 40px', minHeight: '56px' }}
        >
          <div style={{ width: '32px' }}>
            <TokenImage
              letter={token?.logoURI ? null : token?.symbol && token?.symbol?.[0]}
              src={token?.logoURI ? uriToHttp(token?.logoURI) : null}
              height="32"
              width="32"
            />
          </div>
          <Flex flexDirection="column" style={{ margin: '0 10px' }}>
            <Flex flexDirection="row" alignItems="center">
              <Label text={`${token?.name}`} weight={'bold'} size={14} />
            </Flex>
            <StyledLabel>
              <Label weight={'bold'} opacity="0.3" size={11} text={token?.symbol} />
              <Address isDark={isDark}>
                <Span>
                  &nbsp;
                  {token?.address && ` - `}
                  &nbsp;
                </Span>
                {token?.address ? `${toEllipsis(token?.address, 16)}` : ``}
                <StyledExternalLink
                  target="_blank"
                  className="etherscan-link"
                  href={getEtherscanLink(
                    networkId || chainId,
                    token?.address ?? WRAPPED_MAIN_ADDRESS,
                    'token'
                  )}
                  rel="noopener noreferrer"
                  style={{ justifyContent: 'left' }}
                >
                  <DiagonalArrowRight />
                </StyledExternalLink>
              </Address>
            </StyledLabel>
          </Flex>
          <div style={{ marginLeft: 'auto', alignSelf: 'center' }}>
            <Label
              weight={800}
              size={13}
              text={
                weiToEthNum(balance, token?.decimals) === 0
                  ? 0
                  : ubetToEth(balance, token?.decimals) < 0.0001
                  ? '<0.0001'
                  : ubetToEth(balance, token?.decimals) || 0
              }
            />
          </div>
        </Flex>
      </CurrencyConatiner>
      {(disabled || !isActive) && (
        <Warnings isDark={isDark} style={{ margin: '15px 40px' }}>
          <Contant>
            <Label
              size="13"
              weight="bold"
              color="#e28156"
              align="left"
              text={poolWarning}
            />
          </Contant>
          <img
            src={WarningImg}
            style={{
              position: 'absolute',
              bottom: '-12px',
              right: '-11px',
            }}
            alt="WarningImg"
          />
        </Warnings>
      )}
    </>
  )
}

const CurrencySelector = ({
  setCurrency,
  setIsDropdown,
  selected: selectedCurrencies = [],
  onDismiss,
  setSwitching = () => {},
  isSwitching = null,
  isOnLiquidity = false,
  isToCurrency = false,
  isFromCurrency = false,
}) => {
  const selected =
    isFromCurrency || isToCurrency ? [selectedCurrencies?.[0]] : selectedCurrencies

  const { chainId, account } = useWallet()
  const { getToken: getTokenFromContract } = useContext(AccountContext)
  const [placeholder, setPlaceholder] = useState(null)
  const [poolWarning, setPoolWarning] = useState(null)
  const [isTokenInvalid, setIsTokenInvalid] = useState(false)
  const networkId = useSelector(({ network }) => network.id)
  const isDark = useSelector(({ application }) => application.isDark)

  const WRAPPED_MAIN_ADDRESS = config?.[networkId || chainId]?.WRAPPED_MAIN_ADDRESS

  const MAIN_CURRENCY = config?.[networkId || chainId]?.MAIN_CURRENCY
  const MONOData = config?.[networkId || chainId]?.MONO
  const vCASHData = config?.[networkId || chainId]?.VCASH
  const tokens = useSelector(({ user }) => user.tokens)
  const intl = useIntl()

  const poolList = useSelector(({ pools }) => pools?.[networkId || chainId] ?? [])

  const contributedPoolList =
    useSelector(({ user }) => user?.activePools?.[networkId || chainId]) || []

  const networkName = useSelector(({ network }) => network?.NAME)
  const { getPoolData, loading } = usePoolList(false)
  const { getActivePoolData } = useContributedPoolList(false)
  const [searchText, setSearchText] = useState('')
  const [focus, setFocus] = useState(false)

  const allTokens = useSelector(
    ({ tokens }) => tokens.allTokens[networkId || chainId] ?? []
  )

  const filteredData = useSelector(
    ({ tokens }) => tokens.filteredTokens[networkId || chainId] ?? []
  )

  const balances = useSelector(
    ({ user }) => user.balances?.[networkId || chainId] ?? {}
  )

  const sortedData = filteredData.sort(function (a, b) {
    const balanceA = new BigNumber(balances[a?.address ?? account] ?? 0)
    const balanceB = new BigNumber(balances[b?.address ?? account] ?? 0)
    if (weiToEthNum(balanceA) < weiToEthNum(balanceB)) {
      return 1
    }
    if (weiToEthNum(balanceA) > weiToEthNum(balanceB)) {
      return -1
    }
    return 0
  })

  const [tokensLoading, setTokensLoding] = useState(false)
  const { onGetToken, filteredTokenList } = useSearchToken(true, allTokens)

  const dispatch = useDispatch()

  const close = () => {
    setIsDropdown(true)
    onDismiss()
  }

  const onClear = () => {
    onDismiss()
  }

  const [handleImportTokenModal] = useModal(<ImportTokenModal />)

  useEffect(() => {
    if (isOnLiquidity || !poolList?.length) {
      getPoolData()
      account && getActivePoolData()
    }
  }, [isOnLiquidity, account, networkId, chainId])

  useEffect(() => {
    setPlaceholder(
      intl.formatMessage({
        id: 'modal.swap.select.currency.search_bar',
        defaultMessage: 'Search name or paste address',
      })
    )
    setPoolWarning(
      intl.formatMessage({
        id: 'modal.swap.select.currency.pool.warning',
        defaultMessage: 'Pool is not created yet.',
      })
    )
  }, [intl])

  useEffect(() => {
    const currentChainTokens = tokens[networkId || chainId] ?? {}

    const customTokenList = Object.values(currentChainTokens).map((token) => ({
      ...token,
      status: token?.status ?? 1,
    }))

    setTokensLoding(true)
    const tempTokens = []
    if (poolList?.length) {
      poolList.forEach((pool) => {
        const fromList = filteredTokenList.find(
          (token) => token?.address?.toLowerCase() === pool?.token?.toLowerCase()
        )
        const isMONO = pool?.token === MONOData?.address
        const isSelected = selected?.some((token) => token?.address === pool?.token)
        if (!isSelected && fromList && !isMONO) {
          tempTokens.push({
            name: fromList?.name,
            symbol: fromList?.symbol,
            status: pool?.status,
            logoURI: fromList?.logoURI,
            notInList: fromList ? false : true,
            address: fromList?.address,
            chainId: fromList?.chainId,
            decimals: parseInt(fromList?.decimals),
          })
        }
        if (!isSelected && isMONO) {
          tempTokens.push({
            name: MONOData?.name,
            symbol: MONOData?.symbol,
            status: pool?.status,
            logoURI: MONOData?.logoURI,
            notInList: false,
            chainId: MONOData?.chainId,
            address: MONOData?.address,
            decimals: MONOData?.decimals,
          })
        }
      })
    }
    if (contributedPoolList?.length) {
      contributedPoolList.forEach(async (pool) => {
        const index = tempTokens?.findIndex(
          (token) => token?.address === pool?.token
        )
        const isSelected = selected?.some((token) => token?.address === pool?.token)
        if (index === -1 && pool?.token !== MONOData?.address && !isSelected)
          if (pool?.name && pool?.symbol) {
            tempTokens.push({
              name: pool?.name,
              symbol: pool?.symbol,
              status: pool?.status,
              logoURI: pool?.logoURI,
              notInList: true,
              chainId: networkId || chainId,
              address: pool?.token,
              decimals: parseInt(pool?.length),
            })
          } else {
            const searchedToken = await getTokenFromContract(pool?.token)
            const searchedTokenName = await searchedToken?.methods.name().call()
            const searchedTokenSymbol = await searchedToken?.methods.symbol().call()
            const searchedTokenDecimals = await searchedToken?.methods
              .decimals()
              .call()
            tempTokens.push({
              name: searchedTokenName,
              symbol: searchedTokenSymbol,
              logoURI: undefined,
              address: searchedToken?._address,
              notInList: true,
              chainId: networkId || chainId,
              status: 1,
              decimals: searchedTokenDecimals,
            })
          }
      })
    }
    const mainSelected = selected?.some(
      (token) => token?.symbol === MAIN_CURRENCY?.symbol
    )
    const filteredTokens = tempTokens?.filter(
      (token) => token?.chainId === networkId
    )
    const filteredCustomTokenList = customTokenList.filter((token) => {
      const isValidToken = poolList.find(
        (item) => item?.token?.toLowerCase() === token?.address?.toLowerCase()
      )
      const isSelected = selected?.some(
        (currency) => currency?.address === token?.address
      )
      return isValidToken && !isSelected
    })
    const temp = [...filteredTokens, ...filteredCustomTokenList]
    if (!mainSelected) {
      temp.push(MAIN_CURRENCY)
    }
    const vCASHSelected = selected?.some(
      (token) => token?.address === vCASHData?.address
    )
    if (!vCASHSelected && vCASHData && !isOnLiquidity) {
      temp.push(vCASHData)
    }
    const uniqTokens = uniqBy(temp, 'address')
    dispatch(saveTokens({ chainId: networkId || chainId, tokens: uniqTokens }))
    dispatch(
      saveFilteredTokens({
        chainId: networkId || chainId,
        tokens: uniqTokens,
      })
    )
    setTokensLoding(false)
  }, [poolList?.length, dispatch, contributedPoolList?.length, chainId])

  const getToken = useCallback(async () => {
    const tokenData = await onGetToken(searchText)
    if (Array.isArray(tokenData)) {
      setIsTokenInvalid(false)
      dispatch(
        saveFilteredTokens({ chainId: networkId || chainId, tokens: tokenData })
      )
    } else if (tokenData?.isInvalidToken) {
      setIsTokenInvalid(true)
      dispatch(saveFilteredTokens({ chainId: networkId || chainId, tokens: [] }))
    }
  }, [searchText, contributedPoolList?.length])

  useEffect(() => {
    if (searchText !== '') {
      getToken()
    }
  }, [searchText, contributedPoolList?.length])

  const handleCurrency = (value, isActive = true) => {
    if (!isActive) return
    const isWillSwitch = selectedCurrencies?.some(
      (token) =>
        (value?.address && token?.address === value?.address) ||
        (!value?.address && token?.symbol === value?.symbol)
    )
    const switchIndex = isWillSwitch ? 3 - isSwitching : 0
    if (value?.notInList) {
      if (value?.showWarning) {
        handleImportTokenModal({ currrency: value, close, setCurrency })
        !isOnLiquidity && setSwitching(switchIndex)
        return
      }
      dispatch(saveToken({ ...value }))
    }
    setCurrency(value, true)
    !isOnLiquidity && setSwitching(switchIndex)
    close()
  }

  const handelChangeSearchText = (e) => {
    const value = e?.target?.value
    const finalValue = value?.split(' ').join('')
    setSearchText(finalValue)
    if (!finalValue?.length) {
      dispatch(
        saveFilteredTokens({ chainId: networkId || chainId, tokens: allTokens })
      )
    }
  }

  return (
    <StyledResponsiveWrapper isDark={isDark}>
      <CustomStyledModal style={{ display: 'flex' }} isDark={isDark}>
        <Div isDark={isDark}>
          <StyledSearchContainer>
            <RowParent>
              <RowChild>
                <Label
                  text="Select a token"
                  weight={800}
                  size={16}
                  style={{ marginRight: 'auto' }}
                  translateId="modal.swap.select.currency.title"
                />
                <Spacer size="sm" />
              </RowChild>
              <CloseIcon onClick={onClear} data-testid="clear" />
            </RowParent>
            <StyledSearchBarWrapper focus={focus} isDark={isDark}>
              <StyledSearchBar
                id="searchInput"
                onChange={(e) => handelChangeSearchText(e)}
                placeholder={placeholder}
                type="text"
                autoFocus
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                data-testid="search-bar"
              />
              <img
                src={isDark ? darkModeSearch : search}
                style={{
                  marginRight: '25px',
                }}
                alt="darkModeSearch"
              ></img>
            </StyledSearchBarWrapper>
          </StyledSearchContainer>
          <ScrollContainer>
            {loading || (tokensLoading && filteredData.length === 0) ? (
              <SpinnerContainer>
                <Spinner size="40px" thickness={2} color="#2dc48f" />
              </SpinnerContainer>
            ) : (
              <>
                {sortedData?.map((data, index) => (
                  <CurrencyItem
                    key={index}
                    index={index}
                    className="token-container"
                    data={{
                      sortedData,
                      poolList,
                      handleCurrency,
                      chainId: networkId || chainId,
                      WRAPPED_MAIN_ADDRESS,
                      poolWarning,
                    }}
                  />
                ))}
                {isTokenInvalid && sortedData?.length === 0 && (
                  <Label
                    size="15"
                    weight="bold"
                    color="#2eca93"
                    align="center"
                    text={`Entered token address is not in ${networkName} network`}
                    style={{ marginBottom: 20 }}
                    translateId="modal.swap.select.currency.invalid_token"
                    values={{ networkName }}
                  />
                )}
              </>
            )}
          </ScrollContainer>
        </Div>
      </CustomStyledModal>
    </StyledResponsiveWrapper>
  )
}

export default CurrencySelector

const CustomStyledModal = styled(StyledModal)`
  padding: 0;
`
const Div = styled.div`
  margin: auto;
  background: ${(props) => !props.isDark && props.theme.color.background.main};
  padding: 20px 0;
  border-radius: 39px;
  height: 500px;
  transform-origin: 0 100%;
  overflow: hidden;
  width: 450px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 400px
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 350px
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 300px
  `}
`

const StyledLabel = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `}
`

const Span = styled.span`
  display: block;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

const StyledSearchContainer = styled.div`
  margin-top: 1rem;
  position: sticky;
  top: 0;
  padding: 0 40px;
`
const SpinnerContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 4.5rem;
`
const ScrollContainer = styled.div`
  margin-top: 10px;
  height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.scroll};
    border-radius: 10px;
  }
`

const StyledSearchBarWrapper = styled.div`
  border-style: solid;
  border-width: 1.5px;
  border-color: transparent;
  display: flex;
  align-items: center;
  margin: 29px 0 26px 0;
  height: 45px;
  box-shadow: ${({ theme }) => theme.shadows.inset};
  border-radius: 39px;
  ${(props) =>
    props.focus &&
    (props.isDark
      ? props.theme.modalInputFocusBorder
      : props.theme.inputFocusBorder)}
  ${(props) => props.isDark && `background-color: rgba(0, 0, 0, 0.1);`}
`

const StyledSearchBar = styled.input`
  width: 100%;
  margin-left: 25px;
  border: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 800;
  background: unset;
  height: inherit;
  outline: none;
  color: ${({ theme }) => theme.color.font.secondary};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.color.font.secondary};
    opacity: 0.3;
    font-size: 14px;
  }
`

const Address = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: 11px;
  font-weight: bold;
  display: flex;
  opacity: 0.3;
  :hover {
    color: ${({ theme }) => theme.color.font.primary};
    opacity: 1;
    .etherscan-link {
      display: inline-block;
    }
  }
  .etherscan-link {
    display: none;
  }
`
const DiagonalArrowRight = styled(DiagonalArrowRightUpOutline)`
  width: 15px;
  height: 15px;
  margin-left: 0px !important;
  display: none;
`
const CurrencyConatiner = styled.div`
  background: ${(props) => !props.isDark && props.theme.color.background.main};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled, selected }) => (disabled || selected ? 0.5 : 1)};
  :hover {
    background-color: rgba(45, 196, 143, 0.05);
    border-left: 4px solid ${(props) => props.theme.color.border.green};
  }
  :hover ${Address} {
    color: #2eca93;
    opacity: 1;
  }
  :hover ${DiagonalArrowRight} {
    display: block;
  }
`

const RowParent = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  align-items: flex-start;
  `}
`

const RowChild = styled(RowBetween)`
  align-items: baseline;
  flex-wrap: wrap;
  div {
    margin-bottom: 10px;
  }
`
const Contant = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 50px 20px 25px;
`
