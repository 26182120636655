import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { SOCIALLINKS } from 'monox/constants'

import Label from 'components/Label'
import { Row } from 'components/Row'

import TwitterIcon from 'assets/img/twitter.svg'
import TelegramIcon from 'assets/img/telegram.svg'
import MediumIcon from 'assets/img/medium.svg'
import DiscordIcon from 'assets/img/Discord-Logo-White.svg'
import WhitePaperIcon from 'assets/img/white-paper.svg'
import { links } from 'theme/config'

const SocialShare = ({ text, src, onClick }) => {
  const isDark = useSelector(({ application }) => application.isDark)

  return (
    <>
      <Row style={{ padding: '13px 0', cursor: 'pointer' }} onClick={onClick}>
        <Label text={text} size="14" weight="800" color="#ffffff" />
        <img
          src={src}
          style={{ marginLeft: 'auto' }}
          height="19"
          alt="SocialShare"
        />
      </Row>
      <Line isDark={isDark} />
    </>
  )
}

const MobileFooter = () => {
  const isDark = useSelector(({ application }) => application.isDark)

  return (
    <Div isDark={isDark}>
      <Label
        text="The interest rates and APYs may change. The APYs were accurate as of 10/20/2021"
        size="14"
        weight="800"
        opacity={isDark ? 0.5 : 1}
        color="#ffffff"
        align="center"
        style={{ padding: '0 26px 30px 26px' }}
        translateId="common.disclaimer"
      />
      <Line isDark={isDark} />
      <SocialShare
        src={WhitePaperIcon}
        text="Docs"
        onClick={() => {
          window.open(SOCIALLINKS?.lightPaper)
        }}
      />
      <SocialShare
        src={MediumIcon}
        text="Medium"
        onClick={() => {
          window.open(SOCIALLINKS?.medium)
        }}
      />
      <SocialShare
        src={TwitterIcon}
        text="Twitter"
        onClick={() => {
          window.open(SOCIALLINKS?.twitter)
        }}
      />
      <SocialShare
        src={TelegramIcon}
        text="Telegram"
        onClick={() => {
          window.open(SOCIALLINKS?.telegram)
        }}
      />
      <SocialShare
        src={DiscordIcon}
        text="Discord"
        onClick={() => {
          window.open(SOCIALLINKS?.discord)
        }}
      />
      <Label
        text="© 2022 All rights reserved."
        size="14"
        weight="bold"
        color="#ffffff"
        align="center"
        style={{ paddingTop: '50px' }}
        translateId="common.copyright"
      />
      <SpaceBox />
    </Div>
  )
}

export default MobileFooter

const Div = styled.div`
  display: none;
  z-index: 1;
  padding: 30px 44px;
  background: ${({ theme }) => theme.color.background.footer};
  margin-top: 56px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display:block;
  `}
`
const Line = styled.div`
  opacity: ${(props) => (props.isDark ? '0.75' : '0.4')};
  border-bottom: solid 1px
    ${(props) => (props.isDark ? 'rgba(216, 216, 216, 0.15)' : '#ffffff')};
`
const SpaceBox = styled.div`
  height: 70px;
`
