import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import config from 'monox/config'
import { arrayToObject } from 'monox/util'
import useSearchToken from 'hooks/useSearchToken'
import useWindowSize from 'hooks/useWindowSize'
import useWallet from 'hooks/useWallet'

import Spacer from 'components/Spacer'

import TokenData from 'views/Home/components/TokenData'
import Divider from 'components/Divider'

import searchDark from 'assets/img/white-search.svg'
import searchLight from 'assets/img/home-search.svg'
import BigNumber from 'bignumber.js'
import { weiToEthNum } from 'monox/constants'
import { Row } from 'components/Row'

const SearchBar = ({ setIsSearched }) => {
  const { width } = useWindowSize()

  const { onGetToken } = useSearchToken(true, [], true)
  const { chainId, account } = useWallet()
  const networkId = useSelector(({ network }) => network.id)
  const isDark = useSelector(({ application }) => application.isDark)
  const balances = useSelector(
    ({ user }) => user.balances?.[networkId || chainId] ?? {}
  )
  const WRAPPED_MAIN_ADDRESS = config?.[networkId || chainId]?.WRAPPED_MAIN_ADDRESS
  const [placeholder, setPlaceholder] = useState(null)
  const [filteredData, setFilteredData] = useState([])
  const [searchText, setSearchText] = useState('')
  const intl = useIntl()

  const poolList = useSelector(({ pools }) => pools?.[networkId || chainId] ?? [])

  const poolTokens = arrayToObject(poolList)

  const getToken = useCallback(async () => {
    const tokenData = await onGetToken(searchText)
    if (Array.isArray(tokenData)) {
      const sortData = tokenData
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(
          (item) =>
            item?.chainId === networkId &&
            poolTokens?.[item?.address || WRAPPED_MAIN_ADDRESS]
        )
      setFilteredData(sortData)
    } else {
      setFilteredData([])
    }
  }, [searchText])

  const sortedData = filteredData.sort(function (a, b) {
    const balanceA = new BigNumber(balances[a?.address ?? account] ?? 0)
    const balanceB = new BigNumber(balances[b?.address ?? account] ?? 0)
    if (weiToEthNum(balanceA) < weiToEthNum(balanceB)) {
      return 1
    }
    if (weiToEthNum(balanceA) > weiToEthNum(balanceB)) {
      return -1
    }
    return 0
  })

  useEffect(() => {
    if (width > 1450) {
      setIsSearched(false)
    }
  }, [width])

  useEffect(() => {
    if (searchText !== '') {
      getToken()
    } else {
      setFilteredData([])
    }
  }, [searchText])

  useEffect(() => {
    setPlaceholder(
      intl.formatMessage({
        id: 'swap.search_bar',
        defaultMessage: 'Search by token name, symbol, or contract address',
      })
    )
  }, [intl])

  const handleBlur = () => {
    setFilteredData([])
    setIsSearched(false)
    setSearchText('')
  }

  return (
    <Div>
      <OutsideClickHandler onOutsideClick={handleBlur}>
        <div>
          <Row style={{ marginBottom: '8px' }}>
            <img src={isDark ? searchDark : searchLight} alt="searchLight" />
            <StyledInput
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
              placeholder={placeholder}
              value={searchText}
              searchText={searchText}
              data-testid="searchbar"
            />
          </Row>
          <Divider />
        </div>
        {searchText && (
          <DropDown isDark={isDark}>
            <div style={{ margin: '31px' }}>
              <Row style={{ marginBottom: '8px' }}>
                <img src={isDark ? searchDark : searchLight} alt="searchLight" />
                <StyledInput
                  onChange={(e) => {
                    setSearchText(e.target.value)
                  }}
                  placeholder={placeholder}
                  value={searchText}
                  searchText={searchText}
                  autoFocus
                  data-testid="searchbar"
                />
              </Row>
              <Divider />
            </div>
            <ScrollContainer isDark={isDark}>
              {sortedData.map((token, index) => (
                <TokenData
                  token={token}
                  index={index}
                  key={index}
                  isHome={false}
                  setIsSearched={setIsSearched}
                  sortedData={sortedData}
                  nav={true}
                />
              ))}
            </ScrollContainer>
            <Spacer />
          </DropDown>
        )}
      </OutsideClickHandler>
    </Div>
  )
}

export default SearchBar
const Div = styled.div`
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`
const DropDown = styled.div`
  position: absolute;
  width: 100%;
  top: -17px;
  z-index: 15;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  box-shadow: 0 25px 40px 0 rgba(14, 19, 41, 0.2);
  background-color: ${({ isDark }) =>
    isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.5)'};
`
const ScrollContainer = styled.div`
  height: 370px;
  margin-top: 0.5rem;
  margin-bottom: 5px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: ${(props) =>
      props.isDark ? 'rgba(255,255,255,0.4)' : 'rgba(33, 45, 99, 0.4)'};
  }
`

const StyledInput = styled.input`
  width: ${({ searchText }) => (searchText ? 420 : 350)}px;
  @media (max-width: 1300px) {
    width: 350px;
  }
  @media (max-width: 1110px) {
    width: 300px;
  }
  @media (max-width: 1050px) {
    width: 270px;
  }
  @media (max-width: 980px) {
    width: 260px;
  }
  margin-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  appearance: textfield;
  font-weight: ${(props) => (props.size === 'sm' ? 'bold' : 'normal')};
  flex: 1;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 800;
  text-align: left;
  color: ${({ theme }) => theme.color.secondary.main};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding-right:5px;
  width: 300px;
  `}
  ::placeholder {
    color: ${({ theme }) => theme.color.secondary.main};
    opacity: 0.3;
    font-size: 14px;
    font-weight: 800;
  }
`
