import React, { useRef, useState, useEffect, useContext } from 'react'
import Web3 from 'web3'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { AccountContext } from 'contexts/AccountProvider'
import useWindowSize from 'hooks/useWindowSize'
import useWallet from 'hooks/useWallet'
import { targetTimeStamp } from 'monox/constants'
import { timeDifference, timeDifferenceFormat, precise } from 'monox/util'
import { consentSignature } from 'api'

import Spacer from 'components/Spacer'
import Label from 'components/Label'
import { CloseIcon } from 'components/IconButton'
import Checkbox from 'components/CheckBox'
import Modal from 'components/Modal'
import { Row, RowBetween, RowFixed } from 'components/Row'
import StyledIconButton from 'components/StyledIconButton'
import { Warnings } from 'views/Swapper/components/SwapperCard'

import WarningUser from 'assets/img/warnig-user.svg'
import WarningUserConsent from 'assets/img/combined-shape.svg'

const PromoModalUser = ({ onDismiss }) => {
  const { setIsHackedUser, isHackedUser, userHackedData } =
    useContext(AccountContext)
  const { ethereum, account, chainId } = useWallet()

  const modalRef = useRef(null)
  const [checked, setChecked] = useState(false)
  const [remainedTime, setRemainedTime] = useState(null)
  const { width, height } = useWindowSize()
  const top =
    height > modalRef?.current?.clientHeight
      ? (height - modalRef?.current?.clientHeight) / 2
      : 0
  const isDark = useSelector(({ application }) => application.isDark)

  const calculateRealTimeRemained = () => {
    const remainedBySeconds = targetTimeStamp - Date.now() / 1000
    if (remainedBySeconds > 0) {
      const remained = timeDifferenceFormat(timeDifference(remainedBySeconds))
      setRemainedTime(remained)
    }
  }

  const handleAccpet = async () => {
    if (!ethereum || !account) return
    const web3 = new Web3(ethereum)
    let totalAmount = 0
    const consentList = userHackedData.map((item) => {
      totalAmount += item.totalvalue
      return `\t${item?.token_symbol}: $${precise(item.totalvalue, 4)}`
    })
    const message = `Dear ${account?.slice(0, 6)}...${account?.slice(-4)}\n
We owe you:\n
${consentList?.join('\n')}\n
Total amount owed: $${precise(totalAmount, 4)}\n
Signing this transaction means you consent to accepting dMONO terms and conditions displayed on the MonoX website.
    `
    const signature = await web3.eth.personal.sign(message, account, '')
    if (signature) {
      const payload = {
        signature,
        message,
        wallet: account,
        amount: totalAmount,
      }
      consentSignature(payload, chainId).then((res) => {
        if (res?.result && res.wallet) setIsHackedUser(false)
        onDismiss()
      })
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (true) {
        calculateRealTimeRemained()
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const RewardsModal = ({ responsive }) => (
    <Modal
      maxWidth="580"
      style={{ margin: '1rem auto', display: 'block' }}
      responsive={responsive}
    >
      {!isHackedUser ? (
        <>
          <RowBetween style={{ alignItems: 'center', marginBottom: '20px' }}>
            <RowFixed>
              <Label
                text="Terms and condition"
                size="16px"
                weight="800"
                translateId="rewards.modal.title"
              />
            </RowFixed>
            <RowFixed style={{ alignItems: 'center' }}>
              <RowFixed>
                <CloseIcon onClick={onDismiss} data-testid="dismiss" />
              </RowFixed>
            </RowFixed>
          </RowBetween>
          <WarningsContent isDark={isDark}>
            <Contant>
              <Label
                text="You have agreed to dMONO terms and conditions,
                Your dMONO will be airdropped after countdown period is finished."
                size="13"
                weight="bold"
                color="#e28156"
                align="left"
                translateId="consent.modal.content"
              />
            </Contant>
          </WarningsContent>
          {remainedTime && (
            <Warnings isDark={isDark}>
              <Contant>
                <Label
                  text={`${remainedTime} before consent end date`}
                  size="13"
                  weight="bold"
                  color="#e28156"
                  align="left"
                  translateId="rewards.modal.time"
                  values={{
                    remainedTime,
                  }}
                />
              </Contant>
              <img
                src={WarningUserConsent}
                style={{
                  position: 'absolute',
                  bottom: '2px',
                  right: '-11px',
                }}
                alt="WarningImg"
              />
            </Warnings>
          )}
          <Spacer />
          <Row>
            <StyledIconButton
              shadow
              variant="primary"
              icon="arrow"
              onClick={onDismiss}
              block={true}
              translateId="modal.common.transaction.reject.button"
            >
              dismiss
            </StyledIconButton>
          </Row>
        </>
      ) : (
        <>
          <RowBetween style={{ alignItems: 'center', marginBottom: '20px' }}>
            <RowFixed>
              <Label
                text="Terms and condition"
                size="16px"
                weight="800"
                translateId="rewards.modal.title"
              />
            </RowFixed>
            <RowFixed style={{ alignItems: 'center' }}>
              <RowFixed>
                <CloseIcon onClick={onDismiss} data-testid="dismiss" />
              </RowFixed>
            </RowFixed>
          </RowBetween>
          <WarningsContent isDark={isDark}>
            <Contant>
              <Label
                text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                size="13"
                weight="bold"
                color="#e28156"
                align="left"
              />
            </Contant>
          </WarningsContent>
          {remainedTime && (
            <Warnings isDark={isDark}>
              <Contant>
                <Label
                  text={`You have ${remainedTime} to complete your consent.`}
                  size="13"
                  weight="bold"
                  color="#e28156"
                  align="left"
                  translateId="rewards.modal.time"
                  values={{
                    remainedTime,
                  }}
                />
              </Contant>
              <img
                src={WarningUser}
                style={{
                  position: 'absolute',
                  bottom: '-31px',
                  right: '-11px',
                }}
                alt="WarningImg"
              />
            </Warnings>
          )}
          <CustomRow>
            <StyledIconButton
              shadow
              variant="primary"
              icon="arrow"
              onClick={onDismiss}
              block={width < 960 ? true : false}
              translateId="rewards.modal.decline"
            >
              Decline
            </StyledIconButton>
            <Spacer />
            <StyledIconButton
              shadow
              variant="primary"
              icon="arrow"
              onClick={handleAccpet}
              block={width < 960 ? true : false}
              translateId="rewards.modal.accept"
            >
              Accept
            </StyledIconButton>
          </CustomRow>
          <Spacer />
          <Row>
            <Checkbox
              label="Don’t show me again"
              value={checked}
              checked={checked}
              onChange={() => setChecked(!checked)}
              translateId="rewards.modal.hide"
              variant="secondary"
              size="16"
            />
          </Row>
        </>
      )}
    </Modal>
  )

  return (
    <>
      <ModalScrollView top={top}>
        <ModalCenter ref={modalRef}>
          <RewardsModal responsive={false} />
        </ModalCenter>
      </ModalScrollView>
    </>
  )
}

export default PromoModalUser

const ModalScrollView = styled.div`
  position: fixed;
  top: ${(props) => props.top}px;
  left: 0;
  z-index: 1060;
  display: none;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  height: -webkit-fill-available;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: block;
  padding-left: 0px;
  transition: opacity 0.15s linear;
`
const ModalCenter = styled.div`
  padding: 2rem;
  @media (max-width: 415px) {
    padding: 2rem 1rem;
  }
`

const CustomRow = styled(RowBetween)`
  margin-top: 25px;
  @media (max-width: 644px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
`

const Contant = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 50px 20px 25px;
`

export const WarningsContent = styled.div`
  border-left: 5px solid #41dea2;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: ${(props) =>
    props?.isDark ? 'rgba(226, 129, 86, 0.15)' : '#f4eded'};
  min-height: 80px;
  display: flex;
  position: relative;
  overflow: hidden;
  margin-top: 30px;
  ul {
    padding: 30px 20px;
    margin-block-start:0;
    margin-block-end:0;
    padding-inline-start: 18px;
    list-style: none;
    }
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  height: auto;
  padding:.5rem 0;
  `}
`
