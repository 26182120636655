import {
  SET_BOND_DISCOUNTS,
  SET_BOND_PURCHASE,
  SET_BOND_TREASURY,
  SET_BOND_TREASURY_FROM_API,
  CLEAR_BOND_DATA_LIST,
} from './constants'

export function setBondDiscounts(payload) {
  return { type: SET_BOND_DISCOUNTS, payload }
}

export function setBondPurchase(payload) {
  return { type: SET_BOND_PURCHASE, payload }
}

export function setBondTotalTreasury(payload) {
  return { type: SET_BOND_TREASURY, payload }
}

export function setBondTotalTreasuryFromAPI(payload) {
  return { type: SET_BOND_TREASURY_FROM_API, payload }
}

export function clearBondDataList(payload) {
  return { type: CLEAR_BOND_DATA_LIST, payload }
}
