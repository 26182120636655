import { CHANGE_NETWORK, CLEAR_NETWORK } from 'state/network/constants'
import { DEFAULT_NETWORK_ID } from 'monox/constants'
import config from 'monox/config'

const initialState = {
  id: DEFAULT_NETWORK_ID,
  ...config[DEFAULT_NETWORK_ID],
}

const networkReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_NETWORK:
      return {
        ...state,
        id: action.payload.network,
        ...action.payload.networkDetails,
      }
    case CLEAR_NETWORK:
      return {
        id: state?.id,
        ...config[state?.id],
      }
    default:
      return state
  }
}

export default networkReducer
