import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Spinner from 'react-svg-spinner'
import PrivateKeyProvider from 'truffle-privatekey-provider'
import { FormattedMessage } from 'react-intl'
import Web3 from 'web3'
import MobileDetect from 'mobile-detect'

import useModal from 'hooks/useModal'
import useWallet from 'hooks/useWallet'
import { TRANSACTION_STATUS } from 'monox/util'
import config from 'monox/config'
import { networks, supportedChainIds } from 'monox/constants'
import { changeNetwork } from 'state/network/actions'
import { savePrivateKey } from 'state/users/actions'
import { saveProvider } from 'state/provider/actions'

import Button from 'components/Button'
import AccountModal from 'components/TopBar/components/AccountModal'
import WalletListModal from 'components/WalletListModal'

const useQuery = (history) => {
  return new URLSearchParams(history?.location?.search)
}

const AccountButton = ({ isSidebar, mobile }) => {
  const [handleUnlockClick] = useModal(<WalletListModal />)
  const wallet = useSelector(({ user }) => user.wallet)
  const privateKey = useSelector(({ user }) => user.privateKey)
  const networkId = useSelector(({ network }) => network.id)
  const NETWORK_URL = useSelector(({ network }) => network.NETWORK_URL)
  const history = useHistory()
  const query = useQuery(history)
  const dispatch = useDispatch()
  const md = new MobileDetect(window.navigator.userAgent)
  const [onPresentAccountModal] = useModal(<AccountModal history={history} />)
  const networkQuery = query.get('network')

  const networksArray = Object.keys(networks).map((key) =>
    Object.assign(networks[key], { chainId: parseInt(key) })
  )

  const { account, connect, error } = useWallet()
  const transactionsData = useSelector(({ transactions }) => transactions)
  const transactions = transactionsData[networkId] ?? []

  const pendingTransactions = useMemo(() => {
    return transactions.filter((tx) => tx.status === TRANSACTION_STATUS.PENDING)
  }, [transactions])

  let { ethereum } = window

  const switchChain = async (chainId) => {
    const hexChainId = '0x' + Number(chainId).toString(16)
    const network = networks[chainId]
    const data = [
      {
        chainId: hexChainId,
        chainName: network.name,
        nativeCurrency: {
          name: config[chainId].MAIN_CURRENCY.name,
          symbol: config[chainId].MAIN_CURRENCY.symbol,
          decimals: 18,
        },
        rpcUrls: [config[chainId].NETWORK_URL],
        blockExplorerUrls: [network.blockExplorer],
      },
    ]
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainId }],
      })
      account && window.location.reload()
    } catch (switchErr) {
      if (process.env.REACT_APP_DEV_ENV === 'development') {
        console.log(switchErr)
      }
      if (switchErr?.code !== 4001) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: data,
          })
          window.location.reload()
        } catch (addError) {}
      }
    }
  }

  const handleNetworkChange = (id) => {
    dispatch(changeNetwork({ network: id, networkDetails: config[id] }))
    if (id !== networkId && account) {
      switchChain(id)
    }
    if (privateKey) {
      const provider = new PrivateKeyProvider(privateKey, config[id].NETWORK_URL)
      dispatch(savePrivateKey({ id: id, privateKey: privateKey }))
      dispatch(saveProvider(provider))
    } else {
      const new_provider = !!config[id].WSS_URL
        ? new Web3.providers.WebsocketProvider(config[id].WSS_URL, {
            reconnect: {
              auto: true,
              delay: 3000, // ms
              maxAttempts: 10,
              onTimeout: false,
            },
          })
        : new Web3.providers.HttpProvider(NETWORK_URL)
      dispatch(savePrivateKey({ id: id, privateKey: undefined }))
      dispatch(saveProvider(new_provider))
      !!wallet && connect(wallet)
    }
  }

  useEffect(() => {
    if (networkQuery) {
      const networkFromQuery = networksArray.find(
        (network) => network?.name.toLowerCase() === networkQuery
      )
      if (
        networkFromQuery &&
        networkFromQuery?.chainId !== networkId &&
        supportedChainIds.includes(networkFromQuery?.chainId)
      ) {
        !md.mobile() && handleNetworkChange(networkFromQuery?.chainId)
      }
    }
  }, [networkQuery])

  const address = account
    ? account
    : error?.name === 'UnsupportedChainIdError'
    ? ethereum?.selectedAddress
    : null

  return (
    <Div>
      <StyledAccountButton>
        {!address ? (
          <Button
            onClick={handleUnlockClick}
            size="sm"
            text="Connect Wallet"
            translateId="common.wallet.button.not_connected"
            testId="unlock-wallet"
            style={{ whiteSpace: 'noWrap' }}
          />
        ) : pendingTransactions.length > 0 ? (
          <ButtonPending
            onClick={onPresentAccountModal}
            size="sm"
            data-testid="transaction-pending"
          >
            {pendingTransactions.length}{' '}
            <FormattedMessage
              id="common.button.transaction.pending"
              defaultMessage="Pending"
            />
            <Spinner color="#ffffff" />
          </ButtonPending>
        ) : (
          <Button
            onClick={onPresentAccountModal}
            size="sm"
            testId="account-modal"
            text={`${address?.slice(0, 6)}...${address?.slice(-4)}`}
            account={true}
          ></Button>
        )}
      </StyledAccountButton>
    </Div>
  )
}

const Div = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const StyledAccountButton = styled.div`
  button {
    height: 37px;
  }
`

const ButtonPending = styled.button`
  width: 140px;
  height: 31px;
  border-radius: 4px;
  background-color: #abb1c5;
  box-shadow: ${({ theme }) => theme.shadows.topbar};
  border: unset;
  font-size: 13px;
  font-weight: 800;
  color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 5px;
  }
`

export default AccountButton
