import React from 'react'

import Label from 'components/Label'
import Modal from 'components/Modal'
import { RowBetween, RowFixed, Row, RowCenter } from 'components/Row'
import { CloseIcon } from 'components/IconButton'
import StyledIconButton from 'components/StyledIconButton'
import Spacer from 'components/Spacer'

import NoPoolImg from 'assets/img/nopool.png'

const NoPoolModal = ({ onDismiss }) => {
  return (
    <Modal width="450">
      <RowBetween style={{ alignItems: 'center' }}>
        <RowFixed>
          <Label
            text="Pool doesn't exist"
            size="16px"
            weight="800"
            translateId="modal.pool.unavailable.title"
          />
        </RowFixed>
        <RowFixed style={{ alignItems: 'center' }}>
          <RowFixed>
            <CloseIcon onClick={onDismiss} data-testid="dismiss" />
          </RowFixed>
        </RowFixed>
      </RowBetween>
      <Row
        style={{
          marginTop: '41px',
          justifyContent: 'center',
          alignItems: 'baseline',
        }}
      >
        <img
          src={NoPoolImg}
          alt="no pool img"
          style={{ width: '230px', height: '230px' }}
        />
      </Row>
      <RowCenter style={{ alignItems: 'center', marginTop: '20px' }}>
        <StyledIconButton
          block
          shadow
          variant="primary"
          icon="arrow"
          onClick={onDismiss}
          translateId="modal.pool.unavailable.button"
          testId="close"
        >
          Close
        </StyledIconButton>
      </RowCenter>
      <Spacer size="sm" />
    </Modal>
  )
}

export default NoPoolModal
