import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { UnknownTokenIcon } from 'components/IconButton'

const TokenImage = ({
  src,
  style,
  width = 45,
  height = 45,
  letter,
  resize = false,
}) => {
  const isDark = useSelector(({ application }) => application.isDark)

  return (
    <ImageContainer
      style={style}
      height={height}
      width={width}
      letter={letter}
      src={src}
      isDark={isDark}
      resize={resize}
    >
      {src && src !== 'undefined' ? (
        <Image height={height} width={width} src={src} />
      ) : letter ? (
        <Div>{letter}</Div>
      ) : (
        <UnknownTokenIcon height={height} width={width} />
      )}
    </ImageContainer>
  )
}

export default TokenImage

const Div = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
  color: #3fd69c;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ImageContainer = styled.div`
  border: ${(props) =>
    props.src && props.src !== 'undefined'
      ? `-0.5px dashed ${props.theme.color.font.green}`
      : props?.letter && props?.isDark
      ? `-0.5px dashed ${props.theme.color.font.green}`
      : `0.5px dashed ${props.theme.color.font.green}`};

  object-fit: contain;
  background-color: ${({ theme }) => theme.color.background.token};

  border-radius: 100%;
  height: ${(props) =>
    props.resize && props?.letter && !props?.isDark
      ? props.height - 2
      : props.height}px;
  width: ${(props) =>
    props.resize && props?.letter && !props?.isDark
      ? props.height - 2
      : props.width}px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Image = styled.img`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  border-radius: 100%;
`
