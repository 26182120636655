import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Web3 from 'web3'

import { addTransaction } from 'state/transaction/actions'
import { updateApplication } from 'state/applications/actions'
import { updateSwapTransaction } from 'state/swap/actions'
import useWallet from 'hooks/useWallet'
import { ACTIONS, TRANSACTION_STATUS } from 'monox/util'
import { PENDING_TX_FETCH_INTERVAL } from 'monox/constants'

export function shouldCheck(tx) {
  return tx.status === TRANSACTION_STATUS.PENDING
}

export default function TransactionUpdater() {
  const { chainId, ethereum } = useWallet()

  const dispatch = useDispatch()
  const transactions = useSelector((state) => state.transactions)

  const pendingTxs = useMemo(() => {
    return transactions[chainId]?.filter((tx) => shouldCheck(tx))
  }, [transactions, chainId])

  const checkTransactions = () => {
    if (!chainId || !ethereum || !transactions[chainId]) return
    const web3 = new Web3(ethereum)

    transactions[chainId].map((transaction) => {
      if (shouldCheck(transaction)) {
        web3.eth
          .getTransactionReceipt(transaction.tx)
          .then((receipt) => {
            if (receipt && receipt.status) {
              dispatch(
                addTransaction({
                  ...transaction,
                  status: TRANSACTION_STATUS.SUCCESS,
                  tx: receipt.transactionHash,
                })
              )

              if (transaction?.type === ACTIONS.APPROVE) {
                dispatch(updateApplication())
              }
            } else if (receipt && !receipt.status) {
              dispatch(
                addTransaction({
                  ...transaction,
                  status: TRANSACTION_STATUS.FAIL,
                })
              )
              if (
                transaction?.type === 'SWAP' &&
                transaction?.type === 'WRAP' &&
                transaction?.type === 'UNWRAP'
              ) {
                dispatch(
                  updateSwapTransaction({
                    isPerforming: false,
                    isRejected: true,
                    successResult: undefined,
                    successEnded: false,
                  })
                )
              }
            }
          })
          .catch((error) => {
            dispatch(
              addTransaction({
                ...transaction,
                status: TRANSACTION_STATUS.FAIL,
              })
            )
            if (
              transaction?.type === 'SWAP' &&
              transaction?.type === 'WRAP' &&
              transaction?.type === 'UNWRAP'
            ) {
              dispatch(
                updateSwapTransaction({
                  isPerforming: false,
                  isRejected: true,
                  successResult: undefined,
                  successEnded: false,
                })
              )
            }
          })
      }
    })
  }

  useEffect(() => {
    checkTransactions()
  }, [chainId, ethereum, transactions])

  useEffect(() => {
    if (Array.isArray(pendingTxs) && pendingTxs?.length > 0) {
      const interval = setInterval(() => {
        checkTransactions()
      }, PENDING_TX_FETCH_INTERVAL)
      return () => clearInterval(interval)
    }
  }, [pendingTxs])

  useEffect(() => {
    window.addEventListener('visibilitychange', checkTransactions)

    return () => {
      window.removeEventListener('visibilitychange', checkTransactions)
    }
  }, [])

  return null
}
