import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow'
import { toast } from 'react-toastify'

import useWallet from 'hooks/useWallet'
import { MAX_GAS_ESTIMATE } from 'monox/constants'
import config from 'monox/config'

import Label from 'components/Label'
import { Row, RowFixed } from 'components/Row'
import Input from 'components/Input'
import TokenImage from 'components/TokenImage'
import Spacer from 'components/Spacer'
import { NotEnoughGasToast } from 'components/ToastPopup'

const PriceInput = ({
  text,
  amount,
  handleChangeAmount,
  currency,
  toggle = false,
  balance,
  balanceDetail = 0,
  showBalance = true,
  showMax = false,
  showRight = true,
  lessThan = false,
  testId = 'price-input',
  testToggleId = 'toggle',
  translateId,
}) => {
  const { chainId, account } = useWallet()
  const networkId = useSelector(({ network }) => network.id)
  const [isSelector, setIsSelector] = useState(toggle ? true : false)
  const [maxClicked, setMaxClicked] = useState(false)
  const [focus, setFocus] = useState(false)
  const isDark = useSelector(({ application }) => application.isDark)
  const MAIN_CURRENCY = config?.[networkId || chainId]?.MAIN_CURRENCY

  useEffect(() => {
    setMaxClicked(false)
  }, [currency])

  useEffect(() => {
    if (
      (currency?.address ? amount : parseFloat(amount) + MAX_GAS_ESTIMATE) <
      balanceDetail
    ) {
      setMaxClicked(false)
    } else if (amount) {
      setMaxClicked(true)
    }
  }, [amount])

  const handleMaxClick = () => {
    if (currency?.address) {
      handleChangeAmount(balanceDetail)
    } else {
      const value =
        balanceDetail >= MAX_GAS_ESTIMATE ? balanceDetail - MAX_GAS_ESTIMATE : 0
      handleChangeAmount(value)
      if (balanceDetail < MAX_GAS_ESTIMATE) {
        toast(<NotEnoughGasToast mainCurrency={MAIN_CURRENCY?.symbol} />, {
          toastId: 'not-enough-main-currency',
          autoClose: 10000,
          closeOnClick: false,
        })
      }
    }
    setMaxClicked(true)
  }

  const handleInputChange = (e) => {
    if (handleChangeAmount) {
      handleChangeAmount(e.target.value)
    }
    if (maxClicked === true) {
      setMaxClicked(false)
    }
  }
  return (
    <RowInput focus={focus} isDark={isDark}>
      <Div>
        <Row>
          <RowFixed style={{ marginRight: 'auto' }}>
            <Label
              size={12}
              weight={800}
              opacity={0.5}
              text={text}
              translateId={translateId}
            />
          </RowFixed>
          <RowFixed style={{ flexWrap: 'wrap' }}>
            {showRight &&
              (account ? (
                <BalanceContainer balance={balance ? balance.toString() : ''}>
                  {showBalance && (
                    <>
                      {currency && (
                        <>
                          {showMax && !maxClicked ? (
                            <RowFixed>
                              <MaxButton onClick={handleMaxClick} data-testid="max">
                                <FormattedMessage
                                  id="swap.card.token.title.balance.max"
                                  defaultMessage="MAX"
                                />
                              </MaxButton>
                            </RowFixed>
                          ) : (
                            ''
                          )}
                          <Label
                            size={13}
                            weight={800}
                            opacity={0.5}
                            text={`Balance: `}
                            style={{ margin: '0 3px 0 2px' }}
                            translateId="swap.card.token.title.balance"
                          />
                        </>
                      )}
                      <Label
                        size={13}
                        weight={800}
                        opacity={0.5}
                        text={
                          text === 'Price'
                            ? ''
                            : ` ${
                                currency
                                  ? `${
                                      balance > 0
                                        ? balance
                                        : lessThan
                                        ? '<0.0001'
                                        : 0
                                    }`
                                  : '-'
                              }`
                        }
                      />
                    </>
                  )}
                </BalanceContainer>
              ) : (
                <Label
                  size={12}
                  weight={800}
                  opacity={0.5}
                  text="Wallet Disconnected"
                  translateId="common.wallet.disconnect.msg"
                />
              ))}
          </RowFixed>
        </Row>
        <Row style={{ marginTop: '12px' }}>
          {text === 'Price' ? (
            <Text
              onClick={toggle}
              isSelector={isSelector}
              data-testid={testToggleId}
            >
              vUNIT {isSelector ? <ArrowDown /> : ''}
            </Text>
          ) : (
            <>
              <Input
                value={amount}
                type="number"
                onChange={handleInputChange}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                testId={testId}
              />
              {currency?.logoURI && (
                <TokenImage
                  height={24}
                  width={24}
                  src={currency?.logoURI}
                  letter={currency?.symbol && currency?.symbol?.[0]}
                />
              )}
              <Spacer size="sm" />
              <Text
                onClick={toggle}
                data-testid={testToggleId}
                token={currency?.symbol}
                isSelector={isSelector}
              >
                {currency ? (
                  `${currency.symbol}${!showBalance ? ' LP Token' : ''}`
                ) : (
                  <FormattedMessage
                    id="swap.card.token.selection.title"
                    defaultMessage="Choose Token"
                  />
                )}{' '}
                {isSelector ? <ArrowDown /> : ''}
              </Text>
            </>
          )}
        </Row>
      </Div>
    </RowInput>
  )
}

export default PriceInput

const RowInput = styled.div`
  box-shadow: ${(props) =>
    props.focus ? props.theme.shadows.insetFocus : props.theme.shadows.inset};
  border-radius: 23px;
  min-height: 98px;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: ${(props) => props.isDark && 'rgba(0, 0, 0, 0.08)'};
  width: 100%;
  ${(props) =>
    props.focus
      ? props.theme.inputFocusBorder
      : `border: 1.5px solid ${props.theme.color.background.main}`};
`
const Div = styled.div`
  margin: 20px 30px;
  width: 100%;
`
export const MaxButton = styled.div`
  width: auto;
  padding: 0 3px;
  height: 14px;
  margin-left: 5px;
  margin-right: 2px;
  border-radius: 7.5px;
  border: solid 1px ${({ theme }) => theme.color.font.green};
  font-size: 10px;
  font-weight: 800;
  color: ${({ theme }) => theme.color.font.green};
  transition: background-color 0.3s ease-out;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #fff;
    background: ${({ theme }) => theme.color.font.green};
  }
`

const Text = styled.span`
  font-size: 13px;
  font-weight: 800;
  color: ${({ theme }) => theme.color.secondary.main};
  cursor: ${(props) => props.isSelector && 'pointer'};
  text-align: left;
`
const ArrowDown = styled(DownArrow)`
  height: 9px;
  opacity: 0.32;
  color: ${({ theme }) => theme.color.secondary.main};
`
export const BalanceContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: ${(props) => props?.balance?.length > 14 && 'min-content'};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: ${(props) => props?.balance?.length > 7 && 'min-content'};
  `}
`
