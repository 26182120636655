import {
  SAVE_TOKEN,
  SAVE_WALLET,
  SAVE_BALANCE,
  SAVE_PRIVATE_KEY,
  CLEAR_USER_INFO,
  SAVE_ACTIVE_POOLS,
  CLEAR_ACTIVE_POOLS,
  SAVE_RPC_ERROR,
  SAVE_PENDING_SWITCH,
} from 'state/users/constants'

const initialState = {
  tokens: {},
  wallet: '',
  balances: {},
  chainId: 42,
  privateKey: undefined,
  activePools: {},
  rpcError: false,
  pendingSwitch: false,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_TOKEN:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          [action.payload.chainId]: {
            ...state.tokens[action.payload.chainId],
            [action.payload.address ?? action.payload.symbol]: action.payload,
          },
        },
      }
    case SAVE_ACTIVE_POOLS:
      return {
        ...state,
        activePools: {
          ...state.activePools,
          [action.payload.chainId]: action.payload.pools,
        },
      }
    case CLEAR_ACTIVE_POOLS:
      return {
        ...state,
        activePools: {
          ...state.activePools,
          [action.payload.chainId]: [],
        },
      }
    case SAVE_WALLET:
      return {
        ...state,
        wallet: action.payload,
      }
    case SAVE_RPC_ERROR:
      return {
        ...state,
        rpcError: action.payload,
      }
    case SAVE_PENDING_SWITCH:
      return {
        ...state,
        pendingSwitch: action.payload,
      }
    case SAVE_BALANCE:
      if (action.payload?.chainId) {
        return {
          ...state,
          balances: {
            ...state.balances,
            [action.payload.chainId]: {
              ...(state.balances?.[action.payload.chainId] ?? {}),
              [action.payload.address]: action.payload.amount,
            },
          },
        }
      }
      return state
    case SAVE_PRIVATE_KEY:
      return {
        ...state,
        chainId: action.payload.chainId,
        privateKey: action.payload.privateKey,
      }
    case CLEAR_USER_INFO:
      return initialState
    default:
      return state
  }
}

export default userReducer
