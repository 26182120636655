import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { FormattedMessage } from 'react-intl'
import { CaretDown } from '@styled-icons/ionicons-sharp/CaretDown'

import {
  EXPLORER_FILTER,
  EXPLORER_FILTER_ETH,
  isProdEnv,
  SWAP_ALLOW_CHAINS,
  weiToEth,
} from 'monox/constants'
import useWallet from 'hooks/useWallet'
import config from 'monox/config'
import useTokenBalance from 'hooks/useTokenBalance'

import Label from 'components/Label'
import Spacer from 'components/Spacer'
import { RowBetween } from 'components/Row'

// import home from 'assets/img/home.svg'
import pool from 'assets/img/pool.svg'
import swap from 'assets/img/swapNav.svg'
import farm from 'assets/img/farm.svg'
import future from 'assets/img/future.svg'
import explore from 'assets/img/explore-outlined.svg'
import vault from 'assets/img/vault.svg'
import bond from 'assets/img/bond.svg'

const NavButtons = ({ mobileLayout }) => {
  const isDark = useSelector(({ application }) => application.isDark)
  const networkName = useSelector(({ network }) => network?.NAME)?.toLowerCase()

  const [isExploreOpen, setIsExploreOpen] = useState(false)
  const [isBondOpen, setIsBondOpen] = useState(false)
  const ref = useRef(null)
  const bondRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const { chainId } = useWallet()
  const networkId = useSelector(({ network }) => network.id)
  const currency = config?.[chainId]?.VCASH
  const { balance } = useTokenBalance(currency)

  const isSupported = useMemo(() => {
    return SWAP_ALLOW_CHAINS.includes(chainId || networkId)
  }, [chainId, networkId])

  useEffect(() => {
    if (!mobileLayout) {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsExploreOpen(false)
        }
        if (bondRef.current && !bondRef.current.contains(event.target)) {
          setIsBondOpen(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [ref, bondRef])

  const SafeLink = ({ children, to }) => {
    if (history.location.pathname === to?.replace(/\?.*$/, '')) {
      return <Link className="active">{children}</Link>
    }
    return (
      <StyledLink variant="nav" to={to} exact>
        {children}
      </StyledLink>
    )
  }

  return (
    <StyledNavButtons isDark={isDark}>
      {/* <SafeLink to="/home" exact>
        <div>
          <img src={home} alt="home" />
          <Span isDark={isDark}>
            <FormattedMessage
              id="common.menu.bar_item.1"
              defaultMessage="Home"
            />
          </Span>
        </div>
      </SafeLink> */}
      {isSupported && (
        <>
          <SafeLink variant="nav" to={`/swap?network=${networkName}`}>
            <Div>
              <img src={swap} alt="swap" />
              <Span isDark={isDark}>
                <FormattedMessage
                  id="common.menu.bar_item.2"
                  defaultMessage="Swap"
                />
              </Span>
            </Div>
          </SafeLink>
          <SafeLink variant="nav" to={`/pool?network=${networkName}`} exact>
            <Div>
              <img src={pool} alt="pool" />
              <Span isDark={isDark}>
                <FormattedMessage
                  id="common.menu.bar_item.3"
                  defaultMessage="Pool"
                />
              </Span>
            </Div>
          </SafeLink>
          <SafeLink variant="nav" to={`/farm?network=${networkName}`} exact>
            <Div>
              <img src={farm} alt="farm" />
              <Span isDark={isDark}>
                <FormattedMessage
                  id="common.menu.bar_item.4"
                  defaultMessage="Farm"
                />
              </Span>
            </Div>
          </SafeLink>
        </>
      )}
      {/* <SafeLink variant="nav" to={`/futures?network=${networkName}`} exact>
        <Div>
          <img src={future} alt="future" />
          <Span isDark={isDark}>
            <FormattedMessage
              id="common.menu.bar_item.5"
              defaultMessage="Futures"
            />
          </Span>
        </Div>
      </SafeLink> */}
      {/* <SafeLink variant="nav" to={`/vault?network=${networkName}`} exact>
        <Div>
          <img src={vault} alt="vault" width="17" />
          <Span isDark={isDark}>
            <FormattedMessage id="common.menu.bar_item.7" defaultMessage="Vault" />
          </Span>
        </Div>
      </SafeLink> */}
      <SafeLink variant="nav" to={`/bond?network=${networkName}`} exact>
        <Div>
          <img src={bond} alt="bond" className="nav-img" width="16" />
          <Span isDark={isDark}>
            <FormattedMessage id="common.menu.bar_item.8" defaultMessage="Bond" />
          </Span>
        </Div>
      </SafeLink>
      {isSupported && (
        <DropDown ref={ref}>
          <Div onClick={() => setIsExploreOpen(!isExploreOpen)}>
            <img src={explore} alt="explore" className="nav-img" />
            <Span isDark={isDark}>
              <FormattedMessage
                id="common.menu.bar_item.6"
                defaultMessage="Explore"
              />
            </Span>
            <ArrowDown />
          </Div>
          {isExploreOpen && (
            <DropdownItems isDark={isDark} width="296">
              <Spacer />
              {Object.entries(
                networkName == 'ethereum'
                  ? EXPLORER_FILTER_ETH
                  : EXPLORER_FILTER ?? {}
              ).map(([key, item], index) => (
                <Item
                  key={index}
                  onClick={() => {
                    if (history.location.pathname !== `/explore/${key}`) {
                      history.push(`/explore/${key}?network=${networkName}`)
                    }
                    setIsExploreOpen(false)
                  }}
                >
                  <img src={item?.image?.url} width="34" height="39" />
                  <Spacer size="sm" />
                  <div>
                    <Label
                      text={item?.name}
                      size="16"
                      weight="800"
                      style={{ marginBottom: '3px' }}
                      translateId={`home.cta.${key}.title`}
                    />
                    <Label
                      text={item?.short_description?.[0]?.text}
                      size="12"
                      weight="800"
                      opacity="0.5"
                      style={{ lineHeight: 1.08 }}
                      translateId={`home.cta.${key}.description`}
                    />
                  </div>
                </Item>
              ))}
              <Spacer />
            </DropdownItems>
          )}
        </DropDown>
      )}
    </StyledNavButtons>
  )
}

const StyledNavButtons = styled.div`
  display: flex;
  margin-left: auto;
  height: 100%;
  width: 'auto';

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-evenly;
    justify-self: center;
    padding:0 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: ${(props) =>
      props.isDark ? '#052730' : props.theme.color.background.main};
    box-shadow: ${({ theme }) => theme.shadows.topbar}
  `};
`

const Span = styled.span`
  white-space: nowrap;
  color: ${(props) => (props.isDark ? '#ffffff' : props.theme.color.secondary.main)};
  font-size: 14px;
  font-weight: bold;
  @media (max-width: 1250px) {
    display: none;
  }
`

const StyledLink = styled(NavLink)`
  cursor: pointer;
  padding: 2px 15px;
  @media (max-width: 1600px) {
    padding: 2px 10px;
  }
  @media (max-width: 1400px) {
    padding: 2px 8px;
  }
  @media (max-width: 1300px) {
    padding: 5px;
  }
  @media (max-width: 1250px) {
    padding: 2px 14px;
  }
  @media (max-width: 1150px) {
    padding: 2px 11px;
  }
  @media (max-width: 1000px) {
    padding: 2px 9px;
  }
  text-decoration: none;
  border-bottom: 4px solid transparent;
  transition: color 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease,
    border-color 0.2s ease, transform 0.2s ease;
  & img {
    display: none;
    @media (max-width: 1250px) {
      display: flex;
    }
  }
  :hover {
    border-bottom: ${({ theme }) => `4px solid ${theme.color.font.green}`};
    span {
      transform: translateY(-2px);
      color: ${({ theme }) => theme.color.font.green};
    }
    svg {
      transform: translateY(-2px);
    }
  }
`
const Link = styled.div`
  cursor: pointer;
  padding: 2px 15px;
  @media (max-width: 1600px) {
    padding: 2px 10px;
  }
  @media (max-width: 1400px) {
    padding: 2px 8px;
  }
  @media (max-width: 1300px) {
    padding: 5px;
  }
  @media (max-width: 1250px) {
    padding: 2px 14px;
  }
  @media (max-width: 1150px) {
    padding: 2px 11px;
  }
  @media (max-width: 1000px) {
    padding: 2px 9px;
  }
  text-decoration: none;
  border-bottom: 4px solid transparent;
  transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease,
    box-shadow 0.2s ease, transform 0.2s ease;
  & img {
    display: none;
    @media (max-width: 1250px) {
      display: flex;
    }
  }
  div {
    height: 100%;
    display: flex;
    align-items: center;
  }
`
const DropDown = styled.div`
  cursor: pointer;
  position: relative;
  padding: 2px 15px;
  @media (max-width: 1600px) {
    padding: 2px 10px;
  }
  @media (max-width: 1400px) {
    padding: 2px 8px;
  }
  @media (max-width: 1300px) {
    padding: 5px;
  }
  @media (max-width: 1250px) {
    padding: 2px 14px;
  }
  @media (max-width: 1150px) {
    padding: 2px 11px;
  }
  @media (max-width: 1000px) {
    padding: 2px 9px;
  }
  text-decoration: none;
  border-bottom: 4px solid transparent;
  transition: color 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease,
    border-color 0.2s ease, transform 0.2s ease;
  .nav-img {
    display: none;
    @media (max-width: 1250px) {
      display: flex;
    }
  }
  :hover {
    border-bottom: ${({ theme }) => `4px solid ${theme.color.font.green}`};
    span {
      transform: translateY(-2px);
      color: ${({ theme }) => theme.color.font.green};
    }
    svg {
      transform: translateY(-2px);
    }
  }
`
const DropdownItems = styled.div`
  min-width: ${({ width }) => width}px;
  border-radius: 10px;
  position: absolute;
  z-index: 7;
  right: 0;
  top: 80px;
  ${(props) => props.isDark && props.theme.darkMode}
  backdrop-filter: blur(50px);
  box-shadow: 0 25px 40px 0 rgba(14, 19, 41, 0.2);
  background-color: ${({ isDark, theme }) =>
    isDark ? 'rgba(255, 255, 255, 0.1)' : theme.color.background.paleGrey};
`
const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 17px 24px;
  :hover {
    background-color: rgba(45, 196, 143, 0.05);
    border-left: 4px solid ${(props) => props.theme.color.border.green};
  }
`
const Div = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`
const ArrowDown = styled(CaretDown)`
  height: 13px;
  width: 13px;
  margin-left: 4px;
  opacity: 0.6;
  color: ${({ theme }) => theme.color.font.secondary};
`
const StyledRowBetween = styled(RowBetween)`
  align-items: baseline;
  cursor: pointer;
  padding: 8px 0;
  :hover {
    padding-right: 5px;
    background-color: rgba(45, 196, 143, 0.05);
    border-left: 4px solid ${(props) => props.theme.color.border.green};
  }
`

export default NavButtons
