import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import SwapIcon from 'assets/img/swap.svg'
import SwapHoverIcon from 'assets/img/swap-hover.svg'
import SwapDarkIcon from 'assets/img/swap-darkmode.svg'
import SettingIcon from 'assets/img/settings.svg'
import SettingHoverIcon from 'assets/img/settings-hover.svg'
import SettingDarkIcon from 'assets/img/settings-darkmode.svg'
import ChevronIcon from 'assets/img/chevron.svg'
import ChevronHoverIcon from 'assets/img/chevron-hover.svg'
import ChevronDarkIcon from 'assets/img/chevron-darkmode.svg'
import SwapDoubleIcon from 'assets/img/chart-swap.svg'
import SwapDoubleDarkIcon from 'assets/img/dark-swap-double.svg'
import ChevronRightIcon from 'assets/img/chevron-right-icon.svg'
import ChevronDarkRightIcon from 'assets/img/dark-chevron-right-icon.svg'

const IconContainer = ({
  icon,
  iconHover,
  onClick,
  style = {},
  innerStyle = {},
  isSwapped,
  disabled = false,
  active = false,
  testId = 'icon-container',
}) => {
  const [hovered, setHovered] = useState(false)
  const isDark = useSelector(({ application }) => application.isDark)

  return (
    <MainIconContainer style={style}>
      <StyledIconContainer
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(active ? true : false)}
        active={active}
        hovered={hovered}
      >
        <StyledIconInnerContainer
          onClick={onClick}
          disabled={disabled}
          active={active}
          hovered={hovered}
          data-testid={testId}
          isDark={isDark}
          style={innerStyle}
        >
          {hovered ? (
            <Icon src={iconHover} isSwapped={isSwapped} />
          ) : (
            <Icon src={icon} isSwapped={isSwapped} />
          )}
        </StyledIconInnerContainer>
      </StyledIconContainer>
    </MainIconContainer>
  )
}

export const StyledSwapIcon = ({
  isSwapped,
  onClick,
  style,
  disabled,
  testId = 'swap-icon-container',
}) => {
  const isDark = useSelector(({ application }) => application.isDark)
  return (
    <IconContainer
      style={style}
      icon={isDark ? SwapDarkIcon : SwapIcon}
      iconHover={isDark ? SwapDarkIcon : SwapHoverIcon}
      isSwapped={isSwapped}
      onClick={onClick}
      disabled={disabled}
      testId={testId}
    />
  )
}

export const StyledSettingIcon = ({ style, onClick, disabled, active, testId }) => {
  const isDark = useSelector(({ application }) => application.isDark)
  return (
    <IconContainer
      icon={isDark ? SettingDarkIcon : SettingIcon}
      iconHover={SettingHoverIcon}
      style={style}
      onClick={onClick}
      disabled={disabled}
      active={active}
      testId={testId}
    />
  )
}

export const StyledSwapDoubleIcon = ({
  style,
  onClick,
  disabled,
  active,
  testId,
}) => {
  const isDark = useSelector(({ application }) => application.isDark)

  return (
    <IconContainer
      icon={isDark ? SwapDoubleDarkIcon : SwapDoubleIcon}
      iconHover={isDark ? SwapDoubleDarkIcon : SwapDoubleIcon}
      style={style}
      onClick={onClick}
      disabled={disabled}
      active={active}
      testId={testId}
    />
  )
}

export const StyledChevronIcon = ({ style, isSwapped, disabled, innerStyle }) => {
  const isDark = useSelector(({ application }) => application.isDark)

  return (
    <IconContainer
      style={style}
      isSwapped={isSwapped}
      iconHover={ChevronHoverIcon}
      icon={isDark ? ChevronDarkIcon : ChevronIcon}
      disabled={disabled}
      innerStyle={innerStyle}
    />
  )
}

export const StyledRightChevronIcon = ({
  style,
  isSwapped,
  disabled,
  innerStyle,
}) => {
  const isDark = useSelector(({ application }) => application.isDark)

  return (
    <IconContainer
      style={style}
      isSwapped={isSwapped}
      iconHover={isDark ? ChevronDarkRightIcon : ChevronRightIcon}
      icon={isDark ? ChevronDarkRightIcon : ChevronRightIcon}
      disabled={disabled}
      innerStyle={innerStyle}
    />
  )
}

const MainIconContainer = styled.div`
  display: flex;
`

const StyledIconContainer = styled.div`
  box-shadow: ${(props) =>
    props.active || props.hovered ? '' : `${({ theme }) => theme.shadows.switch}`};
  border-radius: 100%;
  width: fit-content;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const StyledIconInnerContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  outline: none;
  background-color: ${({ theme }) => theme.color.background.main};
  border: none;
  box-shadow: ${(props) =>
    props.hovered
      ? `${props.theme.shadows.switch}`
      : !props.isDark
      ? props.active
        ? `${props.theme.shadows.inset}`
        : '0 0 4px 0 #c5cad4, inset 0 0 4px 0 #ffffff'
      : ''};
  transition: 0.3s ease-out;
  cursor: pointer;
`
const Icon = styled.img`
  transition: 0.5s;
`
