import React, { Suspense, useEffect, lazy, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom'
import useWallet from 'hooks/useWallet'
import { ToastContainer } from 'react-toastify'
import axios from 'axios'
import styled from 'styled-components'

import { RESTRICTED_COUNTRIES, BOND_LIST } from 'monox/config'
import { isProdEnv, SWAP_ALLOW_CHAINS } from 'monox/constants'
import { setBondPurchase } from 'state/bond/actions'

import { languages } from 'i18n'
import Spacer from 'components/Spacer'
import TopBar from 'components/TopBar'
import Footer from 'components/Footer'
import Sidebar from 'components/Sidebar'

import TransactionUpdater from 'TransactionUpdater'
import RestrictedRegion from 'RestrictedRegion'

const Swapper = lazy(() => import('views/Swapper'))
const Explore = lazy(() => import('views/Explore'))
const Pool = lazy(() => import('views/Pool'))
const CreatePool = lazy(() => import('views/CreatePool'))
const AddLiquidity = lazy(() => import('views/AddLiquidity'))
const RemoveLiquidity = lazy(() => import('views/RemoveLiquidity'))
const Home = lazy(() => import('views/Home'))
const StakingBoard = lazy(() => import('views/StakingBoard'))
const Analytics = lazy(() => import('views/Analytics'))
const Future = lazy(() => import('views/Future'))
const FutureList = lazy(() => import('views/Future/components/FutureTable'))
const Vault = lazy(() => import('views/Vault'))
const Bond = lazy(() => import('views/Bond'))
const StakeBond = lazy(() => import('views/Bond/StakeBond'))
const BondPurchase = lazy(() => import('views/Bond/BondPurchase'))

const PublicRoutes = ({ setIsDark, lang, setLang, setTheme }) => {
  const history = useHistory()
  const { chainId } = useWallet()
  const dispatch = useDispatch()
  const isDark = useSelector(({ application }) => application.isDark)
  // const theme = useSelector(({ application }) => application.theme)
  const networkId = useSelector(({ network }) => network.id)
  const key = process.env.REACT_APP_IP_CONFIG_KEY
  const location = useLocation()
  const [showSidebar, setShowSidebar] = useState(false)
  const [userLocationInfo, setUserLocationInfo] = useState(null)
  const isSidebar = !location?.pathname?.includes('/home')
  const bondPurchase =
    useSelector(({ bond }) => bond?.bondPurchase)?.[networkId] || {}

  const isSupported = useMemo(() => {
    return SWAP_ALLOW_CHAINS.includes(chainId || networkId)
  }, [chainId, networkId])

  useEffect(() => {
    if (!key) return
    axios
      .get(`https://pro.ip-api.com/json/?key=${key}`)
      .then((res) => {
        res?.data && setUserLocationInfo(res?.data)
        if (RESTRICTED_COUNTRIES.includes(res?.data?.countryCode)) {
          history.push('/restricted-region')
        } else if (history?.location?.pathname === '/restricted-region') {
          history.replace('/home')
        }
      })
      .catch((err) => {
        throw err
      })
  }, [key, history])

  useEffect(() => {
    if (!userLocationInfo || !isProdEnv) return
    axios({
      method: 'post',
      url: 'https://api-rc.monox.finance/ip/api/json',
      data: userLocationInfo,
      responseType: 'json',
    }).catch((err) => {
      throw err
    })
  }, [userLocationInfo])

  useEffect(() => {
    const { token1 } = bondPurchase
    if (token1?.chainId !== networkId) {
      dispatch(
        setBondPurchase({
          chainId: networkId,
          bondPurchase: BOND_LIST[networkId]?.[0],
        })
      )
    }
  }, [networkId])

  return (
    <AppContainer>
      {isSidebar || showSidebar ? (
        <Sidebar
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          isSidebar={isSidebar}
        />
      ) : (
        ''
      )}
      <Body isSidebar={isSidebar} showSidebar={showSidebar}>
        {!isSidebar && showSidebar && <HighlightSidebar />}
        <TopBar
          setIsDark={setIsDark}
          setTheme={setTheme}
          languages={languages}
          setLang={setLang}
          lang={lang}
          isSidebar={isSidebar}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
        />
        <BodyContainer>
          <Suspense fallback={<></>}>
            {isSupported ? (
              <Switch>
                <Route exact path={'/explore/:filter'} component={Explore} />
                {/* 
                <Route exact path={'/explore/:filter'} component={Explore} />
                <Route exact path="/futures" component={Future} />
                <Route exact path="/futures-list" component={FutureList} />
                <Route exact path="/vault" component={Vault} />
              */}
                <Route exact path="/home" component={Home} />
                <Route exact path="/farm" component={StakingBoard} />
                <Route exact path="/bond" component={Bond} />
                <Route exact path="/bond/stake" component={StakeBond} />
                <Route
                  exact
                  path={['/bond/purchase', '/bond/purchase/:name']}
                  component={BondPurchase}
                />
                <Route
                  exact
                  path={[
                    '/swap/:address1/:address2',
                    '/swap/:address1',
                    '/swap/:uni',
                    '/swap',
                  ]}
                >
                  <Swapper />
                </Route>
                <Route exact path={'/pool'}>
                  <Pool />
                </Route>
                <Route
                  exact
                  path={['/create', '/create/:currencyIdA']}
                  component={CreatePool}
                />
                <Route
                  exact
                  path={['/add', '/add/:currencyIdA']}
                  component={AddLiquidity}
                />
                <Route exact path="/remove/:address" component={RemoveLiquidity} />
                <Route exact path={'/analytics/:address'} component={Analytics} />
                <Route
                  exact
                  path="/restricted-region"
                  component={RestrictedRegion}
                />
                <Redirect to={'/home'} />
              </Switch>
            ) : (
              <Switch>
                <Route exact path="/home" component={Home} />
                <Route exact path="/bond" component={Bond} />
                <Route exact path="/bond/stake" component={StakeBond} />
                <Route
                  exact
                  path={['/bond/purchase', '/bond/purchase/:name']}
                  component={BondPurchase}
                />
                <Route
                  exact
                  path="/restricted-region"
                  component={RestrictedRegion}
                />
                <Route exact path="/swap">
                  <Redirect to="/bond" />
                </Route>
                <Redirect to={'/home'} />
              </Switch>
            )}
            <Spacer />
          </Suspense>
        </BodyContainer>
        <Footer isDark={isDark} />
        <ToastContainer
          draggable={false}
          className="styled-toast"
          bodyClassName={isDark ? 'styled-toast-dark-body' : 'styled-toast-body'}
          hideProgressBar
          closeOnClick
          closeButton={false}
          autoClose={4000}
        />
        <TransactionUpdater />
      </Body>
    </AppContainer>
  )
}

export default PublicRoutes

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  flex-direction: column;
  `}
`
const HighlightSidebar = styled.div`
  background: ${(props) => props.theme.color.background.sidebarFocus};
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
`

const Body = styled.div`
  position: relative;
  margin-left: auto;
  height: 100%;
  ${(props) =>
    props.isSidebar
      ? `width: calc(100% - 286px);
  @media (max-width: 1200px) {
    width: calc(100% - 215px);
  }`
      : 'width: 100%;'}
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width:100%;
  `}
  ${({ isSidebar, showSidebar, theme }) =>
    !isSidebar && showSidebar && `background:${theme.color.background.sidebarFocus}`}
`

const BodyContainer = styled.div`
  flex-grow: 1;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding-bottom: 7rem`}
`
