import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import MobileDetect from 'mobile-detect'

import useModal from 'hooks/useModal'
import { networks, DEFAULT_NETWORK_ID, supportedChainIds } from 'monox/constants'

import Label from 'components/Label'
import NetworkListModal from 'components/NetworkListModal'

const NetworkButton = ({ isNetwork = true }) => {
  const md = new MobileDetect(window.navigator.userAgent)
  const [handleNetworkClick] = useModal(<NetworkListModal />)
  const name = useSelector(({ network }) => network?.LONGNAME || network?.NAME)
  const networkId = useSelector(({ network }) => network.id)
  const isDark = useSelector(({ application }) => application.isDark)

  const id = supportedChainIds.includes(networkId) ? networkId : DEFAULT_NETWORK_ID

  return (
    <Div>
      <Button
        onClick={!md.mobile() ? handleNetworkClick : () => {}}
        data-testid="change-network"
        color={isNetwork ? networks?.[id]?.color : 'rgba(239, 61, 98, 0.15)'}
        isNetwork={isNetwork}
      >
        {isNetwork && <Image src={networks?.[id]?.image} alt="Network Button" />}
        <Label
          text={isNetwork ? name : 'Wrong Network'}
          weight="800"
          size="13"
          color={
            isNetwork ? (isDark ? 'white' : networks?.[id]?.fontColor) : '#ef3d62'
          }
          style={{ marginLeft: '8px', marginTop: '2px' }}
          translateId={isNetwork ? null : 'common.menu.bar_item.network'}
        />
      </Button>
    </Div>
  )
}

export default NetworkButton

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px 4px 4px;
  border-radius: 15.5px;
  background-color: ${(props) => props.color};
  cursor: pointer;
  min-height: ${({ isNetwork }) => !isNetwork && '20px'};
  text-align: center;
`
const Div = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Image = styled.img`
  height: 23px;
  width: 23px;
  border-radius: 100%;
`
