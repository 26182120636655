import {
  SET_SWAP_TOLERANCE,
  SET_ADD_LIQUIDITY_TOLERANCE,
  SET_REMOVE_LIQUIDITY_TOLERANCE,
  SET_SWAP_DEADLINE,
  SET_ADD_LIQUIDITY_DEADLINE,
  SET_REMOVE_LIQUIDITY_DEADLINE,
  SET_PROMO_MODAL,
  SET_PROMO_LENGTH,
} from 'state/settings/constants'

export function setSwapTolerance(payload) {
  return { type: SET_SWAP_TOLERANCE, payload }
}

export function setAddLiquidityTolerance(payload) {
  return { type: SET_ADD_LIQUIDITY_TOLERANCE, payload }
}

export function setRemoveLiquidityTolerance(payload) {
  return { type: SET_REMOVE_LIQUIDITY_TOLERANCE, payload }
}

export function setSwapDeadline(payload) {
  return { type: SET_SWAP_DEADLINE, payload }
}

export function setAddLiquidityDeadline(payload) {
  return { type: SET_ADD_LIQUIDITY_DEADLINE, payload }
}

export function setRemoveLiquidityDeadline(payload) {
  return { type: SET_REMOVE_LIQUIDITY_DEADLINE, payload }
}

export function setPromoModal(payload) {
  return { type: SET_PROMO_MODAL, payload }
}

export function setPromoLength(payload) {
  return { type: SET_PROMO_LENGTH, payload }
}
