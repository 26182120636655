import { get, post } from 'api/api'
import { networks } from 'monox/constants'

export async function getPoolList(chainId) {
  const { network } = networks?.[chainId]
  return get(`${network}/pools`, chainId)
}

export async function getSymbolList(chainId) {
  const { network } = networks?.[chainId]
  return get(`${network}/metadata`, chainId)
}

export async function getPool(chainId, id) {
  const { network } = networks?.[chainId]
  return get(`${network}/pools/${id}`, chainId)
}

export async function getPrices(chainId, params) {
  const { network } = networks?.[chainId]
  return get(`${network}/pools/historicals/prices?${params}`, chainId)
}

export async function getContributedPoolList(chainId, account) {
  const { network } = networks?.[chainId]
  return get(`${network}/pools/active/${account}`, chainId)
}

export async function getStakingTrans(chainId, params) {
  const { network } = networks?.[chainId]
  return get(`${network}/${params}/events`, chainId)
}

export async function getAllTokenMetrics(chainId) {
  const { network } = networks?.[chainId]
  return get(`${network}/explore/all_metrics`, chainId)
}

export async function getAllTokenEvents(chainId, pid) {
  const { network } = networks?.[chainId]
  return get(`/${network}/events/alldata${pid ? `?pid=eq.${pid}` : ''}`, chainId)
}

export async function getAddLiquidityEvents(chainId, pid) {
  const { network } = networks?.[chainId]
  return get(`/${network}/addliquidity${pid ? `?pid=eq.${pid}` : ''}`, chainId)
}

export async function getRemoveLiquidityEvents(chainId, pid) {
  const { network } = networks?.[chainId]
  return get(`/${network}/removeliquidity${pid ? `?pid=eq.${pid}` : ''}`, chainId)
}

export async function getPoolAPYData(chainId, pid) {
  const { network } = networks?.[chainId]
  return get(
    `/${network}/pool_apy_data${pid ? `?pid=eq.${pid}&order=id.desc&limit=1` : ''}`,
    chainId
  )
}

export async function getTokenInEvents(chainId, tokenAddress) {
  const { network } = networks?.[chainId]
  return get(
    `/${network}/swap${tokenAddress ? `?tokenIn=eq.${tokenAddress}` : ''}`,
    chainId
  )
}

export async function getTokenOutEvents(chainId, tokenAddress) {
  const { network } = networks?.[chainId]
  return get(
    `/${network}/swap${tokenAddress ? `?tokenOut=eq.${tokenAddress}` : ''}`,
    chainId
  )
}

export async function getTokenSwapEvents(chainId, tokenAddress) {
  const { network } = networks?.[chainId]
  return get(
    `/${network}/swap${
      tokenAddress
        ? `?or=(tokenIn.eq.${tokenAddress},tokenOut.eq.${tokenAddress})&order=timestamp.desc`
        : ''
    }`,
    chainId
  )
}

export async function getSwapEvents(chainId, tokenAddressIn, tokenAddressOut) {
  const { network } = networks?.[chainId]
  return get(
    `/${network}/swap${
      tokenAddressIn && tokenAddressOut
        ? `?or=(tokenIn.eq.${tokenAddressIn},tokenOut.eq.${tokenAddressOut})`
        : ''
    }`,
    chainId
  )
}

export async function getVolume(chainId, tokenAddress, time, start) {
  const { network } = networks?.[chainId]
  return get(
    `/${network}/transactions/${tokenAddress}/time/${time}/start/${start}`,
    chainId
  )
}

export async function getLiquidity(chainId, tokenAddress, time, start) {
  const { network } = networks?.[chainId]
  return get(
    `/${network}/liquidity/${tokenAddress}/time/${time}/start/${start}`,
    chainId
  )
}

export async function getBiggestGains(chainId, type, time) {
  const { network } = networks?.[chainId]
  return get(
    `/${network}/price/tokens/gains_losses/${type}/percentage/time/${time}`,
    chainId
  )
}

export async function getPriceChanges(chainId, tokenAddress) {
  const { network } = networks?.[chainId]
  return get(
    `/${network}/price/${tokenAddress}/gainsandlosses/percentage/time/24H`,
    chainId
  )
}

export async function getRecentlyAdded(chainId, start, order) {
  const { network } = networks?.[chainId]
  return get(
    `/${network}/creation/date/tokens/start/${start}/ordering/${order}`,
    chainId
  )
}

export async function getHighVolume(chainId, start) {
  const { network } = networks?.[chainId]
  return get(`/${network}/volume/tokens/start/${start}`, chainId)
}

export async function getAnalysisData(chainId, tokenAddress, startTime) {
  const { network } = networks?.[chainId]
  return get(`/${network}/data/token/${tokenAddress}/start/${startTime}`, chainId)
}

export async function getAllTokenLiquidity(chainId) {
  const { network } = networks?.[chainId]
  return get(`/${network}/metrics`, chainId)
}

export async function getCoinById(chainId, id) {
  const { network } = networks?.[chainId]
  return get(`/${network}/tweet_coingecko/coin/ethereum/contract/${id}`, chainId)
}

export async function getTweetList(channelName, size, chainId) {
  return get(`/twitter/tweets/tweet/channel/${channelName}/size/${size}`, chainId)
}

export async function getFuturesList(chainId) {
  const { network } = networks?.[chainId]
  return get(`/${network}/futures`, chainId)
}

export async function getVCASHPrice(chainId) {
  const { network } = networks?.[chainId]
  return get(`/${network}/vcash/usdc/price`, chainId)
}

export async function getBondData(chainId) {
  const { network } = networks?.[chainId]
  return get(`/${network}/bond/data`, chainId)
}

export async function getBondMetrics(chainId) {
  const { network } = networks?.[chainId]
  return get(`/${network}/bond/metrics`, chainId)
}

export async function getBondPurchase(chainId) {
  const { network } = networks?.[chainId]
  return get(`/${network}/bond/purchase`, chainId)
}

export async function getBondClaim(chainId) {
  const { network } = networks?.[chainId]
  return get(`/${network}/bond/claim`, chainId)
}

export async function setReferral(data, chainId) {
  return post(`/referral`, data, chainId)
}

export async function getReferralId(data, chainId) {
  return post(`/referral/set/user`, data, chainId)
}

export async function checkIfHackedUser(account, chainId, query) {
  const { network } = networks?.[chainId]
  // return(get('kovan/before_hack?provider=0x564b1a055d9caaaff7435dce6b5f6e522b27de7d&network=eq.polygon'))
  return get(
    `/${network}/before_hack?provider=${account}&network=eq.${query}`,
    chainId
  )
}

export async function checkIfUserConsented(account, chainId) {
  const { network } = networks?.[chainId]
  return get(`/${network}/before_hack_user_consent?provider=eq.${account}`, chainId)
}

export async function consentSignature(data, chainId) {
  const { network } = networks?.[chainId]
  return post(`/${network}/consent/signature`, data, chainId)
}

export async function getBondSale(chainId) {
  const { network } = networks?.[chainId]
  return get(`/${network}/all/bonds/purchased/sum`, chainId)
}
