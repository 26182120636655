import React from 'react'
import styled from 'styled-components'

import Label from 'components/Label'

const ModalTitle = ({ text, height }) => (
  <StyledModalTitle height={height}>
    <Label
      text={text}
      size="18"
      weight="800"
      translateId="modal.common.wallet.connect.title"
    />
  </StyledModalTitle>
)

const StyledModalTitle = styled.div`
  align-items: center;
  display: flex;
  height: ${(props) => (props.height ? props.height : props.theme.topBarSize)}px;
  justify-content: ${(props) => (props.justify ? props.justify : 'center')};
`

export default ModalTitle
