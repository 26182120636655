import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Label from 'components/Label'
import { Row } from 'components/Row'

const SidebarItem = ({
  img,
  activeImg,
  text,
  to,
  dropdown,
  link,
  isOpen,
  setIsOpen = () => {},
  children,
  width,
  translateId,
}) => {
  const history = useHistory()
  const isDark = useSelector(({ application }) => application.isDark)
  const location = useLocation()
  const [active, setActive] = useState('')

  useEffect(() => {
    setActive(location.pathname.includes(to?.replace(/\?.*$/, '')))
    setIsOpen(false)
  }, [location])

  const Item = ({ translateId }) => (
    <>
      <img
        src={isDark ? activeImg : img}
        style={{
          opacity: isDark && (active ? 1 : 0.65),
          marginRight: '24px',
        }}
        width={width ?? 17}
        alt="activeImg"
      />
      <Label
        text={text}
        size="15"
        weight="bold"
        opacity={isDark ? (active ? 1 : 0.65) : 1}
        style={{ textDecoration: active && 'underline' }}
        translateId={translateId}
      />
    </>
  )

  return (
    <>
      {dropdown ? (
        <DropDown>
          <div
            onClick={() => {
              if (link) {
                history.push(to)
              }
              setTimeout(() => setIsOpen(!isOpen), 10)
            }}
          >
            <StyledRow>
              <Item translateId={translateId} />
            </StyledRow>
          </div>
          {isOpen && <DropdownItems isDark={isDark}>{children}</DropdownItems>}
        </DropDown>
      ) : (
        <StyledRow onClick={() => history.push(to)}>
          <Item translateId={translateId} />
        </StyledRow>
      )}
    </>
  )
}

export default SidebarItem

const DropDown = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const DropdownItems = styled.div`
  width: 100%;
  border-radius: 10px;
  background: transparent;
`
const StyledRow = styled(Row)`
  padding-left: 36px;
  cursor: pointer;
  height: 52px;
  display: flex;
  align-items: center;
`
